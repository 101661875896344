<ion-header id="modal-header">
  <ion-toolbar>
    <div class="title-content">
      <ion-title> Terms and Conditions</ion-title>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="modal-content legal">
    <div class="outlined-box">By using our Services, you agree to the following terms and conditions. It's important to understand what you can expect from us, and what we expect from you. Please read them carefully before continuing.</div>
    <div class="section">
      <h3 class="title">Introduction</h3>

      <p>By using the Penmate website (Penmateapp.com - the "Website") or any Penmate Mobile Application (the "App"), you agree to be bound by these Terms of Service (this "Agreement"), whether or not you register as a user ("Member") on the Website or Mobile Application. If you wish to become a Member and make use of the Website or App (our "Services"), please read these Terms of Service. If you object to anything in this Agreement please do not use the Website, App, or any Service provided by Penmate. The Terms of Service are subject to change by Penmate at any time, effective upon posting on the Penmate website or Mobile App, and your use of the Service after such posting will constitute acceptance by you of such changes.</p>
      <h3 class="title">Eligibility</h3>
      <p>
        You must be at least eighteen (18) years of age to register as a Member of the Website or App and use any of our Services. Membership in our Services is void where prohibited. By using our Services, you represent and warrant that you have the right, authority, and capacity to enter into this Agreement and to abide by all the terms and conditions of this Agreement. Those under the age of 18 may use the Website only under the authorization and supervision of a registered Member.
      </p>
      <h3 class="title">Proper Use</h3>
      <p>
        You understand and agree that you are responsible for your own communications, and for any consequences of your use of our Services. You agree that you will use our Services in compliance with all applicable local, state, and federal laws, rules and regulations, as well as the policies, procedures, and restrictions imposed by the applicable Correctional Facilities. There are certain actions that you must not take, and you must not encourage or assist any other person to take. These prohibited actions include but are not limited to:
      </p>
      <ul>
        <li>using our Services to transmit any content that is defamatory, unlawful, harassing, abusive, fraudulent, obscene, or is otherwise objectionable as reasonably determined by Penmate or any Correctional Facility;</li>
        <li>transmitting any content that infringes upon another party's intellectual property rights or other proprietary, contractual, or fiduciary rights or obligations;</li>
        <li>using our Services for any fraudulent or inappropriate purpose; </li>
        <li>acting in any way that violates our policies or those of the applicable Correctional Facility.</li>
      </ul>
      <p>
        If you take any of prohibited action, it may result in non-delivery of your message and/or attachment, immediate termination of your account without refund, and may subject you to state and federal penalties and other legal consequences. We reserve the right to investigate your use of our Services in order to determine whether a violation of your  Agreement occurred or to comply with any applicable law, regulation, legal process, or governmental request.
      </p>
      <h3 class="title">Message Content and Review</h3>
      <p class="bold">
        You understand and agree that each message including any attached media you send will be reviewed, monitored, and preserved by us and the applicable correctional facility, and that you waive any privacy or other confidentiality rights you may have in the contents of your messages and any attached media. <span class="underline">If you are an attorney, you agree you will not use our Services to transmit any confidential or privileged communications (on behalf of yourself and your clients) and you waive any claim against us for violation of the attorney-client privilege. </span>
      </p>
      <h3 class="title">Message Delivery</h3>
      <p >
        Your messages may be delayed as their content may be pending review and approval by the correctional facility or by us. You understand that any correctional facility may refuse to deliver a message or attachment to an incarcerated individual. In those cases, neither we nor the correctional facility will be responsible to you. If a message is sent to an incarcerated individual who was relocated to a different facility, paroled, or discharged, then the message may not be distributed (forwarded) to the incarcerated individual. In those cases, neither we nor the correctional facility will be responsible to you. <span class="underline">You understand and agree that in some circumstances when electronic delivery is not available your message will be delivered via USPS Postal Mail in accordance with the requirements of the correctional facility.</span>
      </p>
      <h3 class="title">Payments and Refunds</h3>
      <ol>
        <li>
          <span>General</span> - You will be billed for use of our Services by making a payment each time you send a message or, in cases where a subscription is created, you will be billed monthly  an online account (your "Billing Account") for use of the Service. You agree to pay Penmate all charges at the prices then in effect for any use of the Service and you authorize Penmate to charge your chosen payment method (your "Payment Method") for the Service. You agree to make payment using that selected Payment Method. Penmate reserves the right to correct any errors or mistakes that it makes even if it has already requested or received payment.
        </li>
        <li>
          <span>Payment Method</span> - The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer, or other provider of your chosen Payment Method (the "Payment Method Provider"). If Pemate does not receive payment from your Payment Method Provider, you agree to pay all amounts due upon demand.
        </li>
        <li>
          <span>Refund Policies</span> - Penmate reserves the right to not issue refunds for messages once processing has started or message delivery has been completed. If there is a delivery or shipping issue, we'll always do our best to make sure your message is resent, your account is credited or upon evaluation a refund is provided. In all cases, refunds will be evaluated on a case by case basis. In cases where credits are purchased, Penmate will not issue refunds after the purchase of credits or for fees charged for service credits that you later decide not to use. Service Credits apply to use of any of our Services including but not limited to messaging or photo delivery.
        </li>
      </ol>

      
      <h3 class="title">Termination of Services</h3>

      <p>
        At any time and for any reason, we may terminate the Services, terminate this  Agreement, or terminate your account. Upon termination, your account will be disabled and you may not be granted access to your account or other content contained in your account. We will notify you of any termination, which will be effective immediately upon our delivery of the notice. If you have any balance remaining on your account, you will not be entitled to a refund.
      </p>
      <h3 class="title">Limitation of our Liability</h3>
      <p class="bold underline">IMPORTANT: THIS PROVISION LIMITS YOUR RIGHTS TO RECOVERY. PLEASE REVIEW IT THOROUGHLY. </p>
      <p><strong> <span class="underline">No Consequential or Indirect Damages.</span>IN NO EVENT SHALL WE OR ANY OF OUR REPRESENTATIVES BE LIABLE TO YOU FOR CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR ENHANCED DAMAGES ARISING OUT OF, RELATING TO, OR IN CONNECTION WITH ANY BREACH OF THIS AGREEMENT OR USE OF OUR SERVICES, REGARDLESS OF (I) WHETHER SUCH DAMAGES WERE FORESEEABLE, (II) WHETHER OR NOT YOU WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND (III) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE CLAIM IS BASED.</strong></p>
      <p>
        <strong><span class="underline">Maximum Liability.</span>IN NO EVENT SHALL OUR LIABILITY ARISING OUT OF OR RELATED TO OUR SERVICES OR THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EXCEED THE TOTAL AMOUNTS YOU PAID TO US PURSUANT TO THIS AGREEMENT.</strong>
      </p>
      <p>
        <strong><span class="underline">Exceptions.</span>THE LIMITATIONS SET FORTH IN THIS LIMITATION OF LIABILITY SECTION SHALL NOT APPLY TO: (I) PERSONAL INJURY OR DEATH OR DAMAGE TO ANY REAL OR TANGIBLE PERSONAL PROPERTY CAUSED BY OUR GROSSLY NEGLIGENT ACTS OR OMISSIONS; OR (II) THE GROSSLY NEGLIGENT ACTS OR OMISSIONS OF US IN PERFORMING OUR OBLIGATIONS UNDER THIS AGREEMENT.</strong>
      </p>
      <h3 class="title">Indemnification</h3>
      <p>
        As stated previously in this Agreement, we are not liable for damages, direct or consequential, resulting from your use of our Services. It is important for you to understand that by accessing and using any of our Services, you agree to indemnify (pay for our losses), defend and hold us, and our affiliates and licensors harmless from and against all losses, expenses, damages and costs, including reasonable attorney's fees, arising out of or relating to any third-party claim concerning: (a) your use of any of our Services (including any activities under your account); (b) violation of your Customer Agreement; or (c) your violation of any law or the rights of another person. You will reimburse us for reasonable attorney's fees, as well as our employees and contractor's time and materials spent responding to any third-party subpoena or other compulsory legal order or process associated with third party claims described above at our then-current hourly rates. These obligations will survive any termination of your relationship with us or your use of our Services. <br/><br/>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS PENMATE, ITS AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS FROM AND AGAINST AND ALL THIRD PARTY CLAIMS, ACTIONS, SUITS, OR PROCEEDINGS, AS WELL AS ANY AND ALL LOSSES, LIABILITIES, DAMAGES, COSTS, AND EXPENSES (INCLUDING REASONABLE ATTORNEY'S FEES) ARISING OUT OF OR ACCRUING FROM (A) YOUR BREACH OF THIS CUSTOMER AGREEMENT; AND (B) YOUR USE OF THE TERMINAL AND THE OTHER PENMATE SERVICES.
      </p>
    </div>

  </div>
</ion-content>
<ion-footer class="footer">
  <div class="modal-actions">
    <ion-button icon-left class="cancel-button" (click)="onCancel()">
      <div class="button-inner">
        <ion-icon name="arrow-back"></ion-icon>
        Cancel
      </div>
    </ion-button>
    <ion-button icon-right class="accept-button" (click)="onAccept()">
      <div class="button-inner">
        Accept & Continue
        <ion-icon slot="end" name="ios-arrow-dropright-circle"></ion-icon>
      </div>
    </ion-button>
  </div>
</ion-footer>