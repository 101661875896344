<ion-content id="my-penmates">
  <div class="page-content" scrollSpyElement="list-scroller">
    <ion-list *ngIf="penmates && penmates.length > 0">
      <div class="list-scroller" style="">
        <div
          #pmContact
          class="pm-contact"
          *ngFor="let penmate of penmates; let i = index"
          [attr.data-index]="i"
        >
          <div>
            <div class="penmate-avatar">
              <div class="icon-circle">
                <div class="inner-circle">
                  <span *ngIf="penmate.initials && !penmate.image" class="initials"
                    >{{penmate.initials}}</span
                  >
                  <img *ngIf="penmate.image" [src]="penmate.image" />
                  <ion-icon
                    *ngIf="!penmate.initials && !penmate.image"
                    name="ios-person"
                  ></ion-icon>
                </div>
                <ion-button ion-button class="remove-button" (click)="onRemovePenmate(penmate)">
                  <ion-icon name="close"></ion-icon>
                </ion-button>
              </div>
            </div>
            <div class="contact-card">
              <div class="contact-details">
                <h3 class="name">{{ penmate.first_name }} {{penmate.last_name}}</h3>
                <h5 class="facility">{{ penmate.facility }}</h5>
                <div *ngIf="requiresIdVerification(penmate)" (click)="onMyAccount()" class="message-button verification-required-alert">
                  <ion-icon name="ios-alert"></ion-icon>
                  <p>Verification Required</p>
                  <ion-icon name="ios-arrow-forward"></ion-icon>
                </div>
              </div>
            </div>
            <div class="penmate-profile">
              <div id="map-container-{{penmate.id}}" class="map-container" [pmContactMap]="penmate">
                <div class="map-cover"></div>
                <div class="map"></div>
              </div>

              <div id="send-message-actions">
                <div id="send-message-title">
                  <p class="title">
                    Connect with {{ penmate.first_name }} {{penmate.last_name}}
                  </p>
                  <p class="subtitle">
                    Send letters, photos, and postcards.
                  </p>
                </div>
              </div>
              <a
                *ngIf="getPendingDraft(penmate); let draft"
                (click)="onEditDraft(draft, penmate)"
                class="message-button message-action edit-draft"
              >
                <ion-grid>
                  <ion-row>
                    <ion-col size="2">
                      <ion-icon name="mail-open" size="large"></ion-icon>
                    </ion-col>
                    <ion-col size="9">
                      <p class="msg-body" *ngIf="draft.message.length > 0">
                        <span>
                          {{ (draft.message.length > 50) ? (draft.message |
                          slice:0:50):(draft.message) }}...</span
                        >
                      </p>
                      <p class="msg-body" *ngIf="draft.message.length == 0">
                        <span> Finish saved message.</span>
                      </p>
                    </ion-col>
                    <ion-col size="1">
                      <ion-icon name="ios-arrow-forward" size="large"></ion-icon>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </a>
              <!--<a
                (click)="onExploreMedia(penmate)"
                class="message-button message-action send-message"
                *ngIf="isNative"
              >
                <ion-grid>
                  <ion-row>
                    <ion-col size="2">
                      <ion-icon name="md-globe" size="large"></ion-icon>
                    </ion-col>
                    <ion-col size="9">
                      <p>Explore what's popular</p>
                    </ion-col>
                    <ion-col size="1">
                      <ion-icon name="ios-arrow-forward" size="large"></ion-icon>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </a>-->
              <a
                (click)="onSendEMessage(penmate)"
                class="message-button message-action send-message e-message"
              >
                <ion-grid>
                  <ion-row>
                    <ion-col size="2">
                      <ion-icon name="ios-chatboxes" size="large"></ion-icon>
                    </ion-col>
                    <ion-col size="9">
                      <p>Send an E-Message</p>
                    </ion-col>
                    <ion-col size="1">
                      <ion-icon name="ios-arrow-forward" size="large"></ion-icon>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </a>
              <a
                (click)="onCreateMessage(penmate)"
                class="message-button message-action send-message"
              >
                <ion-grid>
                  <ion-row>
                    <ion-col size="2">
                      <ion-icon name="ios-create" size="large"></ion-icon>
                    </ion-col>
                    <ion-col size="9">
                      <p>Send Letter & Photos</p>
                    </ion-col>
                    <ion-col size="1">
                      <ion-icon name="ios-arrow-forward" size="large"></ion-icon>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </a>
              <a
                (click)="onCreatePostcard(penmate)"
                class="message-button message-action send-postcard"
              >
                <ion-grid>
                  <ion-row>
                    <ion-col size="2">
                      <ion-icon name="ios-image" size="large"></ion-icon>
                    </ion-col>
                    <ion-col size="9">
                      <p>Create a Postcard</p>
                    </ion-col>
                    <ion-col size="1">
                      <ion-icon name="ios-arrow-forward" size="large"></ion-icon>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </a>

              <a
                (click)="onViewMessages(penmate)"
                class="message-button message-action view-messages"
              >
                <ion-grid>
                  <ion-row>
                    <ion-col size="2">
                      <ion-icon name="paper-plane" size="large"></ion-icon>
                    </ion-col>
                    <ion-col size="9">
                      <p>View Messages</p>
                    </ion-col>
                    <ion-col size="1">
                      <ion-icon name="ios-arrow-forward" size="large"></ion-icon>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ion-list>
  </div>
</ion-content>
<ion-footer>
  <app-footer-nav></app-footer-nav>
</ion-footer>
