import { Component } from '@angular/core';

import { PenmateProfile } from '../penmate-profile/penmate-profile';
import { MyPenmatesPage } from '../my-penmates/my-penmates';

@Component({
  templateUrl: 'tabs.html'
})
export class TabsPage {
  // this tells the tabs component which Pages
  // should be each tab's root Page
  tab2Root: any = PenmateProfile;
  tab1Root: MyPenmatesPage;

  constructor() {
  }
}
