import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/browser";

declare var Penmate;

Sentry.init({
  dsn: "https://fdc4fe1e8730ab594461022e84715f22@o4506431460081664.ingest.us.sentry.io/4508145318952960",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // enabled: Penmate.env && !/development/i.test(Penmate.env.name),

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", 'https://pmate.ngrok.io', 'https://railway.penmateapp.com',  /^https:\/\/m.penmateapp\.com/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
