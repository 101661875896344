import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AppState } from './index';
import { Store } from '@ngrx/store';
import { Http, Headers, RequestOptions, Jsonp } from '@angular/http';
import { get } from 'lodash';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import 'rxjs/add/operator/catch';
import { ApiGateway } from './api-gateway.service';
import { PhotoPickerPhoto } from '../models';

import { serializeError } from 'serialize-error';
import ErrorHandler from '../../lib/error-handler'

declare var stripe: any;
declare var Penmate: any;

@Injectable({ providedIn: 'root' })
export class LetterService {
  constructor(
    public apiClient: ApiGateway,
    private http: Http,
    private store: Store<AppState>,
    private jsonp: Jsonp,
  ) {}

  uploadPhotos(photos) {
    return Observable.create(observer => {
      Promise.all(photos.map((p) => this.compressPhoto(p))).then((compressed) => {
        const files = compressed.filter(Boolean);
        const uploaded = files.map(i => this.uploadPhoto(i));
        Observable.forkJoin(uploaded).subscribe(
          images => {
            observer.next(images);
            observer.complete();
          },
          err => {
            observer.error(err);
            observer.complete();
            ErrorHandler.captureException(err);
            ErrorHandler.captureMessage('Error uploading photos', { error: serializeError(err) });
          },
        );
      })

    });
  }

  compressPhoto(photoFile) {
    return new Promise((resolve, reject)=> {
      try {
        const img = new Image()
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          
          const originalWidth = img.width;
          const originalHeight = img.height;
          const canvasWidth = originalWidth * 0.9;
          const canvasHeight = originalHeight * 0.9;
          
          canvas.width = canvasWidth;
          canvas.height = canvasHeight;
          context.drawImage(
            img,
            0,
            0,
            originalWidth * 0.9,
            originalHeight * 0.9
          );
          
          // reducing the quality of the image
          canvas.toBlob(
            (blob) => {
              if (blob) {
                // showing the compressed image
                return resolve(new File([blob], photoFile.name, {type:"image/png"}))
              }
              resolve(photoFile)
            },
            "image/jpeg",
            0.9
          );
        }
    
        img.onerror = (e) => {
          reject(null)
        }
        img.src = photoFile.base64Data || photoFile.url

      } catch (e) {
        resolve(photoFile)
      }
    })
  }

  uploadPhoto(photoFile) {
    return new Observable(observer => {
      this.apiClient
        .get(`${Penmate.env.apiUrl}/upload-photo-url`, { filename: `${photoFile.name}_${new Date().getTime()}` })
        .subscribe(({ url, key } = {}) => {
          if (url && key) {
            return this.http.put(url, photoFile).subscribe(
              () => {
                this.apiClient
                  .get(`${Penmate.env.apiUrl}/upload-photo-complete`, { key })
                  .subscribe(photo => {
                    observer.next(photo);
                    observer.complete();
                  });
              },
              err => {
                observer.error(err);
                observer.complete();
                ErrorHandler.captureException(err);
                ErrorHandler.captureMessage('Error uploading photo', { error: serializeError(err) });
              },
            );
          }

          ErrorHandler.captureMessage('Error uploading photo/ no s3 url or key');
          observer.error({ error: true });
          return observer.complete();
        });
    });
  }

  getPhoto(path) {
    return this.apiClient.get(`${Penmate.env.apiUrl}/photo`, { path });
  }

  getUserInstagramRecent(accessToken, nextUrl = null) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers });
    let url = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,timestamp&access_token=${accessToken}`;
    if (!!nextUrl) {
      url = nextUrl;
    }
    return new Observable(observer => {
      if (!accessToken) {
        return observer.complete();
      }
      this.http
        .get(url, options)
        .pipe(map(res => res.json()))
        .subscribe(
          (json: any) => {
            const { data, paging } = json;
            const photos = data.map(p => {
              const is_carousel = !!get(p, 'carousel_media');
              const is_video = !!get(p, 'video_versions');
              const formattedPhoto: PhotoPickerPhoto = {
                provider: 'instagram',
                url: p.media_url,
                created_at: moment(p.timestamp).format(),
                id: p.id,
                details: p,
                is_carousel: !!p.media_type.match(/carousel/i),
                is_video: !!p.media_type.match(/video/i),
              };
              return formattedPhoto;
            });
            observer.next({ photos, pagination: paging });
            observer.complete();
          },
          () => {
            observer.complete();
          },
        );
    });
  }

  fetchDrafts() {
    return this.apiClient.get(`${Penmate.env.apiUrl}/drafts`, {}).pipe(map(json => json.drafts)); // copy data top-level
  }

  getLetter(id) {
    return this.apiClient
      .get(`${Penmate.env.apiUrl}/letters/${id}`, {})
      .pipe(map(json => Object.assign({}, json.letter.data, json.letter))); // copy data top-level
  }

  getLetterWithPenmate(id) {
    return this.apiClient
      .get(`${Penmate.env.apiUrl}/letters/${id}`, {})
      .pipe(map(json => ({
        ...json,
        letter: Object.assign({}, json.letter.data, json.letter),
      }))); 
  }

  saveDraft(letter) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/drafts`, {}, { letter })
      .pipe(map(json => Object.assign({}, json.letter.data, json.letter)));
  }

  saveDraftText(id, message) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/drafts/${id}/message`, {}, { message })
      .pipe(map(json => Object.assign({}, json.letter.data, json.letter)));
  }

  confirmMailingAddress(id, confirmed_address) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/letters/${id}/confirm`, {}, { confirmed_address })
      .pipe(map(json => Object.assign({}, json.letter.data, json.letter)));
  }

  getS3Url(assetPath) {
    return `${Penmate.env.apiUrl}/photo?path=${assetPath}`;
  }

  getStripeToken(element, additionalInfo = {}) {
    return new Observable(observer => {
      stripe
        .createToken(element, additionalInfo)
        .then(result => {
          if (result.token) {
            observer.next(result.token);
            observer.complete();
          } else {
            observer.error(result.error);
            observer.complete();
          }
        })
        .catch(result => {
          observer.error(result.error);
          observer.complete();
        });
    });
  }

  addCoupon(code) {
    return this.apiClient
      .get(`${Penmate.env.apiUrl}/coupon-check`, { code })
      .pipe(map(json => json))
      .catch(err => {
        return Observable.of(err);
      });
  }

  getPromos() {
    return this.apiClient
      .get(`${Penmate.env.apiUrl}/promos`, {})
      .pipe(map(json => json))
      .catch(err => {
        return Observable.of(err);
      });
  }

  sendLetter(id: number) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/letters/${id}/send`, {}, {})
      .pipe(map(json => json.letter))
      .catch(res => {
        ErrorHandler.captureMessage('Send Letter Error', { letter_id: id, err: res.error });
        return Observable.of({ error: res.error });
      });
  }

  confirmPayment(id, paymentIntent) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/letters/${id}/send/confirm-payment`, {}, paymentIntent)
      .pipe(map(json => json.letter))
      .catch(res => {
        ErrorHandler.captureMessage('Confirm Payment Error', { letter_id: id, err: res.error });
        return Observable.of({ error: res.error });
      });
  };

  validateAddress(id: number, addressData: Record<string, any>) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/letters/${id}/validate-address`, {}, { address: addressData })
      .pipe(map(json => json))
      .catch(res => {
        ErrorHandler.captureMessage('Address Validation Error', { letter_id: id, err: res.error , addressData });
        return Observable.of({ error: res.error });
      });
  }

  resendLetter(id: number) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/letters/${id}/resend`, {}, {})
      .pipe(map(json => json.letter))
      .catch(res => {
        ErrorHandler.captureMessage('Resend Letter Error', { letter_id: id, err: res.error });
        return Observable.of({ error: res.error });
      });
  }

  getPostcardThemes() {
    const s3Url = 'https://penmate-assets-dev.s3.amazonaws.com/postcard_designs';
    return Observable.create(observer => {
      return this.apiClient
        .get(`${Penmate.env.apiUrl}/postcards/themes`, {})
        .pipe(map(postcards => postcards))
        .catch(error => {
          ErrorHandler.captureMessage('Fetch postcard themes error', { error });
          observer.next({ error });
          return observer.complete();
        })
        .subscribe((postcards: []) => {
          observer.next({ postcards });
          return observer.complete();
        });
    });
  }

  getPendingEmails() {
    return this.apiClient
      .get(`${Penmate.env.apiUrl}/email-messages`, {})
      .pipe(map(json => json))
      .catch(err => {
        return Observable.of(err);
      });
  }

  updateEmailMessage(id, payload) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/email-messages/${id}`, {}, payload)
      .pipe(map(json => json))
      .catch(err => {
        return Observable.of(err);
      });
  }
}
