<ion-header id="modal-header">
  <ion-toolbar>
    <div class="title-content">
      <ion-buttons end>
        <ion-button ion-button icon-only (click)="onCancel()" class="close-button">
          <ion-icon name="close" class="close-icon"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="modal-content">
    <div class="cta">
      <div class="pm-logo"></div>
    </div>
    <p class="title-cta" *ngIf="!showSearchMessage">Create Letter</p>
    <p class="title-cta" *ngIf="showSearchMessage">Few more details...</p>

    <div *ngIf="!showSearchMessage">
      <p class="desc">
        Your e-mail letter has been added and is ready to go!
        <strong>Select your message below and send it off:</strong>
      </p>
      <ion-list>
        <ion-grid>
          <ion-row
            *ngFor="let message of emailMessages; let i = index;"
            class="message-item"
            (click)="onCreateLetter(message)"
          >
            <ion-col size="2" class="message-icon">
              <ion-icon
                name="ios-paper-plane"
                *ngIf="message.num_of_attachments == 0 || !message.num_of_attachments"
              ></ion-icon>
              <ion-icon name="ios-attach" *ngIf="message.num_of_attachments > 0"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <div class="email-message">
                <p class="time-ago">
                  {{message.created_at | amDateFormat:'LL' }}
                </p>
                <p class="msg-recipient" *ngIf="message.inmate">
                  <span class="label">To: </span> <span>{{ message.inmate.name }}</span>
                </p>
                <p class="msg-body" *ngIf="message.body">
                  <span>
                    {{ (message.body.length > 50) ? (message.body | slice:0:50)+'...':(message.body)
                    }}</span
                  >
                </p>
              </div>
            </ion-col>
            <ion-col size="2" class="arrow-col">
              <ion-icon name="arrow-forward"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-list>
    </div>
    <div *ngIf="showSearchMessage">
      <p class="desc">
        We need an updated address to send your letter. <strong>Tap continue below</strong>
        to enter an address or search facilities.
      </p>

      <ion-button icon-left (click)="onCancel()" class="search-button">
        Continue
      </ion-button>
    </div>
  </div>
</ion-content>
