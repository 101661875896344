<ion-header>
  <ion-toolbar>
    <div class="title-content">
      <ion-row>
        <ion-col size="1"></ion-col>
        <ion-col size="10">
          <ion-title>Manage Delivery </ion-title>
        </ion-col>
        <ion-col size="1">
          <ion-icon
            name="close"
            size="large"
            class="close-icon"
            (click)="onClose()"
          ></ion-icon>
        </ion-col>
      </ion-row>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <div id="map-container-{{penmate.id}}" class="map-container" [pmContactMap]="penmate">
      <div class="map-cover"></div>
      <div class="map"></div>
    </div>
    
    <div class="content-container">
      <div class="status-header">
         <p class="title">
            Your {{currentMessage.messageType}}
            <span *ngIf="currentMessage.status === 'processing'"> is processing  📤</span>
            <span *ngIf="currentMessage.status === 'ready_to_be_mailed'">is awaiting pickup 🚚</span>
            <span *ngIf="currentMessage.status ==='mailed' && !currentMessage.delivery_date && !currentMessage?.tracking_history.delivery_completed">is on the way 🚀</span>
            <span *ngIf="currentMessage.delivery_date || !!currentMessage?.tracking_history.delivery_completed">has been delivered 🙌</span>

            
        </p>
         <p class="subtitle"> 
           <span *ngIf="currentMessage.status === 'processing' && !currentMessage?.eMessage">  We're processing it and preparing it to ship.</span>
           <span *ngIf="currentMessage.status === 'processing' && currentMessage?.eMessage">  We're verifying your delivery details.</span>
           <span *ngIf="currentMessage.status === 'ready_to_be_mailed' && currentMessage.shipment_id"> It has been processed and is awaiting USPS pickup.</span>
           <span *ngIf="currentMessage.status === 'mailed' && !currentMessage.delivery_date && !currentMessage?.tracking_history.delivery_completed ">It's currently in transit and will deliver soon. You will be notified when delivery is completed. </span>
           <span *ngIf="currentMessage.status === 'mailed' && (currentMessage.delivery_date || !!currentMessage?.tracking_history.delivery_completed)">The facility mailroom will review and deliver your {{currentMessage.messageType}} to your loved one.</span>

        </p>
      </div>
      <p class="section-header"> Manage My Delivery</p>
      <div class="delivery-menu" *ngIf="currentMessage?.status">
        <div class="recipient-info" (click)="onShowEditAddress()">
          <ion-row>
            <ion-col size="2">
              <div class="menu-icon">
                <p class="to-from">TO:</p>
              </div>
            </ion-col>
            <ion-col size="10">
              <p class="name">{{currentMessage?.toAddress?.first_name}} {{currentMessage?.toAddress?.last_name}} <span  class="idNumber" *ngIf="currentMessage?.toAddress?.idNumber"> - {{currentMessage.toAddress.idNumber}} </span></p>
              <div class="address-edit">
                <div class="text">
                  <p class="location">{{currentMessage?.toAddress?.facility}}</p>
                </div>
                <div class="edit">
                  <div class="edit-action" *ngIf="currentMessage?.status === 'processing'">
                    Edit
                    <ion-icon
                      name="md-create"
                    ></ion-icon>
                  </div>
                </div>
              </div>
            </ion-col>

          </ion-row>
        </div>
        <div class="recipient-info"  (click)="onShowEditAddress()">
          <ion-row>
            <ion-col size="2">
              <div class="menu-icon">
                <p class="to-from">FROM:</p>
              </div>
            </ion-col>
            <ion-col size="10">
              <p class="name">{{currentMessage.fromAddress?.name}}</p>
              <div class="address-edit">
                <div class="text">
                  <p class="location email">{{currentMessage.fromAddress?.address_email}}
                  <p class="location">{{currentMessage.fromAddress?.address_line1}}</p>
                </div>
                <div class="edit">
                  <div class="edit-action" *ngIf="currentMessage?.status === 'processing'">
                    Edit
                    <ion-icon
                      name="md-create"
                    ></ion-icon>
                  </div>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </div>
        <div class="menu-actions">

          <ion-row *ngIf="canShowMenuAction('tracking')" (click)="onShowTrackingModal()">
            <ion-col size="2">
              <div class="menu-icon">
                <ion-icon
                  name="ios-mail-unread"
                  size="large"
              ></ion-icon>
              </div>
            </ion-col>
            <ion-col size="10">
              <div class="menu-action"> 
                <p class="title">Track my message</p>
                <p class="subtitle"> Track your USPS Delivery</p>
              </div>
            </ion-col>
          </ion-row>
          <ion-row  (click)="onShowHelpWidget('delivery-questions')">
            <ion-col size="2">
              <div class="menu-icon delivery-questions">
                <ion-icon
                  name="ios-help-circle"
                  size="large"
              ></ion-icon>
              </div>
            </ion-col>
            <ion-col size="10">
              <div class="menu-action"> 
                <p class="title">
                    <span>Delivery Questions/Help </span>
                  </p>
                <p class="subtitle"> 
                  <span>Get help with delivery questions</span>
                </p>
              </div>
            </ion-col>
          </ion-row>
          <ion-row  *ngIf="canShowMenuAction('resend')" (click)="onResend()">
            <ion-col size="2">
              <div class="menu-icon">
                <ion-icon
                  name="ios-refresh"
                  size="large"
              ></ion-icon>
              </div>
            </ion-col>
            <ion-col size="10">
              <div class="menu-action"> 
                <p class="title">Resend my {{currentMessage.messageType}}</p>
                <p class="subtitle"> Resend your message & photos</p>
              </div>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </div>
  </ion-list>
</ion-content>
