import {
  ModalController,
  NavController,
  LoadingController,
  NavParams,
  IonInfiniteScroll,
} from '@ionic/angular';
import { Component, ViewChild, ChangeDetectorRef, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Headers, RequestOptions, Jsonp } from '@angular/http';
import { chunk, get, filter, orderBy, debounce } from 'lodash';
import { map } from 'rxjs/operators';

import { AppState, LetterService, ApiGateway, SharedService } from '../services';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { PhotoPickerPhoto } from '../models';
import * as moment from 'moment';

@Component({
  templateUrl: 'ig-copy-link.html',
  styleUrls: ['./ig-copy-link.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IGCopyLinkTab implements OnInit {
  state$;
  photos = [];
  isPostcard: any;
  pagination: any;
  loadingSpinner;
  accessToken;

  onLinkChange = debounce(
    async evt => {
      const urlRegex = /(\b((https?):\/\/)?(www.instagram|instagram.com))\/[a-zA-Z]\/.+?(?=[^a-zA-Z\d\s.])/gi;
      const text = get(evt, 'target.value', '');
      if (text.match(urlRegex)) {
        const baseUrl = text.split('?')[0];
        const fetchUrl = `${baseUrl}?__a=1`;
        try {
          const response = await fetch(fetchUrl);
          const json = await response.json();
        } catch {}
      }
    },
    500,
    { leading: false, trailing: true },
  );

  ngOnInit() {
    setTimeout(() => {
      this.accessToken = get(window.history.state, 'accessToken');
      // const path = get(this.route.snapshot, 'parent.routeConfig.path');
      // this.isPostcard = path.match(/new-postcard/i);
      // if (this.accessToken) {
      //   this.getUserPhotos().subscribe();
      // }
    }, 150);
  }
}
