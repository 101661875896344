<ion-header>
    <ion-toolbar style="width: 100%;">
        <div class="title-content">
            <div class="postcard-designs-header">
                <div class="title">
                    <p>Select a Postcard</p>
                </div>
                <ion-icon name="ios-close" (click)="onDismiss()"></ion-icon>
            </div>
        </div>
    </ion-toolbar>
    <div></div>
</ion-header>

<ion-content>
    <ion-segment (ionChange)="onCategoryChange($event)" value="{{postcardCategory}}">
      <ion-segment-button value="popular">
        <ion-label><strong class="new-label"><span>New</span> Postcards</strong></ion-label>
      </ion-segment-button>
      <ion-segment-button value="all">
        <ion-label><strong>Browse All Postcards</strong></ion-label>
      </ion-segment-button>
    </ion-segment>
    <ion-list>
        <div *ngFor="let theme of postcardThemes; let i = index;">
            <ion-button ion-button class="postcard-theme-button" (click)="onSelectTheme(theme)">
                <ion-card>
                    <img src="{{theme.url}}" />
                </ion-card>
            </ion-button>
        </div>
    </ion-list>
</ion-content>

