<a
    [ngClass]="{'selected': photo.selected, 'video': photo.is_video}"
    (click)="onHandleClick($event, photo)"
    class="photo"
>
    <img src="{{photo.url}}" />
    <span class="selected-marker" *ngIf="showTogglePhoto && !removeOnly">
        <ion-icon *ngIf="photo.selected" name="checkmark" size="medium"> </ion-icon>
    </span>
    <span class="timestamp" *ngIf="showTimestamp">
        {{photo.created_at | amTimeAgo }}
    </span>
</a>
<ion-button
    icon-left
    class="select-photo-button"
    *ngIf="showTogglePhoto"
    [ngClass]="{'selected': photo.selected || removeOnly, 'video': photo.is_video}"
    (click)="onToggleSelected(photo)"
    [disabled]="photo.is_video"
>
    <span *ngIf="!photo.is_video">
        <ion-icon name="add" *ngIf="!photo.selected && !removeOnly"></ion-icon>
        <ion-icon name="remove" *ngIf="photo.selected || removeOnly"></ion-icon>
        {{ photo.selected || removeOnly ? 'Remove' : 'Add' }} photo
    </span>
    <span *ngIf="photo.is_video"> video </span>
</ion-button>
