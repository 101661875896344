<ion-header>
  <ion-toolbar>
    <div class="title-content">
      <ion-row>
        <ion-col size="2"></ion-col>
        <ion-col size="8">
          <ion-title> Tracking Info</ion-title>
        </ion-col>
        <ion-col size="2">
          <ion-icon
            name="close"
            size="large"
            class="close-icon"
            (click)="onCloseTracking()"
          ></ion-icon>
        </ion-col>
      </ion-row>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <p class="letter-detail">
      <span class="reg">You're sending </span>
      <span *ngIf="currentMessage.messageType !== 'postcard' ">1 Letter</span>
      <span *ngIf="currentMessage.messageType === 'postcard' ">1 Postcard</span>
      <span *ngIf="currentMessage.selectedPhotos.length > 0 && !isPostcard" class="photo-price">
        +
        <span
          >{{currentMessage.selectedPhotos.length}}
          <span *ngIf="currentMessage?.selectedPhotos?.length > 1"> photos </span>
          <span *ngIf="currentMessage?.selectedPhotos?.length == 1"> photo</span>
        </span>
      </span>
      to:
    </p>
    <div id="to-address">
      <p>
        <span class="label">To: </span>
        <span class="name">
          {{ currentMessage.recipient.name }}
        </span>
        <br />
        <span class="address-line facility"> {{ currentMessage.recipient.facility }} <br /> </span>
      </p>
    </div>

    <div class="container-sm theme1" id="tracking-history">
      <div id="content-block">
        <div id="tracker-success-content">
          <div class="">
            <div class="row">
              <div class="col-10 col-md-8 col-lg-6 mx-auto">
                <div class="tracker-progress-bar tracker-progress-bar-with-dots">
                  <div class="progress">
                    <div
                      class="progress-bar bg-secondary {{trackingHistory.status}}"
                      [ngClass]="{'bg-success': showTrackingState('delivered')}"
                    ></div>
                  </div>

                  <div
                    class="secondary"
                    [ngClass]="{'secondary': trackingHistory.status !== 'delivered', 'success':  trackingHistory.status == 'delivered' || trackingHistory.status == 'mailed' }"
                  >
                    <span class="dot state-0"></span>

                    <span
                      class="state-label state-0"
                      [ngClass]="{'current-state': trackingHistory.status === 'pre_transit', 'past-state': (showTrackingState('delivered') || showTrackingState('in_transit') || showTrackingState('out_for_delivery')) }"
                    >
                      Created
                    </span>

                    <span
                      class="dot state-1"
                      *ngIf="showTrackingState('in_transit') || showTrackingState('processing')"
                    ></span>

                    <span
                      class="state-label state-1"
                      [ngClass]="{'current-state': trackingHistory.status === 'in_transit', 'future-state': !showTrackingState('in_transit'), 'past-state': (showTrackingState('delivered') || showTrackingState('out_for_delivery'))}"
                    >
                      <span *ngIf="currentMessage?.shipment_method === 'parcel'"> In Transit </span>
                      <span *ngIf="currentMessage?.shipment_method !== 'parcel'">Processing</span>
                    </span>

                    <span class="dot state-2" *ngIf="showTrackingState('out_for_delivery')"></span>
                    <span
                      class="state-label state-2 future-state"
                      [ngClass]="{'current-state': trackingHistory.status === 'out_for_delivery' ||  trackingHistory.status === 'ready_to_be_mailed', 'future-state': !showTrackingState('out_for_delivery'),  'past-state': showTrackingState('delivered')}"
                    >
                      <span *ngIf="currentMessage?.shipment_method === 'parcel'"
                        >Out for Delivery</span
                      >
                      <span *ngIf="currentMessage?.shipment_method !== 'parcel'"
                        >Ready to Ship</span
                      >
                    </span>

                    <span class="dot state-3" *ngIf="showTrackingState('delivered')"></span>

                    <span
                      class="state-label state-3 future-state"
                      [ngClass]="{'current-state': trackingHistory.status === 'delivered' || trackingHistory.status === 'mailed','future-state': !showTrackingState('delivered') }"
                    >
                      <span *ngIf="currentMessage?.shipment_method === 'parcel'">Delivered</span>
                      <span *ngIf="currentMessage?.shipment_method !== 'parcel'">Mailed</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row tracker-top-level text-center mb-4">
              <div class="col-md">
                <div class="mb-2 carrier-img mt-4 mt-md-0 USPS"></div>
                <div
                  class="tracking-code text-secondary text-truncate font-weight-bold font-size-h5"
                  *ngIf="trackingHistory.tracking_code"
                >
                  {{trackingHistory.tracking_code}}
                </div>
              </div>
              <div class="col-md pb-3 pb-md-0 est-delivery-date-container">
                <div class="text-muted">
                  <span *ngIf="currentMessage?.shipment_method !== 'parcel'">
                    <span>Estimated Delivery Date</span>
                  </span>

                  <span *ngIf="currentMessage?.shipment_method === 'parcel'">
                    <span *ngIf="trackingHistory.status !== 'delivered'"
                      >Estimated Delivery Date</span
                    >
                    <span *ngIf="trackingHistory.status === 'delivered'">Delivered on</span>
                  </span>
                </div>
                <h1 class="est-delivery-date text-secondary delivery-window" *ngIf="trackingHistory.show_delivery_window">
                  {{deliveryWindowStart}} -  {{deliveryWindowEnd}}
                </h1>
                <h1 class="est-delivery-date text-secondary" *ngIf="!trackingHistory.show_delivery_window">{{estDeliveryDate}}</h1>
                <div class="text-muted">View available tracking events below</div>
              </div>
            </div>

            <div class="my-6" *ngIf="trackingHistory.tracking_details">
              <div class="row">
                <div class="col-md">
                  <ul class="tracking-details list-unstyled mb-4 border border-light">
                    <ng-container *ngFor="let trackingEvent of trackingHistory.tracking_details">
                      <li class="px-3 py-2 mb-3 font-weight-bold bg-gray-100 text-secondary">
                        {{ createMomentObj(trackingEvent.datetime).format('LL')}}
                      </li>

                      <li class="d-flex align-items-center mb-3">
                        <div class="tracking-details-time text-gray-300 font-size-h5 text-right">
                          {{ createMomentObj(trackingEvent.datetime).format('h:mm a')}}
                        </div>
                        <div
                          class="font-size-h3 px-3 px-lg-4"
                          [ngClass]="getTrackingEventIconClass(trackingEvent)"
                        ></div>
                        <div>
                          <div class="font-size-h5">
                            <strong>{{trackingEvent.message}}</strong>
                          </div>
                          <div
                            class="text-gray-400 tracking-location"
                            *ngIf="trackingEvent.tracking_location"
                          >
                            <span *ngIf="trackingEvent.tracking_location.city"
                              >{{trackingEvent.tracking_location.city}},{{' '}}
                            </span>
                            <span *ngIf="trackingEvent.tracking_location.state"
                              >{{trackingEvent.tracking_location.state}}
                            </span>

                            <span *ngIf="trackingEvent.tracking_location.zip"
                              >{{trackingEvent.tracking_location.zip}}</span
                            >
                          </div>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-list>
</ion-content>
