<ion-header>
  <ion-toolbar style="width: 100%;">
    <div class="title-content">
      <h3>Reply Address</h3>
    </div>
  </ion-toolbar>
</ion-header>
<div class="message-avatar">
  <div class="icon-circle">
    <div class="inner-circle">
      <ion-icon name="paper-plane" size="large"></ion-icon>
    </div>
  </div>
</div>
<ion-content #scrollContent>
  <div class="bg-notebook-paper">
    <form [formGroup]="returnAddressForm" (ngSubmit)="onSaveAndContinue()">
      <h2 class="form-title" *ngIf="!addressConfirmation">Where would you like your reply sent?</h2>
      <h2 class="form-title" *ngIf="addressConfirmation">Address Verification</h2>
      <p class="form-title" *ngIf="addressConfirmation">
        We were unable to verify your return address. Please confirm the details below: 
    </p>

      <div id="main-content" #mainContent>
        <ion-list class="address-fields" [class.submitted]="formSubmitted" no-lines>
          <span>
            <ion-item class="form-field name" [class.valid]="returnAddressForm.controls.firstName.valid">
              <ion-label position="stacked">First Name</ion-label>

              <ion-input
                autoComplete="nope"
                type="text"
                formControlName="firstName"
                autoComplete="nope"
              ></ion-input>
            </ion-item>
            <ion-item class="form-field name" [class.valid]="returnAddressForm.controls.lastName.valid">
              <ion-label position="stacked">Last Name</ion-label>

              <ion-input
                autoComplete="nope"
                type="text"
                formControlName="lastName"
                autoComplete="nope"
              ></ion-input>
            </ion-item>
            <ion-item
              class="form-field"
              [class.valid]="returnAddressForm.controls.address_line1.valid"
            >
              <ion-label position="stacked">Address</ion-label>
              <ion-input
                type="text"
                placeholder="123 Any St"
                formControlName="address_line1"
                autoComplete="nope"
              ></ion-input>
            </ion-item>
            <ion-item
              class="form-field"
              [class.valid]="returnAddressForm.controls.address_line2.valid"
            >
              <ion-label position="stacked">Apt/Unit</ion-label>
              <ion-input
                type="text"
                formControlName="address_line2"
                autoComplete="nope"
              ></ion-input>
            </ion-item>
            <ion-item
              class="form-field"
              [class.valid]="returnAddressForm.controls.address_city.valid"
            >
              <ion-label position="stacked">City</ion-label>
              <ion-input
                type="text"
                placeholder="Chicago"
                formControlName="address_city"
                autoComplete="nope"
              ></ion-input>
            </ion-item>
            <ion-item
              class="form-field"
              [class.valid]="returnAddressForm.controls.address_state.valid"
            >
              <ion-label position="stacked">State</ion-label>
              <ion-select formControlName="address_state" name="state">
                <ion-select-option *ngFor="let state of STATE_NAMES">{{state}}</ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item
              class="form-field"
              [class.valid]="returnAddressForm.controls.address_zip.valid"
            >
              <ion-label position="stacked">Zip</ion-label>
              <ion-input
                type="tel"
                formControlName="address_zip"
                autoComplete="nope"
                maxlength="9"
                (ionFocus)="onFocusZip()"
              ></ion-input>
            </ion-item>
          </span>
        </ion-list>
      </div>
    </form>
  </div>
</ion-content>

<ion-footer id="modal-footer">
  <div id="message-actions">
    <ion-grid>
      <ion-row>
        <ion-col col-6 class="back">
          <ion-button ion-button (click)="onCancel()" icon-left>
            <div class="button-inner">
              <ion-icon name="arrow-back"></ion-icon>
              Back
            </div>
          </ion-button>
        </ion-col>
        <ion-col col-6 class="continue">
          <ion-button ion-button icon-right (click)="onSaveAndContinue()">
            <div class="button-inner">
              Continue
              <ion-icon name="arrow-forward"></ion-icon>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
