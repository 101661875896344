import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NavController, ModalController, AlertController, Platform } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService, AppState, NativeClientService, EventService } from '../services';
import { UserActions } from '../actions';
import { AbstractControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AccountVerificationModal } from '../account-verification-modal/account-verification-modal';
import { LoadingModal } from '../loading-modal/loading-modal';
import { LoginPage } from '../login/login.page';
import { isUndefined, isNull, get, some, debounce } from 'lodash';

declare var Penmate;
declare var Beacon;

@Component({
  selector: 'pm-my-account',
  templateUrl: 'my-account.html',
  styleUrls: ['./my-account.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyAccountPage implements OnInit {
  user;
  userEdit = {};
  myAccountForm: FormGroup;
  pageModal;
  billingUrl;
  navigationState;
  beaconRetries = 0;
  beaconInitialized = false;
  skipConfirmation = false;
  sendingVerificationEmail = false;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    public eventService: EventService,
    private nativeService: NativeClientService,
    private store: Store<AppState>,
    private userActions: UserActions,
    private platform: Platform,
    private formBuilder: FormBuilder,
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.navigationState = navigation.extras.state;
    this.myAccountForm = this.formBuilder.group(
      {
        email: ['', Validators.required],
        password: ['', Validators.minLength(5)],
        passwordConfirmation: [''],
        returnAddressName: [''],
        returnAddressStreet: [''],
        returnAddressCity: [''],
        returnAddressState: [''],
        returnAddressZip: [''],
      },
      {
        validator: this.matchPasswordValidation.bind(this), // your validation method
      },
    );
  }

  ngOnInit() {
    this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
      if (isAuthenticated && user) {
        this.user = user;
        this.onResetForm();
        this.store.dispatch(this.userActions.loginUser(user));
        this.billingUrl = false;
        if (this.navigationState && this.navigationState.ratingFeedback) {
          this.onSubmitRatingFeedback(this.navigationState.ratingFeedback)
        }
        this.updateVerificationStatus();
        return;
      }
      this.navCtrl.navigateRoot('/login');
    });

    this.store
    .select(state => state.user)
    .subscribe(({ deviceInfo }) => {
      const metrics = get(deviceInfo, 'metrics');
      this.skipConfirmation = isUndefined(metrics) || isNull(metrics) ? false : metrics === false;
    });

  }

  ionViewDidEnter() {
    this.onInitBeacon(() => {
      this.beaconInitialized = true;
    })
  }

  onInitBeacon = async (cb) => {
    const initRes = Beacon('init', Penmate.env.helpScoutId);
    if (this.beaconRetries < 5 && initRes) {
      this.beaconRetries = this.beaconRetries + 1;
      return setTimeout(() => {
        this.onInitBeacon(cb);
      }, 500)
    }
    this.beaconInitialized = true;
    if (cb) { cb()}
  }

  onSubmitRatingFeedback = (ratingFeedback) => {
    Beacon('init', Penmate.env.helpScoutId);
    this.onInitBeacon(() => {
      Beacon('prefill', {
        name: this.user.name,
        email: this.user.email,
        subject: `Hi, I'm having trouble`,
        text: ratingFeedback.review,
      })
      Beacon('open');
      Beacon('navigate', '/ask/message/');
    })
  }

  onShowConfirmAccountModal = async () => {
    this.pageModal = await this.modalCtrl.create({ component: AccountVerificationModal });
    this.pageModal.onDidDismiss().then(() => {
      this.authService.authenticate().subscribe(({ user }) => {
        this.user = user;
        if (user.phone_number_verified) {
          this.store.dispatch(this.userActions.loginUser(user));
        }
      });
    });
    return this.pageModal.present();
  };

  onResetForm() {
    const returnAddress = get(this.user, 'addresses.returnAddress');
    this.myAccountForm.patchValue({ email: this.user.email, ...returnAddress });
  }

  matchPasswordValidation(AC: AbstractControl) {
    let password = AC.get('password').value;
    let confirmPassword = AC.get('passwordConfirmation').value;
    if (password != confirmPassword) {
      AC.get('passwordConfirmation').setErrors({ matchPassword: true });
    } else {
      AC.get('passwordConfirmation').setErrors(null);
    }
  }

  onEditAccount = async () => {
    if (!this.myAccountForm.valid) {
      return;
    }
    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: {
        message: 'Saving...',
      },
    });
    await loadingModal.present();
    this.authService.updateUser(this.myAccountForm.value).subscribe(
      async user => {
        await loadingModal.dismiss();
        return this.onDisplayAlert('Account updated.', 'Ok');
      },
      async errorMessage => {
        await loadingModal.dismiss();
        const { error } = errorMessage;
        let errorMsg = 'There was an error saving your changes.';
        if (/taken/.test(error)) {
          errorMsg = error;
        }
        this.onDisplayAlert(errorMsg, 'Ok.');
      },
    );
  };

  onCompleteVerification = debounce(async function() {
    try {
      this.sendingVerificationEmail = true;
      this.authService.sendProfileVerificationEmail().subscribe(async () => {
        this.sendingVerificationEmail = false;
        try {
          const alert = await this.alertCtrl.create({
            header: "Check your email",
            message: "We have sent you a link to complete verification. Please check your email and click the link to continue.",
            buttons: [
              {
                text: "OK",
              },
            ],
          });
          await alert.present();
        } catch (e) {}
      })
    } catch (e) {}
  }, 1000);  // Debounce for 1 second

  async onConfirmDeleteAccount() {
    this.eventService.track('delete-account');
    const message = `<span class="bold red">Last warning!</span> Are you sure you want to delete your account? <span class="bold red">This cannot be undone.</span>`;
    const alert = await this.alertCtrl.create({
      header: `Delete account`,
      cssClass: 'delete-account-alert',
      message,
      buttons: [

        {
          text: 'Cancel',
          handler: () => {
          }
        },
        {
          text: 'Yes, Delete',
          cssClass: 'alert-confirm-delete',
          handler: () => {
            this.onDeleteAccount()
          },
        },
      ],
    });
    await alert.present();
  }

  async onDeleteAccount() {
    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: {
        message: 'Deleting account...',
      },
    });
    await loadingModal.present();
    this.authService.deleteAccount().subscribe(async () => {
      await loadingModal.dismiss();
      this.onDeleteAccountSuccess()
    }, async () => {
      await loadingModal.dismiss();
      this.onDeleteAccountSuccess()
    })
  }

  async onDeleteAccountSuccess() {
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    await this.authService.auth0Logout()
    this.onLogOut()
  }

  onLogOut() {
    this.authService
      .logout()
      .subscribe(() => {
        if (this.nativeService.active) {
          return this.nativeService.sendMessage({ type: 'LOGOUT' });
        }
        setTimeout(() => {
          this.navCtrl.navigateRoot('/login');
        }, 350);
      })
      .unsubscribe();
  }

  onDisplayAlert = async (titleText, buttonText) => {
    const alert = await this.alertCtrl.create({
      header: titleText,
      buttons: [
        {
          text: buttonText,
        },
      ],
    });
    await alert.present();
  };

  updateVerificationStatus() {
    if (!this.user || !this.user.id_verification_required|| this.user.id_verification_completed) {
      return;
    }
    this.authService.updateVerificationStatus().subscribe((res) => {
      const { id_verification_completed } = res;
      if (id_verification_completed) {
        this.user.id_verification_completed = id_verification_completed;
      }
    })
  }

  onShowHelpWidget(route) {
    Beacon('init', Penmate.env.helpScoutId);
    if (this.user && this.user.email) {
      Beacon('identify', {
        email: this.user.email,
      })
      Beacon('prefill', {
        email: this.user.email,
      })
    }
    setTimeout(() => {
      Beacon('open');
      Beacon('navigate', route);
    });
  }
}
