import { Component, ViewEncapsulation } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { get, trim } from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import ErrorHandler from '../../lib/error-handler'

import { LoginModal } from '../login-modal/login-modal';
import { LoadingModal } from '../loading-modal/loading-modal'

import { createSupabaseClient } from '../../lib/supabase'

import { AuthService, AppState, EventService, NativeClientService } from '../services';
declare var Penmate;
declare var google;


@Component({
  selector: 'pm-login-link-callback',
  templateUrl: 'login-link-callback.html',
  styleUrls: ['./login-link-callback.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginLinkCallback {
  modal: any;
  currentSearch;
  savedSearchResult;
  searchName;
  savedSearchQuery;
  supabase;aaaaassq
  scriptLoaded;
  googleNonce;
  confirmText = 'Your email has been confirmed.'
  tokenHash;
  loginType;
  redirectTo;
  loginUrl;
  domain;
  verifyPath;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public store: Store<AppState>,
    private storage: Storage,
    private authService: AuthService,
    private eventService: EventService,
    private nativeService: NativeClientService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    const navigation = this.router.getCurrentNavigation();
    const url = new URL(window.location.href);
    this.tokenHash =url.searchParams.get("token");
    this.loginType =url.searchParams.get("type");
    this.redirectTo =url.searchParams.get("redirect_to");
    this.domain =url.searchParams.get("domain");
    this.verifyPath =url.searchParams.get("path");
    this.loginUrl = `https://${this.domain}${this.verifyPath}?token=${this.tokenHash}&type=${this.loginType}&redirect_to=${this.redirectTo}`
  }

  login() {
    if (this.loginUrl) {
      window.location.href = this.loginUrl
      return;
    }
    window.location.href = "/login"
  }
}
