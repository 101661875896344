import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { CreateMessage } from '../models/create-message';
import { isObject, isNull } from 'lodash';

@Injectable({ providedIn: 'root' })
export class CreateMessageActions {
  static SET_MESSAGE_TYPE = 'SET_MESSAGE_TYPE';
  static FETCH_MESSAGE = 'FETCH_MESSAGE';
  static EDIT_MESSAGE = 'EDIT_MESSAGE';
  static ADD_PHOTOS_FROM_DEVICE = 'ADD_PHOTOS_FROM_DEVICE';
  static UPDATE_POSTCARD = 'UPDATE_POSTCARD';

  static REMOVE_PHOTO = 'REMOVE_PHOTO';
  static APPEND_PHOTOS_TO_GALLERY = 'APPEND_PHOTOS_TO_GALLERY';
  static TOGGLE_PHOTO_IN_GALLERY = 'TOGGLE_PHOTO_IN_GALLERY';

  static FETCH_DRAFTS_REQUEST = 'FETCH_DRAFTS_REQUEST';
  static FETCH_DRAFTS_SUCCESS = 'FETCH_DRAFTS_SUCCESS';
  static FETCH_DRAFTS_ERROR = 'FETCH_DRAFTS_ERROR';

  static SAVE_DRAFT = 'SAVE_DRAFT';
  static CONFIRM_ADDRESS = 'CONFIRM_ADDRESS';

  // TODO: Consolidate these into one action (set Message)
  //
  static RESET_MESSAGE = 'RESET_MESSAGE';
  static SET_MESSAGE = 'SET_MESSAGE';

  static REMOVE_PAYMENT_DETAILS = 'REMOVE_PAYMENT_DETAILS';
  static TOGGLE_SOCIAL_MEDIA_COMMENT = 'TOGGLE_SOCIAL_MEDIA_COMMENT';

  resetMessage() {
    return this.createAction(CreateMessageActions.RESET_MESSAGE, {});
  }

  setMessage(payload = {}) {
    return this.createAction(CreateMessageActions.SET_MESSAGE, payload);
  }

  editMessage(message) {
    return this.createAction(CreateMessageActions.EDIT_MESSAGE, message);
  }

  updatePostcard(postcard) {
    return this.createAction(CreateMessageActions.UPDATE_POSTCARD, postcard);
  }

  addPhotosFromDevice(photos) {
    return this.createAction(CreateMessageActions.ADD_PHOTOS_FROM_DEVICE, photos);
  }

  removePhoto(photo) {
    return this.createAction(CreateMessageActions.REMOVE_PHOTO, photo);
  }

  addPhotosToGallery(payload) {
    return this.createAction(CreateMessageActions.APPEND_PHOTOS_TO_GALLERY, payload);
  }

  togglePhotoFromGallery(photo) {
    return this.createAction(CreateMessageActions.TOGGLE_PHOTO_IN_GALLERY, photo);
  }

  fetchDrafts() {
    return this.createAction(CreateMessageActions.FETCH_DRAFTS_REQUEST, {});
  }

  fetchDraftsSuccess(drafts) {
    return this.createAction(CreateMessageActions.FETCH_DRAFTS_SUCCESS, { drafts });
  }

  saveDraft(payload) {
    payload.penmate = isObject(payload.penmate) ? payload.penmate : {};
    if (isNull(payload.pendingSubscription)) {
      delete payload['pendingSubscription'];
    }
    return this.createAction(CreateMessageActions.SAVE_DRAFT, payload);
  }

  confirmMailingAddress(id, address) {
    return this.createAction(CreateMessageActions.CONFIRM_ADDRESS, { id, address });
  }


  fetchMessage(id) {
    return this.createAction(CreateMessageActions.FETCH_MESSAGE, id);
  }

  removePaymentInfo() {
    return this.createAction(CreateMessageActions.REMOVE_PAYMENT_DETAILS, null);
  }

  toggleSocialMediaComment(payload) {
    return this.createAction(CreateMessageActions.TOGGLE_SOCIAL_MEDIA_COMMENT, payload);
  }

  private createAction(type, payload) {
    return { type, payload };
  }
}
