import { ActionReducer, Action } from '@ngrx/store';
import { CreateMessageActions, MyPenmateActions, CustomAction } from '../actions';
import { PhotoPickerPhoto, CreateMessage } from '../models';
import { uniq, compact, findIndex, flatten, orderBy, keys, pick, truncate } from 'lodash';

const initialState: CreateMessage = {
  creditTopUp: null,
  pendingSubscription: null,
  id: null,
  messageType: 'letter',
  eMessage: null,
  emessageAvailable: null,
  isLoading: false,
  hasError: false,
  isResend: false,
  penmate: null,
  message: '',
  summary: '',
  photoType: null,
  numberOfPages: null,
  socialMediaComments: [],
  defaultReturnAddress: null,
  confirmedAddress: null,
  returnAddress: {
    name: null,
    email: null,
    address_line1: null,
    address_line2: null,
    address_city: null,
    address_state: null,
    address_zip: null,
    valid: false,
  },
  selectedPhotos: [],
  postcard: {
    frontImage: null,
    backImage: null,
  },
  photoGallery: {
    photos: [],
    nextPageId: null,
  },
  meta: {},
  status: null,
  payment: null,
  coupon: null,
  price: null,
  priceAfterCoupon: null,
  premiumUpgrade: null,
};

export function createMessageReducer(state = initialState, action: CustomAction): CreateMessage {
  switch (action.type) {
    case MyPenmateActions.VIEW_PENMATE:
      const resetState = state.penmate && state.penmate.id !== action.payload.id;
      return Object.assign({}, resetState ? initialState : state, {
        penmate: action.payload,
      });

    case CreateMessageActions.SET_MESSAGE: {
      const params = pick(action.payload, keys(initialState));
      return Object.assign({}, state, params);
    }

    case CreateMessageActions.RESET_MESSAGE:
      return Object.assign({}, initialState, { penmate: state.penmate });

    case CreateMessageActions.EDIT_MESSAGE:
      return Object.assign({}, state, {
        message: action.payload,
        summary: truncate(action.payload, { length: 140 }),
      });

    case CreateMessageActions.ADD_PHOTOS_FROM_DEVICE:
      const addedPhotosFromDevice = flatten(compact([action.payload])).map(p => {
        p.provider = 'device';
        p.selected = true;
        return p;
      });
      const allSelectedPhotos = state.selectedPhotos.concat(addedPhotosFromDevice);
      return Object.assign({}, state, {
        selectedPhotos: uniq(allSelectedPhotos),
      });

    case CreateMessageActions.UPDATE_POSTCARD:
      return Object.assign({}, state, {
        postcard: Object.assign({}, state.postcard, action.payload),
      });

    case CreateMessageActions.REMOVE_PHOTO:
      let selectedPhotos = state.selectedPhotos;
      const removedIdx = findIndex(selectedPhotos, p => p.img_path === action.payload.img_path);
      if (removedIdx > -1) {
        selectedPhotos.splice(removedIdx, 1);
      }
      return Object.assign({}, state, { selectedPhotos });

    case CreateMessageActions.APPEND_PHOTOS_TO_GALLERY:
      const { photos, nextPageId } = action.payload;
      const galleryPhotos = state.photoGallery.photos.concat(photos);
      return Object.assign({}, state, {
        photoGallery: Object.assign({}, state.photoGallery, {
          photos: galleryPhotos,
          nextPageId,
        }),
      });

    case CreateMessageActions.TOGGLE_PHOTO_IN_GALLERY:
      const { id } = action.payload;
      const toggledPhoto = action.payload;

      // Also Add/Remove photo from selected photos
      //
      let currSelectedPhotos = state.selectedPhotos;
      let selectedIdx = findIndex(currSelectedPhotos, p => p.id === id);
      
      if (toggledPhoto.selected) {
        currSelectedPhotos.push(toggledPhoto);
      } else {
        currSelectedPhotos.splice(selectedIdx, 1);
      }
      return Object.assign({}, state, {
        selectedPhotos: compact(currSelectedPhotos),
      });

    case CreateMessageActions.REMOVE_PAYMENT_DETAILS:
      return Object.assign({}, state, { payment: null });

    case CreateMessageActions.TOGGLE_SOCIAL_MEDIA_COMMENT:
      let socialMediaComments = state.socialMediaComments;
      const commentIdx = findIndex(socialMediaComments, p => p.id === action.payload.id);
      if (commentIdx > -1) {
        socialMediaComments.splice(commentIdx, 1);
      } else {
        socialMediaComments.push(action.payload);
      }
      return Object.assign({}, state, { socialMediaComments: uniq(socialMediaComments) });

    default: {
      return state;
    }
  }
}
