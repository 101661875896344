import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { ApiGateway } from './api-gateway.service';
import { map, switchMap } from 'rxjs/operators';
import { Http, Headers } from '@angular/http';
import { URLSearchParams } from '@angular/http';
import { uniqBy, get } from 'lodash';

declare var Penmate;

@Injectable({ providedIn: 'root' })
export class SearchService {
  constructor(public http: Http, public apiClient: ApiGateway) {}

  getAvailableStates() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http
      .get(`${Penmate.env.apiUrl}/doc-search/available-states`, this.buildHeaders())
      .pipe(map(res => res.json()));
  }

  findFacility(params) {
    return new Observable(observer => {
      this.apiClient
        .get(`${Penmate.env.apiUrl}/find-facility?${this.buildQueryString(params)}`, {})
        .subscribe(
          res => {
            const facilities = uniqBy(res, l => {
              const name = l ? (l.name || '') : '';
              return name.trim();
            }).filter(Boolean);
            observer.next(facilities);
            observer.complete();
          },
          () => {
            observer.error();
            observer.complete();
          },
        );
    });
  }

  search(params) {
    // let search = new URLSearchParams();
    // Object.keys(params).forEach( (prop) => {
    //   search.set(prop, params[prop]);
    // });
    return this.apiClient.get(
      `${Penmate.env.apiUrl}/doc-search?${this.buildQueryString(params)}`,
      {},
    );
  }

  createSearch(searchQuery) {
    return new Observable(observer => {
      this.pollSearch(searchQuery).subscribe(
        data => {
          observer.next(data);
          observer.complete();
        },
        () => {
          observer.error();
          observer.complete();
        },
      );
    });
  }

  fetchSearchResults(jobId) {
    return this.apiClient.get(`${Penmate.env.apiUrl}/doc-search/status/${jobId}`, {});
  }

  fetchSearchDetail(penmate) {
    return this.apiClient.post(`${Penmate.env.apiUrl}/doc-search/detail-search`, {}, { penmate });
  }

  findOrCreatePenmate(penmate) {
    return this.apiClient.post(`${Penmate.env.apiUrl}/doc-search/inmates`, {}, { penmate });
  }

  viewSearchResult(penmate) {
    return new Observable(observer => {
      if (penmate.has_detail_page) {
        return this.pollSearchDetail(penmate).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          () => {
            observer.error();
            observer.complete();
          },
        );
      }

      this.findOrCreatePenmate(penmate).subscribe(
        data => {
          observer.next(data);
          observer.complete();
        },
        () => {
          observer.error();
          observer.complete();
        },
      );
    });
  }

  createSearchAlert(id) {
    return this.apiClient.post(`${Penmate.env.apiUrl}/doc-search/search-alert`, {}, { id });
  }

  private buildHeaders() {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return { headers };
  }

  private buildQueryString(params) {
    let searchParams = new URLSearchParams();
    for (const key in params) {
      searchParams.set(key, params[key]);
    }
    return searchParams.toString();
  }

  private pollSearch(searchQuery) {
    let responseObservable;
    const resolveData = data => {
      responseObservable.next(data);
      responseObservable.complete();
    };

    return new Observable(observer => {
      responseObservable = observer;

      this.search(searchQuery).subscribe(({ job_id }) => {
        // Ensure there's a job id present to poll...
        if (!job_id) {
          responseObservable.error();
          return responseObservable.complete();
        }

        return this.createSearchPoller(job_id).subscribe(
          () => {
            this.fetchSearchResults(job_id).subscribe(resolveData);
          },
          () => {
            observer.error();
            observer.complete();
          },
        );
      });
    });
  }

  private pollSearchDetail(penmateData) {
    let responseObservable;
    const resolveData = data => {
      responseObservable.next(data);
      responseObservable.complete();
    };

    return new Observable(observer => {
      responseObservable = observer;

      this.fetchSearchDetail(penmateData).subscribe(({ penmate, job_id }) => {
        if (penmate) {
          return resolveData(penmate);
        }

        // Ensure there's a job id present to poll...
        if (!job_id) {
          responseObservable.error();
          return responseObservable.complete();
        }

        return this.createSearchPoller(job_id).subscribe(
          () => {
            this.fetchSearchDetail(penmateData).subscribe(resolveData);
          },
          () => {
            observer.error();
            observer.complete();
          },
        );
      });
    });
  }

  private createSearchPoller(jobId) {
    let poller;
    return new Observable(observer => {
      poller = Observable.interval(1000)
        .pipe(
          switchMap(() =>
            this.apiClient.get(`${Penmate.env.apiUrl}/doc-search/search-status`, {
              job_id: jobId,
            }),
          ),
        )
        .subscribe(
          job => {
            if (job.status === 'queued' || job.status === 'working' || !job.status) {
              return;
            }
            if (job.status === 'complete') {
              observer.next();
            }
            if (job.status === 'failed') {
              observer.error();
            }
            poller.unsubscribe();
            observer.complete();
          },
          (e) => {
            observer.error();
            return observer.complete();
          },
        );
    });
  }
}
