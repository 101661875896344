import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { AppState, EventService } from '../services';
import { Store } from '@ngrx/store';


declare var Card: any;
declare var Penmate: any;

@Component({
  selector: 'pm-app-intro-landing',
  styleUrls: ['./app-intro-landing.scss'],
  templateUrl: 'app-intro-landing.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppIntroLandingModal {
  pageHeight;
  currentMessage;
  penmate;
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private eventService: EventService,
    private store: Store<AppState>,
  ) {
    this.store
      .select(state => state.createMessage)
      .subscribe(currentMessage => {
        this.currentMessage = currentMessage;
        this.penmate = currentMessage.penmate;
      });
  }

  onSearch = async () => {
    const openModal = await this.modalCtrl.getTop();
    this.eventService.track('app-intro-search-start');
    return openModal.dismiss();
  };

  onCancel = async () => {
    const openModal = await this.modalCtrl.getTop();
    return openModal.dismiss();
  };
}
