import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams, Platform, AlertController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { get } from 'lodash';
import { LetterService, AppState, AuthService, EventService } from '../services';
import { LoadingModal } from '../loading-modal/loading-modal';

import { CreateMessageActions } from '../actions';
import { Store } from '@ngrx/store';
declare var Card: any;

/*
  Generated class for the PaymentPage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-app-download-modal',
  styleUrls: ['./app-download-modal.scss'],
  templateUrl: 'app-download-modal.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppDownloadModal {
  pageHeight;
  paymentForm;
  currentMessage;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private platform: Platform,
    private letterService: LetterService,
    private authService: AuthService,
    private eventService: EventService,
    private alertCtrl: AlertController,
    private createMessageActions: CreateMessageActions,
    private store: Store<AppState>,
  ) {
    this.store
      .select(state => state.createMessage)
      .subscribe(currentMessage => {
        this.currentMessage = currentMessage;
      });
  }

  onDownloadApp = platform => {
    this.eventService.track('app-download', {
      platform,
    });
  };

  onCancel = async () => {
    this.eventService.track('skip-app-download');
    const openModal = await this.modalCtrl.getTop();
    return openModal.dismiss();
  };
}
