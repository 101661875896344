import { Observable } from 'rxjs/Observable';
import { get } from 'lodash';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class NativeClientService {
  data = new EventEmitter<any>();
  active = false;
  shouldRequestRating;

  constructor() {
    this.active = !!get(window, 'ReactNativeWebView', false);
    if (this.active) {
      this.onSetupPostMessage();
    }
  }

  onSetupPostMessage() {
    (window as any).PMNativeClient = this;
  }

  sendMessage = message => {
    const postMessage = get(window, 'ReactNativeWebView.postMessage');
    if (postMessage) {
      (window as any).ReactNativeWebView.postMessage(JSON.stringify(message));
    }
  };

  receiveMessage = event => {
    const { type, payload } = JSON.parse(event);
    try {
      this.data.emit({ type, payload });
    } catch (e) {
      return false;
    }
  };
}
