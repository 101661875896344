import {
  Directive,
  Input,
  ElementRef,
  AfterViewInit,
  OnChanges,
  HostListener,
  SimpleChanges,
} from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs';
import { sample, map, find, get } from 'lodash';
import { mapStates } from '../services';

const L = require('mapbox.js');
require('leaflet-makimarkers');
require('./leaflet.moving-marker.js');

const mapBoxAccessToken = 'pk.eyJ1Ijoibmlja2wiLCJhIjoibzFaemw0WSJ9.cmGqHO9wJ53PySvjpN8nnw';
const tileLayerDefaultOps = { maxZoom: 4 };
const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

@Directive({
  selector: '[pmContactMap]',
})
export class PMLeafletMapDirective implements AfterViewInit, OnChanges {
  @Input() pmContactMap: any;
  @Input() redrawMap: boolean;
  mapDisplayed;
  tileLayer;
  iconPost;
  iconPost2;
  facilityLoc;
  originLoc;
  map;

  constructor(private http: Http, private el: ElementRef) {
    L.mapbox.accessToken = mapBoxAccessToken;
    L.MakiMarkers.accessToken = mapBoxAccessToken;
    this.tileLayer = new L.TileLayer(tileUrl, tileLayerDefaultOps);

    this.iconPost = L.MakiMarkers.icon({
      icon: 'post',
      color: '#0c8918',
      size: 'm',
    });
    this.iconPost2 = L.MakiMarkers.icon({
      icon: 'post',
      color: '#f96106',
      size: 'm',
    });
  }

  ngOnChanges(changes: any) {
    const redrawMap = get(changes, 'redrawMap.currentValue');

    if (this.facilityLoc && redrawMap && !this.mapDisplayed) {
      this.drawMap().subscribe();
    }
  }

  ngAfterViewInit() {
    this.originLoc = 'Chicago, USA';
    const stateName = get(
      find(mapStates, ['abbreviation', this.pmContactMap.state]),
      'name',
      'USA',
    );

    const facilityStateName = this.pmContactMap.state && stateName ? stateName : null;

    const userSearchState = get(
      this.pmContactMap,
      'data.state',
      get(this.pmContactMap, 'data.search_city', get(this.pmContactMap, 'data.facility', false)),
    );
    const defaultFacilityLoc =
      !userSearchState || userSearchState.match(/fed/i) ? false : `${userSearchState}, USA`;
    this.facilityLoc = this.pmContactMap.state ? `${facilityStateName}, USA` : defaultFacilityLoc;
    if (this.pmContactMap.showMap) {
      this.drawMap().subscribe();
    }
  }

  drawMap() {
    const elementId = `map-${this.pmContactMap.id}-${new Date().getTime()}`;
    const mapEl = this.el.nativeElement.getElementsByClassName('map')[0];
    mapEl.setAttribute('id', elementId);

    return new Observable(observer => {
      // code...
      const facilityGeoLoc = this.facilityLoc
        ? this.geocodePlace(this.facilityLoc)
        : this.geocodePlace('USA');
      const userGeoLoc = this.geocodePlace(this.originLoc);

      this.map = L.map(elementId).addLayer(this.tileLayer);
      this.map.dragging.disable();

      return Observable.forkJoin([facilityGeoLoc /*, userGeoLoc*/]).subscribe(places => {
        observer.next(places);

        if (this.facilityLoc) {
          const featureLayer = L.mapbox.featureLayer(places).addTo(this.map);
          const bounds = featureLayer.getBounds();
          this.map.fitBounds(bounds);
          this.mapDisplayed = true;
          return featureLayer.eachLayer(l => {
            const latLng = l.getLatLng();
            const marker = L.marker([latLng.lat, latLng.lng]).addTo(this.map);
          });
        }
        this.map.setView([37.8, -96], 3);
        this.mapDisplayed = true;
      });
    });
  }

  geocodePlace(place) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const baseUrl = `https://api.tiles.mapbox.com/v4/geocode/mapbox.places/${place}.json`;
    return new Observable(observer => {
      this.http
        .get(`${baseUrl}?access_token=${mapBoxAccessToken}`, { headers })
        .map((res: any) => res.json())
        .subscribe(
          data => {
            const feature = data.features[0] || {};
            observer.next(feature);
            observer.complete();
          },
          err => {
            observer.error(err);
            observer.complete();
          },
        );
    });
  }
}
