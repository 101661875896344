<ion-content>
  <div class="page-content">
    <div class="login-callback">
      <div class="appLogo"></div>
      <div class="login-content">
    
        <div class="loginMessage">
           <p>{{confirmText}}</p>
        </div>
        <ion-row>
          <ion-button id="login-button" class="loginButton" (click)="login()">
            Continue to login
          </ion-button>
        </ion-row>
      </div>
    </div>

  </div>
</ion-content>
