import { ActionReducer, Action } from '@ngrx/store';
import { UserActions, CustomAction } from '../actions';
import { get, omit } from 'lodash';
import { User } from '../models/user';

const initialState: User = {
  id: null,
  name: null,
  email: null,
  password: null,
  isAuthenticated: false,
  isAuthenticating: false,
  error: false,
  authentications: {},
  addresses: {},
  provider: null,
  emailMessages: [],
  pendingEmailMessage: null,
  deviceInfo: null,
  saveBilling: null,
  hasSubscription: null,
  hasSentLetter: null,
  hasSentEmessage: null,
  hasPurchasedCredits: null,
  previousSubscription: null,
  tosAgreement: null,
  creditBalance: 0,
  messageCreditsAvailable: false,
  messageCreditBalance: 0,
  totalMessageCredits: null,
  metrics: {},
  sentLetterTotal: 0,
};

export function userReducer(state = initialState, action: CustomAction): User {
  switch (action.type) {
    case UserActions.LOGIN_USER:
      let nextState = Object.assign({}, state, action.payload);
      nextState.saveBilling = get(action, 'payload.save_billing');
      nextState.hasSubscription = get(action, 'payload.has_subscription');
      nextState.hasSentLetter = get(action, 'payload.has_sent_letter');
      nextState.hasSentEmessage = get(action, 'payload.has_sent_emessage');
      nextState.tosAgreement = get(action, 'payload.tos_agreement');
      nextState.hasPurchasedCredits = get(action, 'payload.has_purchased_credits');
      nextState.previousSubscription = get(action, 'payload.previous_subscription');
      nextState.messageCreditBalance = Math.max(0, get(action, 'payload.message_credit_balance') || 0);
      nextState.messageCreditsAvailable = get(action, 'payload.message_credits_available', false);
      nextState.creditBalance = Math.max(0, get(action, 'payload.credit_balance', 0));
      nextState.totalMessageCredits = Math.max(0, get(action, 'payload.total_message_credits') || 0);
      nextState.sentLetterTotal = get(action, 'payload.sent_letter_total');
      nextState = omit(nextState, ['tos_agreement', 'save_billing', 'has_subscription', 'previous_subscription', 'credit_balance', 'message_credit_balance', 'message_credits_available', 'total_message_credits']);
      return nextState;

    case UserActions.ADD_SOCIAL_ACCOUNT:
      return Object.assign({}, state, {
        authentications: Object.assign({}, state.authentications, {
          [action.payload.provider]: action.payload.account,
        }),
      });

    case UserActions.ADD_SOCIAL_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        authentications: Object.assign({}, state.authentications, {
          [action.payload.provider]: action.payload.account,
        }),
      });

    case UserActions.FETCH_EMAIL_MESSAGES_SUCCESS:
      return Object.assign({}, state, {
        emailMessages: get(action.payload, 'email_messages', []),
      });

    case UserActions.SET_PENDING_EMAIL_MESSAGE:
      return Object.assign({}, state, {
        pendingEmailMessage: get(action.payload, 'message'),
      });

    case UserActions.CLEAR_PENDING_EMAIL_MESSAGE:
      return Object.assign({}, state, {
        pendingEmailMessage: null,
      });

    case UserActions.UPDATE_DEVICE_INFO_SUCCESS:
      return Object.assign({}, state, {
        deviceInfo: {
          ...get(state, 'deviceInfo', {}),
          ...get(action, 'payload', {}),
        },
      });

    case UserActions.SET_NATIVE_MODULES:
      return Object.assign({}, state, {
        deviceInfo: {
          ...(state.deviceInfo || {}),
          nativeModules: {
            ...get(action, 'payload'),
          },
        },
      });

    default: {
      return state;
    }
  }
}
