import { Component, ElementRef, ViewChild, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { NavController, NavParams, ModalController } from '@ionic/angular';
import { isEmpty, get, omit, orderBy, values } from 'lodash';
import { Validators, FormBuilder } from '@angular/forms';
import { CreateMessageActions, MyPenmateActions } from '../actions';
import { Namefully } from 'namefully'

import { ConfirmOrderPage } from '../confirm-order/confirm-order';

import { AppState, LetterService, EventService, mapStates } from '../services';
import { Store } from '@ngrx/store';
/*
  Generated class for the AddReturnAddress page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-create-message-return-address',
  templateUrl: 'create-message-return-address.html',
  styleUrls: ['./create-message-return-address.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateMessageReturnAddress implements OnInit {
  @ViewChild('mainContent', { static: false }) mainContent;
  @ViewChild('scrollContent', { static: false }) scrollContent;
  addressType = 'fromUser';
  savedReturnAddress = {};
  returnAddressForm;
  formSubmitted;
  penmate;
  STATE_NAMES;
  currentMessage;
  penmateId;
  addressConfirmation;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public route: ActivatedRoute,
    public eventService: EventService,
    private createMessageActions: CreateMessageActions,
    private myPenmateActions: MyPenmateActions,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
  ) {
    this.STATE_NAMES = mapStates.map(m => m['abbreviation']);

    this.penmateId = this.navParams.get('penmateId');
    this.addressConfirmation = this.navParams.get('addressConfirmation');
    const returnAddress = this.navParams.get('returnAddress') || {};

    this.store.dispatch(this.myPenmateActions.loadMessages(this.penmateId));

    let firstName;
    let lastName;
    if (this.addressConfirmation && returnAddress && returnAddress.name.length >= 2) {
      const nameParts = new Namefully(returnAddress.name);
      firstName = nameParts.hasMiddle ? `${nameParts.first} ${nameParts.middle}` : nameParts.first
      lastName = nameParts.last
    } 


    this.returnAddressForm = this.formBuilder.group({
      addressType: ['physical', Validators.required],
      email: [null],
      firstName: [firstName, Validators.required],
      lastName: [lastName, Validators.required],
      address_line1: [returnAddress.address_line1],
      address_line2: [returnAddress.address_line2],
      address_city: [returnAddress.address_city],
      address_state: [returnAddress.address_state],
      address_zip: [returnAddress.address_zip],
    });

    this.setAddressFormValidators();

    this.store
      .select(state => state.createMessage)
      .subscribe(currentMessage => {
        this.currentMessage = currentMessage;
      });

    if (this.currentMessage) {
      this.returnAddressForm.valueChanges.subscribe(returnAddress => {

        returnAddress.valid = this.returnAddressForm.valid;

        if (!this.addressConfirmation) {
          returnAddress.addressType = 'physical';
        }
        let computedName = `${returnAddress.firstName} ${returnAddress.lastName}`
        try {
          const computed = new Namefully(`${returnAddress.firstName} ${returnAddress.lastName}`);
          computedName = computed.short
        } catch {
        }

        this.store.dispatch(
          this.createMessageActions.setMessage({
            returnAddress: {
              ...(this.currentMessage.returnAddress || {}),
              ...returnAddress,
              name: computedName,
            },
          }),
        );
      });
    }

    for (const i in this.returnAddressForm.controls) {
      this.returnAddressForm.controls[i].markAsTouched();
    }
  }

  onCancel() {
    this.modalCtrl.dismiss();
  }

  onGetNameParts(name = '') {
    try {
      if (name && name.length > 2) {
        const nameParts = new Namefully(name);
        const firstName = nameParts.hasMiddle ? `${nameParts.first} ${nameParts.middle}` : nameParts.first
        const lastName = nameParts.last
        return { firstName, lastName };
      }
    } catch {}
    return {}
  }

  ngOnInit() {
    this.store
      .select(state => state)
      .pipe(take(1))
      .subscribe(({ myPenmates: { currentPenmate }, user, createMessage }) => {
        this.currentMessage = createMessage;
        this.penmate = currentPenmate || get(createMessage, 'penmate');

        if (this.addressConfirmation) { 
          return;
        }
        const currentReturnAddress = this.currentMessage.returnAddress || {};

        let firstName = currentReturnAddress.firstName;
        let lastName = currentReturnAddress.lastName;
        const firstLastMissing = !firstName && !lastName;

        if (currentReturnAddress.name && firstLastMissing) {
          const nameParts = this.onGetNameParts(currentReturnAddress.name);
          firstName = nameParts.firstName || '';
          lastName = nameParts.lastName || '';
        }

        this.returnAddressForm.patchValue({
          addressType: 'physical',
          firstName,
          lastName,
          email: currentReturnAddress.email,
          address_line1: currentReturnAddress.address_line1,
          address_city: currentReturnAddress.address_city,
          address_state: currentReturnAddress.address_state,
          address_zip: currentReturnAddress.address_zip,
        });

        if (!currentReturnAddress.name || !currentReturnAddress.address_line1) {
          // Set the default address when present
          const defaultAddress = get(user, 'addresses.returnAddress', {});
          if (defaultAddress.returnAddressName && defaultAddress.returnAddressStreet) {
            let firstName;
            let lastName;
            if (defaultAddress.returnAddressName) {
              const nameParts = this.onGetNameParts(defaultAddress.returnAddressName);
              firstName = nameParts.firstName || '';
              lastName = nameParts.lastName || '';
            }
            return this.returnAddressForm.patchValue({
              firstName,
              lastName,
              address_line1: defaultAddress.returnAddressStreet,
              address_city: defaultAddress.returnAddressCity,
              address_state: defaultAddress.returnAddressState,
              address_zip: defaultAddress.returnAddressZip,
            });
          }

          // fallback to last letters address when present...
          if (get(this.penmate, 'messages', []).length > 0) {
            const lastMessages = orderBy(this.penmate.messages, 'id', 'desc');
            const lastMessage = lastMessages[0]
            const lastReturnaddress = get(lastMessage, 'returnAddress') || {};
            if (!isEmpty(lastReturnaddress)) {
              this.returnAddressForm.patchValue(lastReturnaddress);
            }
          }
        }
      })
      .unsubscribe();
  }

  setAddressFormValidators() {
    const address_line1 = this.returnAddressForm.get('address_line1');
    const address_city = this.returnAddressForm.get('address_city');
    const address_state = this.returnAddressForm.get('address_state');
    const address_zip = this.returnAddressForm.get('address_zip');
    const email = this.returnAddressForm.get('email');
    email.setValidators(null);

    [address_line1, address_city, address_state, address_zip].forEach(control => {
      control.setValidators([Validators.required]);
    });
  }

  onSaveAndContinue = async () => {
    this.formSubmitted = true;
    if (!this.returnAddressForm.valid) {
      return;
    }
    this.store.dispatch(this.createMessageActions.saveDraft(this.currentMessage));
    this.eventService.track('add-return-address', {
      letter_id: this.currentMessage.id,
      penmate_id: get(this.currentMessage, 'penmate.id'),
    });
    await this.modalCtrl.dismiss();
  };

  onFocusZip = () => {
    if (this.scrollContent) {
      this.scrollContent.scrollToBottom();
    }
  };
}
