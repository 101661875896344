<ion-header id="photo-picker-header">
  <ion-toolbar>
    <div class="title-content">
      <ion-title class="title">Add Instagram Photos</ion-title>
      <ion-buttons end>
        <ion-button ion-button icon-only (click)="onDismiss()">
          <ion-icon name="close" class="close-icon" size="md"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen>
  <ion-card class="attach-photo-card">
    <ion-card-header>
      <ion-card-title>Add Instagram Photos</ion-card-title>
      <p class="desc">
        <span>New!</span> Attach Instagram photos to your letter or postcard. Choose from your own
        photos or paste a link below.
      </p>
    </ion-card-header>

    <ion-card-content>
      <ion-button
        (click)="onInstagramAuth($event)"
        expand="block"
        type="submit"
        class="ion-no-margin browse-photos-button"
        >Browse my photos</ion-button
      >
      <br />
      <div class="or-break">
        <p>OR</p>
      </div>
      <ion-item>
        <ion-label position="stacked">Instagram link:</ion-label>
        <ion-input
          required
          type="text"
          (input)="onPasteLink($event)"
          [(ngModel)]="linkInput"
          placeholder="Paste instagram link"
        ></ion-input>
      </ion-item>
      <ion-card-title class="add-link">How to link an Instagram Post</ion-card-title>

      <ol class="copy-link-instructions">
        <li>Find a post on instagram</li>
        <li>
          Click the
          <span class="more-button"><ion-icon name="more" size="23" color="#222"></ion-icon></span>
          button in the top right corner
        </li>
        <li>Click on <span class="bold highlight">Copy Link</span></li>
        <li>Paste the link above</li>
      </ol>

      <div class="link-copy-example" *ngIf="!selectedPhotos || selectedPhotos.length === 0">
        <p class="example-title">How to find instagram photo link:</p>

        <ion-img src="/assets/www/assets/img/copy_ig_link_ex.png"></ion-img>
      </div>
    </ion-card-content>
  </ion-card>

  <div *ngIf="selectedPhotos.length > 0" class="selected-photos">
    <h4>Added photos</h4>
    <ion-list>
      <ion-grid id="photo-grid" [ngClass]="selectedPhotos.length + '-loaded'">
        <ion-row *ngFor="let row of photos; let rowIdx = index;">
          <ion-col size="6" *ngFor="let photo of row">
            <pm-photo-picker-photo
              [photo]="photo"
              [rowIdx]="rowIdx"
              [allPhotos]="photos"
              [isPostcard]="isPostcard"
              [onSelectPhoto]="onSelectPhoto"
            >
            </pm-photo-picker-photo>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-list>
  </div>
</ion-content>

<ion-footer id="modal-footer" *ngIf="!isPostcard">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <div class="selected-photos" *ngIf="!showPaginationSpinner">
            <a class="photos-selected-count" (click)="onShowSelectedPhotos()">
              <span>{{selectedPhotos.length}}</span> Photos added
            </a>
          </div>
          <div class="loading-more-spinner" *ngIf="showPaginationSpinner">
            <ion-col size="2"><ion-spinner name="bubbles"></ion-spinner></ion-col>
            <ion-col size="10"> Loading more...</ion-col>
          </div>
        </ion-col>
        <ion-col size="6">
          <ion-buttons end>
            <ion-button ion-button icon-right (click)="onDismiss()">
              Ok, I'm done
              <ion-icon name="md-checkmark"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
