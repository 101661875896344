import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { NavController, ModalController, NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { find, map, get, isEmpty } from 'lodash';
import {
  AppState,
  SearchService,
  MyPenmateService,
  AuthService,
  LetterService,
  EventService,
} from '../services';
import { SearchActions, UserActions, MyPenmateActions } from '../actions';
import { MyPenmatesPage } from '../my-penmates/my-penmates';
import { LoginPage } from '../login/login.page';
import { LoadingModal } from '../loading-modal/loading-modal';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  take,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'pm-page-view-search-result',
  templateUrl: 'view-search-result.html',
  styleUrls: ['./view-search-result.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewSearchResultModal implements OnInit {
  penmate;
  loadingModal;
  modalId;
  searchCity;
  selectedFacility = {};
  facilities$: Observable<any>;
  facilitiesLoading = false;
  facilityInput$ = new Subject<string>();

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    private authService: AuthService,
    private myPenmateService: MyPenmateService,
    private myPenmateActions: MyPenmateActions,
    private searchService: SearchService,
    private eventService: EventService,
    private navParams: NavParams,
    private store: Store<AppState>,
  ) {}

  ionViewDidEnter() {
    this.store
      .select(state => state.search.selectedSearchResult)
      .subscribe(penmate => {
        this.penmate = penmate;
      });
  }

  ngOnInit() {
    this.modalId = this.navParams.data.paramID;
    this.loadFacilities();
  }

  onEnterCity(city) {
    this.searchCity = city;
  }

  isCityInvalid() {
    return isEmpty(this.searchCity) || this.searchCity.length < 3;
  }

  addPenmate = async penmate => {
    this.authService.authenticate().subscribe(async ({ isAuthenticated, user }) => {
      await this.cancel();
      if (isAuthenticated && user) {
        this.showLoadingModal();
        const { id: facility_id, name: facility }: any = this.selectedFacility || {};

        return this.myPenmateService
          .addPenmate({ search_city: this.searchCity, ...penmate, facility_id, facility })
          .finally(async () => {
            await this.loadingModal.dismiss();
          })
          .subscribe(
            async myPenmates => {
              this.store.dispatch(this.myPenmateActions.loadPenmatesSuccess(myPenmates));
              this.navCtrl.navigateForward('/my-penmates');
            },
            err => {
              this.store.dispatch(this.myPenmateActions.addPenmateError(err));
            },
          );
      }
      // send to login
      // this.modalCtrl.dismiss();
      this.navCtrl.navigateForward('/login');
    });
  };

  showLoadingModal = async () => {
    this.loadingModal = await this.modalCtrl.create({ component: LoadingModal }); // change this to v4 signature
    await this.loadingModal.present();
  };

  cancel = async () => {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      return modal.dismiss();
    }
    return true;
  };

  private loadFacilities() {
    this.facilities$ = concat(
      of([]), // default items
      this.facilityInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => (this.facilitiesLoading = true)),
        switchMap(term => {
          return this.searchService
            .findFacility({ q: term, state: get(this.penmate, 'state') })
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.facilitiesLoading = false)),
            );
        }),
      ),
    );
  }
}
