<ion-content>
  <div class="container">
    <div class="bg-circle"></div>
    <ion-button (click)="onCancel()" class="close-button">
      <ion-icon name="close" class="close-icon"></ion-icon>
    </ion-button>

    <div class="inner">
      <div class="letter-artwork"></div>
      <h1 class="title">
        Send a message to an inmate
      </h1>
      <h2 class="title">
        Find an inmate and send messages, photos, and postcards.

      </h2>
      <div class="details">
        <p class="subtitle">Learn how to send messages from your phone:</p>
        <div class="video-embed">
          <iframe src="https://www.youtube.com/embed/plJF1C1As0Y" title="How to contact an inmate" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <p class="subtitle subtitle-2">Send a message in minutes</p>

        <div class="detail-row send-letters">
          <div class="icon">
            <div class="letters-icon"></div>
          </div>
          <span>
            <p class="text">
              <span class="bold" style="color:#058805;">Send letters and emails</span><br />
              Search for an inmate and send letters, emails, and photos ❤️.
            </p>
          </span>
        </div>
        <div class="detail-row design-postcards">
          <div class="icon">
            <div class="postcards-icon"></div>
          </div>
          <p class="text">
            <span class="bold" style="color:#058805;">Create Photo Cards</span><br />
            Send postcards with your favorite photos.
          </p>
        </div>
        <div class="detail-row shipping">
          <div class="icon">
            <div class="mailbox-icon"></div>
          </div>
          <p class="text">
            <span class="bold" style="color:#058805;">Tracking & Delivery</span><br />
            Send emails directly to tablets or deliver printed mail in 3-5 days.
          </p>
        </div>
      </div>

      <div class="get-started-cta">
        <div>
          <ion-button ion-button icon-right class="get-started-button" (click)="onSearch()">
            <span>
              <div class="button-inner">
                Search for an inmate
                <ion-icon name="ios-arrow-dropright-circle"></ion-icon>
              </div>
            </span>
          </ion-button>
          <div class="sub-promo">
            Your first <span class="hlt">text</span> message is free!
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
