import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import {
  NavController,
  Platform,
  ModalController,
  ActionSheetController,
  AlertController,
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { find, some, get, isUndefined, isNull } from 'lodash';
import { MessageTextEditor } from '../message-text-editor/message-text-editor';
import { MyPenmatesPage } from '../my-penmates/my-penmates';
import { IGPhotoPicker } from '../ig-photo-picker/ig-photo-picker';
import { SelectedPhotosModal } from '../selected-photos-modal/selected-photos-modal';
import { FileUploadModal } from '../file-upload-modal/file-upload-modal';
import { ConfirmOrderPage } from '../confirm-order/confirm-order';
import { EmailMessagesModalPage } from '../email-messages-modal/email-messages-modal';
import { EmailDeliveryModal } from '../email-delivery-modal/email-delivery-modal';
import { LegalTermsModal } from '../legal-terms-modal/legal-terms-modal';


import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { htmlToText } from 'html-to-text';

import { CreateMessageActions, UserActions, MyPenmateActions, SearchActions } from '../actions';
import { Observable } from 'rxjs/Observable';
import {
  AppState,
  LetterService,
  AuthService,
  EventService,
  NativeClientService,
  SearchService,
} from '../services';
import { Store } from '@ngrx/store';
import { compact } from 'lodash';

declare var Beacon;
export const DEFAULT_MAX_SELECTED_PHOTOS = 10;
export const EMESSAGE_MAX_SELECTED_PHOTOS = 4;

/*
  Generated class for the CreateMessage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-message-creator',
  templateUrl: 'message-creator.html',
  styleUrls: ['./message-creator.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageCreatorPage implements OnInit {
  keyboardShown;
  maxEditorHeight;
  keyboardEditorHeight;
  selectedPhotos = [];
  selectedPhotosCount;
  selectedPhotosModal;
  emailMessagesModal;
  loadingModal;
  photoPickerProvider;
  photoPickerAccessToken;
  photoPickerModal;
  messageText;
  penmate;
  currentMessage;
  isNative;
  skipConfirmation;
  MAX_SELECTED_PHOTOS = DEFAULT_MAX_SELECTED_PHOTOS;
  isFirstMessage;
  useNativeTextEditor = false;

  modal;

  constructor(
    public navCtrl: NavController,
    public route: ActivatedRoute,
    public router: Router,
    private platform: Platform,
    private store: Store<AppState>,
    private letterService: LetterService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private createMessageActions: CreateMessageActions,
    private myPenmateActions: MyPenmateActions,
    private changeDetectorRef: ChangeDetectorRef,
    private userActions: UserActions,
    private searchActions: SearchActions,
    private authService: AuthService,
    private eventService: EventService,
    private nativeService: NativeClientService,
  ) {
    this.modalCtrl
      .create({
        component: EmailMessagesModalPage,
      })
      .then(modal => {
        this.emailMessagesModal = modal;
      });

    this.store
      .select(state => state.createMessage)
      .subscribe(createMessage => {
        this.penmate = createMessage.penmate;
        this.currentMessage = createMessage;
        this.selectedPhotos = createMessage.selectedPhotos;
        this.messageText = createMessage.message;
        this.MAX_SELECTED_PHOTOS = DEFAULT_MAX_SELECTED_PHOTOS;
        this.isFirstMessage = get(createMessage, 'meta.firstMessage', false);
        if (this.currentMessage.eMessage) {
          this.selectedPhotos =  this.selectedPhotos.slice(0,4)
        }
        // if (!!this.isFirstMessage) {
        //   this.MAX_SELECTED_PHOTOS = ;
        // }

        if (this.selectedPhotosModal && this.selectedPhotos.length === 0) {
          this.selectedPhotosModal.dismiss();
        }
      });

    this.store
      .select(state => state.user)
      .subscribe(({ deviceInfo, ...user }) => {
        const metrics = get(deviceInfo, 'metrics');
        this.skipConfirmation = isUndefined(metrics) || isNull(metrics) ? false : metrics === false;
      });
  }

  ngOnInit() {
    if (this.currentMessage.id) {
      this.store.dispatch(this.createMessageActions.fetchMessage(this.currentMessage.id));
      setTimeout(() => {
        this.onSaveMessage();
      }, 300);
    }
    this.store
      .select(state => state.createMessage.penmate)
      .take(2)
      .subscribe(penmate => {
        if (penmate && penmate.id) {
          this.store.dispatch(this.myPenmateActions.viewPenmate(penmate));
          return this.store.dispatch(this.myPenmateActions.loadMessages(penmate.id, 1));
        }
        const penmateId = this.route.snapshot.paramMap.get('id');
        this.store
          .select(state => state.myPenmates.penmates)
          .take(2)
          .subscribe(penmates => {
            if (penmates.length === 0) {
              return;
            }
            const pmate = find(penmates, p => p && p.id === parseInt(penmateId, 10));
            if (pmate) {
              return this.store.dispatch(this.createMessageActions.setMessage({ penmate: pmate }));
            }
            this.navCtrl.navigateRoot('/penmates');
          });
      });

    this.store
      .select(state => state.user.deviceInfo)
      .subscribe(deviceInfo => {
        // this.useNativeTextEditor = !!get(deviceInfo, 'nativeModules.textEditor', false);
      });
  }

  ionViewDidEnter() {
    this.isNative = !!this.nativeService.active;
    Beacon('destroy');
    this.store
    .select(state => state.user)
    .take(1)
    .subscribe((user) => {
      if (!user || !user.id) {
        return this.navCtrl.navigateBack('/my-penmates');
      }

      const metrics = get(user, 'deviceInfo.metrics');
      const skipTos = isUndefined(metrics) || isNull(metrics) ? false : metrics === false;

      if (skipTos) {
        return;
      }

      if (!user.tosAgreement) {
        this.onShowLegalTerms();
      }
    });
  }

  onShowLegalTerms() {
    this.modalCtrl
    .create({
      component: LegalTermsModal,
      componentProps: { }
    })
    .then(async (modal) => {
      await modal.present();
    })
  }

  onSubscribeNativeTextEditor() {
    this.nativeService.data.subscribe(({ type, payload }) => {
      const payloadData = payload || {};
      if (type === 'TEXT_EDITOR_CONTENT_CHANGE') {
        this.store.dispatch(this.createMessageActions.editMessage(payloadData.text));
      }
      if (type === 'TEXT_EDITOR_SAVE') {
        this.store.dispatch(this.createMessageActions.editMessage(payloadData.text));
        this.onSaveMessage();
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  onChangeEMessageFacility() {
    this.modalCtrl
      .create({
        component: EmailDeliveryModal,
        componentProps: { 
          changeFacilityOnly: true,
        }
      })
      .then(async (modal) => {
        await modal.present();
        modal.onDidDismiss().then((payload: any) => {
          const eMessage = get(payload, 'data.eMessage');
          if (eMessage) {
            this.store.dispatch(
              this.createMessageActions.saveDraft({ ...this.currentMessage, eMessage }),
            );
          }
        });
      })
  }

  onCheckPendingEmails() {
    this.route.queryParams.subscribe(params => {
      if (params.emailMessage) {
        return;
      }
      this.letterService.getPendingEmails().subscribe(({ email_messages }) => {
        this.store.dispatch(this.userActions.fetchEmailMessagesSuccess({ email_messages }));
        const hasEmailMssage = some(
          email_messages,
          email => get(email, 'inmate.id') === parseInt(this.route.snapshot.paramMap.get('id'), 10),
        );
        if (hasEmailMssage && this.emailMessagesModal) {
          this.emailMessagesModal.present();
        }
      });
    });
  }

  onEditMessage = async () => {
    const penmate_id = this.route.snapshot.paramMap.get('id');
    this.eventService.track('edit-message', { penmate_id, message_type: 'letter' });

    if (this.useNativeTextEditor) {
      this.onSubscribeNativeTextEditor();
      this.nativeService.sendMessage({
        type: 'TEXT_EDITOR_TOGGLE',
        data: { visible: true, text: this.currentMessage.message },
      });
      return;
    }

    const componentProps = { id: penmate_id }
    if (this.currentMessage && this.currentMessage.eMessage) {
      componentProps['maxLength'] = 3500;
    }
    this.modal = await this.modalCtrl.create({
      component: MessageTextEditor,
      componentProps
    });
    await this.modal.present({ updateUrl: false });
  };

  onSelectPhotosFromDevice(): void {
    const penmateId = this.route.snapshot.paramMap.get('id');
    const allowMultiple = !this.currentMessage.eMessage;
    this.navCtrl.navigateForward(`/penmates/${penmateId}/new-message/upload?allowMultiple=${allowMultiple}`);
  }

  onAddInstagramPhotos = () => {
    const penmateId = this.route.snapshot.paramMap.get('id');
    const routeUrl = `/penmates/${penmateId}/new-message/instagram-photos`;
    return this.router.navigateByUrl(routeUrl);
  };

  onShowLibrary = () => {
    this.nativeService.sendMessage({ type: 'SHOW_LIBRARY' });
  };

  onAddPhotos = async () => {
    if (this.selectedPhotos.length >= this.MAX_SELECTED_PHOTOS) {
      let maxPhotosMessage = `You've attached ${this.MAX_SELECTED_PHOTOS} photos to your message. We cant send more than ${this.MAX_SELECTED_PHOTOS} photos at a time or the facility may reject your message. To send more than ${this.MAX_SELECTED_PHOTOS} photos, please create a new message.`;
      if (this.isFirstMessage) {
        maxPhotosMessage = `You can send up to ${this.MAX_SELECTED_PHOTOS} photos for free with your first message. To send more than ${this.MAX_SELECTED_PHOTOS} photos, please create a new message.`;
      }

      return this.onDisplayErrorAlert(
        'Photo limit reached',
        `Max ${this.MAX_SELECTED_PHOTOS} photos attached.`,
        maxPhotosMessage,
        'OK',
      );
    }
    return this.onSelectPhotosFromDevice();
  };

  getInstagramRecentPhotos(nextPageId = null) {
    const req = this.letterService.getUserInstagramRecent(this.photoPickerAccessToken, nextPageId);

    req.subscribe((media: any) => {
      this.store.dispatch(this.createMessageActions.addPhotosToGallery(media));
    });
    return req;
  }

  onPhotoGalleryLoadMore() {
    return new Observable(observer => {
      this.store
        .select(state => state.createMessage)
        .subscribe(createMessage => {
          const { nextPageId } = createMessage.photoGallery;
          if (!nextPageId) {
            observer.next();
            return observer.complete();
          } // no more pagination!

          if (this.photoPickerProvider === 'instagram') {
            this.getInstagramRecentPhotos(nextPageId).subscribe(data => {
              observer.next(data);
            });
          }

          if (this.photoPickerProvider === 'facebook') {
            // code...
          }
        })
        .unsubscribe();
    });
  }

  onToggleGalleryPhoto(photo) {
    this.store.dispatch(this.createMessageActions.togglePhotoFromGallery(photo));
  }

  onShowSelectedPhotos = async () => {
    this.selectedPhotosModal = await this.modalCtrl.create({
      component: SelectedPhotosModal,
      componentProps: {
        photos: this.selectedPhotos,
      },
    });
    await this.selectedPhotosModal.present({ updateUrl: false });
  };

  onDeleteMessage() {
    this.store.dispatch(
      this.myPenmateActions.deleteMessage({
        penmateId: this.penmate.id,
        message: this.currentMessage,
      }),
    );
  }

  onSaveMessage = async () => {
    return new Promise(resolve => {
      const shippingMethod = this.currentMessage.eMessage ? 'emessage' : 'letter';
      this.store.dispatch(
        this.createMessageActions.saveDraft({ ...this.currentMessage, shippingMethod, messageType: 'letter' }),
      );
      this.eventService.gaEventTrack('Message Edit', {
        event_category: 'User',
      });
      setTimeout(() => {
        resolve(null);
      }, 300);
    });
  };

  onConfirmDeleteMessage = async () => {
    const cancelButton = {
      text: 'Cancel',
      role: 'cancel',
      handler: () => {},
    };
    const delButton = {
      text: 'Delete',
      cssClass: 'alert-button delete-button',
      handler: () => {
        if (this.currentMessage.id) {
          this.onDeleteMessage();
        }
        this.store.dispatch(this.createMessageActions.resetMessage());
        this.navCtrl.navigateForward('/my-penmates');
      },
    };

    // const saveButton = {
    //   text: 'Yes, Save',
    //   cssClass: 'save-button',
    //   handler: () => {
    //     this.onSaveMessage();
    //     this.navCtrl.navigateForward('/my-penmates');
    //   },
    // };

    const confirm = await this.alertCtrl.create({
      header: 'Delete this message?',
      buttons: [cancelButton, delButton],
    });
    await confirm.present();
  };

  onSaveAndGoBack() {
    this.store
      .select(state => state.createMessage)
      .subscribe(async createMessage => {
        if (!createMessage.penmate) {
          const penmateId = createMessage.penmate.id;
          await this.onSaveMessage();
        }
        return this.navCtrl.navigateBack(`/my-penmates`);
      })
      .unsubscribe();
  }

  onSaveAndContinue() {
    let penmateId = get(this.penmate, 'id');
    if (this.skipConfirmation) {
      return this.navCtrl.navigateForward(`/penmates/${penmateId}/messages`);
    }

    this.store
      .select(state => state.createMessage)
      .take(1)
      .subscribe(async createMessage => {
        this.onSaveMessage();
        const penmateId = createMessage.penmate
          ? createMessage.penmate.id
          : get(this.penmate, 'id');
        try {
          this.store.dispatch(
            this.createMessageActions.saveDraft({ ...createMessage, messageType: 'letter' }),
          );
        } finally {
          if (!createMessage.message || createMessage.message.length <= 5) {
            const confirm = await this.alertCtrl.create({
              header: 'Blank message',
              subHeader: "Your message is empty. Please add text to continue.",
              buttons: [{
                text: 'Ok',
                cssClass: 'alert-button',
                handler: () => {},
              }],
            });
            return await confirm.present();
          }
          this.navCtrl.navigateForward(`penmates/${penmateId}/new-message/confirm-order`);
          this.eventService.gaEventTrack('Message Edit', {
            event_category: 'User',
            event_label: 'Letter'
          });
        }
      });
    return true;
  }

  onTextAreaFocus() {
    this.keyboardShown = true;
  }

  onTextAreaBlur() {
    this.keyboardShown = false;
    this.store.dispatch(this.createMessageActions.editMessage(this.messageText));
  }

  onDisplayErrorAlert = async (titleText, subHeader, message, buttonText) => {
    const alert = await this.alertCtrl.create({
      header: titleText,
      subHeader,
      message,
      buttons: [
        {
          text: buttonText,
        },
      ],
    });
    await alert.present();
  };

  hasPhotos() {
    return this.selectedPhotos && this.selectedPhotos.length > 0;
  }

  isMessageEdited() {
    return this.hasPhotos() || this.currentMessage.message.length > 0;
  }
}
