import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { Storage } from '@ionic/storage';
import {
  OAuthService,
  NullValidationHandler,
  OAuthSuccessEvent,
  OAuthErrorEvent,
} from 'angular-oauth2-oidc';

import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';

import { AppState } from './';
import {  Facebook } from 'ng2-cordova-oauth/core';

import { OauthBrowser } from 'ng2-cordova-oauth/platform/browser';
import { map } from 'rxjs/operators';

import { UserActions, MyPenmateActions, SearchActions } from '../actions';
import { ApiGateway } from './api-gateway.service';
import { MyPenmateService } from './my-penmates.service';
import { NativeClientService } from './nativeClient.service';
import { IG_OAUTH_CONFIG } from './oauth.config';
import { createSupabaseClient } from '../../lib/supabase';


import ErrorHandler from '../../lib/error-handler'

declare const AccountKit: any;
declare var Penmate;


@Injectable({ providedIn: 'root' })
export class AuthService {
  // jwtHelper: JwtHelper = new JwtHelper();
  idToken;
  currentUser;
  loggedIn: boolean = null;
  requestedScopes = 'openid profile email';
  supabase;

  constructor(
    public http: Http,
    public authHttp: HttpClient,
    public apiClient: ApiGateway,
    public router: Router,
    private storage: Storage,
    private store: Store<AppState>,
    private myPenmateService: MyPenmateService,
    private nativeService: NativeClientService,
    private myPenmateActions: MyPenmateActions,
    private userActions: UserActions,
    private searchActions: SearchActions,
    private oauthService: OAuthService,
  ) {
    this.storage.get('id_token').then(token => {
      this.idToken = token;
    });
    this.currentUser = store.select(state => state.user);
    this.supabase = createSupabaseClient();
  }

  handleAuthentication() {
    return new Observable(observer => {
      this.supabase.auth.getSession().then(({ data, error }) => {
        if (data && data.session) {
          window.location.hash = '';
          const headers = new Headers();
          headers.append('Content-Type', 'application/json');
          return this.http
            .post(
              `${Penmate.env.apiUrl}/login?type=web`,
              JSON.stringify({ supabase: data.session }),
              {
                headers,
              },
            )
            .pipe(map((res: any) => res.json()))
            .subscribe(
              data => {
                this.handleAuthSuccess(data, observer);
              },
              res => {
                const error = res.json();
                this.handleAuthError(error, observer);
              },
            );
        } else if (error) {
          return this.router.navigate(['/login']);
        }
      })

    });
  }

  handleInstagramLogin() {
    return new Observable(observer => {
      this.oauthService.events.subscribe(event => {
        if (event instanceof OAuthErrorEvent) {
          observer.error();
        }

        if (event instanceof OAuthSuccessEvent) {
          // code..
          const access_token = this.oauthService.getAccessToken();
          return this.onIGAuthenticateSuccess({ access_token }, observer);
        }
      });

      this.oauthService.tryLogin();
    });
  }

  createLoginLink = (email) => {
    const res =  this.apiClient.post(
      `${Penmate.env.apiUrl}/login-link`,
      {},
      { email, native: false }
    )
    res.subscribe(() => {}, (error) => {
      return ErrorHandler.captureMessage("Login Link Error", { error, email });
    })
    return res;
  }

  loginWithSupabase = (data) => {
    const res =  this.apiClient.post(
      `${Penmate.env.apiUrl}/login?type=web`,
      {},
      { supabase: data }
    )
    res.subscribe(() => {}, (error) => {
      return ErrorHandler.captureMessage("Login Link Error", { error, user: data.user });
    })
    return res;
  }

  checkLogin = (email) => {
    return this.apiClient.get(`${Penmate.env.apiUrl}/check-login`, { email: encodeURIComponent(email) });
  }

  requestPasswordReset = (email, create_password = false) => {
    return this.apiClient.post(`${Penmate.env.apiUrl}/email/reset-password`,  {}, { email: encodeURIComponent(email), create_password });
  }
  

  handleLoginNavigation = (navCtrl, savedSearch: Record<string, any> = {}) => {
    return new Observable(observer => {
      if (!navCtrl) {
        observer.next();
        observer.complete()
        return;
      };
      this.myPenmateService.getPenmates().subscribe(
        fetchedPenmates => {
          this.store.dispatch(this.myPenmateActions.loadPenmatesSuccess(fetchedPenmates));
          observer.next()
          observer.complete();
          if (window.location.pathname.match(/my-account/i)) {
            return;
          }
  
          if (savedSearch.savedSearchQuery) {
            this.store.dispatch(this.searchActions.clearSavedSearch());
            return navCtrl.navigateForward('/add-penmate', {
              state: { searchQuery: savedSearch.savedSearchQuery },
            });
          }
          if (fetchedPenmates.length === 0) {
            return navCtrl.navigateForward('/add-penmate');
          }
          return navCtrl.navigateForward('/my-penmates');
        },
        () => {
          navCtrl.navigateForward('/add-penmate');
        },
      );
    })
  }

  async isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiry = await this.storage.get('expires_at');
    const expiresAt = JSON.parse(expiry);
    return new Date().getTime() < expiresAt;
  }

  acceptTerms() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.authHttp.post(`${Penmate.env.apiUrl}/my-account/terms`, {}, { headers })
  }

  deleteAccount() {
    return this.apiClient.post(
      `${Penmate.env.apiUrl}/my-account/delete`,
      {},
      {},
    );
  }

  updateUser(user) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return new Observable(observer => {
      return this.authHttp
        .post(`${Penmate.env.apiUrl}/my-account`, { user }, { headers })
        .subscribe(
          data => {
            this.handleAuthSuccess(data, observer);
          },
          res => {
            this.handleAuthError(res, observer);
          },
        );
    });
  }

  addSocialAccount(provider, account) {
    return this.apiClient.post(
      `${Penmate.env.apiUrl}/add-social-account`,
      {},
      { provider, account },
    );
  }

  updateDeviceInfo(deviceInfo) {
    return this.apiClient.post(
      `${Penmate.env.apiUrl}/metrics/device`,
      {},
      { device_info: deviceInfo, deviceInfo },
    );
  }

  handleAuthSuccess(data, observer) {
    this.storage.set('user', data.user);
    this.storage.set('id_token', data.auth_token).then(() => {
      this.store
        .select(state => state.search.currentSearch)
        .take(1)
        .subscribe(penmate => {
          // Add pending search when present...
          if (penmate && penmate.first_name && penmate.last_name && penmate.state) {
            const req = this.myPenmateService
              .addPenmate(penmate)
              .finally(() => {
                observer.next(data.user);
                observer.complete();
              })
              .subscribe(
                myPenmates => {
                  this.store.dispatch(this.myPenmateActions.addPenmateSuccess(myPenmates));
                },
                err => {
                  this.store.dispatch(this.myPenmateActions.addPenmateError(err));
                },
              );
            return req;
          }
          //... or, just return authorized user
          observer.next(data.user);
          observer.complete();
        });
    });
  }

  handleAuthError(error, observer) {
    ErrorHandler.captureMessage('Login Error', { error });
    if (error.status === 500) {
      observer.error();
    } else {
      observer.error(error);
    }
  }

  validateToken() {
    return this.authHttp.get(`${Penmate.env.apiUrl}/validate-token`);
  }

  authenticate() {
    let isAuthenticated = false;

    return new Observable(observer => {
      this.storage.get('id_token').then(token => {
        this.idToken = token;
        return this.validateToken().subscribe(
          user => {
            isAuthenticated = true;
            observer.next({ isAuthenticated, user });
            observer.complete();
          },
          () => {
            observer.next({ isAuthenticated });
            observer.complete();
          },
        );
      });
    });
  }

  onNativeAuthenticateProvider(provider) {
    return new Observable(observer => {
      const response = this.nativeService.data;

      if (provider === 'instagram') {
        response.subscribe(({ type, payload }) => {
          if (type === 'AUTHENTICATE_IG_SUCCESS') {
            this.onIGAuthenticateSuccess(payload, observer);
          }
        });
        return this.nativeService.sendMessage({ type: 'AUTHENTICATE_IG' });
      }

      if (provider === 'facebook') {
        response.subscribe(({ type, payload }) => {
          if (type === 'AUTHENTICATE_FB_SUCCESS') {
            this.onFBAuthenticateSuccess(payload, observer);
          }
        });
        return this.nativeService.sendMessage({ type: 'AUTHENTICATE_FB' });
      }
    });
  }

  authenticateProvider(provider) {
    if (this.nativeService.active && provider) {
      return this.onNativeAuthenticateProvider(provider);
    }

    if (provider === 'instagram') {
      return this.authenticateIG();
    }

    if (provider === 'facebook') {
      return this.authenticateFB();
    }
  }

  authenticateFB() {
    const provider = 'facebook';

    return new Observable(observer => {
      this.currentUser
        .subscribe(user => {
          const account = get(user, `authentications.${provider}`);

          if (account && account.access_token) {
            observer.next({ provider, account });
            return observer.complete();
          }
          const oauth = new OauthBrowser();
          const client = new Facebook({
            clientId: Penmate.env.facebookAppId,
            appScope: ['email', 'public_profile', 'user_photos'],
            redirectUri: `${Penmate.env.rootUrl}/v2/facebook/callback`,
          });

          oauth.logInVia(client).then(
            resp => {
              this.onFBAuthenticateSuccess(resp, observer);
            },
            error => {
              ErrorHandler.captureMessage('Auth error: Login with Facebook', { error });
              observer.complete();
            },
          );
        })
        .unsubscribe();
    });
  }

  authenticateIG() {
    return new Observable(observer => {
      this.oauthService.configure(IG_OAUTH_CONFIG);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();
      this.oauthService.initImplicitFlow();
      observer.complete();
    });
  }

  onIGAuthenticateSuccess = async (authPayload, observer) => {
    const provider = 'instagram';
    const selfUrl = `https://graph.instagram.com/me?fields=id,username,account_type&access_token=${authPayload['access_token']}`;
    const response = await fetch(selfUrl);
    const userData = await response.json();
    const igAccount = { ...authPayload, ...userData };
    this.store.dispatch(this.userActions.addSocialAccount(provider, igAccount));
    observer.next({ provider, account: igAccount });
    observer.complete();
  };

  onFBAuthenticateSuccess = async (authPayload, observer) => {
    const provider = 'facebook';
    const fbAccount = Object.assign({}, authPayload, {
      accessToken: authPayload['access_token'],
    });
    this.store.dispatch(this.userActions.addSocialAccount(provider, fbAccount));
    observer.next({ provider, account: fbAccount });
    observer.complete();
  };

  logout() {
    return new Observable(observer => {
      this.storage.clear();
      this.supabase.auth.signOut()
      observer.next();
      observer.complete();
    });
  }

  auth0Logout() {
    return new Observable(observer => {
      observer.next();
      observer.complete();
    });
  }

  createSMSVerification(phoneNumber) {
    return this.apiClient.post(
      `${Penmate.env.apiUrl}/sms-verification`,
      {},
      { phone_number: phoneNumber},
    );
  }

  confirmSMSVerification(phoneNumber, code) {
    return this.apiClient.post(
      `${Penmate.env.apiUrl}/sms-verification-confirm`,
      {},
      { phone_number: phoneNumber, code },
    );
  }

  setupBillingIntent() {
    return this.apiClient.get(`${Penmate.env.apiUrl}/billing/setup-intent`, {});
  }

  updateBillingIntent(letter_id) {
    return this.apiClient.get(`${Penmate.env.apiUrl}/billing/update-intent`, { letter_id });
  }

  getPaymentMethod(id = null) {
    return this.apiClient.get(`${Penmate.env.apiUrl}/billing/payment-method`, { id });
  }
  getPendingIntent(invoiceId = null, letterId = null) {
    return this.apiClient
      .get(`${Penmate.env.apiUrl}/billing/pending-intent`, { id: invoiceId, letter_id: letterId })
  }
  updateCredits(invoiceId, creditOption) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/billing/${invoiceId}/update-credits`, {}, { creditOption })
  }

  updatePayment(invoiceId, payment) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/billing/${invoiceId}/update-payment`, {}, { payment })
  }

  processLetterPayment(letterId, payment) {
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/billing/${letterId}/process-payment`, {}, { payment })
      .pipe(map(json => json.letter))
  }
  
  purchaseCredits(payload) {
    return this.apiClient.post(
      `${Penmate.env.apiUrl}/billing/purchase-credits`, 
      {},
      payload
    );
  }
  confirmPayment(id) {
    return this.apiClient.get(`${Penmate.env.apiUrl}/billing/${id}/payment-intent`, {});
  }
  saveBilling(shouldSave) {
    return this.apiClient.post(
      `${Penmate.env.apiUrl}/billing/toggle-save`,
      {},
      { save_billing: !!shouldSave },
    );
  }

  updateVerificationStatus() {
    return this.apiClient.get(`${Penmate.env.apiUrl}/my-account/refresh-verification-status`, {});
  }

  sendProfileVerificationEmail() {
    return this.apiClient.post(`${Penmate.env.apiUrl}/my-account/send-verification-email`, {}, {});
  }

  createBillingSession() {
    return this.apiClient.post(`${Penmate.env.apiUrl}/billing/session`, {}, {});
  }

  createCheckoutSession( opts = {}) {
    return this.apiClient.post(`${Penmate.env.apiUrl}/billing/checkout-session`, {}, opts);
  }

  getCheckoutSession(sessionId: string) {
    return this.apiClient.get(`${Penmate.env.apiUrl}/billing/checkout/${sessionId}`, {});
  }
}
