import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { trigger, animate, style, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NavController,
  Platform,
  ModalController,
  AlertController,
  LoadingController,
} from '@ionic/angular';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Location, Appearance } from '@angular-material-extensions/google-maps-autocomplete';

import { UserActions, CreateMessageActions, MyPenmateActions, SearchActions } from '../actions';
import {
  AppState,
  LetterService,
  EventService,
  AuthService,
  MyPenmateService,
  NativeClientService,
  mapStates,
  mapCountries,
} from '../services';
import { debounce, get, orderBy, merge, isEmpty, includes, find, isUndefined } from 'lodash';
import { LoadingModal } from '../loading-modal/loading-modal';
import { MessageIndexPage } from '../message-index/message-index';
import { CreateMessageReturnAddress } from '../create-message-return-address/create-message-return-address';
import { MyPenmatesPage } from '../my-penmates/my-penmates';
import { AddPaymentSheet } from '../add-payment-sheet/add-payment-sheet';
import { AccountVerificationModal } from '../account-verification-modal/account-verification-modal';
import { AppDownloadModal } from '../app-download-modal/app-download-modal';
import { AppUpgradeModal } from '../app-upgrade-modal/app-upgrade-modal';
import { EmailDeliveryModal } from '../email-delivery-modal/email-delivery-modal';
import { SelectedPhotosModal } from '../selected-photos-modal/selected-photos-modal';

import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';

import ErrorHandler from '../../lib/error-handler'

import {
  StripeInstance,
  StripeFactoryService,
} from 'ngx-stripe';

import { Store } from '@ngrx/store';
import { debounceTime, startWith, distinctUntilChanged, takeWhile } from 'rxjs/operators';

import { Validators, FormBuilder } from '@angular/forms';
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

declare var Penmate;
declare var paypal;
declare var braintree;
declare var Rollbar;
declare var google;
declare var gtag;

/*
  Generated class for the ConfirmOrder page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-page-confirm-order',
  templateUrl: 'confirm-order.html',
  styleUrls: ['./confirm-order.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmOrderPage implements OnInit {
  public appearance = Appearance;
  stripe: StripeInstance;
  currentMessage;
  penmate;
  pageHeight;
  couponCode;
  pageModal;
  paymentRequired = true;
  showPaypalPayment;
  isPostcard;
  showPromoCodeInput = false;
  showIgPromo = false;
  hideBack = false;
  firstMessage = false;
  currentReplyOption = '';
  returnAddressValid = false;
  showEditAddress = true;
  GoogleAutocomplete;
  autocomplete;
  autocompleteItems = [];
  user;
  activeSubscription = false;
  pastDueSubscription = false;
  creditTopUp = false;
  onPromo = false;
  loadingTotal = false;
  orderOptionSelected = false;
  freeShipping = false;
  photoCredits = 0;
  STATE_NAMES;
  COUNTRY_NAMES;
  creditOptionForm;
  showEmessageDisabledMsg;
  math = Math;
  creditTopUpRequired = true;


  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public route: ActivatedRoute,
    public platform: Platform,
    public store: Store<AppState>,
    public createMessageActions: CreateMessageActions,
    public myPenmateActions: MyPenmateActions,
    public searchActions: SearchActions,
    public letterService: LetterService,
    public myPenmateService:   MyPenmateService,
    public eventService: EventService,
    public userActions: UserActions,
    public authService: AuthService,
    public nativeService: NativeClientService,
    public formBuilder: FormBuilder,
    private stripeFactory: StripeFactoryService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    public zone: NgZone,
  ) {
    this.pageHeight = this.platform.height();

    this.eventService.gaEventTrack('Confirm Order', {
      event_category: 'User',
    });
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    this.autocomplete = { input: '' };
    this.autocompleteItems = [];
    this.STATE_NAMES = mapStates.map(m => m['abbreviation']).sort();
    this.COUNTRY_NAMES = mapCountries.map(m => ({name: m['name'], code: m['code'] }))
      .sort((a, b) => a.name.localeCompare(b.name));
    this.COUNTRY_NAMES = [
      ...this.COUNTRY_NAMES.filter(country => country.code.toLowerCase() === 'us'),
      ...this.COUNTRY_NAMES.filter(country => country.code.toLowerCase() !== 'us')
    ];
  }

  ngOnInit() {
    // On load setup state...
    const url = new URL(window.location.href);
    const paymentIntentClientSecret = url.searchParams.get("setup_intent_client_secret");
    const paymentSucceeded = url.searchParams.get('paymentSucceeded');

    this.stripe = this.stripeFactory.create(Penmate.env.stripeKey);
    this.store
      .select((state) => state.user)
      .subscribe((user) => {
        this.user = user;
      });

    this.store
      .select((state) => state.createMessage)
      .subscribe((currentMessage) => {
        this.currentMessage = currentMessage;
        this.activeSubscription = !!currentMessage.meta.subscription;
        this.onPromo = !!currentMessage.meta.onPromo;
        this.photoCredits = currentMessage.meta.photoCredits
        this.creditTopUp = currentMessage.creditTopUp;
        this.pastDueSubscription = /past_due/i.test(currentMessage.meta.subscription);
        this.firstMessage = currentMessage.meta.firstMessage;
        this.freeShipping = currentMessage.meta.freeShipping;
        this.penmate = currentMessage.penmate;

        if (this.penmate) {
          this.penmate.showMap = true;
        }
        this.showPromoCodeInput = currentMessage.coupon;
        this.paymentRequired =
          currentMessage.priceAfterCoupon > 0 ||
          (currentMessage.priceAfterCoupon === 0 &&
            this.currentMessage.photo &&
            this.currentMessage.photoType !== 'regular');

        if (this.currentMessage.shippingMethod == 'letter' && this.currentMessage.meta.letterCreditBalance > 0) {
          const paymentDiff = this.onCalculateRegularTotal() - (this.currentMessage.meta.letterCreditBalance * 0.01)
          this.paymentRequired = this.onCalcMinTotal(paymentDiff) > 0;
        }
        if (this.currentMessage.meta.hasLetterCredit) {
          this.paymentRequired = this.currentMessage.meta.letterCreditsDue > this.currentMessage.meta.letterCreditAmt;
        }
        if (this.currentMessage.meta.hasLetterCredit || !this.currentMessage.meta.hasLetterCredit && this.user.messageCreditsAvailable) {
          this.creditTopUpRequired = this.currentMessage.meta.letterCreditsDue > this.currentMessage.meta.letterCreditAmt;
        }
        if (!this.user.has_sent_letter && 
            this.currentMessage.shippingMethod !== 'emessage'
          ) {
              
              this.paymentRequired = this.currentMessage.priceAfterCoupon > 0;
              if (this.currentMessage.meta.firstMessage) {
                this.creditTopUpRequired = this.currentMessage.photoType == 'glossy'
              } else {
                this.creditTopUpRequired = this.paymentRequired 
              }
            }

        if (!this.user.has_sent_letter && this.currentMessage.shippingMethod == 'emessage') {
          this.paymentRequired = this.currentMessage.priceAfterCoupon > 0;
          this.creditTopUpRequired = true
        }

        if (this.currentMessage.isResend) {
          this.creditTopUpRequired = false;
        }


        this.paymentRequired = this.currentMessage.priceAfterCoupon > 0;
        this.isPostcard = currentMessage.messageType === 'postcard';
        const couponLabel = get(currentMessage, 'coupon.code');
        this.couponCode = currentMessage.coupon ? couponLabel : null;
        this.isReturnAddressValid();
      });

    // Find penmate or redirect
    this.store
      .select((state) => state.createMessage)
      .take(2)
      .subscribe((currentMessage) => {
        const { penmate } = currentMessage;
        this.store.dispatch(this.createMessageActions.saveDraft(currentMessage));
        if (currentMessage.eMessage || currentMessage.messageType === 'postcard') {
          this.orderOptionSelected = true;
        }
        this.eventService.track('confirm-order', {
          value: this.currentMessage.price,
        });
        this.currentReplyOption =
          get(currentMessage, 'returnAddress.addressType', '') === 'physical'
            ? 'physical'
            : 'email';
        this.store.dispatch(
          this.createMessageActions.setMessage({
            returnAddress: {
              email: this.user.email,
              ...currentMessage.returnAddress,
            },
          }),
        );
        if (penmate && penmate.id) {
          this.store.dispatch(this.myPenmateActions.viewPenmate(penmate));
          return this.store.dispatch(this.myPenmateActions.loadMessages(penmate.id));
        }

        // Lookup penmate or redirect
        const penmateId = this.route.snapshot.paramMap.get('id');
        this.store
          .select((state) => state.myPenmates.penmates)
          .take(2)
          .subscribe((penmates) => {

            if (penmates.length === 0 && !paymentIntentClientSecret && !paymentSucceeded) {
              return this.navCtrl.navigateRoot('/penmates');
            }

            const pmate = find(penmates, (p) => p && p.id === parseInt(penmateId, 10));
            if (pmate) {
              this.store.dispatch(this.createMessageActions.setMessage({ penmate: pmate }));
            }
            
            if (!pmate && !paymentIntentClientSecret && !paymentSucceeded) {
              this.navCtrl.navigateRoot('/penmates');
            }
          });
      });

    //

    // on Auth validate/setup user state...
    this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
      if (!isAuthenticated || !user) {
        const route = window.location.pathname.replace(/^\//, '');
        this.store.dispatch(this.searchActions.saveRoute(route));
        return this.navCtrl.navigateRoot('/login');
      }
      this.store.dispatch(this.userActions.loginUser(user));
      if (paymentIntentClientSecret) {
        this.myPenmateService.getPenmates().subscribe(penmates => {
          this.store.dispatch(this.myPenmateActions.loadPenmatesSuccess(penmates));
          this.store.dispatch(this.createMessageActions.fetchDrafts());
          this.onAddPayment()
        })
      }

    });
  }

  ionViewDidEnter() {
    this.onSetDefaultReturnAddress();
    this.onSetDefaultShippingMethod();
    this.onAddDefaultPaymentMethod();
  }

  ionViewWillEnter(){
    this.onInitCreditOptionForm();
  }


  onInitCreditOptionForm() {
    this.store
    .select(({ createMessage }) => ({ createMessage }))
    .take(5)
    .pipe(debounceTime(1500))
    .subscribe(({ createMessage: currentMessage }) => {
      const photos = currentMessage.selectedPhotos || []
      const mailboxEnabled = get(currentMessage, 'meta.mailboxEnabled') || false
      const defaultCreditAmt = mailboxEnabled || this.currentMessage.messageType === 'postcard' && !this.user.has_sent_letter ? "1" : "5"
      let defaultOption = photos.length > 1 && this.currentMessage.messageType != 'postcard'  ? "5" : defaultCreditAmt;
      if (currentMessage.meta.letterCreditsDue > 5) {
        defaultOption = '10';
      }
      this.creditOptionForm = this.formBuilder.group({
        creditOption: [],
      });

      this.creditOptionForm.valueChanges.pipe(
        distinctUntilChanged(),
        startWith(this.creditOptionForm.value),
      ).subscribe(({ creditOption }) => {
        if (creditOption) {
          this.currentMessage.creditOption = creditOption;
          this.store.dispatch(this.createMessageActions.setMessage({ creditOption }));
          this.loadingTotal = true;
          this.onSaveDraft();
        }
        this.store
        .select((state) => state.createMessage)
        .pipe(debounceTime(1200))
        .take(1)
        .subscribe(() => {
          this.loadingTotal = false;
        });

      });
      if (this.currentMessage.id) {
        this.creditOptionForm.setValue({
          creditOption: defaultOption,
        })
      }
    })
  }
  
  onAddDefaultPaymentMethod() {
    this.authService.getPaymentMethod().subscribe((payment) => {
      this.store
        .select(({ createMessage, user }) => ({ createMessage, user }))
        .take(5)
        .subscribe(({ createMessage: currentMessage, user }) => {
          if (currentMessage.payment || !user.saveBilling || user.messageCreditsAvailable) {
            return;
          }
          this.currentMessage.payment = payment;
          this.currentMessage.coupon = !user.hasSentLetter ? currentMessage.coupon : null;
          this.store.dispatch(this.createMessageActions.saveDraft(this.currentMessage));
        });
    });
  }

  onSetDefaultReturnAddress = () => {
    this.store
      .select(({ createMessage }) => ({ createMessage }))
      .take(5)
      .pipe(debounceTime(1500))
      .subscribe(({ createMessage: currentMessage }) => {
        const { defaultReturnAddress = {} } = currentMessage;
        if (
          !get(currentMessage, 'returnAddress.email_address_line1') &&
          !get(currentMessage, 'returnAddress.address_line1')
        ) {
          this.store.dispatch(
            this.createMessageActions.setMessage({
              returnAddress: defaultReturnAddress,
            }),
          );
        }
      });
  };

  onSetDefaultShippingMethod = () => {
    this.store
      .select(({ createMessage, user }) => ({ createMessage, user }))
      .take(4)
      .subscribe(({ createMessage: currentMessage, user }) => {
        if (currentMessage.messageType === 'postcard') {
           this.currentMessage.shippingMethod = 'letter';
           this.currentMessage.eMessage = null;
           return;
        }
        this.currentMessage.shippingMethod = this.currentMessage.eMessage ? 'emessage' : 'letter';
        if (this.currentMessage.shippingMethod == 'emessage' && !this.currentMessage.meta.mailboxEnabled && user && user.hasSentEmessage && user.sentLetterTotal <= 2) {
          this.currentMessage.shippingMethod = 'letter'
          this.showEmessageDisabledMsg = true
        }
      })
  };

  onAddPayment() {
    const url = new URL(window.location.href);
    const messageId = url.searchParams.get("messageId");
    if (messageId && messageId.length) {
      return this.letterService.getLetterWithPenmate(messageId).subscribe( async ({ letter, penmate }) => {
        this.store.dispatch(
          this.createMessageActions.setMessage({
            ...letter,
            penmate
          }),
        );
        this.store
        .select(({ createMessage }) => ({ createMessage }))
        .pipe(takeWhile(({ createMessage: currentMessage }) => !currentMessage || currentMessage.id !== parseInt(messageId), true))
        .subscribe(() => {
          this.onOpenPaymentModal();
        })
      }, async () => {
        const alert = await this.alertCtrl.create({
          header: `Payment Error`,
          cssClass: 'error-alert',
          message: 'There was an error processing your payment',
          buttons: [
            {
              text: 'Try Again',
              handler: () => {
                this.onCancel()
              },
            },
          ],
        });
        return alert.present();
      }) 
    }
    return this.onOpenPaymentModal();
  }

  onOpenPaymentModal = () => {
    const url = new URL(window.location.href);
    const paymentIntentClientSecret = url.searchParams.get("setup_intent_client_secret");
    const messageId = url.searchParams.get("messageId");
    const isEmessage = /emessage/i.test(this.currentMessage.shippingMethod)
    const creditOption = this.currentMessage.creditOption;
    const paymentDescription =  !!creditOption && this.creditTopUpRequired ?  `${creditOption} ${parseInt(creditOption) > 1 ? 'credits' : 'credit'}` : null;
    const sheetRef = this.bottomSheet.open(AddPaymentSheet, {
      data: {
        creditTopUp: this.creditTopUp,
        creditOption,
        amount: this.onCalculateTotalWithTax(),
        processingFee: this.currentMessage.meta.processingFee * 0.01,
        letterId: this.currentMessage.id,
        user: this.user,
        paymentDescription,
      },
      panelClass: 'payment-sheet',
    });

    sheetRef.afterDismissed().subscribe(async (currMessage) => {
      this.orderOptionSelected = true;
      this.showEditAddress = false;
      if(currMessage && currMessage.payment) {
        this.currentMessage.payment = currMessage.payment;
        this.currentMessage.shippingMethod = currMessage.shippingMethod
        this.currentMessage.coupon = null;
        this.onSaveDraft(250)
      }
      if (paymentIntentClientSecret) {
        if (isEmpty(this.currentMessage.penmate) || !this.currentMessage.penmate.id || messageId && parseInt(messageId) !== this.currentMessage.id) {
          
          return this.letterService.getLetterWithPenmate(messageId).subscribe( async ({ letter, penmate }) => {
            this.store.dispatch(
              this.createMessageActions.setMessage({
                ...letter,
                penmate
              }),
            );
            const alert = await this.alertCtrl.create({
              header: `Review your message`,
              backdropDismiss: false,
              message: 'Your payment method has been updated. Please double-check your message before sending.',
              buttons: [
                {
                  text: 'Ok, got it',
                  handler: () => {
                    const routePath = window.location.pathname.split('/confirm-order')[0]
                    this.router.navigateByUrl(routePath);
                  },
                },
              ],
            })
            return alert.present()
          })
        }
      }
    });
  }

  onEmailReturnAddressChanged(event, label) {
    this.store.dispatch(
      this.createMessageActions.setMessage({
        returnAddress: {
          ...get(this.currentMessage, 'returnAddress', {}),
          addressType: 'email',
          [label]: event.target.value,
        },
      }),
    );
  }

  onReturnAddressAutocompleteChange = (value) => {
    this.onEmailReturnAddressChanged({ target: { value } }, 'email_address_line1');
  };

  onAutocompleteSelected(result) {
    const address_components = get(result, 'address_components', []);
    if (isEmpty(address_components)) {
      return;
    }
    const cityData = find(address_components, ({ types }) => includes(types, 'locality'));
    const stateData = find(address_components, ({ types }) =>
      includes(types, 'administrative_area_level_1'),
    );
    const zipData = find(address_components, ({ types }) => includes(types, 'postal_code'));
    let formatted_address = get(result, 'formatted_address');
    formatted_address = !isEmpty(formatted_address)
      ? formatted_address.replace(', USA', '')
      : formatted_address;
    const address = {
      email_address_line1: formatted_address,
      address_city: get(cityData, 'short_name'),
      address_state: get(stateData, 'short_name'),
      address_zip: get(zipData, 'short_name'),
      formatted_address,
    };
    this.store.dispatch(
      this.createMessageActions.setMessage({
        returnAddress: {
          ...get(this.currentMessage, 'returnAddress', {}),
          addressType: 'email',
          ...address,
        },
      }),
    );
    this.isReturnAddressValid();
  }

  onSubmitAddress() {
    this.showEditAddress = false;
    this.store.dispatch(
      this.createMessageActions.setMessage({
        returnAddress: {
          ...get(this.currentMessage, 'returnAddress', {}),
          addressType: 'email',
        },
      }),
    );
    this.onSaveDraft(350);
    this.eventService.track('return-address-saved', {
      addressType: 'email',
      letter: this.currentMessage.id,
    });
  }

  onToggleEmailDelivery() {
    this.loadingTotal = false;
    this.couponCode = null;
    this.currentMessage.coupon = null;
    this.onShowEmailDeliveryModal();
  }

  onShowEmailDeliveryModal() {
    return new Promise(async (resolve, reject) => {
      this.modalCtrl
        .create({
          component: EmailDeliveryModal,
        })
        .then(async (modal) => {
          await modal.present();
          modal.onDidDismiss().then((payload: any) => {
            const addCredits = get(payload, 'data.addCredits');
            // process payload
          });
        })
        .finally(() => {
          resolve(null);
        });
    });
  }


  onTogglePremiumUpgrade(isSubscription = false) {
    this.loadingTotal = false;

    // this.onChangeShipping({ detail: { value: 'letter' } });
    if (!isSubscription || this.activeSubscription) {
      if ( this.activeSubscription ||
        (this.currentMessage.selectedPhotos.length == 0 &&
          !this.user.has_sent_letter &&
          !this.isPostcard)
      ) {
        return;
      }
      this.couponCode = null;
      this.currentMessage.coupon = null;
      this.orderOptionSelected = true;
      return this.onSavePaymentOption(false);
    }
    this.couponCode = null;
    this.currentMessage.coupon = null;
    this.orderOptionSelected = true;
    this.onShowAppUpgradeModal();
  }

  onShowAppUpgradeModal() {
    return new Promise(async (resolve, reject) => {
      this.modalCtrl
        .create({
          component: AppUpgradeModal,
        })
        .then(async (modal) => {
          await modal.present();
          modal.onDidDismiss().then((payload: any) => {
            const addCredits = get(payload, 'data.addCredits');
            if (isUndefined(addCredits)) {
              return;
            }
            this.creditTopUp = addCredits;
            this.onSavePaymentOption(this.creditTopUp);
          });
        })
        .finally(() => {
          resolve(null);
          this.eventService.track('show-app-upgrade', {
            letter_id: this.currentMessage.id,
          });
        });
    });
  }

  onSavePaymentOption(isCreditTopUp) {
    this.creditTopUp = isCreditTopUp;
    this.currentMessage.isCreditTopUp = this.creditTopUp;
    this.loadingTotal = true;
    this.store.dispatch(
      this.createMessageActions.setMessage({
        creditTopUp: this.creditTopUp,
      }),
    );
    this.onSaveDraft(250);
    this.store
      .select((state) => state.createMessage)
      .pipe(debounceTime(800))
      .subscribe(() => {
        this.loadingTotal = false;
      });
  }

  onReturnAddressInfo = async () => {
    this.eventService.track('show-return-address-info');
    const alert = await this.alertCtrl.create({
      header: `Email Replies`,
      cssClass: 'return-address-info',
      message: `
      Use this option if you would only like replies delivered to your e-mail. Return mail will come
      to Penmate and you will be notified by e-mail. <br/><br/> If you would like
       ${this.penmate.name} to write back to you directly, choose "Add Return Address".`,
      buttons: [
        {
          text: 'OK',
        },
      ],
    });
    await alert.present();
  };


  onConfirmEmessage = async () => {
    this.eventService.track('emessage-confirm-alert');
    const message = `E-messages may be reviewed by the facility.  If your message
        is unable to be delivered by E-message it will be delivered by USPS Mail. <br/><br/> Do not
        send anything inappropriate or your message may be rejected. <span>You will be notified
        by email when your message is delivered.</span>`

    const alert = await this.alertCtrl.create({
      header: `Reminder`,
      cssClass: 'reminder-alert',
      message,
      buttons: [

        {
          text: 'Cancel',
          handler: () => {
            this.eventService.track('emessage-cancel');
          }
        },
        {
          text: 'OK, Send',
          handler: () => {
            this.onSubmit({ confirm: true})
          },
        },
      ],
    });
    await alert.present();
  };


  onConfirmSendMessage = async () => {
    this.eventService.track('message-send-confirm');
    const message = `Are you ready to send this message?`

    const alert = await this.alertCtrl.create({
      header: `Send Message`,
      cssClass: 'reminder-alert confirm-message-alert',
      message,
      buttons: [

        {
          text: 'Cancel',
          handler: () => {
            this.eventService.track('message-send-cancel');
          }
        },
        {
          text: 'Yes, send',
          handler: () => {
            this.onSubmit({ confirm: true})
          },
        },
      ],
    });
    await alert.present();
  };


  onToggleFirstMessageCoupon = async () => {
    this.loadingTotal = true;
    const loading = await this.loadingCtrl.create({ spinner: 'dots' });
    await loading.present();
    this.couponCode = '#sendlove';
    this.firstMessage = true;
    this.orderOptionSelected = true;
    this.currentMessage.photoType = 'regular';
    this.letterService.addCoupon(this.couponCode).subscribe((resp) => {
      loading.dismiss();
      this.loadingTotal = false;
      this.currentMessage.coupon = (resp as any).coupon;
      this.store.dispatch(this.createMessageActions.removePaymentInfo());
      this.store.dispatch(this.createMessageActions.saveDraft(this.currentMessage));
      this.onSavePaymentOption(false);
    });
  };

  onReplyOptionChange = (event) => {
    const value = event.target.value;
    this.currentReplyOption = value;
    this.showEditAddress = true;
    this.store.dispatch(
      this.createMessageActions.setMessage({
        returnAddress: {
          ...get(this.currentMessage, 'returnAddress', {}),
          addressType: value,
        },
      }),
    );
    this.isReturnAddressValid();
  };

  onReturnAddressEmailChange = (event) => {
    const value = event.target.value;
    this.store.dispatch(
      this.createMessageActions.setMessage({
        returnAddress: {
          ...get(this.currentMessage, 'returnAddress', {}),
          addressType: 'email',
          email: value,
        },
      }),
    );
    this.isReturnAddressValid();
  };

  onReturnAddressMailingAddressChange = (event) => {
    const value = event.target.value;
    this.store.dispatch(
      this.createMessageActions.setMessage({
        returnAddress: {
          ...get(this.currentMessage, 'returnAddress', {}),
          addressType: 'email',
          email_address_line1: value,
        },
      }),
    );
    this.isReturnAddressValid();
  };

  onReturnAddressNameChange = (event, nameType) => {
    const value = event.target.value;
    const nameParts = {
      firstName: nameType === 'first' ? value : get(this.currentMessage, 'returnAddress.firstName'),
      lastName: nameType === 'last' ? value : get(this.currentMessage, 'returnAddress.lastName'),
    };
    this.store.dispatch(
      this.createMessageActions.setMessage({
        returnAddress: {
          ...get(this.currentMessage, 'returnAddress', {}),
          addressType: 'email',
          ...nameParts,
          name:
            !isEmpty(nameParts.firstName) && !isEmpty(nameParts.lastName)
              ? `${nameParts.firstName} ${nameParts.lastName}`
              : null,
        },
      }),
    );
    this.isReturnAddressValid();
  };

  onToggleReturnAddressButton = (addressValid) => {
    // if (!!addressValid && this.currentReplyOption === 'physical') {
    //   return;
    // }
    this.currentReplyOption = 'physical';
    this.onAddReturnAddress();
  };

  onAddReturnAddress = async () => {
    if (this.currentReplyOption === 'email') {
      this.showEditAddress = true;
      return;
    }
    this.eventService.track('show-add-return-address');
    this.currentReplyOption = 'physical';
    this.showEditAddress = true;
    const penmateId = this.route.snapshot.paramMap.get('id');
    this.pageModal = await this.modalCtrl.create({
      component: CreateMessageReturnAddress,
      componentProps: { penmateId },
    });
    this.store.dispatch(
      this.createMessageActions.setMessage({
        returnAddress: {
          ...get(this.currentMessage, 'returnAddress', {}),
          addressType: 'physical',
        },
      }),
    );
    this.eventService.track('return-address-saved', {
      addressType: 'physical',
      letter: this.currentMessage.id,
    });
    await this.pageModal.present();
  };

  isReturnAddressValid = () => {
    const addressType = get(this.currentMessage, 'returnAddress.addressType');
    this.returnAddressValid = false;
    const firstName = get(this.currentMessage, 'returnAddress.firstName');
    const lastName = get(this.currentMessage, 'returnAddress.lastName');
    const name = get(this.currentMessage, 'returnAddress.name');
    const addressLine1 = get(this.currentMessage, 'returnAddress.address_line1');

    const emailAddressLine1 = get(this.currentMessage, 'returnAddress.email_address_line1');
    const addressCity = get(this.currentMessage, 'returnAddress.address_city');
    const addressState = get(this.currentMessage, 'returnAddress.address_state');
    const addressZip = get(this.currentMessage, 'returnAddress.address_zip');
    const email = get(this.currentMessage, 'returnAddress.email', '');
    this.returnAddressValid = ( (!isEmpty(firstName) && firstName.replace(/\./g, "") > 1 && !isEmpty(lastName)) || !isEmpty(name)) && !isEmpty(addressLine1)
    if (this.returnAddressValid  && !isEmpty(addressCity)) {
      return this.returnAddressValid;
    }

    return this.returnAddressValid && !isEmpty(addressZip);

  };

  onSaveDraft = (timeout = 0) => {

    if (this.currentMessage.meta.hasLetterCredit) {
      this.paymentRequired = this.currentMessage.meta.letterCreditsDue > this.currentMessage.meta.letterCreditAmt;
    }
    setTimeout(() => {
      this.store.dispatch(this.createMessageActions.saveDraft(this.currentMessage));
    }, timeout);
  };

  onSendEmessage() {
    this.pageModal = this.modalCtrl
      .create({
        component: EmailDeliveryModal,
        componentProps: {
          penmateData: this.currentMessage ? this.currentMessage.penmate : null,
        }
      })
      .then(async (modal) => {
        await modal.present();
        modal.onDidDismiss().then((payload: any) => {
          const eMessage = get(payload, 'data.eMessage');
          this.currentMessage.shippingMethod = eMessage ? 'emessage' : this.currentMessage.shippingMethod;
          const canceled = get(payload, 'data.cancel')
          if (canceled) {
            this.currentMessage.shippingMethod = 'letter'
          }
          return this.store.dispatch(this.createMessageActions.saveDraft({
            ...this.currentMessage, 
            eMessage: eMessage || this.currentMessage.eMessage,
          }));
        });
      })
  }

  onShowSelectedPhotos = async () => {
    this.pageModal = await this.modalCtrl.create({
      component: SelectedPhotosModal,
      componentProps: {
        photos: this.currentMessage.selectedPhotos,
      },
    });
    await this.pageModal.present({ updateUrl: false });
  };

  hasPhotos() {
    return this.currentMessage.selectedPhotos && this.currentMessage.selectedPhotos.length > 0;
  }

  onChangeEmessage = (evt) => {
    if (this.currentMessage.eMessage) {
      this.loadingTotal = true;
      const shippingMethod = 'emessage';
      this.currentMessage.shippingMethod = shippingMethod
      this.store.dispatch(this.createMessageActions.setMessage({ shippingMethod }));
      this.onSaveDraft();
      this.store
        .select((state) => state.createMessage)
        .pipe(debounceTime(1200))
        .subscribe(() => {
          this.loadingTotal = false;
        });
      return setTimeout(() => {
        this.currentMessage.shippingMethod = shippingMethod;
      }, 1500)
    }
    this.onSendEmessage()
  }


  onChangeShipping = (evt) => {
    const shippingMethod = get(evt, 'detail.value');
    if (!shippingMethod) { return; }

    if (shippingMethod === 'emessage' ) {
       return ;
    }

    this.loadingTotal = true;
    this.currentMessage.shippingMethod = shippingMethod;
    this.store.dispatch(this.createMessageActions.setMessage({ shippingMethod }));
    this.onSaveDraft(250);
    this.store
      .select((state) => state.createMessage)
      .pipe(debounceTime(1200))
      .take(1)
      .subscribe((currentMessage) => {
        this.loadingTotal = false;
        if (currentMessage && ((currentMessage.meta.letterCreditAmt || 0) + 5 < currentMessage.meta.letterCreditsDue)) {
          this.currentMessage.creditOption = '10';
          if (this.creditOptionForm) {
            this.creditOptionForm.setValue({
              creditOption: '10',
            })
          }
        }
      });
    setTimeout(() => {
      this.currentMessage.shippingMethod = shippingMethod;
    }, 1500)
  };

  onChangePhotoPrinting = (evt) => {
    const photoType = get(evt, 'detail.value');
    if (photoType) {
      this.loadingTotal = true;
      this.currentMessage.photoType = photoType;
      this.store.dispatch(this.createMessageActions.setMessage({ photoType }));
      this.paymentRequired = photoType !== 'regular';
      this.onSaveDraft();
      this.store
        .select((state) => state.createMessage)
        .pipe(debounceTime(1200))
        .take(1)
        .subscribe((currentMessage) => {
          this.loadingTotal = false;
          if (currentMessage && ((currentMessage.meta.letterCreditAmt || 0) + 5 < currentMessage.meta.letterCreditsDue)) {
            this.currentMessage.creditOption = '10';
            if (this.creditOptionForm) {
              this.creditOptionForm.setValue({
                creditOption: '10',
              })
            }
          }
        })
    }
  };

  canShowEmessageReminder = () => {
    if (
        !this.isPostcard && 
        !this.currentMessage.isResend && 
        this.currentMessage.shippingMethod !== 'emessage' && 
        (this.currentMessage.price > 0 || !this.user.hasSentEmessage)
    ) {
        return !!this.currentMessage.emessageAvailable;
    }
    return false;
  }

  onShowPaymentModal = async () => {
    this.pageModal = await this.modalCtrl.create({ component: AddPaymentSheet });
    await this.pageModal.present({ updateUrl: false });
  };

  onShowVerifyAccountModal = async () => {
    this.pageModal = await this.modalCtrl.create({ component: AccountVerificationModal });
    this.pageModal.onDidDismiss().then(() => {
      this.authService.authenticate().subscribe(({ user }) => {
        if (user.phone_number_verified) {
          this.onSubmit({ skipVerify: true });
        }
      });
    });
    return this.pageModal.present();
  };

  onToggleCoupon = async () => {
    const loading = await this.loadingCtrl.create({ spinner: 'dots' });

    if (this.currentMessage.coupon) {
      this.currentMessage.coupon = null;
      this.currentMessage.photoType = 'regular';
      this.loadingTotal = false;
      return this.store.dispatch(this.createMessageActions.saveDraft(this.currentMessage));
    }

    await loading.present();
    this.letterService.addCoupon(this.couponCode).subscribe((resp) => {
      loading.dismiss();
      this.loadingTotal = false;
      if (resp === 404) {
        return this.onDisplayErrorAlert('Invalid code', 'OK');
      }
      if ((resp as any).error) {
        return this.onDisplayErrorAlert('Invalid code', 'OK');
      }
      this.eventService.track('add-promo-code', {
        code: this.couponCode,
        penmate_id: get(this.currentMessage, 'penmate.id'),
      });
      this.currentMessage.coupon = (resp as any).coupon;
      this.currentMessage.photoType = 'regular';
      this.store.dispatch(this.createMessageActions.removePaymentInfo());
      this.store.dispatch(this.createMessageActions.saveDraft(this.currentMessage));
    });
  };
  

  removePayment = async () => {
    const confirmRemove = await this.alertCtrl.create({
      header: 'Remove this payment method?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Yes',
          cssClass: 'ok',
          handler: () => {
            this.store.dispatch(this.createMessageActions.removePaymentInfo());
            this.authService.saveBilling(false).subscribe();
          },
        },
      ],
    });
    await confirmRemove.present();
  };

  onDisplayErrorAlert = async (titleText, buttonText, subHeader = null) => {
    const alert = await this.alertCtrl.create({
      cssClass: 'error-alert',
      header: titleText,
      subHeader,
      buttons: [
        {
          text: buttonText,
        },
      ],
    });
    await alert.present();
  };

  onCancel = () => {
    const penmateId = this.penmate.id;
    const url = new URL(window.location.href);
    const paymentIntentClientSecret = url.searchParams.get("setup_intent_client_secret");
    const paymentSucceeded = url.searchParams.get('paymentSucceeded');

    if (!penmateId && !paymentIntentClientSecret && !paymentSucceeded) {
      return false;
    }
    const routePath = window.location.pathname.split('/confirm-order')[0]
    this.router.navigateByUrl(routePath);
  };

  onShowAppDownloadModal = () => {
    return new Promise((resolve, reject) => {
      this.modalCtrl
        .create({
          component: AppDownloadModal,
        })
        .then(async (modal) => {
          await modal.present();
        })
        .finally(() => {
          resolve(null);
          this.eventService.track('show-app-download', {
            letter_id: this.currentMessage.id,
          });
        });
    });
  };

  onTrackConversion = () => {

    const newUserValue = 150;
    const price = this.currentMessage.priceAfterCoupon;
    const formattedValue = (this.paymentRequired && !!price ? price : newUserValue) * 0.01;
    
    this.eventService.gaEventTrack('Sent Message', {
      event_category: 'User',
      value: formattedValue
    });
    this.eventService.awConversion(formattedValue)

    this.eventService.track('submit-letter-success', {
      letter_id: this.currentMessage.id,
      message_type: this.currentMessage.messageType,
      penmate_id: get(this.currentMessage, 'penmate.id'),
      purchased: !!this.paymentRequired,
      value: this.currentMessage.priceAfterCoupon,
      emessage: !!this.currentMessage.emessage
    });

    if (!!this.paymentRequired) {
      this.eventService.track('paid-order', {
        order_id: this.currentMessage.id,
        message_type: this.currentMessage.messageType,
        '$price': this.currentMessage.priceAfterCoupon,
        price: this.currentMessage.priceAfterCoupon,
      })
    }
    if (!!this.currentMessage.eMessage) {
      this.eventService.track('emessage-send', {
        order_id: this.currentMessage.id,
        price: this.currentMessage.price,
        value:  this.currentMessage.price
      })
    }
  };

  onCalculateTotal() {
    const priceKey = this.couponCode ? 'priceAfterCoupon' : 'price';
    return get(this.currentMessage, priceKey) * 0.01;
  }

  onCalculateTax() {
    if (this.currentMessage) {
      const {deliveryOptions}  = get(this.currentMessage, 'meta', {});
      const shippingMethod = this.currentMessage.shippingMethod;
      if (shippingMethod && !/letter/i.test(shippingMethod) && deliveryOptions['emessage']) {
        return deliveryOptions['emessage'] * 0.01
      }
      return this.onCalculateTotal() * 0.029 + 0.3;
    }
  }
  onCalcMinTotal(amount) {
    return amount < 0.5 ? 0 : amount;
  }

  onCalculateTotalWithTax() {
    if (this.currentMessage) {
      const {deliveryOptions, creditOptions, processingFee}  = get(this.currentMessage, 'meta', {});
      const creditOption = creditOptions[get(this.currentMessage, 'creditOption')];
      const shippingMethod = this.currentMessage.shippingMethod
      const subtotal = this.onCalculateTotal() + (processingFee * 0.01);
      if (this.couponCode && 
        this.currentMessage.meta.firstMessage &&
        !this.isPostcard && 
        !this.user.has_sent_letter && 
        this.currentMessage.shippingMethod != 'emessage' &&
        this.currentMessage.photoType == 'regular'
      ){
        return 0;
      }
      return subtotal
    }
  }

  onCalcLetterCreditApplied() {
    if (this.currentMessage) {
      const letterCredit = this.currentMessage.meta.letterCreditBalance * 0.01;
      const { processingFee }  = get(this.currentMessage, 'meta', {});
      const subtotal = this.onCalculateTotal() + (processingFee * 0.01);
      let letterCreditAmt = this.currentMessage.meta.letterCreditApplied * 0.01;
      if (this.currentMessage.meta.letterCreditApplied > 0) {
        if (subtotal >= letterCredit ) {
          const diff = subtotal - letterCredit;
          letterCreditAmt = (diff < 0.5 ? letterCreditAmt + diff : letterCreditAmt)
        }
      }
  
      return letterCreditAmt;
    }
  }

  onCalculateRegularTotal() {
    if (this.currentMessage) {
      const {deliveryOptions, creditOptions, processingFee}  = get(this.currentMessage, 'meta', {});
      return this.onCalculateTotal() + (processingFee * 0.01);
    }
  }


  onCalculatePremiumPhotos() {
    const unitCost = this.currentMessage.meta.pricePerPhoto * 0.01;
    if (isEmpty(this.currentMessage.selectedPhotos) || 
      this.photoCredits > this.currentMessage.selectedPhotos.length
     ) {
      return unitCost;
    }
    const net_photos = this.currentMessage.selectedPhotos.length - this.photoCredits;
    const cost = unitCost * net_photos
    return cost;
  }

  onShowPhoneNumberVerify() {
    this.eventService.track('show-phone-number-verify');
    return this.onShowVerifyAccountModal();
  }

  hasFreeShipping() {
    const showFreeShipping = this.user.creditBalance > 0 || 
    this.creditTopUp || 
    this.currentMessage.coupon &&  this.currentMessage.coupon.active ||  
    !this.currentMessage.coupon && this.currentMessage.selectedPhotos.length == 0 && (this.activeSubscription) || 
    this.freeShipping || 
    this.isPostcard && !this.user.has_sent_letter
    return showFreeShipping;
  }

  onHandleSubmitError = (res) => {
    const letter = get(res, 'error.letter'); 
    const sale_object = get(letter, 'sale', {});
    this.eventService.track('submit-letter-error', {
      letter_id: this.currentMessage.id,
      message_type: this.currentMessage.messageType,
      penmate_id: get(this.currentMessage, 'penmate.id'),
      error: sale_object.error || res.error,
    });
    if (sale_object.error || get(res.error, 'payment_error')) {
      let payment_error = sale_object.error || get(res.error, 'payment_error');
      const generic_payment_err = 'There was an error with your payment method. Please try again or use a different payment card.'
      payment_error = /PaymentIntent|payment_intent/i.test(payment_error) ? generic_payment_err : payment_error;
      this.store.dispatch(this.createMessageActions.removePaymentInfo());
      return this.onDisplayErrorAlert('Payment Error', 'Try again', payment_error);
    }

    const paymentAction = get(sale_object, 'payment_action.requires_action') || false;
    const paymentIntentId = get(sale_object, 'payment_action.payment_intent_client_secret');
    if (paymentAction && paymentIntentId) {
      return this.stripe.confirmCardPayment(paymentIntentId).subscribe(async (res)=> {
        if (res.error) {
          return this.onDisplayErrorAlert('Payment Error', 'Try again', res.error.message);
        }
        const paymentIntent = get(res, 'paymentIntent', {});
        const { id: intent_id, payment_method, client_secret } = paymentIntent;
        const intentPayload = {  intent_id, payment_method, client_secret }
        const loadingModal = await this.modalCtrl.create({
          component: LoadingModal,
          componentProps: {
            message: 'Sending Message...',
          },
        });
        await loadingModal.present();
        this.letterService.confirmPayment(letter.id, intentPayload).subscribe(async (res) => {
          await loadingModal.dismiss();
          if (res.error) {
            return this.onDisplayErrorAlert('Payment Error', 'Try again');
          }
          this.onHandleSubmitSuccess();
        })
      })
    }

    ErrorHandler.captureMessage('Submit Letter Error', { error: res.error });
    return this.onDisplayErrorAlert('Sorry, there was an error', 'Try again');
  }

  onConfirmMissingPhotos = async () => {
    const alert = await this.alertCtrl.create({
      header: `Dont forget your free photos!`,
      cssClass: 'return-address-info',
      message: `Photos are free with your first message, you only pay shipping.  Would you like to add photos?`,
      buttons: [
        {
          cssClass: 'secondary',
          text: 'No, send without photos',
          handler: () => {
            this.onSubmit({ skipConfirmPhotos: true, confirm: true});
          },
        },
        {
          cssClass: 'primary',
          text: 'Yes, I want to add photos',
          handler: () => {
            if (this.currentMessage.selectedPhotos.length == 0) {
              return this.onCancel()
            }
            this.onTogglePremiumUpgrade(false)
          },
        },
      ],
    });
    await alert.present();
  };

  onHandleSubmitSuccess = () => {
    this.onTrackConversion();
    this.store.dispatch(this.myPenmateActions.viewPenmate(this.currentMessage.penmate));
    this.store.dispatch(this.createMessageActions.resetMessage());

    const penmateId = this.currentMessage.penmate.id;
    this.store.dispatch(this.myPenmateActions.loadMessages(penmateId, 1))
    this.navCtrl.navigateRoot(`/penmates/${penmateId}/messages`);

    this.nativeService.sendMessage({
      type: 'SUBMIT_LETTER ',
      data: { ...this.currentMessage.penmate },
    });

    if (!this.nativeService.active) {
      this.onShowAppDownloadModal();
    }
  }

  onHandleSubmit = debounce((opts: any = {}) => {
    this.onSubmit(opts);
  }, 3000, { leading: true, trailing: false});

  onSubmit = async (opts: any = {}) => {

    this.onSaveDraft();

    if (this.currentMessage.eMessage && !opts.confirm) {
      return this.onConfirmEmessage();
    }

    if (!this.currentMessage.eMessage && !opts.confirm) {
      return this.onConfirmSendMessage();
    }

    if (
      this.currentMessage.coupon &&
      this.currentMessage.coupon.active &&
      !opts.skipConfirmPhotos 
    ) {
      return this.onConfirmMissingPhotos();
    }

    if (!this.isReturnAddressValid()) {
      this.showEditAddress = true;
      return;
    }
    if (
      !opts.skipVerify &&
      this.currentMessage.payment === null &&
      !this.user.phone_number_verified
    ) {
      return this.onShowPhoneNumberVerify();
    }

    this.eventService.track('submit-letter-start', {
      letter_id: this.currentMessage.id,
      message_type: this.currentMessage.messageType,
      penmate_id: get(this.currentMessage, 'penmate.id'),
    });

    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: {
        message: 'Sending Message...',
      },
    });
    await loadingModal.present();

    setTimeout(() => {
      this.letterService.sendLetter(this.currentMessage.id).subscribe(async (res) => {
        await loadingModal.dismiss();
        const returnAddressValid = get(res, 'data.return_address_valid') || true
        if (!returnAddressValid) {
          const returnAddress = get(res, 'data.returnAddress') || {};
          this.pageModal = await this.modalCtrl.create({
            component: CreateMessageReturnAddress,
            componentProps: { penmateId: res.inmate.id, returnAddress, addressConfirmation: true },
          }).then(async (modal) => {
            await modal.present();
            modal.onDidDismiss().then((payload: any) => {
              this.onHandleSubmitSuccess()
            });
          }); 
          return;
        };
        if (res.error) {
          return this.onHandleSubmitError(res);
        }
        this.onHandleSubmitSuccess()
      });
    }, 500);
  };
}
