import { Component, ViewEncapsulation, Input } from '@angular/core';
import { NavParams, AlertController } from '@ionic/angular';

import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { get } from 'lodash';
import {  ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, AuthService, EventService, LetterService, NativeClientService } from '../services';

import { CreateMessageActions } from '../actions';
import { LoadingModal } from '../loading-modal/loading-modal';

// import { map as _map } from 'lodash';
import { map } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';

declare var Beacon;
declare var Penmate;

@Component({
  selector: 'pm-message-delivery-modal',
  templateUrl: 'message-delivery-modal.html',
  styleUrls: ['./message-delivery-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageDeliveryModal {
  @ViewChildren('pmContact') pmContactEl;
  penmate;
  navCtrl;
  trackingHistory;
  currentMessage;
  estDeliveryDate;
  isPostcard;
  onShowTracking;
  onShowEditAddressModal;
  state$;
  public waypointHit;
  public pendingEmailMessage;
  public waypoints: { index: number; position: number }[] = [];
  fromAddress;
  shouldRequestRating;
  canRequestRating;

  constructor(
    private navParams: NavParams,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    private eventService: EventService,
    private nativeService: NativeClientService,
    private authService:  AuthService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public letterService: LetterService,
    private store: Store<AppState>,
    private createMessageActions: CreateMessageActions
  ) {
    this.state$ = this.route.paramMap.pipe(map(() => window.history.state));
    this.currentMessage = this.navParams.get('currentMessage');
    this.isPostcard = this.currentMessage.messageType === 'postcard';
    this.onShowTracking = this.navParams.get('onShowTracking');
    this.onShowEditAddressModal = this.navParams.get('onEditAddress');
    this.penmate = this.navParams.get('penmate');
    this.navCtrl = this.state$.navCtrl;
    this.fromAddress = this.currentMessage.from_address;
    this.store
    .select(state => state.createMessage)
    .subscribe(createMessage => {
      const { confirmedAddress } = createMessage
      this.currentMessage.fromAddress = confirmedAddress ? confirmedAddress.fromAddress : this.currentMessage.from_address
      this.currentMessage.toAddress = confirmedAddress ? confirmedAddress.toAddress : this.currentMessage.inmate
      this.currentMessage.toAddress.idNumber = confirmedAddress ? confirmedAddress.toAddress.id_number : this.currentMessage.inmate.inmate_id
      this.currentMessage.inmate = this.currentMessage.toAddress;
      this.currentMessage.default_from_address = this.currentMessage.fromAddress;
      const sinceDate = moment('2023-06-18');
      const messageDeliveryDate = moment(this.currentMessage.delivery_date);
      const isNewReply = !!this.currentMessage.delivery_date && messageDeliveryDate.isAfter(sinceDate)
      this.shouldRequestRating = isNewReply
    });
  }

  ionViewDidEnter() {
    this.setupWaypoints();
  }

  ngOnInit() {
    this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
      if (!!isAuthenticated && user) {
        const metrics = user.metrics || {}
        const isNativeServiceActive = this.nativeService.active;
        if ( isNativeServiceActive && 
            metrics.request_rating && 
            !metrics.rating_requested
          ) {
            this.canRequestRating = true;
            this.nativeService.shouldRequestRating = false;
        }
      }
    })
  }

  setupWaypoints() {
    this.pmContactEl.changes.subscribe(t => {
      for (const pmEl of t._results) {
        const position = pmEl.nativeElement.getBoundingClientRect().top;
        const index = pmEl.nativeElement.getAttribute('data-index');
        this.registerWaypoint(parseInt(index, 10), position);
      }
    });
  }

  onShowEditAddress = () => {
    if  (this.currentMessage.status == 'processing')  {
      this.onShowEditAddressModal();
    }

  }


  onShowHelpWidget = (query) => {
    Beacon('init', Penmate.env.helpScoutId);
    setTimeout(() => {
      Beacon('open');
      Beacon('search', 'delivery');
      
    });
  }
  
  isProcessing = () => {
    return this.currentMessage.status.match(/ready_to_be_mailed|processing/i);
  }

  isMailed = () => {
    return this.currentMessage.status === 'mailed';
  }

  isDelivered = () => {
    return !!this.currentMessage.delivery_date;
  }

  onShowTrackingModal = () => {
    this.onShowTracking();
  }

  canShowMenuAction = (action) => {
    switch(action) {
      case 'tracking':
        return this.currentMessage.status.match(/ready_to_be_mailed|mailed|processing/i);
      case 'deliveryHelp':
      case 'resend':
        return !!this.currentMessage.delivery_date && !this.currentMessage.emailMessage;
      default:
        return false;
    }
  }

  onClose = () => {
    this.modalCtrl.dismiss();
    if( this.canRequestRating && this.shouldRequestRating) {
      this.eventService.track('rating-request-pending');
      const payload = {
        modalTitle: "Good news! Your letter has been delivered!",
        modalSubtitle: "How was your experience sending your message?",
        modalSubtitleStyle: {textAlign: 'center', paddingHorizontal: 45},
        helpBtnText: "I need help",
        rateBtnText: "It was great!"
      }
      setTimeout(() => {
        this.nativeService.sendMessage({ type: 'REQUEST_RATING', payload })
        this.shouldRequestRating = undefined;
        this.nativeService.shouldRequestRating = false;
      }, 500)
    }
  };

  onResend = async () => {
    this.eventService.track('resend-letter-alert');
    const alert = await this.alertCtrl.create({
      header: `Resend my ${this.currentMessage.messageType}`,
      cssClass: 'resend-alert',
      message: `As a courtesy, you can resend your letter if you run into any delivery issues. Your letter will be processed again for free however you may be required to pay a $1.99 shipping fee.`,
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            this.eventService.track('resend-letter-alert-cancel');
          }
        },
        {
          text: 'Resend it',
          handler: () => {
            this.onConfirmResend();
          },
        },
      ],
    });
    await alert.present();
  }

  onConfirmResend = async () => {
    this.eventService.track('resend-letter-alert-confirm');

    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: {
        message: 'Preparing message...',
      },
    });
    await loadingModal.present();

    this.letterService.resendLetter(this.currentMessage.id).subscribe(async (draft) => {
      await loadingModal.dismiss();
      if (draft.error) {
        // handle error
        return;
      }
      const messageType = get(draft, 'data.messageType') === 'postcard' ? 'postcard' : 'message';
      const penmate  = draft.inmate;
      const draftData = get(draft, 'data', {});
      const draftMessage = { ...draftData, id: draft.id };
      this.eventService.track('resend-letter', {
        letter_id: draft.id,
        message_type: messageType,
        penmate_id: penmate.id,
      });
      
      this.store.dispatch(this.createMessageActions.setMessage({ ...draftMessage, payment: null }));
      this.onClose();
      this.navCtrl.navigateForward(`/penmates/${penmate.id}/new-${messageType}/confirm-order`);
    })

  };

  createMomentObj = date => {
    return moment(date);
  };

  private registerWaypoint(index: number, position: number) {
    this.waypoints.push({ index, position });
  }

}
