<ion-content>
  <div class="modal-content">

    <div class="page-content">
      <div class="message-artwork-wrapper">
        <div class="message-artwork"></div>
      </div>
      <h2 class="title"> Your credits have been added 🎉</h2>
      <p class="desc"><span class="hlt">You're all set!</span> Any messages currently processing will be updated
        and you will receive a notification once delivery has been completed.
      </p>
      <ion-button class="continue-btn" (click)="onContinue()">
        Ok, got it.
      </ion-button>
    </div>


  </div>
</ion-content>
