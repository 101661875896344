<ion-content>
  <div class="loading-modal-content">
    <div id="pm-loading-modal" class="loading-msg-container">
      <div class="logo"></div>
      <p class="loading-msg">{{ loadingMsg }}</p>
    </div>
  </div>
  <ion-button
    *ngIf="showCloseButton"
    ion-button
    expand="block"
    shape="round"
    class="button cancel"
    (click)="cancel()"
  >
    <ion-icon name="close"></ion-icon>
  </ion-button>
</ion-content>
