import {
  Component,
  Input,
  ElementRef,
  Renderer,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs/Observable';
// import { PhotoViewer } from 'ionic-native';
import { findIndex, filter, uniqueId, isFunction } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '../services';
import { CreateMessageActions } from '../actions';
import { PreviewPhotoModal } from '../pages';

/*
  Generated class for the PhotoPickerPhoto directive.

  See https://angular.io/docs/ts/latest/api/core/index/DirectiveMetadata-class.html
  for more info on Angular 2 Directives.
*/
@Component({
  selector: 'pm-photo-picker-photo',
  templateUrl: 'photo-picker-photo.html',
  styleUrls: ['./photo-picker-photo.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PhotoPickerPhoto {
  @Input() allPhotos: any;
  @Input() isPostcard: any;
  @Input() photo: any;
  @Input() rowIdx: any;
  @Input() confirmRemove: boolean;
  @Input() showTogglePhoto: boolean = true;
  @Input() showTimestamp: boolean;
  @Input() onClick: any;
  @Input() viewCtrl: any;
  @Input() onSelectPhoto: any;
  @Input() onCanSelect: any;
  @Input() persistSelection: boolean = true;
  @Input() removeOnly: boolean;

  previewPhotoModal;
  uuid;
  viewer;

  constructor(
    private store: Store<AppState>,
    private createMessageActions: CreateMessageActions,
    private changeDetectorRef: ChangeDetectorRef,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
  ) {
    this.uuid = uniqueId('photo-');
  }

  onToggleSelected(photo) {
    // // Find photo in collection and mark is selected..
    let canSelect = true;
    if (!photo.selected && isFunction(this.onCanSelect)) {
      canSelect = this.onCanSelect(photo);
    }
    if (!canSelect) {
      return;
    }

    const photoIndex = findIndex(this.allPhotos[this.rowIdx], (p: any) => p.id === photo.id);
    if (this.confirmRemove) {
      // code...
      this.onConfirmRemove(() => {
        photo.selected = false;
        this.allPhotos[this.rowIdx].splice(photoIndex, 1);
        this.store.dispatch(this.createMessageActions.togglePhotoFromGallery(photo));
      });
    } else {
      photo.selected = !photo.selected;
      if (!this.isPostcard && this.persistSelection) {

        this.allPhotos[this.rowIdx].splice(photoIndex, 1, photo);
        this.store.dispatch(this.createMessageActions.togglePhotoFromGallery(photo));

        this.changeDetectorRef.detectChanges();

        // const postcard = {
        //   frontImage: photo
        // }
        // this.store.dispatch(this.createMessageActions.updatePostcard(postcard));
      }
      if (this.onSelectPhoto) {
        this.onSelectPhoto(photo);
      }
    }
  }

  onHandleClick(evt, photo) {
    evt.preventDefault();

    // this.viewer = new Viewer(document.getElementById(this.uuid));
    // console.log(this.viewer.view());
    // window.viewer = this.viewer;
    if (this.onClick) {
      return this.onClick(photo);
    }
    if (!photo.is_video) {
      this.onPreviewPhoto(photo);
    }
  }

  onPreviewPhoto = async photo => {
    const previewModal = await this.modalCtrl.create({
      component: PreviewPhotoModal,
      componentProps: {
        photo,
      },
    });
    await previewModal.present();
  };

  onConfirmRemove = async onConfirm => {
    const confirm = await this.alertCtrl.create({
      header: 'Remove this photo?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Yes',
          handler: () => {
            onConfirm();
          },
        },
      ],
    });
    await confirm.present();
  };
}
