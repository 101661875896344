<ion-header>
  <ion-toolbar style="width: 100%;">
    <div class="title-content">
      <h3>Send {{ penmate && penmate.first_name }} a Postcard</h3>
      <p>Add a message, photos, and more</p>
    </div>
  </ion-toolbar>
  <div class="message-avatar">
    <div class="icon-circle">
      <div class="inner-circle">
        <ion-icon name="chatbubbles"></ion-icon>
      </div>
    </div>
  </div>
</ion-header>

<ion-content>
  <ion-list>
    <div id="edit-postcard">
      <div *ngIf="!currentPostcard?.frontImage && !currentMessage?.message">
        <h3 class="postcard-create-title">Create a Postcard</h3>
        <div class="postcard-back-template"></div>
      </div>
      <div
        id="select-design"
        class="postcard-details"
        [ngClass]="{'hasImage': currentPostcard?.frontImage}"
      >
        <p class="title">Your Postcard Design</p>
        <div class="postcard-design" *ngIf="currentPostcard?.frontImage">
          <div
            class="front-image"
            [ngClass]="{'portrait': orientation == 'portrait'}"
            [style.background-image]="'url(' + currentPostcard.frontImage.url + ')'"
          ></div>
        </div>
        <div class="select-design-buttons">
          <button class="edit-button" (click)="onSelectPostcardDesign()">
            Browse designs
          </button>
          <span>OR</span>
          <button class="edit-button upload-image" (click)="onUploadPhoto()">
            Upload a photo
          </button>
        </div>
      </div>
      <div id="your-message" class="postcard-details">
        <p class="title">Add a message</p>
        <p class="preview-label" *ngIf="currentMessage?.summary">Preview</p>

        <button class="postcard-preview-button" (click)="onEditMessage()">
          <div class="postcard-back-template" *ngIf="currentMessage?.summary">
            <p class="postcard-text" [innerHTML]="truncateText(currentMessage.message, 200)"></p>
          </div>
        </button>
        <p class="message-summary" [innerHTML]="currentMessage?.summary"></p>

        <button class="edit-button message-button" (click)="onEditMessage()">
          <span *ngIf="!currentMessage?.message">Enter a message</span>
          <span *ngIf="currentMessage?.message">Edit message</span>
        </button>
      </div>
    </div>
  </ion-list>
</ion-content>

<ion-footer class="footer">
  <div id="message-navigation">
    <ion-grid>
      <ion-row>
        <ion-col col-6 class="back-button">
          <ion-button ion-button (click)="cancelMessage()" icon-right>
            <div class="button-inner">
              <ion-icon slot="start" name="ios-arrow-dropleft-circle"></ion-icon>
              Back
            </div>
          </ion-button>
        </ion-col>
        <ion-col col-6 class="continue-button">
          <ion-button
            icon-right
            id="message-continue-button"
            (click)="onSaveAndContinue()"
            [disabled]="!currentPostcard?.frontImage?.url"
          >
            <div class="button-inner">
              Continue
              <ion-icon slot="end" name="ios-arrow-dropright-circle"></ion-icon>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
