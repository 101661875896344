import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { IG_OAUTH_CONFIG } from '../services/oauth.config';
import { AppState } from '../services';

@Component({
  selector: 'ib-page-loading-modal',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthCallback {
  constructor(public store: Store<AppState>) {
    const path = window.location.pathname.substring(1);
    const isIgCallback = path.match(/instagram-native/i);
    if (isIgCallback) {
      const scheme = `penmateapp://oauth/`;
      const params = window.location.search;
      const redirect = `${scheme}${path}${params}`;
      window.location.href = redirect;
    }
  }
}
