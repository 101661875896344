import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { debounceTime, map, switchMap, mergeMap, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { SearchActions, CustomAction } from '../actions';
import { SearchService, AppState } from '../services';

import ErrorHandler from '../../lib/error-handler'


@Injectable()
export class SearchEffects {
  @Effect()
  search$ = this.actions$.pipe(
    ofType(SearchActions.SEARCH_REQUEST),
    map((action: CustomAction) => action.payload),
    switchMap(searchQuery => {
      return this.searchService
        .createSearch(searchQuery)
        .map((res: any) => {
          if (res.error) {
            return this.searchActions.searchError('Error completing search.');
          }
          return this.searchActions.searchSuccess(res);
        })
        .catch(error => {
          ErrorHandler.captureMessage('Search Error', { searchQuery });
          return Observable.of(this.searchActions.searchError(error));
        });
    }),
  );

  // @Effect() search$: Observable<any> = this.actions$.pipe(ofType(SearchActions.SEARCH_REQUEST))
  //   .pipe(debounceTime(300))
  //   .pipe(map((action: CustomAction) => action.payload))
  //   .pipe(switchMap(searchQuery => {
  //     console.log("@@@@ SEARCH QUERY IS --->>", searchQuery);
  //     return this.searchService.search(searchQuery)
  //       .map(res => this.searchActions.searchSuccess(res))
  //       .catch(error => {
  //         Rollbar.error('Search Error', {searchQuery} );
  //         return Observable.of(this.searchActions.searchError(error));
  //       });
  //   }));

  @Effect() viewSearch$ = this.actions$
    .pipe(ofType(SearchActions.VIEW_SEARCH_RESULT))
    .pipe(debounceTime(300))
    .pipe(map((action: CustomAction) => action.payload))
    .pipe(
      switchMap(penmate => {
        return this.searchService
          .viewSearchResult(penmate)
          .map(res => {
            return this.searchActions.viewSearchResultSuccess(res);
          })
          .catch(error => {
            this.store
              .select(state => state.user)
              .subscribe(user => {
                ErrorHandler.captureMessage('View Search Result Error', { penmate, error, user_id: user.id });
              })
              .unsubscribe();
            return Observable.of(this.searchActions.searchError(error));
          });
      }),
    );

  constructor(
    private actions$: Actions,
    private searchActions: SearchActions,
    private searchService: SearchService,
    private store: Store<AppState>,
  ) {}
}
