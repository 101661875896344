<ion-header id="photo-picker-header">
  <ion-toolbar>
    <div class="title-content">
      <ion-title class="title">Your Instagram Photos</ion-title>
      <ion-buttons end>
        <ion-button ion-button icon-only (click)="onDismiss()">
          <ion-icon name="close" class="close-icon" size="md"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="page-content">
    <ion-list>
      <ion-grid id="photo-grid" [ngClass]="photos.length + '-loaded'">
        <ion-row *ngFor="let row of photos; let rowIdx = index;">
          <ion-col size="6" *ngFor="let photo of row">
            <pm-photo-picker-photo
              [photo]="photo"
              [rowIdx]="rowIdx"
              [allPhotos]="photos"
              [isPostcard]="isPostcard"
              [onSelectPhoto]="onSelectPhoto"
              [onCanSelect]="onCanSelectPhoto"
            >
            </pm-photo-picker-photo>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-list>
    <ion-infinite-scroll (ionInfinite)="onLoadMore($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</ion-content>

<ion-footer id="modal-footer" *ngIf="!isPostcard">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-buttons end>
            <ion-button ion-button icon-right (click)="onDismiss()">
              Ok, I'm done
              <ion-icon name="md-checkmark"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
