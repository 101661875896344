<ion-content>
  <div class="page-content">
    <div class="login-callback">
      <ion-button class="back-btn" ion-button (click)="cancel()" icon-left>
        <div class="button-inner">
          <ion-icon name="arrow-back"></ion-icon>
          Back
        </div>
      </ion-button>
      <div class="appLogo"></div>
      <div class="login-content">
        <div class="login-container">
          <div class="login-box">
            <div>
              <div>
                <h3 style="text-align: center; font-weight: 700;">{{ isCreatePassword ? 'Create Password' : 'Reset Password'}}</h3>
                <p *ngIf="isCreatePassword" class="create-password-title">
                   Please create a password to secure your account.
                </p>
              </div>
              <form [formGroup]="resetPasswordForm">
                <ion-item class="email-field name">
                  <ion-label position="stacked">Email</ion-label>
                  <ion-input
                    type="text"
                    placeholder="Your e-mail"
                    formControlName="email"
                    autoComplete="nope"
                  ></ion-input>
                </ion-item>
                <p class="password-info">
                  Password must be at least 6 characters.
                </p>
                <ion-item class="input-field name">
                  <ion-label position="stacked">
                    Create New Password
                  </ion-label>
                  <ion-input
                    type="password"
                    placeholder="Enter password"
                    formControlName="password"
                    autoComplete="nope"
                  ></ion-input>
                </ion-item>

                <ion-item class="input-field name">
                  <ion-label position="stacked">Confirm Password</ion-label>
                  <ion-input
                    type="password"
                    placeholder="Enter password"
                    formControlName="passwordConfirmation"
                    autoComplete="nope"
                  ></ion-input>
                </ion-item>
                <ion-button 
                  class="submitBtn" 
                  [disabled]="!isPasswordValid()"
                  ion-button
                  icon-right 
                  (click)="onUpdatePassword()"
                >
                  <span *ngIf="!loading">
                    <div class="button-inner">
                      {{isCreatePassword ? 'Login' : 'Update Password'}}
                    </div>
                  </span>
                  <ion-spinner *ngIf="loading"></ion-spinner>
                </ion-button>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</ion-content>
