<ion-content>
  <div class="page-content">
    <div class="loginBg">
      <div class="appLogo"></div>
    </div>
    <div class="login-content">
      <div class="loginMessage">
        <div class="default" *ngIf="!savedSearchResult && !savedSearchQuery">
          <h3>Send love when it matters most.</h3>
          <p>
            Send letters and photos to anyone incarcerated
            <span class="bold">right from your phone.</span>
          </p>
        </div>
        <div class="currentSearch" *ngIf="savedSearchResult || savedSearchQuery">
          <h3>Connect with {{searchName}}</h3>
          <p>
            Send letters and photos to {{searchName}}
            <span class="bold">right from your phone.</span>
          </p>
        </div>
      </div>
      <ion-row>
        <ion-button id="login-button" class="loginButton" (click)="login()">
          Login or Signup to continue
        </ion-button>
      </ion-row>
    </div>
  </div>
</ion-content>
