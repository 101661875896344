import { Component, ViewEncapsulation, ViewChild, Input, Renderer2 } from '@angular/core';
import { ModalController, NavParams, Platform, AlertController, NavController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { get, isEmpty, debounce, mergeWith } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import { Router, ActivatedRoute, } from '@angular/router'
import { Location } from '@angular/common';

import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SearchService, LetterService, AppState, AuthService,  EventService, MyPenmateService } from '../services';
import { AddPaymentSheet } from '../add-payment-sheet/add-payment-sheet';
import { AddCreditsSuccessModal } from '../add-credits-success-modal/add-credits-success-modal';

import { concat, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeWhile,
} from 'rxjs/operators';
import { LoadingModal } from '../loading-modal/loading-modal';
import { SearchActions, UserActions, MyPenmateActions } from '../actions';
import { Store } from '@ngrx/store';
import US_STATES from '../add-penmate/us-states'
import { Search } from '../models';

import ErrorHandler from '../../lib/error-handler'

import {
  StripeInstance,
  StripeFactoryService
} from 'ngx-stripe';


declare var Penmate: any;
declare var Beacon;
declare global {
  interface Window {
      confetti: any;
  }
}


@Component({
  selector: 'pm-add-credits',
  styleUrls: ['./add-credits.scss'],
  templateUrl: 'add-credits.html',
  encapsulation: ViewEncapsulation.None,
})
export class AddCreditsPage {
  user;
  pageHeight;
  letterId;
  penmate;
  creditOptionForm;
  setupIntent;
  invoiceId;
  currentMessage;
  loadingTotal = true;
  loading = false;
  paymentRequired = true;
  paymentRemoved = false;
  stripe: StripeInstance;



  constructor(
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
    private stripeFactory: StripeFactoryService,
    private authService: AuthService,
    private eventService: EventService,
    private letterService: LetterService,
    private myPenmateService:  MyPenmateService,
    private myPenmateActions: MyPenmateActions,
    private searchActions: SearchActions,
    private userActions: UserActions,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private bottomSheet: MatBottomSheet,
    private renderer: Renderer2
  ) {

  }

  ngOnInit() {
    Beacon('destroy');

    this.eventService.track('add-credits-start')
    const url = new URL(window.location.href);
    const paymentIntentClientSecret = url.searchParams.get("setup_intent_client_secret");
    const paymentSucceeded = url.searchParams.get('paymentSucceeded');

    this.stripe = this.stripeFactory.create(Penmate.env.stripeKey);

    this.store
    .select((state) => state.user)
    .subscribe((user) => {
      this.user = user;
    });

    if (!this.currentMessage || !this.user) {
      this.loading = true;
    }
  
    this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
      if (!isAuthenticated || !user) {
        const route = window.location.pathname.replace(/^\//, '');
        this.store.dispatch(this.searchActions.saveRoute(route));
        return this.navCtrl.navigateRoot('/login');
      }
      this.user = user;
      this.store.dispatch(this.userActions.loginUser(user));
      this.onLoadPaymentIntent();
    });
    this.onLoadConfettiJs()

    this.onLoadPenmate();
  }


  onLoadConfettiJs() {
    if (!window.confetti) {
      const script = this.renderer.createElement('script');
      script.src = `https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.2/dist/confetti.browser.min.js`;
      this.renderer.appendChild(document.head, script);
    }
  }
 
  onLoadPaymentIntent() {
    const url = new URL(window.location.href);
    const messageId = url.searchParams.get("messageId");
    const letterId = url.searchParams.get("letterId");

    const paymentIntentClientSecret = url.searchParams.get("setup_intent_client_secret");
    const paymentSucceeded = url.searchParams.get('paymentSucceeded');
    if (messageId && messageId.length && paymentIntentClientSecret) {
       return this.onAddPayment()
    }

    this.authService.getPendingIntent(null, letterId).subscribe(({ intent, payment_method: paymentMethod, inmate, ...invoice}) => {
      this.setupIntent = intent;
      this.invoiceId = invoice.id;
      this.currentMessage = invoice;
      this.currentMessage.returnAddress = invoice.return_address;
      this.currentMessage.payment = paymentMethod;
      this.currentMessage.inmate = inmate;
      this.onInitCreditOptionsForm()
      this.loading = false;

    })
  }

  onInitCreditOptionsForm() {
    const defaultOption = this.currentMessage.message_credit_option || '5'
    this.creditOptionForm = this.formBuilder.group({
      creditOption: [defaultOption],
    });
    this.creditOptionForm.valueChanges.pipe(
      distinctUntilChanged(),
      startWith(this.creditOptionForm.value),
    ).subscribe(({ creditOption }) => {
      if (!this.currentMessage.id) {
        return;
      }
      this.currentMessage.creditOption = creditOption;
      this.loadingTotal = true;
      this.authService.updateCredits(this.currentMessage.id, creditOption).subscribe(invoice => {
        this.currentMessage = mergeWith({}, this.currentMessage, invoice, (a, b, k) => b === null && k !== 'payment' ? a : undefined)
        this.loadingTotal = false;
      })
    });
    if (this.currentMessage.id) {
      this.creditOptionForm.setValue({
        creditOption: defaultOption,
      })
    }
  }

  onLoadPenmate() {
    const url = new URL(window.location.href);

    const penmateId = url.searchParams.get('penmateId');
    if (!penmateId) {
      return
    }
    this.myPenmateService.getPenmates().subscribe(penmates => {
      this.store.dispatch(this.myPenmateActions.loadPenmatesSuccess(penmates));
      const penmate = penmates.find(({ id }) => id == penmateId)
      if (penmate) {
        this.store.dispatch(this.myPenmateActions.viewPenmate(penmate));
        this.store.dispatch(this.myPenmateActions.loadMessages(penmateId, 1));
      }
    })
  }

  onCalculateTotal() {
    return get(this.currentMessage, 'data.subtotal') * 0.01;
  }

  onCalculateTax() {
    if (this.currentMessage) {
      const { delivery_fee }  = this.currentMessage
      return delivery_fee * 0.01
    }
  }

  onCalculateTotalWithTax() {
    if (this.currentMessage) {
      const { processing_fee }  = this.currentMessage
      return this.onCalculateTotal() + (processing_fee * 0.01);
    }
  }

  onOpenPaymentModal = () => {
    const url = new URL(window.location.href);
    const paymentIntentClientSecret = url.searchParams.get("setup_intent_client_secret");
    const letterId = url.searchParams.get("letterId");

    const creditOption = this.creditOptionForm.controls['creditOption'].value
    const sheetRef = this.bottomSheet.open(AddPaymentSheet, {
      data: {
        amount: this.onCalculateTotalWithTax(),
        invoiceId: this.currentMessage.id,
        setupIntent: this.setupIntent,
        currentMessage: this.currentMessage,
        paymentDescription: `${creditOption} ${parseInt(creditOption) > 1 ? 'credits' : 'credit'}`,
        processingFee: this.currentMessage.processing_fee * 0.01,
        user: this.user,
        letterId: letterId,
        addCredits: true,
        paymentRemoved: this.paymentRemoved || !this.currentMessage.payment
      },
      panelClass: 'payment-sheet',
    });

    sheetRef.afterDismissed().subscribe((currMessage) => {
      if(currMessage && currMessage.payment) {
        this.currentMessage.payment = currMessage.payment;
        this.currentMessage.coupon = null;
        this.onUpdatePayment();
      }
      if (paymentIntentClientSecret) {

      }
    });
  }

  onUpdatePayment = () => {
    this.authService.updatePayment(this.currentMessage.id, this.currentMessage.payment).subscribe(invoice => {
      this.currentMessage = mergeWith({}, this.currentMessage, invoice, (a, b, k) => b === null && k !== 'payment' ? a : undefined)
    })
  }

  removePayment = async () => {
    const confirmRemove = await this.alertCtrl.create({
      header: 'Remove this payment method?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Yes',
          cssClass: 'ok',
          handler: () => {
            this.currentMessage.payment = null;
            this.paymentRemoved = true;
          },
        },
      ],
    });
    await confirmRemove.present();
  };

  onAddPayment() {
    const url = new URL(window.location.href);
    const messageId = url.searchParams.get("messageId");
    const letterId = url.searchParams.get("letterId");

    if (messageId && messageId.length) {
      return this.authService.getPendingIntent(messageId, letterId).subscribe(({ intent, payment_method: paymentMethod, inmate, ...invoice}) => {
        this.setupIntent = intent;
        this.invoiceId = invoice.id;
        this.currentMessage = invoice;
        this.currentMessage.returnAddress = invoice.return_address;
        this.currentMessage.payment = paymentMethod || invoice.payment;
        this.currentMessage.inmate = inmate;
        this.onInitCreditOptionsForm()
        this.onOpenPaymentModal();
        this.loading = false;

      }, async () => {
        const alert = await this.alertCtrl.create({
          header: `Payment Error`,
          cssClass: 'error-alert',
          message: 'There was an error processing your payment',
          buttons: [
            {
              text: 'Try Again',
              handler: () => {

                const routePath = window.location.pathname.split('/billing/add-credits')[0]
                this.router.navigateByUrl(routePath);
              }
            },
          ],
        });
        return alert.present();
      }) 
    }
    return this.onOpenPaymentModal();
  }

  onDisplayErrorAlert = async (titleText, buttonText, subHeader = null) => {
    const alert = await this.alertCtrl.create({
      cssClass: 'error-alert',
      header: titleText,
      subHeader,
      buttons: [
        {
          text: buttonText,
        },
      ],
    });
    await alert.present();
  };

  onHandleSubmitSuccess = async () => {
    const modal = await this.modalCtrl.create({
      component: AddCreditsSuccessModal,
    });
    await modal.present();
  }


  onSubmit = async () => {
    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: {
        message: 'Adding Credits...',
      },
    });
    await loadingModal.present();

    this.authService.purchaseCredits({
      ...this.creditOptionForm.value,
      payment: this.currentMessage.payment, 
      amount: this.onCalculateTotalWithTax(),
      invoice_id: this.currentMessage.id,
    }).subscribe(async (data) => {
      await loadingModal.dismiss();

      const paymentAction = get(data, 'requires_action') || false;
      const paymentIntentId = get(data, 'payment_intent_client_secret');

      if (paymentAction && paymentIntentId) {
        return this.stripe.confirmCardPayment(paymentIntentId).subscribe(async (res)=> {
          if (res.error) {
            return this.onDisplayErrorAlert('Payment Error', 'Try again', res.error.message);
          }

          const paymentIntent = get(res, 'paymentIntent', {});
          const { id: intent_id, payment_method, client_secret } = paymentIntent;
          const intentPayload = {  intent_id, payment_method, client_secret }

          const payment = {
            id: paymentIntent.payment_method,
            paymentIntentId: paymentIntent.id,
            confirmedIntentId: paymentIntent.id
          }
          await loadingModal.present();
          this.authService.purchaseCredits({
            ...this.creditOptionForm.value,
            payment,
            amount: this.onCalculateTotalWithTax(),
            invoice_id: this.currentMessage.id,
          }).subscribe(async (data) => {
            await loadingModal.dismiss();
            if (data.succeeded) {
              return this.onHandleSubmitSuccess()
            }
            const errorMessage = data.payment_error || 'There was an error processing your payment'
            ErrorHandler.captureMessage('Credit Purchase Error', { error: data.error || data.payment_error, invoiceId: this.currentMessage.id });
            return this.onDisplayErrorAlert(errorMessage, 'Try again');
          })
        })
      }

      if (data.succeeded) {
        return this.onHandleSubmitSuccess()
      }
      
      const errorMessage = data.payment_error || 'There was an error processing your payment'
      ErrorHandler.captureMessage('Credit Purchase Error', { error: data.error || data.payment_error, invoiceId: this.currentMessage.id });
      return this.onDisplayErrorAlert(errorMessage, 'Try again');
    })
  }
  onCancel = () => {
    const routePath = `/penmates`;
    this.router.navigateByUrl(routePath);
  };


}
