<ion-content>
  <div class="page-content">
    <div class="add-penmate-container">
      <div class="logo"></div>
      <div *ngIf="penmate" class="penmate-detail">
        <p *ngIf="penmate.inmate_id">
          Do you want to connect with {{ penmate.first_name }} {{ penmate.last_name }}?
        </p>
        <div *ngIf="!penmate.inmate_id">
          <h3 class="name">{{ penmate.first_name }} {{ penmate.last_name }}</h3>
          <p>
            Searching <strong> {{penmate.state}}</strong> records for
            <strong>{{ penmate.first_name }} {{ penmate.last_name }}</strong>.
          </p>
          <p class="enter-city-label">Please add a few extra details below:</p>
          <div class="no-result">
            <ion-item class="city-input" no-padding>
              <ion-label position="stacked">City:</ion-label>
              <ion-input
                type="text"
                id="search-last-known-city"
                name="search_city"
                (input)="onEnterCity($event.target.value)"
              >
              </ion-input>
              <small
                class="input-error"
                *ngIf="formSubmitted && addPenmateForm.value?.addressMethod?.byAddress?.facility && addPenmateForm.errors?.addressMethod"
                >Please enter an inmate id or birthdate</small
              >
            </ion-item>

            <div class="facility-autocomplete">
              <ion-label>Name of Jail or Prison</ion-label>
              <ng-select
                [items]="facilities$ | async"
                bindLabel="name"
                [minTermLength]="5"
                [dropdownPosition]="'top'"
                [loading]="facilitiesLoading"
                [typeahead]="facilityInput$"
                typeToSearchText="Enter name of Jail or Prison"
                [addTag]="true"
                addTagText="Search:"
                [(ngModel)]="selectedFacility"
              >
              </ng-select>
            </div>
          </div>
        </div>

        <ion-button
          id="add-penmate-button"
          (click)="addPenmate(penmate)"
          expand="block"
          shape="round"
          class="button continue"
          [disabled]="!penmate.inmate_id && (isCityInvalid() || !selectedFacility?.name)"
        >
          <span> Continue </span>
        </ion-button>
        <ion-button
          ion-button
          expand="block"
          shape="round"
          class="button cancel"
          (click)="cancel()"
        >
          Cancel
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
