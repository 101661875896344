import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, map, switchMap, mergeMap, tap } from 'rxjs/operators';
import { UserActions, CustomAction } from '../actions';
import { AuthService, AppState, LetterService } from '../services';

@Injectable()
export class UserEffects {
  @Effect() addSocialAccount$ = this.actions$
    .pipe(ofType(UserActions.ADD_SOCIAL_ACCOUNT))
    .pipe(debounceTime(300))
    .pipe(map((action: CustomAction) => action.payload))
    .pipe(
      switchMap(({ provider, account }) => {
        return this.authService
          .addSocialAccount(provider, account)
          .map(({ account: savedAccount }) => {
            return this.userActions.addSocialAccountSuccess(provider, savedAccount);
          });
      }),
    );

  @Effect() fetchEmailMessages$ = this.actions$
    .pipe(ofType(UserActions.FETCH_EMAIL_MESSAGES))
    .pipe(debounceTime(300))
    .pipe(map((action: CustomAction) => action.payload))
    .pipe(
      switchMap(() => {
        return this.letterService
          .getPendingEmails()
          .map(payload => this.userActions.fetchEmailMessagesSuccess(payload));
      }),
    );

  @Effect() updateDeviceInfo$ = this.actions$
    .pipe(ofType(UserActions.UPDATE_DEVICE_INFO))
    .pipe(debounceTime(300))
    .pipe(map((action: CustomAction) => action.payload))
    .pipe(
      switchMap(deviceInfo => {
        return this.authService.updateDeviceInfo(deviceInfo).map(({ metrics }) => {
          return this.userActions.updateDeviceInfoSuccess({ ...deviceInfo, metrics });
        });
      }),
    );

  constructor(
    private actions$: Actions,
    private userActions: UserActions,
    private authService: AuthService,
    private letterService: LetterService,
    private store: Store<AppState>,
  ) {}
}
