<edit-message-navbar penmate="penmate"></edit-message-navbar>
<ion-content>
  <div class="page-content">
    <div class="top-action-buttons"  #actionButtons>
      <h3>Edit message </h3>
      <button mat-fab color="accent" class="top-action-button" (click)="cancelMessage()">
          <span *ngIf="keyboardShown">
            <span class="icon"><span>✏️</span></span>
            <span>
              Done writing
            </span>
          </span>
          <span *ngIf="!keyboardShown">
            <span class="icon"><ion-icon name="arrow-back" ></ion-icon></span>
            <span>
              Back
            </span>
          </span>
      </button>
    </div>
    <form [formGroup]="textEditorForm" (ngSubmit)="onSaveAndContinue()">
      <div
        id="new-message-editor"
        class="bg-text-editor message-editor-container"
        #textEditor
        [ngClass]="{'postcard-editor': isPostcard}"
        [ngStyle]="{'height.px': !keyboardShown && hasPhotos() ? maxEditorHeight * .85 :  maxEditorHeight}"
      >
        <header class="header" *ngIf="textEditorForm.controls['messageText'].value.length === 0 && !keyboardShown">
          <ion-row class="header-row">
            <ion-col size="10">
              <h2 *ngIf="!isPostcard">Add a message</h2>
              <h2 *ngIf="isPostcard">Postcard message</h2>
              <h4 *ngIf="isPostcard" class="postcard-char-limit">
                (Limited to 200 characters)
              </h4>
            </ion-col>
            <ion-col size="2">
              <ion-icon name="close-circle" class="delete-message" (click)="cancelMessage()">
              </ion-icon>
            </ion-col>
          </ion-row>
        </header>
        <div id="message-content" class="edit" [ngStyle]="{'margin-top.px': isPostcard ? 45 : 30}">
          <textarea
            rows="10"
            autosizeMaxRows="15"
            autosize
            formControlName="messageText"
            (focus)="onTextAreaFocus()"
            (focusout)="onTextAreaBlur()"
            (blur)="onTextAreaBlur()"
            placeholder="Type here"
            [attr.maxlength]="getMessageMaxLength()"
          ></textarea>
        </div>
      </div>
    </form>
  </div>
</ion-content>

<ion-footer
  [ngStyle]="{'bottom.px': keyboardShown ? getKeyboardFooterPos() : null, 'position': 'relative'}"
>
  <div id="message-actions">
    <ion-row>
      <ion-col size="12" class="continue">
        <ion-button icon-right (click)="onSaveAndContinue()" [disabled]="textEditorForm.controls['messageText']?.value?.length === 0">
          <div class="button-inner">
            Save & Continue
            <ion-icon slot="end" name="ios-arrow-dropright-circle"></ion-icon>
          </div>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-footer>
