import { NgModule, ErrorHandler, NO_ERRORS_SCHEMA } from '@angular/core';
import { ScrollSpyModule } from 'ngx-scrollspy';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { File } from '@ionic-native/file/ngx';
import { IonicImageLoader } from 'ionic-image-loader';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ImageViewerModule } from 'ngx-image-viewer';
import { NgxUploaderModule } from 'ngx-uploader';
import { Angulartics2Module } from 'angulartics2';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxStripeModule } from 'ngx-stripe';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgmCoreModule } from '@agm/core';
import { MatExpansionModule } from '@angular/material'
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CodeInputModule } from 'angular-code-input';


import {
  MatBottomSheetModule,
  MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
} from '@angular/material/bottom-sheet';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LocationStrategy, PathLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { compose } from '@ngrx/core/compose';
// import { StoreModule, combineReducers } from '@ngrx/store';
import {
  StoreModule,
  ActionReducerMap,
  ActionReducer,
  MetaReducer,
  combineReducers,
} from '@ngrx/store';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { EffectsModule } from '@ngrx/effects';
import { Http, HttpModule, JsonpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AuthHttp, AuthConfig } from 'angular2-jwt';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

import { Storage, IonicStorageModule } from '@ionic/storage';
import { StorageSyncEffects, storageSync } from 'ngrx-store-ionic-storage';
import { MomentModule } from 'angular2-moment';
import { FileUploadModule } from 'ng2-file-upload';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreLogMonitorModule, useLogMonitor } from '@ngrx/store-log-monitor';
import {
  userReducer,
  searchReducer,
  myPenmateReducer,
  createMessageReducer,
  saveSearchReducer,
} from './reducers';
import { UserActions, SearchActions, MyPenmateActions, CreateMessageActions } from './actions';
import {
  AuthService,
  SearchService,
  ApiGateway,
  MyPenmateService,
  LetterService,
  SharedService,
  EventService,
  NativeClientService,
} from './services';
import { SearchEffects, MyPenmateEffects, UserEffects, CreateMessageEffects } from './effects';
import {
  PhotoPickerPhoto,
  PMLeafletMapDirective,
  EditMessageNavBar,
  ImagePreview,
  AppFooterNav,
} from './components';

import { AutoresizeDirective } from './autoresize-textarea/autoresize-textarea';
import { CardModule } from './ngx-card/src/module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// import { GlobalErrorHandler } from '../lib/global-error-handler'

import * as pageComponents from './pages';
declare var Penmate;
const GOOGLE_MAPS_API_KEY = 'AIzaSyCy1bjFPJJLzzkqBDrZlyHllVGPkMnQC2c';

export const storageConfig = {
  name: '__PMdb',
  storeName: 'appStore',
  driverOrder: ['sqlite', 'websql', 'localstorage'],
};

export const storage = new Storage(storageConfig);

export function instrumentOptions() {
  return {
    monitor: useLogMonitor({ visible: false, position: 'right' }),
  };
}
export function provideStorage() {
  return storage;
}

export function getAuthHttp(http) {
  return new AuthHttp(
    new AuthConfig({
      globalHeaders: [{ Accept: 'application/json' }],
      tokenGetter: () => {
        const token =  Penmate.env.authToken ? Penmate.env.authToken : storage.get('id_token');
        return token;
      },
    }),
    http,
  );
}

export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      const token =  Penmate.env.authToken ? Penmate.env.authToken : storage.get('id_token');
      return token;
    },
    whitelistedDomains: [
      'localhost',
      'pmate.ngrok.io',
      'penmateapp.com',
      'pmate-staging.herokuapp.com',
      'm.penmateapp.com',
      'railway.penmateapp.com'
    ],
  };
}

const PAGES = [
  pageComponents.LoginPage,
  pageComponents.LoginModal,
  pageComponents.LoginLinkCallback,
  pageComponents.ResetPasswordConfirm,
  pageComponents.PostcardCreatorPage,
  pageComponents.PostcardDesignPicker,
  pageComponents.MessageCreatorPage,
  pageComponents.MessageTextEditor,
  pageComponents.MessageIndexPage,
  pageComponents.MessageShowPage,
  pageComponents.MyPenmatesPage,
  pageComponents.AddPenmatePage,
  pageComponents.SearchResultsModal,
  pageComponents.ViewSearchResultModal,
  pageComponents.FileUploadModal,
  pageComponents.PostcardFileUploadModal,
  pageComponents.SelectedPhotosModal,
  pageComponents.CreateMessageReturnAddress,
  pageComponents.SelectedPhotosModal,
  pageComponents.IGPhotoPicker,
  pageComponents.IGCopyLinkTab,
  pageComponents.IGSearchTab,
  pageComponents.IGMyPhotosTab,
  pageComponents.ConfirmOrderPage,
  pageComponents.PostcardConfirmOrderPage,
  pageComponents.LoadingModal,
  pageComponents.AddPaymentSheet,
  pageComponents.TabsPage,
  pageComponents.MessageTrackingModal,
  pageComponents.MessageDeliveryModal,
  pageComponents.MessageIncompleteModal,
  pageComponents.PenmateProfile,
  pageComponents.MyAccountPage,
  pageComponents.PreviewPhotoModal,
  pageComponents.AuthCallback,
  pageComponents.EmailMessagesModalPage,
  pageComponents.AccountVerificationModal,
  pageComponents.AppDownloadModal,
  pageComponents.AppUpgradeModal,
  pageComponents.AppIntroLandingModal,
  pageComponents.EmailDeliveryModal,
  pageComponents.AddCreditsPage,
  pageComponents.AddCreditsSuccessModal,
  pageComponents.MessagePaymentUpdate,
  pageComponents.ConfirmAddressModal,
  pageComponents.LegalTermsModal,
];

export function declarations() {
  return [
    AppComponent,
    PMLeafletMapDirective,
    PhotoPickerPhoto,
    ImagePreview,
    AppFooterNav,
    EditMessageNavBar,
    AutoresizeDirective,
    ...PAGES,
  ];
}

export const reducers = {
  user: userReducer,
  search: searchReducer,
  myPenmates: myPenmateReducer,
  createMessage: createMessageReducer,
  savedSearch: saveSearchReducer,
  router: routerReducer,
};

export const storageSyncReducer = storageSync({
  keys: ['createMessage', 'savedSearch'], // Only sync these keys
  ignoreActions: [], // Don't sync when these actions occur
  hydratedStateKey: 'hydrated', // Add this key to the state
  onSyncError: e => {},
});

export function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

export const metaReducers: MetaReducer<any, any>[] = [storageMetaReducer];

export const appReducer = compose(
  storageSyncReducer,
  combineReducers,
)(reducers);

export function rootReducer(state: any, action: any) {
  return appReducer(state, action);
}

@NgModule({
  declarations: declarations(),
  entryComponents: [AppComponent, AppFooterNav, ...PAGES],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios', scrollAssist: true, scrollPadding: true }),
    AgmCoreModule.forRoot({
      apiKey: GOOGLE_MAPS_API_KEY,
      libraries: ['places'],
    }),
    MatGoogleMapsAutocompleteModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    HttpModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    JsonpModule,
    FileUploadModule,
    IonicImageLoader.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    IonicStorageModule.forRoot(storageConfig),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
      },
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage],
      },
    }),
    // EffectsModule.forRoot([SearchEffects]),
    // EffectsModule.forRoot([UserEffects]),
    // EffectsModule.forRoot([MyPenmateEffects]),
    // EffectsModule.forRoot([StorageSyncEffects]),
    // EffectsModule.forRoot([CreateMessageEffects]),
    EffectsModule.forRoot([
      SearchEffects,
      UserEffects,
      MyPenmateEffects,
      CreateMessageEffects,
      StorageSyncEffects,
    ]),
    StoreDevtoolsModule.instrument(instrumentOptions),
    StoreLogMonitorModule,
    ScrollSpyModule.forRoot(),
    ImageViewerModule.forRoot({
      allowFullscreen: false,
      btnShow: {
        zoomIn: false,
        zoomOut: false,
        rotateClockwise: false,
        rotateCounterClockwise: false,
        next: false,
        prev: false,
      },
    }),
    NgxUploaderModule,
    CardModule,
    NgxStripeModule.forRoot(),
    MatBottomSheetModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    CodeInputModule.forRoot({
      codeLength: 6,
      isCharsCode: false,
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserActions,
    SearchActions,
    MyPenmateActions,
    CreateMessageActions,
    AuthService,
    SearchService,
    ApiGateway,
    MyPenmateService,
    LetterService,
    SharedService,
    EventService,
    NativeClientService,
    File,
    // {provide: ErrorHandler, useClass: GlobalErrorHandler},
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
