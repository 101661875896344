import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

// import {
//  MessageCreatorPage,
//  PostcardCreatorPage,
// } from "../pages";

import { PostcardCreatorPage } from '../postcard-creator/postcard-creator';
import { MessageCreatorPage } from '../message-creator/message-creator';

declare var Beacon;
declare var Penmate;

@Component({
  selector: 'edit-message-navbar',
  templateUrl: 'edit-message-navbar.html',
  styleUrls: ['./edit-message-navbar.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditMessageNavBar {
  public penmateId;
  @Input() penmate: any;
  @Input() back: any;
  @Input() hideBack: boolean;
  @Input() showDoneWriting: boolean;
  @Input() showHelp: boolean;

  constructor(public navCtrl: NavController, private route: ActivatedRoute) {}

  
  onShowHelpWidget(route = '/ask/') {
    Beacon('init', Penmate.env.helpScoutId);
    setTimeout(() => {
      Beacon('open');
      Beacon('navigate', route); // Welcome screen
    }, 350);
  }

  onGoBack() {
    if (this.back) {
      return this.back();
    }
    const penmateId = this.route.snapshot.paramMap.get('id');
    if (!penmateId) {
      return false;
    }
    const isPostcard = /new-postcard/.test(document.location.href);
    // const component = isPostcard ? PostcardCreatorPage : MessageCreatorPage;
    const routePath = isPostcard
      ? `/penmates/${penmateId}/new-postcard`
      : `/penmates/${penmateId}/new-message`;
    this.navCtrl.navigateBack(routePath);
  }

  onDoneWriting() {
    return true;
  }
}