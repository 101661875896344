import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavController, NavParams, ActionSheetController, ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { AppState, LetterService, AuthService, EventService } from '../services';
// import {
//   IGPhotoPicker,
//   FileUploadModal,
// } from "../pages";

import { IGPhotoPicker } from '../ig-photo-picker/ig-photo-picker';
import { FileUploadModal } from '../file-upload-modal/file-upload-modal';

import { Store } from '@ngrx/store';

import { CreateMessageActions } from '../actions';
/*
  Generated class for the SearchLoadingModal page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-postcard-design-picker',
  templateUrl: 'postcard-design-picker.html',
  styleUrls: ['./postcard-design-picker.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PostcardDesignPicker implements OnInit {
  loadingMsg;
  postcard;
  allPostcards = [];
  postcardThemes;
  postcardCategory = 'popular';
  photoPickerProvider;
  photoPickerAccessToken;
  modal;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public route: ActivatedRoute,
    public router: Router,
    private createMessageActions: CreateMessageActions,
    private letterService: LetterService,
    private eventService: EventService,
    private actionSheetCtrl: ActionSheetController,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.fetchPostcardThemes();
  }

  // onSelectPhotosFromDevice(): void {
  //   this.modal = this.modalCtrl.create(PostcardFileUploadModal,  {
  //     id: this.navParams.get("id"),
  //     isPostcard: true,
  //     onSelectPhoto: this.onSelectTheme,
  //     accessToken: this.photoPickerAccessToken
  //   });
  //   this.modal.present({ updateUrl: false });
  // }

  onDismiss = async () => {
    await this.modalCtrl.dismiss();
  };

  onShowModal = async modalCmp => {
    await this.modalCtrl.dismiss();
    this.modal = await this.modalCtrl.create({
      component: modalCmp,
      componentProps: {
        id: this.route.snapshot.paramMap.get('id'),
        isPostcard: true,
        onSelectPhoto: this.onSelectTheme,
        accessToken: this.photoPickerAccessToken,
      },
    });
    await this.modal.present();
  };

  fetchPostcardThemes() {
    this.letterService.getPostcardThemes().subscribe(({ postcards }) => {
      this.allPostcards = postcards;
      this.onFilterPostcards();
    });
  }

  onFilterPostcards() {
    this.postcardThemes = this.allPostcards.filter(p =>
      this.postcardCategory === 'popular' ? p.source === 'ig' : !!p.url,
    );
  }

  onCategoryChange(event) {
    this.postcardCategory = event.target.value;
    this.onFilterPostcards();
  }

  onSelectTheme = ({ url }) => {
    const postcard = { frontImage: { url } };
    this.eventService.track('select-postcard-theme', { url });
    this.store.dispatch(this.createMessageActions.updatePostcard(postcard));
    this.modalCtrl.dismiss();
  };
}
