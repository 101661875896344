import { Component, ViewEncapsulation } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { SearchActions, UserActions } from '../actions';
import { AppState, SearchService, AuthService, EventService } from '../services';
import { keys } from 'lodash';
import { LoginPage } from '../pages';

@Component({
  selector: 'pm-page-search-results',
  templateUrl: 'search-results.html',
  styleUrls: ['./search-results.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchResultsModal {
  currentSearch;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    private authService: AuthService,
    private eventService: EventService,
    private store: Store<AppState>,
    private searchActions: SearchActions,
  ) {}

  ionViewDidEnter() {
    this.store
      .select(state => state.search.currentSearch)
      .subscribe(currentSearch => {
        if (!currentSearch) {
          return;
        }
        this.currentSearch = currentSearch;
      });
  }

  cancel = async () => {
    await this.modalCtrl.dismiss();
  };

  viewSearchResult(penmate) {
    const res = this.authService.authenticate().subscribe(
      ({ isAuthenticated, user }) => {
        if (isAuthenticated && user) {
          this.store.dispatch(this.searchActions.viewSearchResult(penmate));
          this.eventService.track('view-search-result', { penmate_id: penmate.id, ...penmate });
          res.unsubscribe();
          return;
        }
        res.unsubscribe()
        this.store.dispatch(this.searchActions.saveSearchResult(penmate));
        this.cancel();
        this.navCtrl.navigateForward('/login');
      },
      () => {
        // send to login
        this.store.dispatch(this.searchActions.saveSearchResult(penmate));
        this.cancel();
        this.navCtrl.navigateForward('/login');
      },
    )
  }
}
