import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AppState, LetterService } from '../services';
import { Store } from '@ngrx/store';
import { chunk, get, filter, isUndefined } from 'lodash';
import { CreateMessageActions } from '../actions';
/*
  Generated class for the SelectedPhotosModal page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
    selector: 'pm-page-selected-photos-modal',
    templateUrl: 'selected-photos-modal.html',
    styleUrls: ['./selected-photos-modal.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SelectedPhotosModal implements OnInit {
    photos = [];
    selectedCount;
    numOfPhotos;
    showTogglePhoto = true;

    constructor(
        public navCtrl: NavController,
        public modalCtrl: ModalController,
        public alertCtrl: AlertController,
        public route: ActivatedRoute,
        private store: Store<AppState>,
        private letterService: LetterService,
        private createMessageActions: CreateMessageActions,
    ) {}

    ngOnInit() {
        const showTogglePhoto =
            this.showTogglePhoto || this.route.snapshot.paramMap.get('showTogglePhoto');
        this.showTogglePhoto = isUndefined(showTogglePhoto) ? true : !!showTogglePhoto;
        this.numOfPhotos = this.photos.length;
        this.photos = chunk(this.photos, 2);

        this.store
            .select(state => state.createMessage)
            .subscribe(createMessage => {
                this.selectedCount = createMessage.selectedPhotos.length;
            });
    }

    goBack = async () => {
        await this.modalCtrl.dismiss();
    };
}
