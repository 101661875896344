import {
  ModalController,
  NavController,
  LoadingController,
  NavParams,
  IonInfiniteScroll,
  AlertController,
} from '@ionic/angular';
import { Component, ViewChild, ChangeDetectorRef, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Headers, RequestOptions, Jsonp } from '@angular/http';
import { chunk, get, filter, orderBy, find } from 'lodash';
import { map } from 'rxjs/operators';

import { AppState, LetterService, ApiGateway, SharedService, EventService } from '../services';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { PhotoPickerPhoto } from '../models';
import { DEFAULT_MAX_SELECTED_PHOTOS } from '../message-creator/message-creator';
import * as moment from 'moment';

@Component({
  selector: 'pm-ig-my-photos',
  templateUrl: 'ig-my-photos-tab.html',
  styleUrls: ['./ig-my-photos.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IGMyPhotosTab implements OnInit {
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

  state$;
  photos = [];
  selectedPhotos = [];

  isPostcard: any;
  pagination: any;
  loadingSpinner;
  accessToken;
  MAX_SELECTED_PHOTOS = DEFAULT_MAX_SELECTED_PHOTOS;
  isFirstMessage;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public route: ActivatedRoute,
    public letterService: LetterService,
    private sharedService: SharedService,
    private eventService: EventService,
    private store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private jsonp: Jsonp,
    private apiClient: ApiGateway,
  ) {
    this.state$ = this.route.paramMap.pipe(map(() => window.history.state));

    this.store
      .select(state => state.createMessage)
      .subscribe(message => {
        this.selectedPhotos = message.selectedPhotos;
        this.MAX_SELECTED_PHOTOS = DEFAULT_MAX_SELECTED_PHOTOS;
        this.isFirstMessage = get(message, 'meta.firstMessage', false);
        if (this.isFirstMessage) {
          this.MAX_SELECTED_PHOTOS = 4;
        }
      });
  }

  ngOnInit() {
    setTimeout(() => {
      this.accessToken = get(window.history.state, 'accessToken');
      const path = window.location.href;
      this.isPostcard = path.match(/new-postcard/i);
      if (this.accessToken) {
        return this.getUserPhotos().subscribe();
      }
      return this.onDismiss();
    }, 150);
  }

  ionViewDidEnter() {}

  ionViewDidLeave() {
    this.hideLoadingSpinner();
  }

  hideLoadingSpinner = async () => {
    if (this.loadingSpinner) {
      try {
        await this.loadingCtrl.dismiss(null, 'cancel');
      } catch (e) {}
    }
  };

  onDismiss = async () => {
    const penmateId = this.route.snapshot.paramMap.get('id');
    this.navCtrl.navigateBack(`/penmates/${penmateId}/new-message`);
  };

  showLoadingSpinner = async () => {
    this.loadingSpinner = await this.loadingCtrl.create({
      message: 'Please wait...',
      duration: 2000,
    });
    return this.loadingSpinner.present();
  };

  getUserPhotos(nextUrl = false) {
    if (!nextUrl) {
      this.showLoadingSpinner();
    }
    return new Observable(observer => {
      this.letterService.getUserInstagramRecent(this.accessToken, nextUrl).subscribe(
        ({ photos, pagination }) => {
          this.hideLoadingSpinner();
          this.pagination = pagination;
          const nextPhotos = this.photos.splice(0);
          const mappedPhotos = photos.map(photo => ({
            ...photo,
            selected: !!find(this.selectedPhotos, p => p.id === photo.id),
          }));
          this.photos = nextPhotos.concat(chunk(mappedPhotos, 2));
          this.changeDetectorRef.detectChanges();
          observer.next(this.photos);
          observer.complete();
        },
        () => {
          this.hideLoadingSpinner();
          observer.complete();
        },
      );
    }).finally(() => {
      this.hideLoadingSpinner();
    });
  }

  onLoadMore(event) {
    const nextUrl = this.pagination.next;
    if (!!nextUrl) {
      this.showLoadingSpinner();
      this.sharedService.data.emit({
        event: { type: 'pagination', data: { loading: true } },
      });
      this.getUserPhotos(nextUrl).subscribe(
        () => {
          this.sharedService.data.emit({
            event: { type: 'pagination', data: { loading: false } },
          });
          this.hideLoadingSpinner();
          event.target.complete();
        },
        () => {
          this.hideLoadingSpinner();
        },
      );
    } else {
      event.target.complete();
    }
  }

  onCanSelectPhoto = () => {
    if (this.selectedPhotos.length >= this.MAX_SELECTED_PHOTOS) {
      let maxPhotosMessage = `You've attached ${this.MAX_SELECTED_PHOTOS} photos to your message. We cant send more than ${this.MAX_SELECTED_PHOTOS} photos at a time or the facility may reject your message. To send more than ${this.MAX_SELECTED_PHOTOS} photos, please create a new message.`;
      if (this.isFirstMessage) {
        maxPhotosMessage = `You can send up to ${this.MAX_SELECTED_PHOTOS} photos for free with your first message. To send more than ${this.MAX_SELECTED_PHOTOS} photos, please create a new message.`;
      }
      this.onDisplayErrorAlert(
        'Photo limit reached',
        `Max ${this.MAX_SELECTED_PHOTOS} photos attached`,
        maxPhotosMessage,
        'OK',
      );
      return false;
    }
    return true;
  };

  onSelectPhoto = ({ url }) => {
    this.eventService.track('add-photos', { provider: 'instagram' });
    if (this.isPostcard) {
      this.sharedService.data.emit({
        event: { type: 'onSelectPostcardPhoto', data: { url } },
      });
      const penmateId = this.route.snapshot.parent.paramMap.get('id');
      return this.navCtrl.navigateBack(`/penmates/${penmateId}/new-postcard`);
    }
  };

  private formatSearchMedia(photo) {
    const image: any = orderBy(photo.images, 'width', 'desc')[1 || 0];
    const is_carousel = !!get(photo, 'carousel_media');
    const is_video = !!get(photo, 'video_versions');

    const formattedPhoto: PhotoPickerPhoto = {
      url: photo.media_url || image.url,
      id: photo.id,
      created_at: moment.unix(photo.created_at).format(),
      provider: 'instagram',
      details: photo,
      is_carousel,
      is_video,
    };
    return formattedPhoto;
  }

  onDisplayErrorAlert = async (titleText, subHeader, message, buttonText) => {
    const alert = await this.alertCtrl.create({
      header: titleText,
      subHeader,
      message,
      buttons: [
        {
          text: buttonText,
        },
      ],
    });
    await alert.present();
  };
}
