import { AuthConfig } from 'angular-oauth2-oidc';

// const config = new AuthConfig({
//   oidc: false,
//   loginUrl: `https://api.instagram.com/oauth/authorize`,
//   clientId: '742931839552313',
//   redirectUri: `${window.location.origin}/fb-ig/callback`,
//   scope: 'user_profile user_media',
//   responseType: 'code',
// });

export const IG_OAUTH_CONFIG: AuthConfig = {
  clientId: '742931839552313',
  redirectUri: `${window.location.origin}/fb-ig/callback`,
  postLogoutRedirectUri: '',
  loginUrl: 'https://api.instagram.com/oauth/authorize',
  scope: 'user_profile user_media',
  resource: '',
  rngUrl: '',
  oidc: false,
  requestAccessToken: true,
  options: null,
  issuer: 'https://api.instagram.com',
  tokenEndpoint: `${window.location.origin}/api/oauth/ig/access_token`,
  responseType: 'code',
  showDebugInformation: false,
  strictDiscoveryDocumentValidation: false,
  customQueryParams: null,

  // timeoutFactor: 0.75,
  // sessionCheckIntervall: 3000,
  // sessionCheckIFrameName: 'angular-oauth-oidc-check-session-iframe',
  // disableAtHashCheck: false,
  // skipSubjectCheck: false,
  // silentRefreshRedirectUri: 'http://localhost:4200/silent-refresh.html',
  // silentRefreshMessagePrefix: '',
  // silentRefreshShowIFrame: false,
  // silentRefreshTimeout: 20000,
  // silentRefreshIFrameName: 'angular-oauth-oidc-silent-refresh-iframe',
  // dummyClientSecret: null,
  // requireHttps: 'remoteOnly',
};
