import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  take,
  filter,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';

import { NavController, Platform, ModalController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Event as NavigationEvent, ActivationEnd } from '@angular/router';

import { Store } from '@ngrx/store';
import { pick, debounce, isEqual, get, isEmpty } from 'lodash';
import {
  SearchService,
  AppState,
  MyPenmateService,
  AuthService,
  EventService,
  NativeClientService,
} from '../services';
import { SearchActions, UserActions, MyPenmateActions } from '../actions';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';

import { LoadingModal } from '../loading-modal/loading-modal';
import { SearchResultsModal } from '../search-results/search-results';
import { ViewSearchResultModal } from '../view-search-result/view-search-result';
import { EmailMessagesModalPage } from '../email-messages-modal/email-messages-modal';
import { AppIntroLandingModal } from '../app-intro-landing/app-intro-landing';

import { MyPenmatesPage } from '../my-penmates/my-penmates';
import { LoginPage } from '../login/login.page';
import US_STATE_LIST from './us-states';

import ErrorHandler from '../../lib/error-handler'


declare var Penmate;

const defaultModalProps = { name: null, modal: null, isLoaded: false, currentSearch: null };

export const manualInputValidation = control => {
  const addressMethod = get(control.get('addressMethod'), 'value', {});
  const selectedFacility = get(control.get('selectedFacility'), 'value', {});

  if (addressMethod.type === 'byAddress') {
    const inmateId = get(addressMethod, 'byAddress.inmate_id', '').trim();
    const birthDate = get(addressMethod, 'byAddress.birthdate', '').trim();
    const facilityName = get(selectedFacility, 'name', '').trim();
    return isEmpty(inmateId) && isEmpty(birthDate) ? { addressMethod: true } : null;
  }
  return null;
};

const promiseDelay = (timeout = 50) =>
  new Promise((resolve, reject) => {
    setTimeout(resolve, timeout);
  });

@Component({
  selector: 'pm-page-add-penmate',
  templateUrl: 'add-penmate.html',
  styleUrls: ['./add-penmate.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddPenmatePage implements OnInit {
  public FORM_TYPE = {
    byAddress: 'byAddress',
    bySearch: 'bySearch',
  };

  formSubmitted: boolean = false;
  pageHeight;
  addPenmateForm;
  penmate;
  searchParams;
  formType;
  availableStates;
  showAddFacilityInfo: boolean = false;
  showAddPenmateLoading = false;
  ageOptions = ['Under 25', '25-30', '31-35', '36-40', '41-50', '51-60', 'Over 60'];
  raceOptions = [
    'Black/African-American',
    'White/Caucasian',
    'Latin/Hispanic',
    'Native American',
    'Other',
  ];
  currentModal = defaultModalProps;
  lastSearch;
  loadingVisible = false;
  loaded = false;
  US_STATE_LIST;
  user;
  onDisplayNoResults = debounce(
    currentSearch => {
      this.store.dispatch(this.searchActions.viewSearchResultSuccess(currentSearch));
    },
    2000,
    { leading: true, trailing: false },
  );
  facilities$: Observable<any>;
  facilitiesLoading = false;
  facilityInput$ = new Subject<string>();
  isFirstSearch = false;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public router: Router,
    public route: ActivatedRoute,
    private platform: Platform,
    private formBuilder: FormBuilder,
    private myPenmateService: MyPenmateService,
    private searchService: SearchService,
    private authService: AuthService,
    private eventService: EventService,
    private nativeService: NativeClientService,
    private store: Store<AppState>,
    private searchActions: SearchActions,
    private myPenmateActions: MyPenmateActions,
    private userActions: UserActions,
  ) {
    this.pageHeight = platform.height();
    this.buildSearchForm();
    this.US_STATE_LIST = US_STATE_LIST.map(({ abbrev }) => abbrev);

    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state;
    const savedSearch = get(state, 'savedSearchResult');

    const savedSearchQuery = get(state, 'searchQuery');

    if (savedSearch) {
      this.onSelectSearchResult(savedSearch);
      this.store.dispatch(searchActions.clearSavedSearch());
    }

    if (savedSearchQuery) {
      this.searchParams = savedSearchQuery;
      this.buildSearchForm();
      const isFacilitySearch =
        get(savedSearchQuery, 'addressMethod.type') === this.FORM_TYPE.bySearch;
      if (isFacilitySearch) {
        this.changeFormType(this.FORM_TYPE.bySearch);
      }
      return;
    }
    
    this.router.events.pipe(
      filter(( event: NavigationEvent ) => ( event instanceof ActivationEnd ))
    ).subscribe(
      () => {
        this.route.queryParams.subscribe(params => {
          this.searchParams = params;
          this.buildSearchForm();
        });
      }
    );
  }

  ngOnInit() {
    this.loadFacilities();
    this.isFirstSearch = this.route.snapshot.queryParams['first-search']
    if (this.isFirstSearch) {
      this.onShowAppIntroModal();
    }
  }

  ionViewDidEnter() {
    this.formSubmitted = false;

    this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
      this.loaded = true;
      if (isAuthenticated && user) {
        this.user = user;
        return true;
      }
    });

    this.searchService.getAvailableStates().subscribe(
      states => {
        this.store.dispatch(this.searchActions.loadAvailableStates(states));
      },
      error => {
        ErrorHandler.captureMessage('Add Penmate Error', { error });
      },
    );
    this.availableStates = this.store.select(state => state.search.availableStates);
    let showEmailMessageModal = false;
    if (history && history.state) {
      showEmailMessageModal = history.state.showEmailMessageModal
    }
    if (showEmailMessageModal) {
      this.onShowEmailMessageModal();
    }
  }

  initAddressMethodFormGroup() {
    const group = this.formBuilder.group({
      type: [this.FORM_TYPE.byAddress],
      byAddress: this.formBuilder.group(this.initAddressMethodByAddressModel()),
      bySearch: this.formBuilder.group(this.initAddressMethodBySearchModel()),
    });

    return group;
  }

  initAddressMethodByAddressModel() {
    const savedInmateId = get(this.searchParams, 'addressMethod.byAddress.inmate_id', '');

    const model = {
      inmate_id: [savedInmateId],
      address_line1: [''],
      birthdate: [''],
    };
    return model;
  }

  initAddressMethodBySearchModel() {
    const model = {
      gender: ['M', Validators.required],
      age_range: [
        get(this.searchParams, 'addressMethod.bySearch.age_range', ''),
        Validators.required,
      ],
      ethnicity: [
        get(this.searchParams, 'addressMethod.bySearch.ethnicity', ''),
        Validators.required,
      ],
    };
    return model;
  }

  buildSearchForm() {
    const defaultFacilityName = get(this.searchParams, 'facility_name');
    const defaultFacilityId = get(this.searchParams, 'facility_id');
    const defaultFacility = defaultFacilityName && defaultFacilityId ? {
      id: defaultFacilityId,
      name: defaultFacilityName,
    } : {}
    this.addPenmateForm = this.formBuilder.group(
      {
        first_name: [get(this.searchParams, 'first_name', ''), Validators.required],
        last_name: [get(this.searchParams, 'last_name', ''), Validators.required],
        gender: ['M', Validators.required],
        state: [get(this.searchParams, 'facility_state', ''), Validators.required],
        selectedFacility: [defaultFacility],
        addressMethod: this.initAddressMethodFormGroup(),
      },
      { validators: manualInputValidation },
    );
    this.subscribeAddressMethodChanges();
    const isPreloadedSearch = !!(defaultFacility.id && defaultFacility.name)
    if (isPreloadedSearch && this.isAddPenmateFormValid()) {
      this.submit(this.addPenmateForm.value, true);
    }
  }

  changeFormType(formType) {
    const ctrl: FormControl = (<any>this.addPenmateForm).controls.addressMethod.controls.type;
    ctrl.setValue(formType);
  }

  subscribeAddressMethodChanges() {
    // controls
    const byAddressCtrl = this.addPenmateForm.get('addressMethod.byAddress') as FormGroup;
    const bySearchCtrl = this.addPenmateForm.get('addressMethod.bySearch') as FormGroup;

    // initialize value changes stream
    const changes$ = this.addPenmateForm.get('addressMethod.type').valueChanges;

    // subscribe to the stream
    changes$.subscribe(formType => {
      if (formType === this.FORM_TYPE.byAddress) {
        this.setAddressMethodValidity(byAddressCtrl, this.initAddressMethodByAddressModel());
        this.clearAddressMethodValidity(bySearchCtrl);
      }
      if (formType === this.FORM_TYPE.bySearch) {
        this.setAddressMethodValidity(bySearchCtrl, this.initAddressMethodBySearchModel());
        this.clearAddressMethodValidity(byAddressCtrl);
      }
    });
  }

  onShowEmailMessageModal = async () => {
    const modal = await this.modalCtrl.create({
      component: EmailMessagesModalPage,
      componentProps: { showSearchMessage: true },
    });
    await modal.present();
  };

  presentModal = async (name, componentClass, data = {}) => {
    if (this.currentModal.name && this.currentModal.name !== name) {
      await this.dismissModal();
      await promiseDelay(150);
    }
    this.currentModal.name = name;
    this.currentModal.modal = await this.modalCtrl.create({
      id: name,
      component: componentClass,
      componentProps: { ...data },
      showBackdrop: false,
    });
    this.currentModal.modal.onDidDismiss().then(async () => {
      const openModal = await this.modalCtrl.getTop();
      if (openModal) {
        openModal.dismiss();
      }
    });
    this.currentModal.currentSearch = this.lastSearch;
    await this.currentModal.modal.present();
    return this.currentModal.modal;
  };

  dismissModal = async () => {
    try {
      await this.modalCtrl.dismiss();
      this.currentModal = defaultModalProps;
      return this.currentModal;
    } catch {
      return true;
    }
  };

  onSearchLoading = async (isLoading, currentSearch) => {
    this.loadingVisible = true;
    return this.presentModal('loading', LoadingModal, {
      loaderType: 'search',
      showCloseButton: true,
      stateName: currentSearch.state,
    });
  };

  onSearchError(hasError) {
    if (hasError && this.currentModal.name === 'loading') {
      return this.currentModal.modal.dismiss();
    }
  }

  onSelectSearchResult = async selectedSearchResult => {
    if (selectedSearchResult && this.currentModal.name !== 'selectedResult') {
      await this.presentModal('selectedResult', ViewSearchResultModal);
    }
  };

  fieldIsInvalid(fieldNamePath) {
    const field = this.addPenmateForm.get(fieldNamePath);
    return field && !field['valid'] && this.formSubmitted;
  }

  isAddPenmateFormValid = () => {
    const {
      value: { first_name, last_name, state, selectedFacility = {} },
    } = this.addPenmateForm;
    if (this.user) {
      return !isEmpty(first_name) && !isEmpty(last_name) && !isEmpty(get(selectedFacility, 'name'));
    }
    return !isEmpty(first_name) && !isEmpty(last_name) && !isEmpty(state);
  };

  onAddPenmate = formBody => {
    this.authService.authenticate().subscribe(async ({ isAuthenticated, user }) => {
      if (!isAuthenticated || !user) {
        const searchQuery = this.addPenmateForm.value;
        this.store.dispatch(this.searchActions.saveSearchQuery(searchQuery));
        return this.navCtrl.navigateForward('/login', {
          state: { searchQuery },
        });
      }
      this.showAddPenmateLoading = true;

      setTimeout(() => {
        this.changeFormType(this.FORM_TYPE.byAddress);
        this.showAddFacilityInfo = true;
        this.penmate = {
          showMap: true,
          state: get(formBody, 'state'),
          first_name: get(formBody, 'first_name'),
          last_name: get(formBody, 'last_name'),
        };
        this.showAddPenmateLoading = false;
      }, 1300);
    });
  };

  onCancel() {
    this.showAddFacilityInfo = false;
    this.changeFormType(this.FORM_TYPE.byAddress);
  }

  submit = async (formBody, isValid) => {
    this.formSubmitted = true;
    if (!isValid) {
      return;
    }

    this.authService.authenticate().subscribe(async ({ isAuthenticated, user }) => {
      if (!isAuthenticated || !user) {
        this.store.dispatch(this.searchActions.saveSearchQuery(formBody));
        return this.navCtrl.navigateForward('/login', {
          state: { searchQuery: formBody },
        });
      }

      this.user = user;

      await this.clearSearch();

      // Determine the type of form ('add by search' or 'by address') and
      // submit the appropriate action
      const formType = formBody.addressMethod.type;

      // ('First name', last name, and gender are common attributes to both
      // forms so we'll add those to the params in both casses)
      const basicFields = pick(formBody, 'first_name', 'last_name', 'gender', 'state');
      const facility = get(formBody, 'selectedFacility.name');
      const facility_id = get(formBody, 'selectedFacility.id');

      const formParams = Object.assign(
        {},
        { ...basicFields, facility, facility_id },
        formBody.addressMethod[formType],
      );

      // Subscribe to search result changes before submit...
      //

      this.onHandleLoadingChanges();
      this.onHandleSearchSuccess();
      this.onHandleSearchError();
      this.onHandleSelectResult();
      // Submit!
      //
      if (formType === this.FORM_TYPE.bySearch) {
        this.submitSearchForm(formParams);
        this.nativeService.sendMessage({ type: 'CREATE_SEARCH', data: formParams });
        this.eventService.track('create-search', formParams);
      }
      if (formType === this.FORM_TYPE.byAddress) {
        this.eventService.track('enter-penmate-manually', formParams);
        this.submitAddPenmate(formParams);
      }
      this.onTrackConversion();
    });
  };

  onTrackConversion = () => {
    this.eventService.awAddPenmate();
    this.eventService.gaEventTrack('Created Search', {
      event_category: 'User',
    });
  };

  onHandleLoadingChanges = () => {
    this.store
      .select(({ search: { isLoading, currentSearch } }) => ({ isLoading, currentSearch }))
      .auditTime(500)
      .subscribe(async ({ isLoading, currentSearch }) => {
        if (!currentSearch) {
          return;
        }
        if (isLoading && !this.loadingVisible) {
          this.onSearchLoading(isLoading, currentSearch);
        }
        // if (!isLoading) {
        //   if (this.currentModal && this.currentModal.name === 'loading') {
        //     await this.modalCtrl.dismiss(null, null, 'loading');
        //   }
        // }
      });
  };

  onHandleSearchSuccess = () => {
    const sub = this.store
      .select(({ search: { currentSearch } }) => currentSearch)
      .subscribe(async currentSearch => {
        if (!get(currentSearch, 'results')) {
          return;
        }

        if (currentSearch.results.length > 0 && !this.lastSearch) {
          this.lastSearch = currentSearch;

          try {
            await this.modalCtrl.dismiss(null, null, 'loading');
          } catch {
          } finally {
            this.presentModal('results', SearchResultsModal);
            sub.unsubscribe();
          }
        }

        if (currentSearch.results.length === 0 && !this.lastSearch) {
          sub.unsubscribe();
          try {
            await this.modalCtrl.dismiss(null, null, 'loading');
          } finally {
            this.onDisplayNoResults(currentSearch);
          }
          // code...
        }
        // await this.handleFormResults(res);
      });
  };

  onHandleSearchError = () => {
    const sub = this.store
      .select(({ search: { currentSearch, hasError } }) => ({ currentSearch, hasError }))
      .subscribe(async ({ currentSearch, hasError }) => {
        if (!currentSearch) {
          return;
        }
        if (hasError) {
          try {
            await this.modalCtrl.dismiss(null, null, 'loading');
          } finally {
            this.store.dispatch(this.searchActions.viewSearchResultSuccess(currentSearch));
            sub.unsubscribe();
          }
        }
      });
  };

  onHandleSelectResult = () => {
    const sub = this.store
      .select(({ search: { selectedSearchResult, currentSearch } }) => ({
        selectedSearchResult,
        currentSearch,
      }))
      .subscribe(async ({ selectedSearchResult, currentSearch }) => {
        if (!currentSearch) {
          return;
        }
        if (selectedSearchResult) {
          this.onSelectSearchResult(selectedSearchResult);
          return sub.unsubscribe();
        }
      });
  };

  clearSearch = async () => {
    return new Promise(async resolve => {
      this.store.dispatch(this.searchActions.clearSearch());
      this.lastSearch = null;
      this.loadingVisible = false;
      this.currentModal = defaultModalProps;
      await promiseDelay(100);
      resolve();
    });
  };

  submitSearchForm(params) {
    return this.store.dispatch(this.searchActions.searchRequest(params));
  }

  submitAddPenmate = async params => {
    this.authService.authenticate().subscribe(async ({ isAuthenticated, user }) => {
      if (isAuthenticated && user) {
        const loadingModal = await this.presentModal('loading', LoadingModal, {
          loaderType: 'profile',
          message: 'Loading Profile. Please wait...',
        });
        return this.myPenmateService.addPenmate(params).subscribe(
          async myPenmates => {
            await loadingModal.dismiss();
            this.store.dispatch(this.myPenmateActions.loadPenmatesSuccess(myPenmates));
            this.nativeService.sendMessage({ type: 'ADD_PENMATE', data: params });

            // this.navCtrl.navigateForward('/my-penmates');
            this.navCtrl.navigateForward('/my-penmates');
            this.showAddFacilityInfo = false;
            this.eventService.gaEventTrack('Added Penmate', {
              event_category: 'User',
            });
          },
          async err => {
            await loadingModal.dismiss().catch(async () => {
              return await loadingModal.dismiss();
            });
            this.store.dispatch(this.myPenmateActions.addPenmateError(err));
          },
        );
      }
      // send to login
      this.navCtrl.navigateForward('/login');
    });
  };

  trackByFn(item: any) {
    return item.name;
  }

  onShowAppIntroModal() {
    return new Promise(async (resolve) => {
      this.modalCtrl
        .create({
          component: AppIntroLandingModal,
        })
        .then(async (modal) => {
          await modal.present();
        })
        .finally(() => {
          resolve(null);
        });
    });
  }

  private clearAddressMethodValidity(control: FormGroup) {
    Object.keys(control.controls).forEach(key => {
      const ctrl = control.get(key);
      ctrl.clearValidators();
      ctrl.updateValueAndValidity();
    });
  }

  private setAddressMethodValidity(control: FormGroup, model) {
    Object.keys(control.controls).forEach(key => {
      const ctrl = control.get(key);
      ctrl.setValidators(model[key][1]);
      ctrl.updateValueAndValidity();
    });
  }

  private loadFacilities() {
    this.facilities$ = concat(
      of([]), // default items
      this.facilityInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => (this.facilitiesLoading = true)),
        switchMap(term => {
          const { state } = get(this.addPenmateForm, 'value', {});
          if (isEmpty(term)) {
            this.facilitiesLoading = false;
            return of([]);
          }
          return this.searchService.findFacility({ q: term, state }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.facilitiesLoading = false)),
          );
        }),
      ),
    );
  }
}
