import { Component, ViewEncapsulation } from '@angular/core';
import { FileUploadModal } from './file-upload-modal';

@Component({
    selector: 'pm-postcard-file-upload-modal',
    templateUrl: 'file-upload-modal.html',
    styleUrls: ['./file-upload-modal.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PostcardFileUploadModal extends FileUploadModal {}
