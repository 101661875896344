import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';

import { AlertController, Platform, NavController, ModalController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import {get,  isEmpty, some } from 'lodash';
import * as moment from 'moment';
import { Storage } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Store } from '@ngrx/store';
import {
  MyPenmatesPage,
  AddPenmatePage,
  LoginPage,
  LoadingModal,
  EmailMessagesModalPage,
} from './pages';
import {
  AuthService,
  AppState,
  MyPenmateService,
  EventService,
  LetterService,
  NativeClientService,
} from './services';
import { UserActions, MyPenmateActions, SearchActions, CreateMessageActions } from './actions';

import ErrorHandler from '../lib/error-handler'

declare var Penmate;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  modal;
  emailMessagesModal;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private myPenmateService: MyPenmateService,
    private myPenmateActions: MyPenmateActions,
    private createMessageActions: CreateMessageActions,
    private searchActions: SearchActions,
    private store: Store<AppState>,
    private letterService: LetterService,
    private userActions: UserActions,
    private authService: AuthService,
    private eventService: EventService,
    private nativeService: NativeClientService,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private router: Router,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.modal = await this.modalCtrl.create({
        component: LoadingModal,
        componentProps: { message: 'Loading...' },
      });

      const url = new URL(window.location.href);
      const paymentIntentClientSecret = url.searchParams.get("setup_intent_client_secret");
      const paymentSucceeded = url.searchParams.get('paymentSucceeded');
      this.eventService.start({ source: 'mobile', path: document.location.pathname });

      this.authService.handleAuthentication().subscribe(this.onHandleAuthentication, error => {
        this.eventService.track('login-error', { source: 'mobile' });
        ErrorHandler.captureMessage('Login / Auth error', { error });
      });


      this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
        
        if (isAuthenticated && user) {
          this.store.dispatch(this.myPenmateActions.loadPenmates());
          this.store.dispatch(this.userActions.loginUser(user));
          this.eventService.gaUserData(user.profile_hash)

          const billingRoute = window.location.pathname.match(/billing/i)
          const resetPasswordRoute = window.location.pathname.match(/reset-password/i);
          if (resetPasswordRoute || billingRoute || (paymentIntentClientSecret || paymentSucceeded) && window.location.pathname.match(/confirm-order/i)) {
            return;
          }

          try {
            this.storage.get('gclid').then(value => {
              if (!!value) {
                this.eventService.track('add-metrics', { gclid: value });
                this.storage.remove('gclid')
              }
            })
          } catch {/*noop*/}

          if (window.location.pathname.match(/my-account/i)) {
            return;
          }

          const routeSnapshot = this.route.snapshot;
          const pathname = window.location.pathname;
          const searchPaths = ['add-penmate', 'find-an-inmate', 'search']
          const isSearchPath = some(searchPaths, (path: string) => pathname.match(new RegExp(path, 'i')));
          const isFirstSearch = this.route.snapshot.queryParams['first-search'];
          const gclidQuery =  this.route.snapshot.queryParams['gclid'];
          const forwardgClidQuery = user.has_sent_letter && !!gclidQuery
          
          if (!isEmpty(routeSnapshot.queryParams) && 
          (isSearchPath || isFirstSearch) && 
          !forwardgClidQuery) {
            return;
          }
          const route = forwardgClidQuery ? `/my-penmates?gclid=${gclidQuery}` : '/my-penmates';
          this.navCtrl.navigateForward(route);
        }
      });
    });

    this.nativeService.data.subscribe(({ type, payload }) => {
      const payloadData = payload || {};
      const { url, state: currentState, caption, author } = payloadData;

      if (type === 'NAVIGATE') {
        return this.navCtrl.navigateForward(url);
      }

      if (type === 'ADD_PHOTO') {
        const photo = {
          id: url,
          url,
          caption,
          author,
          selected: true,
          created_at: moment().format(),
          provider: 'instagram',
          source: 'graphql',
          is_carousel: false,
          is_video: false,
        };
        this.store.dispatch(this.createMessageActions.togglePhotoFromGallery(photo));
      }

      if (type === 'DEVICE_INFO') {
        this.store.dispatch(this.userActions.updateDeviceInfo(payloadData));
        const { version, os } = payloadData;
        const versionParts = (version || "").split('.')
        const isIOS =  /ios/i.test(os || '');
        const isAndroid =  /android/i.test(os || '');
        if (!this.nativeService.active || versionParts.length != 3) {
          return;
        }

        this.storage.get('id_token').then(token => {
          this.storage.set('id_token', token || Penmate.env.authToken);
        })
        const minorVersion = parseInt(versionParts[2]);
        const shouldUpgradeiOS = isIOS && minorVersion < 31;
        const shouldUpgradeAndroid =  isAndroid &&  minorVersion < 32
        if (shouldUpgradeiOS || shouldUpgradeAndroid) {
          this.onNotifyAppUpgrade({ isIOS })
        }
      }

      if (type === 'SET_NATIVE_MODULES') {
        this.store.dispatch(this.userActions.setNativeModules(payloadData));
      }

      if (type === 'SUBMIT_RATING_FEEDBACK') {
        this.eventService.track('rating-requested');
        if (!!payloadData && payloadData.showContactForm) {
          this.navCtrl.navigateForward('/my-account', {
            state: { ratingFeedback: payloadData },
          });
        }
      }
    });
  }

  onHandleAuthentication = user => {
    this.store
      .select(state => ({
        penmates: state.myPenmates.penmates,
        savedSearch: state.savedSearch,
      }))
      .pipe(take(1))
      .subscribe(({ penmates, savedSearch }) => {

        const resetPasswordRoute = window.location.pathname.match(/reset-password/i);
        const addCreditsRoute = window.location.pathname.match(/add-credits/i);

        const url = new URL(window.location.href);
        const paymentIntentClientSecret = url.searchParams.get("setup_intent_client_secret");
        if (resetPasswordRoute || paymentIntentClientSecret || addCreditsRoute) {
          return;
        }
        
        
        this.eventService.track('login', { source: 'mobile' });
        const userGClid = get(user, 'metrics.gclid')

        if (userGClid && !addCreditsRoute) {
          /* TODO: INCLUDE SAVED SEARCH IF PRESENT */
          const route = user.has_sent_letter || penmates.length > 0 ? 'my-penmates' : 'find-an-inmate';
          return this.navCtrl.navigateForward(`/${route}?gclid=${userGClid}`);
        }

        if (savedSearch.savedRoute) {
          this.store.dispatch(this.searchActions.clearSavedRoute());
          return this.navCtrl.navigateForward(savedSearch.savedRoute);
        }

        if (savedSearch.savedSearchResult) {
          this.navCtrl.navigateForward('/add-penmate', {
            state: { savedSearchResult: savedSearch.savedSearchResult },
          });
          this.eventService.track('view-search-result', savedSearch.savedSearchResult);
          return setTimeout(() => {
            return this.store.dispatch(
              this.searchActions.viewSearchResult(savedSearch.savedSearchResult),
            );
          }, 500);
        }

        this.authService.handleLoginNavigation(this.navCtrl, savedSearch).subscribe(() => {
          this.modalCtrl.getTop().then(modal => {
            modal.dismiss();
          });
        });
        try {
          this.storage.get('gclid').then(value => {
            if (!!value) {
              this.eventService.track('add-metrics', { gclid: value });
              this.storage.remove('gclid')
            }
          })
        } catch {/*noop*/}
      })
      .unsubscribe();
  };

  onNotifyAppUpgrade = async ({ isIOS }) => {
    if (!this.nativeService || !this.nativeService.active) {
      return;
    }
    const iOSLink = 'https://apps.apple.com/us/app/penmate-send-mail-to-jail/id955317881';
    const AndroidLink = 'market://details?id=com.penmate';
    const appStoreLink = isIOS ? iOSLink : AndroidLink;
    const alert = await this.alertCtrl.create({
      header: 'New Version Available',
      subHeader: 'Please upgrade your app',
      cssClass: 'upgrade-alert app-upgrade-alert',
      message: 'A new version of Penmate is now available. Tap below to upgrade.',
      buttons: [
        {
          text: 'Upgrade',
          handler: () => {
            this.nativeService.sendMessage({
              type: 'SHOW_EXTERNAL_URL',
              data: { url: appStoreLink },
            });
          },
        },
      ],
    });
    await alert.present();
  }
}
