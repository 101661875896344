import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class UserActions {
  static LOGIN_USER = 'LOGIN_USER';
  static ADD_SOCIAL_ACCOUNT = 'ADD_SOCIAL_ACCOUNT';
  static ADD_SOCIAL_ACCOUNT_SUCCESS = 'ADD_SOCIAL_ACCOUNT_SUCCESS';

  static CONFIRM_PHONE_SUCCESS = 'CONFIRM_PHONE_SUCCESS';

  static USER_EMAIL_SIGNUP = 'USER_EMAIL_SIGNUP';

  static FETCH_EMAIL_MESSAGES = 'FETCH_EMAIL_MESSAGES';
  static FETCH_EMAIL_MESSAGES_SUCCESS = 'FETCH_EMAIL_MESSAGES_SUCCESS';
  static FETCH_EMAIL_MESSAGES_ERROR = 'FETCH_EMAIL_MESSAGES_ERROR';

  static SET_PENDING_EMAIL_MESSAGE = 'SET_PENDING_EMAIL_MESSAGE';
  static CLEAR_PENDING_EMAIL_MESSAGE = 'CLEAR_PENDING_EMAIL_MESSAGE';

  static UPDATE_DEVICE_INFO = 'UPDATE_DEVICE_INFO';
  static UPDATE_DEVICE_INFO_SUCCESS = 'UPDATE_DEVICE_INFO_SUCCESS';

  static SET_NATIVE_MODULES = 'SET_NATIVE_MODULES';

  loginUser(user) {
    return {
      type: UserActions.LOGIN_USER,
      payload: user,
    };
  }

  userEmailSignup(user) {
    return {
      type: UserActions.USER_EMAIL_SIGNUP,
      payload: user,
    };
  }

  addSocialAccount(provider, account) {
    return {
      type: UserActions.ADD_SOCIAL_ACCOUNT,
      payload: { provider, account },
    };
  }

  addSocialAccountSuccess(provider, account) {
    return {
      type: UserActions.ADD_SOCIAL_ACCOUNT_SUCCESS,
      payload: { provider, account },
    };
  }

  fetchEmailMessages() {
    return {
      type: UserActions.FETCH_EMAIL_MESSAGES,
      payload: {},
    };
  }

  fetchEmailMessagesSuccess(payload) {
    return {
      type: UserActions.FETCH_EMAIL_MESSAGES_SUCCESS,
      payload,
    };
  }

  setPendingEmailMessage(message) {
    return {
      type: UserActions.SET_PENDING_EMAIL_MESSAGE,
      payload: { message },
    };
  }

  clearPendingEmailMessage() {
    return {
      type: UserActions.CLEAR_PENDING_EMAIL_MESSAGE,
      payload: {},
    };
  }

  updateDeviceInfo(payload) {
    return {
      type: UserActions.UPDATE_DEVICE_INFO,
      payload,
    };
  }

  updateDeviceInfoSuccess(payload) {
    return {
      type: UserActions.UPDATE_DEVICE_INFO_SUCCESS,
      payload,
    };
  }

  setNativeModules(payload) {
    return {
      type: UserActions.SET_NATIVE_MODULES,
      payload,
    };
  }
}
