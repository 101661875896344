import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class MyPenmateActions {
  static FIND_PENMATE_REQUEST = 'FIND_PENMATE_REQUEST';
  static FIND_PENMATE_SUCCESS = 'FIND_PENMATE_SUCCESS';
  static FIND_PENMATE_ERROR = 'FIND_PENMATE_ERROR';
  static ADD_PENMATE_REQUEST = 'ADD_PENMATE_REQUEST';
  static ADD_PENMATE_SUCCESS = 'ADD_PENMATE_SUCCESS';
  static ADD_PENMATE_ERROR = 'ADD_PENMATE_ERROR';
  static SELECT_PENMATE = 'SELECT_PENMATE';
  static LOAD_PENMATES_REQUEST = 'LOAD_PENMATES_REQUEST';
  static LOAD_PENMATES_SUCCESS = 'LOAD_PENMATES_SUCCESS';
  static LOAD_PENMATES_ERROR = 'LOAD_PENMATES_ERROR';
  static REMOVE_PENMATE_REQUEST = 'REMOVE_PENMATE_REQUEST';
  static REMOVE_PENMATE_SUCCESS = 'REMOVE_PENMATE_SUCCESS';
  static REMOVE_PENMATE_ERROR = 'REMOVE_PENMATE_ERROR';

  static VIEW_PENMATE = 'VIEW_PENMATE';

  static FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
  static FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
  static FETCH_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';

  static FETCH_MESSAGE_REQUEST = 'FETCH_MESSAGE_REQUEST';
  static FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS';
  static FETCH_MESSAGE_ERROR = 'FETCH_MESSAGE_ERROR';

  static DELETE_MESSAGE = 'DELETE_MESSAGE';

  findPenmate(payload) {
    return this.createAction(MyPenmateActions.FIND_PENMATE_REQUEST, payload);
  }

  findPenmateSuccess(payload) {
    return this.createAction(MyPenmateActions.FIND_PENMATE_SUCCESS, payload);
  }

  findPenmateError(payload = null) {
    return this.createAction(MyPenmateActions.FIND_PENMATE_ERROR, payload);
  }

  addPenmateSuccess(payload) {
    return this.createAction(MyPenmateActions.ADD_PENMATE_SUCCESS, payload);
  }

  addPenmateError(error = null) {
    return this.createAction(MyPenmateActions.ADD_PENMATE_ERROR, error);
  }

  removePenmateSuccess(payload) {
    return this.createAction(MyPenmateActions.REMOVE_PENMATE_SUCCESS, payload);
  }

  removePenmateError(error = null) {
    return this.createAction(MyPenmateActions.REMOVE_PENMATE_ERROR, error);
  }

  selectPenmate(payload) {
    return this.createAction(MyPenmateActions.SELECT_PENMATE, payload);
  }

  loadPenmates(payload = null) {
    return this.createAction(MyPenmateActions.LOAD_PENMATES_REQUEST, payload);
  }

  loadPenmatesSuccess(payload) {
    return this.createAction(MyPenmateActions.LOAD_PENMATES_SUCCESS, payload);
  }

  loadPenmatesError(payload) {
    return this.createAction(MyPenmateActions.LOAD_PENMATES_ERROR, payload);
  }

  viewPenmate(payload) {
    return this.createAction(MyPenmateActions.VIEW_PENMATE, payload);
  }

  loadMessages(penmate_id, page = undefined) {
    return this.createAction(MyPenmateActions.FETCH_MESSAGES_REQUEST, { penmate_id, page});
  }

  loadMessage(payload) {
    return this.createAction(MyPenmateActions.FETCH_MESSAGE_REQUEST, payload);
  }

  loadMessagesSuccess(payload) {
    return this.createAction(MyPenmateActions.FETCH_MESSAGES_SUCCESS, payload);
  }

  loadMessageSuccess(payload) {
    return this.createAction(MyPenmateActions.FETCH_MESSAGE_SUCCESS, payload);
  }

  loadMessagesError(payload) {
    return this.createAction(MyPenmateActions.FETCH_MESSAGES_ERROR, payload);
  }

  deleteMessage(payload) {
    return this.createAction(MyPenmateActions.DELETE_MESSAGE, payload);
  }

  private createAction(type, payload) {
    return { type, payload };
  }
}
