<ion-header id="modal-header">
  <ion-toolbar>
    <div class="title-content">
      <ion-title> Download the App </ion-title>
      <ion-buttons end>
        <ion-button (click)="onCancel()" ion-button icon-only class="close-button">
          <ion-icon name="close" class="close-icon"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="modal-content">
    <div class="download-hero"></div>
    <h2 style="text-align: center; color: yellow;">Download the App</h2>

    <ul id="app-features">
      <li class="highlight">Track your letters and postcards</li>
      <li>Setup delivery notifications</li>
      <li>Free promos and discounts</li>
    </ul>
    <div>
      <a
        href="https://itunes.apple.com/app/id955317881"
        target="_blank"
        (click)="onDownloadApp('ios')"
        ><div class="ios download-btn"></div
      ></a>
      <a
        href="https://play.google.com/store/apps/details?id=com.penmate"
        target="_blank"
        (click)="onDownloadApp('android')"
        ><div class="android download-btn"></div
      ></a>
    </div>
    <a class="skip-link" (click)="onCancel()">I'll do it later</a>
  </div>
</ion-content>
