
<ion-content #scrollContent>
  <div class="bg-notebook-paper">
      <div class="message-avatar">
        <div class="icon-circle">
          <div class="inner-circle">
            <ion-icon name="paper-plane" size="large"></ion-icon>
          </div>
        </div>
      </div>
    <form [formGroup]="addressForm" (ngSubmit)="onSaveAndContinue()">
      <h3 class="form-title">
          <span *ngIf="currentMessage.needs_info">Your message is incomplete.</span>
          <span *ngIf="!currentMessage.needs_info"> Edit delivery details</span>
      </h3>
        <p class="form-subtitle">
        Please double-check the details below and fill in any missing details:
      </p>
      <div id="main-content" #mainContent>
        <ion-list  class="address-fields" [class.submitted]="formSubmitted" no-lines>
            <p class="section-title">
            <span>
              Your message will be sent to:
            </span>
            </p>
  
            <div class="field-section" formGroupName="toAddress">
              <ion-item class="form-field name" [class.valid]="addressForm.controls.toAddress.controls.first_name.valid">
                <ion-label >Inmate First Name:</ion-label>
                  <ion-input
                    autoComplete="nope"
                    type="text"
                    formControlName="first_name"
                  ></ion-input>
              </ion-item>
              <ion-item class="form-field name" [class.valid]="addressForm.controls.toAddress.controls.last_name.valid">
                <ion-label >Inmate Last Name:</ion-label>
                  <ion-input
                    autoComplete="nope"
                    type="text"
                    formControlName="last_name"
                  ></ion-input>
              </ion-item>
              <ion-item class="form-field name" [class.valid]="addressForm.controls.toAddress.controls.facility.valid">
                <ion-label position="stacked">Facility/Location:</ion-label>

                <ion-input
                  autoComplete="nope"
                  type="text"
                  formControlName="facility"
                ></ion-input>
              </ion-item>

              <p class="id-number-note"> ID Number:</p>

              <ion-item class="form-field name" [class.valid]="addressForm.controls.toAddress.controls.id_number.valid">
                <ion-label >ID Number:</ion-label>
                <ion-input
                  autoComplete="nope"
                  type="text"
                  formControlName="id_number"
                ></ion-input>
              </ion-item>
          </div>
          <p class="section-title">Your return address:</p>
          <p class="note-text">A return address may be required to deliver your message.</p>
          <div class="field-section" formGroupName="fromAddress">
            <ion-item class="form-field name" [class.valid]="addressForm.controls.fromAddress.controls.name.valid">
              <ion-label >Name</ion-label>
              <ion-input
                autoComplete="nope"
                type="text"
                formControlName="name"
                autoComplete="nope"
              ></ion-input>
            </ion-item>
            <ion-row class="hint ion-align-items-center">
              <ion-col class="icon-col" size="2">
                <ion-icon name="information-circle" class="icon"></ion-icon>
              </ion-col>
              <ion-col size="10">
                <p class="text"> <span>Important:</span> Enter your full name (NO nicknames) or your message may not be approved.</p>
              </ion-col>
            </ion-row>
            <ion-item
              class="form-field"
              [class.valid]="addressForm.controls.fromAddress.controls.address_line1.valid"
            >
              <ion-label >Address Line 1</ion-label>
              <ion-input
                type="text"
                placeholder="123 Any St"
                formControlName="address_line1"
                autoComplete="nope"
              ></ion-input>
            </ion-item>
            <ion-item
              class="form-field"
              [class.valid]="addressForm.controls.fromAddress.controls.address_line2.valid"
            >
              <ion-label position="stacked">Address Line 2</ion-label>
              <ion-input
                type="text"
                formControlName="address_line2"
                autoComplete="nope"
              ></ion-input>
            </ion-item>
            <ion-item
              class="form-field"
              [class.valid]="addressForm.controls.fromAddress.controls.address_city.valid"

            >
              <ion-label position="stacked">
                <span *ngIf="addressForm.controls.fromAddress.controls.address_country.value == 'other'">
                  City / Town
                </span>
                <span *ngIf="addressForm.controls.fromAddress.controls.address_country.value !== 'other'"
                >
                  City
                </span>
              </ion-label>
              <ion-input
                type="text"
                placeholder="Enter City / Town"
                formControlName="address_city"
                autoComplete="nope"
              ></ion-input>
            </ion-item>

            <ion-item
              class="form-field"
            >
              <ion-label position="stacked">Country</ion-label>
              <ion-select formControlName="address_country" name="country">
                <ion-select-option value="US">United States</ion-select-option>
                <ion-select-option value="other">Other</ion-select-option>
              </ion-select>
            </ion-item> 

            <ion-item
              class="form-field"
              [class.valid]="addressForm.controls.fromAddress.controls.address_state.valid"
              *ngIf="addressForm.controls.fromAddress.controls.address_country.value == 'US'"
            >
              <ion-label>State</ion-label>
              <ion-select formControlName="address_state" name="state">
                <ion-select-option *ngFor="let state of STATE_NAMES">{{state}}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item
              class="form-field"
              *ngIf="addressForm.controls.fromAddress.controls.address_country.value == 'other'"
            >
              <ion-label position="stacked">State / Province / Region</ion-label>
              <ion-input
                type="text"
                placeholder="Enter State or Province"
                formControlName="address_state"
                autoComplete="nope"
              ></ion-input>
            </ion-item>

            <ion-item
              class="form-field"
              [class.valid]="addressForm.controls.fromAddress.controls.address_zip.valid"

            >
              <ion-label>
                <span *ngIf="addressForm.controls.fromAddress.controls.address_country.value == 'other'">
                  ZIP / Postal Code
                </span>
                <span *ngIf="addressForm.controls.fromAddress.controls.address_country.value !== 'other'"
                >
                  Zip Code
                </span>
              </ion-label>
              <ion-input
                type="tel"
                formControlName="address_zip"
                autoComplete="nope"
                maxlength="5"
                (ionFocus)="onFocusZip()"
              ></ion-input>
            </ion-item>
          </div>
        </ion-list>
      </div>
    </form>
  </div>
</ion-content>

<ion-footer id="modal-footer">
  <div id="message-actions">
    <ion-grid>
      <ion-row>
        <ion-col col-6 class="back">
          <ion-button ion-button (click)="onCancel()" icon-left>
            <div class="button-inner">
              <ion-icon name="arrow-back"></ion-icon>
              Back
            </div>
          </ion-button>
        </ion-col>
        <ion-col col-6 class="continue">
          <ion-button ion-button icon-right (click)="onSaveAndContinue()" [disabled]="!addressForm.valid">
            <div class="button-inner">
              Submit
              <ion-icon name="arrow-forward"></ion-icon>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
