import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController, NavController, NavParams, Platform, AlertController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { get } from 'lodash';
import { LetterService, AppState, AuthService, EventService } from '../services';
import { LoadingModal } from '../loading-modal/loading-modal';
import { UserActions } from '../actions';

import { CreateMessageActions } from '../actions';
import { Store } from '@ngrx/store';
declare var Card: any;

/*
  Generated class for the PaymentPage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-legal-terms-modal',
  styleUrls: ['./legal-terms-modal.scss'],
  templateUrl: 'legal-terms-modal.html',
  encapsulation: ViewEncapsulation.None,
})
export class LegalTermsModal {
  pageHeight;
  paymentForm;
  currentMessage;

  constructor(
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public navParams: NavParams,
    private userActions: UserActions,
    private authService: AuthService,
    private eventService: EventService,
    private store: Store<AppState>,
  ) {
    this.store
      .select(state => state.createMessage)
      .subscribe(currentMessage => {
        this.currentMessage = currentMessage;
      });
  }

  onDownloadApp = platform => {
    this.eventService.track('app-download', {
      platform,
    });
  };

  onAccept = async () => {
    this.eventService.track('accept-terms');
    this.authService.acceptTerms().subscribe( res => {
      const user = get(res, 'user');
      if (user) {
        this.store.dispatch(this.userActions.loginUser(user));
      }
    })
    const openModal = await this.modalCtrl.getTop();
    return openModal.dismiss({ cancel: true});
  }

  onCancel = async () => {
    this.navCtrl.navigateBack('/my-penmates')
    this.eventService.track('cancel-terms');
    const openModal = await this.modalCtrl.getTop();
    return openModal.dismiss({ cancel: true});
  };
}
