import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { compact, get } from 'lodash';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { debounceTime, map, switchMap, mergeMap, catchError } from 'rxjs/operators';
import { MyPenmate } from '../models/my-penmate';
import { MyPenmateActions, CreateMessageActions, CustomAction } from '../actions';
import { MyPenmateService, AppState } from '../services';
import * as Sentry from "@sentry/browser";

import ErrorHandler from '../../lib/error-handler'


@Injectable()
export class MyPenmateEffects {
  @Effect() findPenmate$ = this.actions$
    .pipe(ofType(MyPenmateActions.FIND_PENMATE_REQUEST))
    .pipe(debounceTime(300))
    .pipe(map((action: CustomAction) => action.payload))
    .pipe(
      switchMap(penmate_id => {
        return this.myPenmateService
          .findPenmate(penmate_id)
          .map(penmate => this.myPenmateActions.findPenmateSuccess(penmate))
          .catch(error => {
            ErrorHandler.captureMessage('Find Penmate Error', {
              penmate_id,
              error,
            });
            return Observable.of(this.myPenmateActions.findPenmateError(error));
          });
      }),
    );

  @Effect() getMessages$ = this.actions$
    .pipe(ofType(MyPenmateActions.FETCH_MESSAGES_REQUEST))
    .pipe(debounceTime(300))
    .pipe(map((action: CustomAction) => action.payload))
    .pipe(
      switchMap(({ penmate_id, page }) => {
        return this.myPenmateService
          .loadMessages(penmate_id, page)
          .map(({ letters, emessages, penmate }) => ({ letters, penmate, emessages }))
          .catch(error => {
            this.store
              .select(state => state.user)
              .subscribe(user => {
                if (error && error.status !== 401) {
                  ErrorHandler.captureMessage('Load Messages', {
                    penmate_id,
                    error,
                    user_id: user.id,
                  });
                }
              })
              .unsubscribe();
            return Observable.of(this.myPenmateActions.loadMessagesError(error));
          });
      }),
    )
    .pipe(
      mergeMap((result: any) => {
        const messages = [...get(result, 'letters', []), ...get(result, 'emessages', [])]
        return Observable.from(
          compact([
            result.penmate ? this.myPenmateActions.viewPenmate(result.penmate) : null,
            messages ? this.myPenmateActions.loadMessagesSuccess(messages) : null,
          ]),
        );
      }),
    );

  @Effect() getMessage$ = this.actions$
    .pipe(ofType(MyPenmateActions.FETCH_MESSAGE_REQUEST))
    .pipe(debounceTime(300))
    .pipe(map((action: CustomAction) => action.payload))
    .pipe(
      switchMap(({ emessage, message_id}) => {
        return this.myPenmateService
          .loadMessage(message_id, emessage)
          .map(({ letter, penmate }) => ({ letter, penmate }))
          .catch(error => {
            this.store
              .select(state => state.user)
              .subscribe(user => {
                if (error && error.status !== 401) {
                  ErrorHandler.captureMessage('Load Message', {
                    message_id,
                    error,
                    user_id: user.id,
                  });
                }
              })
              .unsubscribe();
            return Observable.of(this.myPenmateActions.loadMessagesError(error));
          });
      }),
    )
    .pipe(
      mergeMap((result: any) => {
        return Observable.from(
          compact([
            result.penmate ? this.myPenmateActions.viewPenmate(result.penmate) : null,
            result.letter ? this.myPenmateActions.loadMessageSuccess(result.letter) : null,
          ]),
        );
      }),
    );
  @Effect() getPenmates$ = this.actions$.pipe(
    ofType(MyPenmateActions.LOAD_PENMATES_REQUEST),
    debounceTime(300),
    switchMap(() => {
      return this.myPenmateService.getPenmates().pipe(
        map(penmates => {
          return this.myPenmateActions.loadPenmatesSuccess(penmates);
        }),
        catchError(error => {
          this.store
            .select(state => state.user)
            .subscribe(user => {
              if (error && error.status !== 401) {
                ErrorHandler.captureMessage('Load Penmates', {
                  error,
                  user_id: user.id,
                });
              }
            })
            .unsubscribe();
          return Observable.of(this.myPenmateActions.loadPenmatesError(error));
        }),
      );
    }),
  );

  @Effect() deleteMessage$ = this.actions$
    .pipe(ofType(MyPenmateActions.DELETE_MESSAGE))
    .pipe(debounceTime(300))
    .pipe(map((action: CustomAction) => action.payload))
    .pipe(
      switchMap(({ penmateId, message }) => {
        return this.myPenmateService
          .deleteMessage(penmateId, message)
          .map(({ letters }) => this.myPenmateActions.loadMessagesSuccess(letters))
          .catch(error => {
            this.store
              .select(state => state.user)
              .subscribe(user => {
                ErrorHandler.captureMessage('View Messages Error', {
                  letter_id: message.id,
                  error,
                  user_id: user.id,
                });
              })
              .unsubscribe();
            return Observable.of(this.myPenmateActions.loadMessagesError(error));
          });
      }),
    );

  constructor(
    private actions$: Actions,
    private myPenmateActions: MyPenmateActions,
    private myPenmateService: MyPenmateService,
    private store: Store<AppState>,
  ) {}
}
