import { Component, ViewEncapsulation, ViewChild, Input, Renderer2 } from '@angular/core';
import { ModalController, NavParams, Platform, AlertController, NavController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { get, isEmpty, debounce, mergeWith } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import { Router, ActivatedRoute, } from '@angular/router'
import { Location } from '@angular/common';

import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SearchService, LetterService, AppState, AuthService,  EventService, MyPenmateService } from '../services';
import { AddPaymentSheet } from '../add-payment-sheet/add-payment-sheet';
import { AddCreditsSuccessModal } from '../add-credits-success-modal/add-credits-success-modal';

import { concat, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeWhile,
} from 'rxjs/operators';
import { LoadingModal } from '../loading-modal/loading-modal';
import { SearchActions, UserActions, MyPenmateActions } from '../actions';
import { Store } from '@ngrx/store';
import US_STATES from '../add-penmate/us-states'
import { Search } from '../models';

import ErrorHandler from '../../lib/error-handler'

import {
  StripeInstance,
  StripeFactoryService
} from 'ngx-stripe';


declare var Penmate: any;
declare var Beacon;
declare global {
  interface Window {
      confetti: any;
  }
}


@Component({
  selector: 'pm-message-payment-update',
  styleUrls: ['./message-payment-update.scss'],
  templateUrl: 'message-payment-update.html',
  encapsulation: ViewEncapsulation.None,
})
export class MessagePaymentUpdate {
  user;
  pageHeight;
  letterId;
  penmate;
  creditOptionForm;
  setupIntent;
  invoiceId;
  currentMessage;
  loadingTotal = true;
  loading = false;
  paymentRequired = true;
  paymentRemoved = false;
  stripe: StripeInstance;
  messageId;



  constructor(
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
    private stripeFactory: StripeFactoryService,
    private authService: AuthService,
    private eventService: EventService,
    private letterService: LetterService,
    private myPenmateService:  MyPenmateService,
    private myPenmateActions: MyPenmateActions,
    private searchActions: SearchActions,
    private userActions: UserActions,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private bottomSheet: MatBottomSheet,
    private renderer: Renderer2
  ) {

  }

  ngOnInit() {
    Beacon('destroy');
    
    const url = new URL(window.location.href);
    const messageId = url.searchParams.get("messageId");
    const letterId = url.searchParams.get("letterId");
    this.messageId = this.route.snapshot.paramMap.get('id');
    this.stripe = this.stripeFactory.create(Penmate.env.stripeKey);
    const paymentIntentClientSecret = url.searchParams.get("payment_intent_client_secret");
    const paymentSucceeded = url.searchParams.get('paymentSucceeded');
    this.eventService.track('update-payment-start')

    this.store
    .select((state) => state.user)
    .subscribe((user) => {
      this.user = user;
    });
    
    if (!this.currentMessage || !this.user) {
      this.loading = true;
    }

    this.letterService.getLetterWithPenmate(this.messageId).subscribe( async ({ letter, penmate }) => {
      this.loading = false;
      this.currentMessage = letter;
      this.penmate = penmate;
      this.currentMessage.penmate = penmate;
      if (messageId && messageId.length && paymentIntentClientSecret) {
        return this.onOpenPaymentModal()
     }
    })
  
  }


  onCalculateTotal() {
    return get(this.currentMessage, 'data.subtotal') * 0.01;
  }

  onCalculateTax() {
    if (this.currentMessage) {
      const { delivery_fee }  = this.currentMessage
      return delivery_fee * 0.01
    }
  }

  onCalculateTotalWithTax() {
    if (this.currentMessage) {
      const { processing_fee }  = this.currentMessage
      return this.onCalculateTotal() + (processing_fee * 0.01);
    }
  }

  onOpenPaymentModal = () => {
    const url = new URL(window.location.href);
    const paymentIntentClientSecret = url.searchParams.get("setup_intent_client_secret");
    const letterId = url.searchParams.get("letterId");

    const sheetRef = this.bottomSheet.open(AddPaymentSheet, {
      data: {
        currentMessage: this.currentMessage,
        paymentDescription: `Update your payment details to continue to send your message`,
        user: this.user,
        letterId: this.currentMessage.id,
        updatePayment: true,
        paymentRemoved: this.paymentRemoved || !this.currentMessage.payment
      },
      panelClass: 'payment-sheet',
    });

    sheetRef.afterDismissed().subscribe((currMessage) => {
      if(currMessage && currMessage.payment) {
        console.log("@@@ CURR MESSAGE -->>", currMessage)
        this.currentMessage.payment = currMessage.payment;
        this.currentMessage.coupon = null;
        this.onUpdatePayment();
      }
      if (paymentIntentClientSecret) {

      }
    });
  }

  onUpdatePayment = () => {
    this.authService.processLetterPayment(this.currentMessage.id, this.currentMessage.payment).subscribe(letter => {
      this.currentMessage = letter;
      console.log("@@@ UPDATED LETTER _->>", this.currentMessage)

    })
  }

  removePayment = async () => {
    const confirmRemove = await this.alertCtrl.create({
      header: 'Remove this payment method?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Yes',
          cssClass: 'ok',
          handler: () => {
            this.currentMessage.payment = null;
            this.paymentRemoved = true;
          },
        },
      ],
    });
    await confirmRemove.present();
  };

  onAddPayment() {
    const url = new URL(window.location.href);
    const messageId = url.searchParams.get("messageId");
    const letterId = url.searchParams.get("letterId");

    if (messageId && messageId.length) {
      return this.authService.getPendingIntent(messageId, letterId).subscribe(({ intent, payment_method: paymentMethod, inmate, ...invoice}) => {
        this.setupIntent = intent;
        this.invoiceId = invoice.id;
        this.currentMessage = invoice;
        this.currentMessage.returnAddress = invoice.return_address;
        this.currentMessage.payment = paymentMethod || invoice.payment;
        this.currentMessage.inmate = inmate;
        this.onOpenPaymentModal();
        this.loading = false;

      }, async () => {
        const alert = await this.alertCtrl.create({
          header: `Payment Error`,
          cssClass: 'error-alert',
          message: 'There was an error processing your payment',
          buttons: [
            {
              text: 'Try Again',
              handler: () => {

                const routePath = window.location.pathname.split('/billing/add-credits')[0]
                this.router.navigateByUrl(routePath);
              }
            },
          ],
        });
        return alert.present();
      }) 
    }
    return this.onOpenPaymentModal();
  }

  onDisplayErrorAlert = async (titleText, buttonText, subHeader = null) => {
    const alert = await this.alertCtrl.create({
      cssClass: 'error-alert',
      header: titleText,
      subHeader,
      buttons: [
        {
          text: buttonText,
        },
      ],
    });
    await alert.present();
  };

  onHandleSubmitSuccess = async () => {
    const modal = await this.modalCtrl.create({
      component: AddCreditsSuccessModal,
    });
    await modal.present();
  }


  onSubmit = async () => {
    if (this.currentMessage && !!this.currentMessage.sale.paid) {
      return this.onCancel();
    }
    this.onOpenPaymentModal();
  }

  onCancel = () => {
    this.eventService.track('update-payment-cancel')
    const routePath = `/penmates`;
    this.router.navigateByUrl(routePath);
  };


}
