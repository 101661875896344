<ion-content>
  <ion-row class="search-results-header">
    <ion-col size="9">
      <div
        [ngStyle]="{'justify-content': 'center', 'display': 'flex', 'align-items': 'center', 'flex': '1'}"
        class="header-title"
      >
        <h6>Searching {{currentSearch && currentSearch.state}} Prisons/Jails</h6>
      </div>
    </ion-col>
    <ion-col size="3">
      <ion-button id="cancel-results" (click)="cancel()" clear class="cancel-button">
        Cancel
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-list id="pm-search-results" *ngIf="currentSearch && currentSearch.results">
    <ion-item
      no-highlight
      *ngFor="let result of currentSearch.results; let index=index; let odd=odd; let even=even;"
      class="search-result"
      (click)="viewSearchResult(result)"
      [ngClass]="{ odd: odd, even: even }"
    >
      <ion-avatar slot="start" class="avatar-image" [ngClass]="{ hasImage: result.image }">
        <ion-icon *ngIf="!result.image" name="person"> </ion-icon>
        <div
          class="image"
          *ngIf="result.image"
          [ngStyle]="{'background-image': 'url(' + result.image + ')'}"
        ></div>
      </ion-avatar>
      <div
        [ngStyle]="{'display': 'flex', 'flex-direction': 'row', 'align-items': 'center', 'flex': '1'}"
      >
        <div [ngStyle]="{'flex': '0.7'}">
          <h2>{{ result.name }}</h2>
          <h3>{{ result.facility }}</h3>
        </div>
        <div
          [ngStyle]="{'flex': '0.3', 'display': 'flex', 'align-items': 'center', 'justify-content': 'center'}"
        >
          <p class="extra-details">
            <span class="detail age" *ngIf="result.age">
              <span class="label">Age:</span>{{result.age}}
            </span>
            <span class="detail ethnicity" *ngIf="result.race">
              <span class="label">Race:</span>{{result.race}}
            </span>
          </p>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-content>
