<ion-content id="modal-content">
    <div class="page-content">
        <ion-segment [(ngModel)]="currentTab">
            <ion-segment-button value="searchUsers" (ionSelect)="onTabChange('searchUsers')">
                Search Users
            </ion-segment-button>
            <ion-segment-button value="searchTags" (ionSelect)="onTabChange('searchTags')">
                Search Hashtags
            </ion-segment-button>
        </ion-segment>
        <ion-toolbar class="search-toolbar">
            <ion-searchbar
                class="search-ig-input"
                autocorrect="off"
                [(ngModel)]="searchInput"
                debounce="5"
                [showCancelButton]="false"
                [placeholder]="tabs[currentTab].placeholder"
                (keyup.enter)="onSearchInput($event)"
            >
            </ion-searchbar>
        </ion-toolbar>
        <div id="search-null-state" *ngIf="canShowNullState()">
            <div id="search-users-null-state" class="search-null-state-content">
                <ion-icon name="search"></ion-icon>
                <p class="search-input" *ngIf="searchInput">
                    Searching: <span>{{ searchInput }}</span>
                </p>
                <ion-button
                    id="submit-search-button"
                    ion-button
                    (click)="onSubmitSearch()"
                    [disabled]="!searchInput"
                >
                    Search!
                </ion-button>
            </div>
            <div id="search-tags-null-state" class="search-null-state-content"></div>
        </div>
        <ion-list>
            <ion-grid id="photo-grid">
                <ion-row *ngFor="let row of tabs[currentTab].photos; let rowIdx = index;">
                    <ion-col size="6" *ngFor="let photo of row">
                        <pm-photo-picker-photo
                            [photo]="photo"
                            [rowIdx]="rowIdx"
                            [allPhotos]="tabs[currentTab].photos"
                            [isPostcard]="isPostcard"
                            [onSelectPhoto]="onSelectPhoto"
                        >
                        </pm-photo-picker-photo>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-list>

        <div
            id="search-user-result"
            *ngIf="currentTab == 'searchUsers' && tabs[currentTab].user && tabs[currentTab].showUser"
        >
            <ion-card>
                <ion-card-content class="profile-card">
                    <ion-row>
                        <ion-col size="4">
                            <div
                                class="profile-pic"
                                [style.background-image]="'url(' + tabs[currentTab].user.profile_pic_url + ')'"
                            ></div>
                        </ion-col>
                        <ion-col size="8">
                            <h3 class="username-title">
                                {{tabs[currentTab].user.username}}
                            </h3>
                            <p class="byline">{{ tabs[currentTab].user.byline }}</p>
                            <ion-button
                                ion-button
                                class="view-posts-button"
                                (click)="onViewPostsFromUser(tabs[currentTab].user)"
                            >
                                See Posts
                            </ion-button>
                        </ion-col>
                    </ion-row>
                    <ion-icon
                        name="md-close"
                        class="cancel-action"
                        (click)="onCancelSearchUser()"
                    ></ion-icon>
                </ion-card-content>
            </ion-card>
        </div>
    </div>
    <ion-infinite-scroll (ionInfinite)="onLoadMore($event)" threshold="150px">
        <ion-infinite-scroll-content loadingSpinner="null"></ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ion-content>
