<ion-header>
  <ion-toolbar style="width: 100%;">
    <div class="title-content">
      <h3>Your Delivery Details</h3>
    </div>
    <div class="message-avatar">
      <div class="icon-circle">
        <div class="inner-circle">
          <ion-icon name="paper-plane" size="large"></ion-icon>
        </div>
      </div>
    </div>
    <div id="mailing-info" class="section">
      <div class="address-row recipient">
        <ion-icon name="ios-paper-plane" class="icon"></ion-icon>
        <div class="inner">
          <p class="letter-detail">
            <span *ngIf="currentMessage?.eMessage">Message</span>
            <span *ngIf="currentMessage?.shippingMethod !== 'emessage' && currentMessage.messageType !== 'postcard'">Letter</span>
            <span *ngIf="currentMessage?.shippingMethod !== 'emessage' && currentMessage.messageType === 'postcard'">Postcard</span>
            <span
              *ngIf="currentMessage.selectedPhotos.length > 0 && !isPostcard"
              class="photo-price"
            >
              +
              <span
                >{{currentMessage.selectedPhotos.length}}
                <span *ngIf="currentMessage?.selectedPhotos?.length > 1"> photos </span>
                <span *ngIf="currentMessage?.selectedPhotos?.length == 1"> photo</span>
              </span>
            </span>
            to:
          </p>
          <p class="name">{{penmate?.name}}</p>
          <p class="address-facility">{{currentMessage?.eMessage?.name || penmate?.facility}}</p>
        </div>
      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content #scrollContent>



  <form [formGroup]="returnAddressForm" (ngSubmit)="onSaveAndContinue()">
    <ion-row class="header-row">
      <ion-icon name="mail-open" class="icon"></ion-icon>
      <h2 class="form-title"> Send replies to:</h2>
    </ion-row>

    <h3 class="form-subtitle">How should {{penmate.name}} reply to you?</h3>

    <div id="main-content" #mainContent>
      <ion-list class="address-fields" [class.submitted]="formSubmitted" no-lines>
        <span>
          <section class="name-section">
            <div class="name-input form-section-fields">
              <ion-item class="form-field name" [class.valid]="returnAddressForm.controls.firstName.valid" [class.invalid]="isFieldInvalid('firstName')">
                <ion-label position="stacked">First name</ion-label>
                <ion-input
                  autoComplete="nope"
                  type="text"
                  formControlName="firstName"
                ></ion-input>

              </ion-item>
              <ion-item class="form-field name" [class.valid]="returnAddressForm.controls.lastName.valid" [class.invalid]="isFieldInvalid('lastName')">
                <ion-label position="stacked">Last name</ion-label>
                <ion-input
                  autoComplete="nope"
                  type="text"
                  formControlName="lastName"
                ></ion-input>
              </ion-item>
              <ion-item class="form-field name" [class.valid]="returnAddressForm.controls.lastName.valid">
                <ion-label position="stacked">E-mail</ion-label>
                <ion-input
                  autoComplete="nope"
                  type="text"
                  formControlName="email"
                  disabled="true"
                ></ion-input>
              </ion-item>
            </div>
            <ion-row class="hint ion-align-items-center" *ngIf="!shouldConfirmAddress">
              <ion-col class="icon-col" size="2">
                <ion-icon name="information-circle" class="icon"></ion-icon>
              </ion-col>
              <ion-col size="10">
                <p class="text"> <span>Important:</span> Enter your full name (NO nicknames) or your message may not be approved.</p>
              </ion-col>
            </ion-row>
          </section>
          <section class="form-section return-address" *ngIf="!shouldConfirmAddress">
            <h3 class="section-title"> Return address </h3>
            <h3 class="form-subtitle ion-text-left"> The facility may require a return address to approve your message. </h3>
            <div class="form-section-fields">
              <ion-item
                class="form-field"
                [class.valid]="returnAddressForm.controls.address_line1.valid"
                [class.invalid]="isFieldInvalid('address_line1')"
              >
                <ion-label position="stacked">Address Line 1</ion-label>
                <ion-input
                  type="text"
                  placeholder="123 Any St"
                  formControlName="address_line1"
                  autoComplete="nope"
                ></ion-input>
              </ion-item>
              <ion-item
                class="form-field"
                [class.valid]="returnAddressForm.controls.address_line2.valid"
              >
                <ion-label position="stacked">Address Line 2</ion-label>
                <ion-input
                  type="text"
                  formControlName="address_line2"
                  autoComplete="nope"
                ></ion-input>
              </ion-item>
              <ion-item
                class="form-field"
                [class.invalid]="isFieldInvalid('address_city')"
              >
                <ion-label position="stacked">
                  <span *ngIf="returnAddressForm.get('address_country').value == 'other'">
                    City / Town
                  </span>
                  <span *ngIf="returnAddressForm.get('address_country').value !== 'other'"
                  >
                    City
                  </span>
                </ion-label>
                
                <ion-input
                  type="text"
                  placeholder="Enter City"
                  formControlName="address_city"
                  autoComplete="nope"
                ></ion-input>
              </ion-item>
              <ion-item
                class="form-field"
              >
                <ion-label position="stacked">Country</ion-label>
                <ion-select formControlName="address_country" name="country">
                  <ion-select-option value="US">United States</ion-select-option>
                  <ion-select-option value="other">Other</ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item
                class="form-field"
                [class.valid]="returnAddressForm.controls.address_state.valid"
                [class.invalid]="isFieldInvalid('address_state')"
                *ngIf="returnAddressForm.get('address_country').value == 'US'"
              >
                <ion-label position="stacked">State</ion-label>
                <ion-select formControlName="address_state" name="state">
                  <ion-select-option *ngFor="let state of STATE_NAMES">{{state}}</ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item
                class="form-field"
                [class.valid]="returnAddressForm.controls.address_state.valid"
                *ngIf="returnAddressForm.get('address_country').value == 'other'"

              >
                <ion-label position="stacked">State / Province / Region</ion-label>
                <ion-input
                  type="text"
                  placeholder="Enter State or Province"
                  formControlName="address_state"
                  autoComplete="nope"
                ></ion-input>
              </ion-item>
              <ion-item
                class="form-field"
                [class.valid]="returnAddressForm.controls.address_zip.valid"
                [class.invalid]="isFieldInvalid('address_zip')"

              >
                <ion-label position="stacked">
                  <span *ngIf="returnAddressForm.get('address_country').value == 'other'">
                    ZIP / Postal Code
                  </span>
                  <span *ngIf="returnAddressForm.get('address_country').value !== 'other'"
                  >
                    Zip Code
                  </span>
                </ion-label>
                <ion-input
                  formControlName="address_zip"
                  autoComplete="nope"
                  maxlength="15"
                  (ionFocus)="onFocusZip()"
                ></ion-input>
              </ion-item>
            </div>
          </section>
          <section class="form-section return-address confirm-address"  *ngIf="shouldConfirmAddress">
            <h3 class="section-title">Confirm your return address</h3>
            <h3 class="form-subtitle ion-text-left">Please confirm the recommended changes below: </h3>
            <ion-row class="hint ion-align-items-center" *ngIf="validationResult?.missing_components?.length > 0">
              <ion-col class="icon-col" size="2">
                <ion-icon name="information-circle" class="icon"></ion-icon>
              </ion-col>
              <ion-col size="10">
                <p class="text">Please make sure the following details are correct: 
                  <span 
                    class="missing-component"
                    *ngFor="let component of validationResult.missing_components"
                  >
                    <br/>{{component}}
                  </span>
                </p>
              </ion-col>
            </ion-row>
            
            <div 
              class="form-section-fields"
              [ngClass]="{'selected': confirmedAddress?.addressType === 'user'}"
            >
              <div 
                class="address-option input-address"
              >
                <ion-row>
                  <ion-col size="2" class="icon-col ion-align-items-center" (click)="onConfirmAddress('user')">
                    <ion-icon name="radio-button-off" class="icon" *ngIf="!confirmedAddress || confirmedAddress?.addressType !== 'user'"></ion-icon>
                    <ion-icon name="radio-button-on"  class="icon" *ngIf="confirmedAddress?.addressType === 'user'"></ion-icon>
                  </ion-col>
                  <ion-col size="9" class="address-text">
                    <h4>What you entered:</h4>
                    <p>
                      {{returnAddressForm.value["firstName"]}} {{returnAddressForm.value["lastName"]}}<br/>
                      {{returnAddressForm.value["address_line1"]}}<br/>
                      <span *ngIf="returnAddressForm.value['address_line2']">
                        {{returnAddressForm.value["address_line2"]}}<br/></span>
                      {{returnAddressForm.value["address_city"]}}, {{returnAddressForm.value["address_state"]}} {{returnAddressForm.value["address_zip"]}}
                    </p>
                  </ion-col>
                </ion-row>
                <div class="action-buttons">
                  <ion-button class="action-btn" (click)="onConfirmAddress('user')" ion-button icon-left>
                    <div class="button-inner">
                      <ion-icon name="checkmark-circle-outline"></ion-icon>
                      Use this address
                    </div>
                  </ion-button>
                  <ion-button class="action-btn edit" ion-button icon-right (click)="onEditAddress()">
                    <div class="button-inner">
                      <ion-icon name="create"></ion-icon>
                      Edit this address
                    </div>
                  </ion-button>
                </div>
              </div>
            </div>
            <div 
              class="form-section-fields"
              [ngClass]="{'selected': confirmedAddress?.addressType === 'suggested'}"
            >
              <div 
                class="address-option suggested-address"
              >
                <ion-row>
                  <ion-col size="2" class="icon-col ion-align-items-center"  (click)="onConfirmAddress('suggested')">
                    <ion-icon name="radio-button-off" class="icon" *ngIf="!confirmedAddress || confirmedAddress?.addressType !== 'suggested'"></ion-icon>
                    <ion-icon name="radio-button-on"  class="icon" *ngIf="confirmedAddress?.addressType === 'suggested'"></ion-icon>
                  </ion-col>
                  <ion-col size="9" class="address-text">
                    <h4>Suggested address:</h4>
                    <p>
                      {{returnAddressForm.value["firstName"]}} {{returnAddressForm.value["lastName"]}}<br/>
                      {{validationResult.validated_address.address_line1}}<br/>
                      <span *ngIf="validationResult.validated_address.address_line2">
                        {{validationResult.validated_address.address_line2}}<br/></span>
                      {{validationResult.validated_address.address_city}}, {{validationResult.validated_address.address_state}} {{validationResult.validated_address.address_zip}}
                    </p>
                  </ion-col>
                </ion-row>
                <div class="action-buttons">
                  <ion-button class="action-btn" (click)="onConfirmAddress('suggested')" ion-button icon-left>
                    <div class="button-inner">
                      <ion-icon name="checkmark-circle-outline"></ion-icon>
                      Use this address
                    </div>
                  </ion-button>
                </div>
              </div>
            </div>
          </section>
        </span>
      </ion-list>
    </div>
  </form>
</ion-content>

<ion-footer id="modal-footer">
  <div id="message-actions">
    <ion-grid>
      <ion-row>
        <ion-col col-6 class="back">
          <ion-button ion-button (click)="onCancel()" icon-left>
            <div class="button-inner">
              <ion-icon name="arrow-back"></ion-icon>
              Back
            </div>
          </ion-button>
        </ion-col>
        <ion-col col-6 class="continue">
          <ion-button 
            ion-button 
            icon-right 
            (click)="onSaveAndContinue()" 
            [disabled]="!returnAddressForm.valid || shouldConfirmAddress && !confirmedAddress?.addressType"
          >
            <div class="button-inner">
              Continue
              <ion-icon name="arrow-forward"></ion-icon>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
