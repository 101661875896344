<ion-content>
  <div class="container">
    <div class="bg-circle left"></div>
    <div class="bg-circle right"></div>

    <ion-button (click)="onCancel()" class="close-button">
      <ion-icon name="close" class="close-icon"></ion-icon>
    </ion-button>

    <div class="inner">
      <div class="message-artwork-wrapper">
        <div class="message-artwork"></div>
      </div>
      <h1 class="title">
        <span class="title-2">
          <span *ngIf="!currentMessage || !currentMessage.inmate">Add credits to your account</span>
          <span *ngIf="currentMessage?.inmate">Add credits to send messages to {{currentMessage.inmate.name}} </span>
        </span>
      </h1>
      <div class="details" *ngIf="!loading">
        <p class="desc credits-enabled-msg text-center bold">
          Your messaging account has been <span color="green">approved</span> and is now active 🎉
         </p>
        <p class="desc credits-enabled-msg text-center">Add credits to your account to send messages and photos.</p>
      </div>
      <span
      *ngIf="loading"
       style="display:flex; align-items:center; justify-content:center;"
     >
       <ion-spinner name="lines-small"></ion-spinner>
     </span>
      <div id="price-total" *ngIf="currentMessage?.id && !!creditOptionForm">
        <span>

          <div
            class="line-item choose-shipping choose-credits"
            id="choose-credits"
          >
            <form [formGroup]="creditOptionForm">
              <ion-radio-group formControlName="creditOption" >
                <ion-list style="width:100%;" radio-group>
                  <ion-list-header>
                    <ion-label class="emessage-header">
                      <span class="header-title">
                        <ion-icon name="ios-star"></ion-icon>
                        <span>Add e-message credits </span>
                      </span>
                      <p class="credit-desc">
                        1 credit per message / photo
                      </p>
                    </ion-label>
                  </ion-list-header>
                  <ion-item class="shipping-option e-message">
                    <ion-label>
                      <div class="speed-cost">
                        <div
                          style="
                            width: 90%;
                            display: block;
                            text-wrap: pretty;
                            "
                        >
                          <p class="service">
                            <span>Add 5 credits: <span class="ea">$1.99 ea</span></span>
                          </p>
                          <p class="credit-desc">
                            Send <span class="hlt">5</span> messages or photos
                          </p>
                        </div>

                        <p class="cost">
                          <span>
                            {{ 5 * (currentMessage?.data.creditOptions["5"]  * 0.01) |
                            currency:'USD':'symbol':'1.2-2'}}
                          </span>
                        </p>
                      </div>
                    </ion-label>
                    <ion-radio name="creditOption" slot="start" value="5" mode="md" ></ion-radio>
                  </ion-item>
                  <ion-item class="shipping-option e-message">
                    <ion-label>
                      <div class="speed-cost">
                        <div
                          style="
                            width: 90%;
                            display: block;
                            text-wrap: pretty;
                            "
                        >
                          <p class="service">
                            <span>Add 10 credits: <span class="ea">$1.75 ea</span></span>
                          </p>
                          <p class="credit-desc">
                            Send <span class="hlt">10</span> messages or photos
                          </p>
                        </div>

                        <p class="cost">
                          <span>
                            {{ 10 * (currentMessage?.data.creditOptions["10"] * 0.01) |
                              currency:'USD':'symbol':'1.2-2'}}
                          </span>
                        </p>
                      </div>
                    </ion-label>
                    <ion-radio name="creditOption" slot="start" value="10" mode="md"></ion-radio>
                  </ion-item>
                  <ion-item class="shipping-option e-message">
                    <ion-label>
                      <div class="speed-cost">
                        <div
                          style="
                            width: 90%;
                            display: block;
                            text-wrap: pretty;
                            "
                        >
                          <p class="service">
                            <span>Add 15 credits: <span class="ea">$1.50 ea</span></span>
                          </p>
                          <p class="credit-desc">
                            Send <span class="hlt">15</span> messages or photos
                          </p>
                        </div>

                        <p class="cost">
                          <span>
                            {{ 15 * (currentMessage?.data.creditOptions["15"] * 0.01) |
                              currency:'USD':'symbol':'1.2-2'}}
                          </span>
                        </p>
                      </div>
                    </ion-label>
                    <ion-radio name="creditOption" slot="start" value="15" mode="md"></ion-radio>
                  </ion-item>
                </ion-list>
              </ion-radio-group>

            </form>
          </div>

          <div class="line-item">
            <p class="label">Delivery Method:</p>
            <p class="price bold">
              E-Message
            </p>
          </div>
          <div class="line-item">
            <p class="label">
                     
              <span *ngIf="creditOptionForm.value.creditOption">
                {{creditOptionForm?.value.creditOption}}
                <span [ngPlural]="creditOptionForm?.value?.creditOption">
                  <ng-template ngPluralCase="=1"> credit</ng-template>
                  <ng-template ngPluralCase="other"> credits</ng-template>
                </span>
              </span>
            </p>
            <p class="price">
              <span
                *ngIf="loadingTotal"
                style="display:flex; align-items:center; justify-content:center;"
              >
                <ion-spinner name="lines-small"></ion-spinner>
              </span>
              <span *ngIf="!loadingTotal">
                <span>
                  {{ (currentMessage?.data['subtotal']) * 0.01 |
                currency:'USD':'symbol':'1.2-2'}}
                </span>
              </span>
            </p>
          </div>
          <div class="line-item">
            <p class="label">Transaction / Processing</p>
            <p class="price">
              <span
                *ngIf="loadingTotal"
                style="display:flex; align-items:center; justify-content:center;"
              >
                <ion-spinner name="lines-small"></ion-spinner>
              </span>
              <span *ngIf="!loadingTotal">
                {{ currentMessage?.processing_fee * 0.01 | currency:'USD':'symbol':'1.2-2'}}
              </span>
            </p>
          </div>
          <div class="line-item bold" *ngIf="paymentRequired">
            <p class="label">Total</p>
            <p class="price">
              <span
                *ngIf="loadingTotal"
                style="display:flex; align-items:center; justify-content:center;"
              >
                <ion-spinner name="lines-small"></ion-spinner>
              </span>
              <span *ngIf="!loadingTotal">
                {{ onCalculateTotalWithTax() | currency:'USD':'symbol':'1.2-2'}}
              </span>
            </p>
          </div>
        </span>
        <button
          class="pay-with-card-button"
          (click)="onAddPayment()"
          *ngIf="currentMessage && !currentMessage?.payment"
        >
          <ion-icon name="card" class="icon"></ion-icon>
          {{" "}}
          Add Payment
        </button>
        <p class="order-terms">
          Message credit purchases are non-refundable and cannot be transferred between accounts.
        </p>
      </div>
    </div>
    <div
      id="payment-detail-container"
      *ngIf="currentMessage?.payment"
      >
      <div id="payment-success">
        <div>
        <div id="attached-payment">
          <ion-grid>
            <ion-row>
              <ion-col size="1" class="ok-icon">
                <ion-icon name="checkmark-circle"></ion-icon>
              </ion-col>
              <ion-col size="9">
                <p class="bill-to">Bill to</p>
                <div class="card-info" [ngClass]="{'cashapp': currentMessage.payment.brand == 'cashapp'}">
                  <div class="payment-type card" *ngIf="currentMessage.payment.brand !== 'cashapp'">
                    <ion-icon name="ios-card"></ion-icon>
                    <span class="card-type">Card&nbsp;</span>
                    <span class="card-last4">ending in {{currentMessage.payment.last4}}</span>
                  </div>

                  <div *ngIf="currentMessage.payment.brand == 'cashapp'" class="payment-type cashapp-pay">
                    <span class="cashapp-logo"></span>
                    <span class="card-type">My Cash App</span>
                  </div>
                </div>
              </ion-col>
              <ion-col size="2">
                <a (click)="removePayment()">
                  <ion-icon name="ios-close-circle" class="remove-icon"></ion-icon>
                </a>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        </div>
      </div>
    </div>
  </div>

</ion-content>

<ion-footer id="modal-footer">
  <div id="message-actions">
    <ion-grid>
      <ion-row>
        <ion-col size="12" class="continue">
          <ion-button
            class="pay-with-card-button"
            (click)="onSubmit()"
            *ngIf="currentMessage?.id"
            [disabled]="!currentMessage.payment"
          >
            <ion-icon name="card" class="icon"></ion-icon>
            &nbsp;Purchase Credits
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
