import { Component, ViewEncapsulation, Input } from '@angular/core';

import {
  ModalController,
  NavParams,
  Platform,
  AlertController,
  NavController,
} from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { get, truncate } from 'lodash';
import { LetterService, AppState, AuthService, EventService } from '../services';
import { LoadingModal } from '../loading-modal/loading-modal';
import { CreateMessageActions, MyPenmateActions, UserActions } from '../actions';
import { Store } from '@ngrx/store';

/*
  Generated class for the PaymentPage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-email-messages-modal',
  styleUrls: ['./email-messages-modal.scss'],
  templateUrl: 'email-messages-modal.html',
  encapsulation: ViewEncapsulation.None,
})
export class EmailMessagesModalPage {
  emailMessages;
  @Input() showSearchMessage;
  
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public navCtrl: NavController,
    private platform: Platform,
    private letterService: LetterService,
    private authService: AuthService,
    private eventService: EventService,
    private alertCtrl: AlertController,
    private myPenmateActions: MyPenmateActions,
    private createMessageActions: CreateMessageActions,
    private userActions: UserActions,
    private store: Store<AppState>,
  ) {
    // TODO: Check if penmate is found, otherwise, save message and take user to search
    this.store
      .select(state => state.user.emailMessages)
      .subscribe(emailMessages => {
        this.emailMessages = emailMessages;
      });
  }

  onCreateLetter = async message => {
    await this.modalCtrl.dismiss();
    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Loading letter...' },
    });
    await loadingModal.present();
    this.letterService
      .updateEmailMessage(message.id, { createLetter: true })
      .subscribe(async res => {
        await loadingModal.dismiss();
        const letter = get(res, 'letter', {});
        const penmate = get(res, 'penmate');
        this.store.dispatch(this.userActions.setPendingEmailMessage(letter));
        if (penmate) {
          this.store.dispatch(this.myPenmateActions.viewPenmate(penmate));
          this.store.dispatch(this.createMessageActions.setMessage(letter));
          this.navCtrl.navigateForward(`/penmates/${penmate.id}/new-message`);
          return this.store.dispatch(this.myPenmateActions.loadPenmates());
        }
        this.navCtrl.navigateForward(`/add-penmate`, {
          state: {
            showEmailMessageModal: true,
          },
        });
      });
  };

  onCancel = async () => {
    const openModal = await this.modalCtrl.getTop();
    return openModal.dismiss();
  };
}
