import { ActionReducer, Action } from '@ngrx/store';
import { MyPenmateActions, CreateMessageActions, CustomAction } from '../actions';
import { MyPenmate } from '../models';
import { uniqBy, toArray, get, isEqual, flatten, isEmpty, unionBy } from 'lodash';

const initialState: MyPenmate = {
  isLoading: false,
  currentPenmate: null,
  penmates: [],
  drafts: [],
  error: null,
};

export function myPenmateReducer(state = initialState, action: CustomAction): MyPenmate {
  switch (action.type) {
    case MyPenmateActions.FIND_PENMATE_REQUEST:
      return Object.assign({}, state, { isLoading: true });

    case MyPenmateActions.FIND_PENMATE_SUCCESS:
      return Object.assign({}, state, { isLoading: false, currentPenmate: action.payload });

    case MyPenmateActions.FIND_PENMATE_ERROR:
      return Object.assign({}, state, { isLoading: false, error: action.payload });

    case MyPenmateActions.VIEW_PENMATE: {
      if (isEqual(action.payload.id, get(state, 'currentPenmate.id'))) {
        return Object.assign({}, state, {
          currentPenmate: {
            ...state.currentPenmate,
            ...action.payload,
          },
        });
      }
      return Object.assign({}, state, { currentPenmate: action.payload });
    }

    case MyPenmateActions.LOAD_PENMATES_SUCCESS: {
      const currentPenmates = toArray(state.penmates);
      const payloadPenmates = toArray(action.payload);
      const nextPenmates = unionBy(payloadPenmates, currentPenmates, 'id');
      return Object.assign({}, state, {
        isLoading: false,
        penmates: uniqBy(nextPenmates, 'id'),
      });
    }
    case MyPenmateActions.LOAD_PENMATES_ERROR:
      return Object.assign({}, state, { isLoading: false, error: action.payload });

    case MyPenmateActions.REMOVE_PENMATE_SUCCESS:
      // copy data key to top-level
      return Object.assign({}, state, {
        penmates: toArray(action.payload),
      });

    case MyPenmateActions.FETCH_MESSAGES_SUCCESS: {
      const previousMessages = get(state, 'currentPenmate.messages') || [];
      const sentMessages = action.payload.map(m => {
        let msg = Object.assign({}, m.data, m); // copy data key to top-level
        return msg;
      });
      const nextMessages = uniqBy([...previousMessages, ...sentMessages], 'id')
      const nextState =  Object.assign({}, state, {
        currentPenmate: Object.assign({}, state.currentPenmate, { messages: nextMessages }),
      });
      return nextState;
    }
    case MyPenmateActions.FETCH_MESSAGE_SUCCESS:
      // copy data key to top-level
      const message = Object.assign({}, action.payload.data, action.payload); // copy data key to top-level
      const currentPenmate = Object.assign({}, state.currentPenmate);
      currentPenmate.messages = currentPenmate.messages || [];
      const existingIdx = currentPenmate.messages.findIndex(m => m.id == message.id);
      if (existingIdx) {
        currentPenmate.messages[existingIdx] = message;
      } else {
        currentPenmate.messages.push(message);
      }
      currentPenmate.messages = uniqBy(currentPenmate.messages, 'id');
      if (isEmpty(currentPenmate.messages)) {
        return state;
      }
      return Object.assign({}, state, {
        currentPenmate,
      });

    case MyPenmateActions.FETCH_MESSAGES_ERROR:
      return Object.assign({}, state, { isLoading: false, hasError: true });

    case CreateMessageActions.FETCH_DRAFTS_SUCCESS:
      return Object.assign({}, state, { drafts: get(action, 'payload.drafts', []) });
    default: {
      return state;
    }
  }
}
