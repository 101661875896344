import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { AppState } from './index';
import { Store } from '@ngrx/store';
import { map, catchError } from 'rxjs/operators';
import { omit, get } from 'lodash';

import { ApiGateway } from './api-gateway.service';
import { Http } from '@angular/http';
import ErrorHandler from '../../lib/error-handler'

declare var Penmate;

@Injectable({ providedIn: 'root' })
export class MyPenmateService {
  constructor(public apiClient: ApiGateway, private http: Http, private store: Store<AppState>) {}

  addPenmate(penmate) {
    const penmateData = {
      ...penmate,
      data: {
        ...omit(get(penmate, 'data', {}), ['results']),
      },
    };
    return this.apiClient
      .post(`${Penmate.env.apiUrl}/my-penmates`, {}, { penmate: penmateData })
      .pipe(
        map(res => res.penmates),
        catchError(error => {
          this.store
            .select(state => state.user.id)
            .subscribe(userId => {
              ErrorHandler.captureMessage('Add Penmate Error', { error, penmate, user_id: userId });
            });
          return Observable.throw(error);
        }),
      );
  }

  removePenmate(penmate_id) {
    return this.apiClient.delete(`${Penmate.env.apiUrl}/my-penmates`, { penmate_id }).pipe(
      map((res: any) => res.penmates),
      catchError(error => {
        this.store
          .select(state => state.user.id)
          .subscribe(userId => {
            ErrorHandler.captureMessage('Remove Penmate Error', { error, penmate_id, user_id: userId });
          });
        return Observable.throw(error);
      }),
    );
  }

  findPenmate(slug) {
    return this.http.get(`${Penmate.env.apiUrl}/doc-search/inmates/${slug}`).pipe(
      map((res: any) => {
        return res.json();
      }),
      catchError(error => {
        this.store
          .select(state => state.user.id)
          .subscribe(userId => {
            ErrorHandler.captureMessage('Find Penmate Error', { error, slug, user_id: userId });
          });
        return Observable.throw(error);
      }),
    );
  }

  getPenmates() {
    return this.apiClient.get(`${Penmate.env.apiUrl}/my-penmates`, {}).pipe(
      map(res => res.penmates),
      catchError(error => {
        this.store
          .select(state => state.user.id)
          .subscribe(userId => {
            if (error && error.status !== 401) {
              ErrorHandler.captureMessage('Get Penmates Error', { error, user_id: userId });
            }
          });
        return Observable.throw(error);
      }),
    );
  }

  loadMessages(penmateId, page = undefined) {
    return this.apiClient.get(`${Penmate.env.apiUrl}/letters`, { penmate_id: penmateId, page }).pipe(
      catchError(error => {
        this.store
          .select(state => state.user.id)
          .subscribe(userId => {
            if (error.status !== 401) {
              ErrorHandler.captureMessage('Get Messages Error', { error, user_id: userId });
            }
          });
        return Observable.throw(error);
      }),
    );
  }

  loadMessage(messageId, emessage = false) {
    return this.apiClient.get(`${Penmate.env.apiUrl}/letters/${messageId}`, { emessage }).pipe(
      catchError(error => {
        this.store
          .select(state => state.user.id)
          .subscribe(userId => {
            if (error && error.status !== 401) {
              ErrorHandler.captureMessage('Get Messages Error', { error, user_id: userId });
            }
          });
        return Observable.throw(error);
      }),
    );
  }

  deleteMessage(penmateId, letter) {
    return this.apiClient.delete(`${Penmate.env.apiUrl}/letters/${letter.id}`, {
      penmate_id: penmateId,
    });
  }
}
