import { Component, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { NavController, ModalController, AlertController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { FormBuilder, Validators } from '@angular/forms';
import { get, trim } from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import ErrorHandler from '../../lib/error-handler'

import { LoginModal } from '../login-modal/login-modal';
import { LoadingModal } from '../loading-modal/loading-modal'

import { createSupabaseClient } from '../../lib/supabase'

import { AuthService, AppState, EventService, NativeClientService } from '../services';
declare var Penmate;
declare var google;


@Component({
  selector: 'pm-reset-password-confirm',
  templateUrl: 'reset-password-confirm.html',
  styleUrls: ['./reset-password-confirm.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordConfirm implements AfterViewInit {
  modal: any;
  currentSearch;
  savedSearchResult;
  searchName;
  savedSearchQuery;
  supabase;
  scriptLoaded;
  googleNonce;
  confirmText = 'Your email has been confirmed.'
  tokenHash;
  isCreatePassword;
  resetPasswordForm;
  isNative;
  loading = false;
  formDisabled = false;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,

    public store: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,

  ) {

    const navigation = this.router.getCurrentNavigation();
    const url = new URL(window.location.href);
    this.tokenHash =url.searchParams.get("token");
    this.isNative = url.searchParams.get("native") === 'true';
    const loginAction =  url.searchParams.get("login_action");
    this.isCreatePassword = loginAction.length && /create-password/i.test(loginAction);

    this.supabase = createSupabaseClient();

    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password:  ['', Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(25)
        ])
      ],
      passwordConfirmation:  ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(25)
      ])
    ],
    });

    
  }

  cancel() {
    window.location.href = "/login"
  }


  async onUpdatePassword() {
    if (!this.resetPasswordForm.valid || this.formDisabled) {
      return;
    }
    const { password } = this.resetPasswordForm.value;
    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Loading...' },
    });
    await loadingModal.present();
    try {
      const { data, error} = await this.supabase.auth.updateUser({ password })
      if (error && !/old password/i.test(error.message)) {
        return this.onShowLoginError()
      }
      this.authService.handleAuthentication().subscribe(() => {
        this.authService.handleLoginNavigation(this.navCtrl).subscribe(() => {
          this.modalCtrl.getTop().then(modal => {
            modal.dismiss();
          });
        })
      });
      if (this.isNative) {
        const { data, error } = await this.supabase.auth.refreshSession()

        if (data && data.session) {
          const { session: { access_token, refresh_token } } = data;
          window.location.href = `penmateapp://magic/login?access_token=${access_token}&refresh_token=${refresh_token}`
        }
      }
      return;
    } finally {
      await loadingModal.dismiss();
    }
  }

  isPasswordValid() {
    const { password, passwordConfirmation } = this.resetPasswordForm.value;
    return this.resetPasswordForm.valid && (password == passwordConfirmation);
  }

  async onShowLoginError() {
    const alert = await this.alertCtrl.create({
      header: 'Login Error',
      message: `There was an error confirming your email. Your reset password link may have expired.`,
      buttons: [
        {
          text: 'Try again',
          cssClass: 'ok',
          handler: () => {
            window.location.href = "/login"
          },
        },
      ],
    });
    await alert.present();
  }


  async ngAfterViewInit()  {
    window.addEventListener("keyboardDidShow", () => {
      document.activeElement.scrollIntoView(true);
    });

    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Loading...' },
    });
    await loadingModal.present();
    try {
      const { data, error } =  await this.supabase.auth.verifyOtp({ token_hash: this.tokenHash, type: 'recovery'});
      if (error) {
        this.formDisabled = true;
        return this.onShowLoginError();
      }
      if (data && data.user) {
        this.resetPasswordForm.patchValue({ email: data.user.email })
      }
    } finally {
      await loadingModal.dismiss();
    }
  }
}
