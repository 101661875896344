<ion-content>
  <div class="container">
    <div class="bg-circle"></div>
    <ion-button (click)="onCancel()" class="close-button">
      <ion-icon name="close" class="close-icon"></ion-icon>
    </ion-button>

    <div class="inner">
      <div class="letter-artwork"></div>
      <h1 class="title">
        Photo Pack
        <span class="free-photos">Send free photos with every message.</span>
      </h1>
      <div class="details">
        <p class="subtitle">With each letter you can:</p>

        <div class="detail-row send-letters">
          <div class="icon">
            <div class="letters-icon"></div>
          </div>
          <span>
            <p class="text">
              Add <span class="bold" style="color:#058805;"> 10 free photos</span>
              to each message 🙌🏾.
            </p>
          </span>
        </div>
        <div class="detail-row design-postcards">
          <div class="icon">
            <div class="postcards-icon"></div>
          </div>
          <p class="text">
            <span class="bold" style="color:#058805;">Create Photo Cards.</span><br />
            Send postcards with your favorite photos.
          </p>
        </div>
        <div class="detail-row shipping">
          <div class="icon">
            <div class="mailbox-icon"></div>
          </div>
          <p class="text">
            <span class="bold" style="color:#058805;">Free Shipping.</span><br />
            Delivery in 3-5 days.
          </p>
        </div>
      </div>

      <div class="subscription">
        <div id="paymentCta">
          <ion-button ion-button icon-right class="upgradeBtn" (click)="onUpgrade()">
            <span>
              <div class="button-inner">
                3 letters for only $6.99
                <ion-icon name="ios-arrow-dropright-circle"></ion-icon>
              </div>
            </span>
          </ion-button>
          <div class="sub-price">
            Free Shipping + Free Photos
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
