import { Component, ViewChild, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import {
  IonContent,
  NavController,
  Platform,
  ModalController,
  ActionSheetController,
  AlertController,
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';

// import {
//   MessageTextEditor,
//   MyPenmatesPage,
//   PostcardFacebookPhotoPicker,
//   PostcardDesignPicker,
//   IGPhotoPicker,
//   SelectedPhotosModal,
//   FileUploadModal,
//   PostcardConfirmOrderPage,
//   LoginPage,
// } from "../pages";

import { MessageTextEditor } from '../message-text-editor/message-text-editor';
import { MyPenmatesPage } from '../my-penmates/my-penmates';
import { PostcardDesignPicker } from '../postcard-design-picker/postcard-design-picker';
import { IGPhotoPicker } from '../ig-photo-picker/ig-photo-picker';
import { SelectedPhotosModal } from '../selected-photos-modal/selected-photos-modal';
import { FileUploadModal } from '../file-upload-modal/file-upload-modal';
import { PostcardConfirmOrderPage } from '../confirm-order/postcard-confirm-order';
import { LoginPage } from '../login/login.page';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { htmlToText } from 'html-to-text';
import { Observable } from 'rxjs/Observable';
import {
  AppState,
  LetterService,
  AuthService,
  SharedService,
  EventService,
  NativeClientService,
} from '../services';
import { CreateMessageActions, UserActions, MyPenmateActions } from '../actions';
import { Store } from '@ngrx/store';
import { truncate, find, get } from 'lodash';

declare var Beacon;

function isImgPortraitOrientation(src) {
  var orientation,
    img = new Image();
  img.src = src;
  return img.naturalWidth < img.naturalHeight;
}

@Component({
  selector: 'pm-postcard-creator',
  templateUrl: 'postcard-creator.html',
  styleUrls: ['./postcard-creator.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PostcardCreatorPage implements OnInit {
  @ViewChild(IonContent, { static: false }) _content: IonContent;

  modal;
  keyboardShown;
  maxEditorHeight;
  keyboardEditorHeight;
  selectedPhotos = [];
  selectedPhotosCount;
  selectedPhotosModal;
  loadingModal;
  photoPickerProvider;
  photoPickerAccessToken;
  photoPickerModal;
  messageText;
  penmate;
  currentMessage = { message: null, summary: null };
  postcardThemes = [];
  postcardWidth;
  isNative;
  currentPostcard = { frontImage: null, backImage: null };
  orientation;
  useNativeTextEditor = false;

  constructor(
    public navCtrl: NavController,
    public route: ActivatedRoute,
    public router: Router,
    private platform: Platform,
    private store: Store<AppState>,
    private letterService: LetterService,
    private eventService: EventService,
    private sharedService: SharedService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private createMessageActions: CreateMessageActions,
    private myPenmateActions: MyPenmateActions,
    private userActions: UserActions,
    private authService: AuthService,
    private nativeService: NativeClientService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.platform.ready().then(() => {
      this.authService.authenticate().subscribe(({ isAuthenticated }) => {
        if (isAuthenticated) {
          return this.store
            .select(state => state.createMessage)
            .subscribe(createMessage => {
              this.penmate = createMessage.penmate;
              this.currentMessage = createMessage;
              this.selectedPhotos = createMessage.selectedPhotos;
              this.currentPostcard = createMessage.postcard;
              this.messageText = createMessage.message;

              const frontImageUrl = get(createMessage, 'postcard.frontImage.url');
              if (frontImageUrl) {
                let orientation;
                const img = new Image();
                img.onload = () => {
                  if (img.naturalWidth > img.naturalHeight) {
                    orientation = 'landscape';
                  } else if (img.naturalWidth < img.naturalHeight) {
                    orientation = 'portrait';
                  } else {
                    orientation = 'even';
                  }
                  this.orientation = orientation;
                };
                img.src = frontImageUrl;
              }
            });
        }
        this.navCtrl.navigateForward('/login');
      });
    });
  }

  ngOnInit() {
    this.store.dispatch(this.createMessageActions.setMessage({ messageType: 'postcard' }));

    if (this.currentMessage && this.currentMessage['id']) {
      this.store.dispatch(this.createMessageActions.fetchMessage(this.currentMessage['id']));
    }

    this.store
      .select(state => state.createMessage.penmate)
      .take(2)
      .subscribe(penmate => {
        if (penmate && penmate.id) {
          this.store.dispatch(this.myPenmateActions.viewPenmate(penmate));
          return this.store.dispatch(this.myPenmateActions.loadMessages(penmate.id));
        }
        const penmateId = this.route.snapshot.paramMap.get('id');

        this.store
          .select(state => state.myPenmates.penmates)
          .take(2)
          .subscribe(penmates => {
            if (penmates.length === 0) {
              return;
            }
            const pmate = find(penmates, p => p && p.id === parseInt(penmateId, 10));
            if (pmate) {
              return this.store.dispatch(this.createMessageActions.setMessage({ penmate: pmate }));
            }
            this.navCtrl.navigateRoot('/penmates');
          });
      });

    this.sharedService.data.subscribe(data => {
      const eventType = get(data, 'event.type');
      if (eventType === 'onSelectPostcardPhoto') {
        const url = get(data, 'event.data.url');
        const postcard = { frontImage: { url } };
        this.store.dispatch(this.createMessageActions.updatePostcard(postcard));
      }
    });

    // this.store
    //   .select(state => state.user.deviceInfo)
    //   .subscribe(deviceInfo => {
    //     this.useNativeTextEditor = !!get(deviceInfo, 'nativeModules.textEditor', false);
    //   });
  }

  ionViewDidEnter() {
    this.isNative = !!this.nativeService.active;
    Beacon('destroy');
  }

  onSubscribeNativeTextEditor() {
    this.nativeService.data.subscribe(({ type, payload }) => {
      const payloadData = payload || {};
      if (type === 'TEXT_EDITOR_CONTENT_CHANGE') {
        this.store.dispatch(this.createMessageActions.editMessage(payloadData.text));
      }
      if (type === 'TEXT_EDITOR_SAVE') {
        this.store.dispatch(this.createMessageActions.editMessage(payloadData.text));
        this.onSaveMessage();
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  onUploadPhoto(): void {
    const penmateId = this.route.snapshot.paramMap.get('id');
    this.navCtrl.navigateForward(
      `/penmates/${penmateId}/new-postcard/upload?allowMultiple=false&isPostcard=true`,
    );
  }

  onSelectPostcardDesign = async () => {
    this.modal = await this.modalCtrl.create({
      component: PostcardDesignPicker,
      componentProps: {
        id: this.route.snapshot.paramMap.get('id'),
      },
    });
    await this.modal.present();
  };

  onEditMessage = async () => {
    const penmate_id = this.route.snapshot.paramMap.get('id');

    if (this.useNativeTextEditor) {
      this.onSubscribeNativeTextEditor();
      this.nativeService.sendMessage({
        type: 'TEXT_EDITOR_TOGGLE',
        data: { visible: true, text: this.currentMessage.message, maxLength: 350 },
      });
      return;
    }
    this.modal = await this.modalCtrl.create({
      component: MessageTextEditor,
      componentProps: {
        id: penmate_id,
        isPostcard: true,
        maxLength: 200,
      },
    });
    this.eventService.track('edit-message', { message_type: 'postcard', penmate_id });
    await this.modal.present();
  };

  onShowSelectedPhotos = async () => {
    this.selectedPhotosModal = await this.modalCtrl.create({
      component: SelectedPhotosModal,
      componentProps: {
        photos: this.selectedPhotos,
      },
    });
    await this.selectedPhotosModal.present({ updateUrl: false });
  };

  onDeleteMessage() {
    this.store.dispatch(
      this.myPenmateActions.deleteMessage({
        penmateId: this.penmate.id,
        message: this.currentMessage,
      }),
    );
    this.navCtrl.navigateBack('/my-penmates');
  }

  onSaveMessage = async () => {
    return new Promise(resolve => {
      this.store.dispatch(
        this.createMessageActions.saveDraft({ ...this.currentMessage, messageType: 'postcard' }),
      );
      setTimeout(() => {
        resolve();
      }, 300);
    });
  };

  cancelMessage = async () => {
    const delButton = {
      text: 'No, Delete it',
      handler: () => {
        if (this.currentMessage['id']) {
          this.onDeleteMessage();
        }
        this.store.dispatch(this.createMessageActions.resetMessage());
        this.navCtrl.navigateForward('/my-penmates');
      },
    };

    const saveButton = {
      text: 'Yes, Save',
      handler: async () => {
        await this.onSaveMessage();
        this.navCtrl.navigateForward('/my-penmates');
      },
    };

    const confirm = await this.alertCtrl.create({
      header: 'Save draft?',
      buttons: [delButton, saveButton],
    });
    await confirm.present();
  };

  onSaveAndContinue = async () => {
    await this.onSaveMessage();
    this.store
      .select(state => state.createMessage)
      .subscribe(() => {
        this.navCtrl.navigateForward(`/penmates/${this.penmate.id}/new-postcard/confirm-order`);
      })
      .unsubscribe();

    this.eventService.gaEventTrack('Message Edit', {
      event_category: 'User',
      event_label: 'Postcard'
    });
  };

  onTextAreaFocus() {
    this.keyboardShown = true;
  }

  onTextAreaBlur() {
    this.keyboardShown = false;
    this.store.dispatch(this.createMessageActions.editMessage(this.messageText));
  }

  onDisplayErrorAlert = async (titleText, buttonText) => {
    const alert = await this.alertCtrl.create({
      header: titleText,
      buttons: [
        {
          text: buttonText,
        },
      ],
    });
    await alert.present();
  };

  hasPhotos() {
    return this.selectedPhotos && this.selectedPhotos.length > 0;
  }

  isMessageEdited() {
    return this.currentPostcard['frontImage'];
  }

  truncateText(text, length) {
    return truncate(text, { length: length });
  }
}
