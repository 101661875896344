<ion-header id="modal-header">
  <ion-toolbar>
    <div class="title-content">
      <ion-title> Confirm account </ion-title>
      <ion-buttons end>
        <ion-button ion-button icon-only (click)="onCancel()" class="close-button">
          <ion-icon name="close" class="close-icon"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="modal-content">
    <ion-icon name="phone-portrait" class="phone-logo"></ion-icon>
    <h2>Confirm your account</h2>
    <p class="title-text" [style.textAlign]="showCodeInput ? 'center' : 'left'">
      <span *ngIf="!showCodeInput"
        ><strong class="highlight"
          >To send and receive messages please confirm your account below.</strong
        >
        <br />
        <strong
          >Enter a mobile number below and we'll send you a text message to confirm your
          account:</strong
        >
      </span>

      <span *ngIf="showCodeInput"
        >Enter verification code sent to <strong>{{numberWithCountryCode}}</strong> below:</span
      >
    </p>
    <form
      *ngIf="!showCodeInput"
      novalidate
      id="enter-phone-form"
      [formGroup]="phoneInputForm"
      (ngSubmit)="onSendCode()"
    >
      <div class="mobile-input">
        <ion-select name="countryCode" formControlName="selectedCountry">
          <ion-select-option
            name="selectedCountry"
            *ngFor="let code of  countryCodes; index as i;"
            value="{{code.Name}}"
          >
            {{code.Unicode}} {{code.Name}} +{{code.Dial}}
          </ion-select-option>
        </ion-select>
        <ion-input
          formControlName="phoneNumber"
          name="phoneNumber"
          type="text"
          placeholder="Enter phone number"
        >
        </ion-input>
      </div>

      <ion-button
        id="submit-number-btn"
        type="submit"
        class="submit-btn"
        [disabled]="!phoneInputForm.valid"
      >
        <ion-icon name="phone-portrait" class="btn-icon"></ion-icon>
        Send code
      </ion-button>
    </form>

    <form
      *ngIf="showCodeInput"
      novalidate
      id="enter-code-form"
      [formGroup]="codeInputForm"
      (ngSubmit)="onConfirmCode()"
    >
      <div class="mobile-input">
        <ion-input
          formControlName="code"
          name="code"
          type="text"
          placeholder="Enter verification code"
        >
        </ion-input>
      </div>

      <ion-button
        id="submit-code-btn"
        type="submit"
        class="submit-btn"
        [disabled]="!codeInputForm.valid"
      >
        Submit code
      </ion-button>
      <a class="reset-form-link" (click)="onResetForm()">
        Didn't get a code? Request another one
      </a>
    </form>

    <div id="account-help">
      <span id="help-icon">ℹ️</span>
      <p>Do you need help <br/>verifying your account?</p>
      <ion-button
      id="request-help-button"
      class="help-btn"
      (click)="onRequestHelp()"
    >
      Send us a message
    </ion-button>
    </div>
  </div>
</ion-content>
