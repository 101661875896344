import { Component, ViewEncapsulation, Input } from '@angular/core';
import { NavController, NavParams, ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';

@Component({
  selector: 'ib-page-loading-modal',
  templateUrl: 'loading-modal.html',
  styleUrls: ['./loading-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingModal {
  loadingMsg;
  @Input() showCloseButton;
  @Input() loaderType;
  @Input() message;
  @Input() stateName;

  constructor(
    public navCtrl: NavController,
    public route: ActivatedRoute,
    public modalCtrl: ModalController,
  ) {}

  ionViewDidEnter() {
    if (this.loaderType === 'search') {
      return this.displaySearchLoadingMsg();
    }
    if (this.loaderType === 'IGVerify') {
      return this.displayIGFollowVerifyMsg();
    }
    this.displayDefaultLoadingMsg();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  displayDefaultLoadingMsg() {
    this.loadingMsg = this.message;
  }

  displaySearchLoadingMsg() {
    const stateName = this.stateName;

    const msgs = [
      'Connecting. Please wait...',
      'Connecting. Please wait...',
      `Searching ${stateName} facilities...`,
      `Still Searching ${stateName} facilities. Please wait...`,
      `Reading ${stateName} results... `,
      `Searching ${stateName} records...`,
      `Reading ${stateName} results... `,
      `Searching ${stateName} results... `,
      'Almost done...',
    ];
    this.loadingMsg = msgs[0];
    const interval = IntervalObservable.create(3800).subscribe(n => {
      if (msgs[n]) {
        this.loadingMsg = msgs[n];
        return this.loadingMsg;
      }
      interval.unsubscribe();
    });
  }

  displayIGFollowVerifyMsg() {
    const msgs = ['Please wait...', `Connecting to Instagram...`, `Verifying...`, 'Almost done...'];
    this.loadingMsg = msgs[0];
    const interval = IntervalObservable.create(3800).subscribe(n => {
      if (msgs[n]) {
        this.loadingMsg = msgs[n];
        return this.loadingMsg;
      }
      interval.unsubscribe();
    });
  }
}
