import { Directive, ElementRef, Input, OnChanges, } from '@angular/core';

@Directive({ selector: '[imgPreview]' })
export class ImagePreview {

  @Input() image: any;

  constructor(private el: ElementRef) { }

  ngOnChanges() {

      let reader = new FileReader();
      let el = this.el;

      reader.onloadend = function () {
        el.nativeElement.style.backgroundImage = 'url(' + reader.result + ')';
      };

      if (this.image) {
          return reader.readAsDataURL(this.image);
      }

  }

}
