// CREDIT:
//  The vast majority of this code came right from Ben Nadel's post:
//  http://www.bennadel.com/blog/3047-creating-specialized-http-clients-in-angular-2-beta-8.htm
//
// My updates are mostly adapting it for Typescript:
//  1. Importing required modules
//  2. Adding type notations
//  3. Using the 'fat-arrow' syntax to properly scope in-line functions
//
import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions, RequestMethod, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { catchError, map, share, filter } from 'rxjs/operators';
// import { AuthHttp  } from '@auth0/angular-jwt';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpResponse,
  HttpRequest,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';

import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/catch';

import ErrorHandler from '../../lib/error-handler'

export class ApiGatewayOptions {
  method: string;
  url: string;
  headers = {};
  params = {};
  data = {};
}

@Injectable({ providedIn: 'root' })
export class ApiGateway {
  // Define the internal Subject we'll use to push errors
  errorsSubject = new Subject<any>();

  // Provide the *public* Observable that clients can subscribe to

  // Define the internal Subject we'll use to push the command count
  pendingCommandsSubject = new Subject<number>();
  pendingCommandCount = 0;

  errors$: Observable<any>;

  // Provide the *public* Observable that clients can subscribe to
  pendingCommands$: Observable<number>;

  constructor(private authHttp: HttpClient) {
    // Create our observables from the subjects
    this.errors$ = this.errorsSubject.asObservable();
    this.pendingCommands$ = this.pendingCommandsSubject.asObservable();
    this.handleHttpErrors();
  }

  // I perform a GET request to the API, appending the given params
  // as URL search parameters. Returns a stream.
  get(url: string, params: any, headers?: any): Observable<any> {
    const options = new ApiGatewayOptions();
    options.method = 'GET';
    options.url = url;
    options.params = params;
    options.headers = headers;
    return this.request(options);
  }

  // I perform a POST request to the API. If both the params and data
  // are present, the params will be appended as URL search parameters
  // and the data will be serialized as a JSON payload. If only the
  // data is present, it will be serialized as a JSON payload. Returns
  // a stream.
  post(url: string, params: any, data: any): Observable<any> {
    if (!data) {
      data = params;
      params = {};
    }
    const options = new ApiGatewayOptions();
    options.method = 'POST';
    options.url = url;
    options.params = params;
    options.data = data;

    return this.request(options);
  }

  delete(url: string, params: any): Observable<any> {
    const options = new ApiGatewayOptions();
    options.method = 'DELETE';
    options.url = url;
    options.params = params;
    return this.request(options);
  }

  private request(options: ApiGatewayOptions): Observable<any> {
    options.method = options.method || 'GET';
    options.url = options.url || '';
    options.params = options.params || {};
    options.data = options.data || {};
    const isFormData = options.data instanceof FormData;
    this.addXsrfToken(options);

    if (!isFormData) {
      options.headers = options.headers || { 'Content-Type': 'application/json' };
      this.addContentType(options);
    }

    // let requestOptions = new RequestOptions();
    // requestOptions.method = options.method;
    // requestOptions.url = options.url;
    // requestOptions.headers = new Headers(options.headers);
    // requestOptions.search = this.buildUrlSearchParams(options.params);
    // requestOptions.body = JSON.stringify(options.data);

    const isCommand = options.method !== 'GET';

    if (isCommand) {
      this.pendingCommandsSubject.next(++this.pendingCommandCount);
    }

    const httpRequest = new HttpRequest(
      options.method,
      options.url,
      isFormData ? options.data : JSON.stringify(options.data),
      {
        headers: new HttpHeaders(options.headers),
        params: this.buildUrlSearchParams(options.params),
        reportProgress: false,
      },
    );
    const stream = this.authHttp
      .request(httpRequest)
      .pipe(
        filter((event: HttpEvent<any>) => {
          return event instanceof HttpResponse;
        }),
      )
      .pipe(map((res: any) => res.body))
      .pipe(catchError(this.handleError))
      .pipe(share());

    stream.subscribe(data => {
      if (isCommand) {
        this.pendingCommandsSubject.next(--this.pendingCommandCount);
      }
    });

    return stream;
  }

  private addContentType(options: ApiGatewayOptions): ApiGatewayOptions {
    if (options.method !== 'GET') {
      options.headers['Content-Type'] = 'application/json; charset=UTF-8';
    }
    return options;
  }

  private addXsrfToken(options: ApiGatewayOptions): ApiGatewayOptions {
    const xsrfToken = this.getXsrfCookie();
    if (xsrfToken) {
      options.headers['X-XSRF-TOKEN'] = xsrfToken;
    }
    return options;
  }

  private getXsrfCookie(): string {
    const matches = document.cookie.match(/\bXSRF-TOKEN=([^\s;]+)/);
    try {
      return matches && decodeURIComponent(matches[1]);
    } catch (decodeError) {
      return '';
    }
  }

  private buildUrlSearchParams(params: any): HttpParams {
    let searchParams = new HttpParams();
    for (const key in params) {
      if (params[key]) {
        searchParams = searchParams.append(key, params[key]);
      }
    }
    return searchParams;
  }

  private unwrapHttpError(error: any): any {
    try {
      return error.json();
    } catch (jsonError) {
      return {
        code: -1,
        message: 'An unexpected error occurred.',
      };
    }
  }

  private handleError = (error: HttpErrorResponse) => {
    this.errorsSubject.next(error);
    return Observable.throw(error);
  };

  private unwrapHttpValue(value: Response): any {
    return value.json();
  }

  private handleHttpErrors() {
    this.errors$.subscribe((value: any) => {
      if (value && value.status !== 401) {
        ErrorHandler.captureMessage(`API Error`, value);
      }
    });
  }
}
