import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  NavController,
  Platform,
  LoadingController,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

import {
  AppState,
  AuthService,
  EventService,
  SharedService,
  NativeClientService,
} from '../services';
import { Store } from '@ngrx/store';
import { UserActions, MyPenmateActions, CreateMessageActions } from '../actions';
import { Observable } from 'rxjs/Observable';
import { LoginPage, MessageCreatorPage, PostcardCreatorPage } from '../pages';

@Component({
  selector: 'pm-profile-page',
  templateUrl: 'penmate-profile.html',
  styleUrls: ['./penmate-profile.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PenmateProfile implements OnInit {
  penmate: any;
  currentMessage;
  modal: any;
  user: any;
  isNative;
  isMobile;

  constructor(
    public platform: Platform,
    public navCtrl: NavController,
    public route: ActivatedRoute,
    public authService: AuthService,
    public eventService: EventService,
    public sharedService: SharedService,
    public loadingCtrl: LoadingController,
    public userActions: UserActions,
    public createMessageActions: CreateMessageActions,
    public myPenmateActions: MyPenmateActions,
    public store: Store<AppState>,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private nativeService: NativeClientService,
  ) {
    platform.ready().then(() => {
      this.store.dispatch(
        this.myPenmateActions.findPenmate(this.route.snapshot.paramMap.get('id')),
      );
    });
  }

  ngOnInit() {
    this.isNative = !!this.nativeService.active;
    this.isMobile = this.platform.is('mobile');

    this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
      if (isAuthenticated && user) {
        this.user = user;
      }
    });
    this.store
      .select(state => state.myPenmates.currentPenmate)
      .subscribe(penmate => {
        this.penmate = penmate;
        if (this.penmate) {
          this.penmate.showMap = true;
        }
      });
    this.store
      .select(state => state.createMessage)
      .subscribe(currentMessage => {
        this.currentMessage = currentMessage;
      });
  }

  onExploreMedia() {
    const penmate = this.penmate;
    this.nativeService.sendMessage({ type: 'SHOW_LIBRARY', payload: { penmate } });
  }

  onCreateMessage = async () => {
    const penmate = this.penmate;
    const loading = await this.loadingCtrl.create({ spinner: 'dots' });
    await loading.present();
    this.authService.authenticate().subscribe(async ({ isAuthenticated, user }) => {
      await loading.dismiss();
      if (isAuthenticated) {
        this.store.dispatch(this.userActions.loginUser(user));

        if (this.currentMessage.penmate && this.currentMessage.penmate.id !== penmate.id) {
          this.store.dispatch(this.createMessageActions.resetMessage());
        }
        this.store.dispatch(this.createMessageActions.setMessage({ penmate: this.penmate }));
        this.eventService.track('create-letter', { penmate_id: penmate.id });
        return this.navCtrl.navigateForward(
          `/penmates/${this.route.snapshot.paramMap.get('id')}/new-message`,
        );
      }
      this.navCtrl.navigateForward('/login');
    });
  };

  onCreatePostcard = async () => {
    const penmate = this.penmate;

    const loading = await this.loadingCtrl.create({ spinner: 'dots' });
    await loading.present();
    this.authService.authenticate().subscribe(async ({ isAuthenticated, user }) => {
      await loading.dismiss();
      if (isAuthenticated) {
        this.store.dispatch(this.userActions.loginUser(user));

        if (this.currentMessage.penmate && this.currentMessage.penmate.id !== penmate.id) {
          this.store.dispatch(this.createMessageActions.resetMessage());
        }
        this.store.dispatch(this.createMessageActions.setMessage({ penmate: this.penmate }));
        this.eventService.track('create-postcard', { penmate_id: penmate.id });
        return this.navCtrl.navigateForward(
          `/penmates/${this.route.snapshot.paramMap.get('id')}/new-postcard`,
        );
      }
      this.navCtrl.navigateForward('/login');
    });
  };

  onViewMessages() {
    const penmate = this.penmate;

    this.store.dispatch(this.myPenmateActions.viewPenmate(penmate));
    this.navCtrl.navigateForward(`/penmates/${penmate.id}/messages`).then(() => {
      this.sharedService.data.emit({
        event: {
          type: 'onGoBack',
          data: {
            onGoBack: () => {
              this.navCtrl.navigateBack(`/penmates/${penmate.id}`);
            },
          },
        },
      });
    });

    this.eventService.track('view-messages', { penmate_id: penmate.id });
  }

  // onCreateMessage(penmate) {
  //   this.store.dispatch(this.myPenmateActions.viewPenmate(penmate));

  //   //this.store.dispatch(this.createMessageActions.resetMessage());
  //   this.modal = this.modalCtrl.create(MessageCreatorPage);
  //   this.modal.present();
  // }
}
