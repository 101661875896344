<ion-toolbar *ngIf="user" id="nav-add-penmate">
    <ion-buttons>
        <ion-row class="button-row">
            <ion-col size="4" class="button-col">
                <ion-button (click)="gotoAddPenmate()">
                    <ion-icon
                        class="toolbar-icon"
                        name="ios-search"
                        slot="icon-only"
                        routerDirection="root"
                        routerLink="/find-an-inmate"
                        routerLinkActive="route-active"
                    ></ion-icon>
                </ion-button>
            </ion-col>

            <ion-col size="4" class="button-col">
                <ion-button
                    (click)="gotoPenmates()"
                    id="nav-my-penmates"
                    *ngIf="penmates && penmates.length > 0"
                >
                    <ion-icon
                        class="toolbar-icon"
                        name="ios-chatbubbles"
                        slot="icon-only"
                        routerDirection="root"
                        routerLink="/my-penmates"
                        routerLinkActive="route-active"
                    ></ion-icon>
                </ion-button>
            </ion-col>

            <ion-col size="4" class="button-col">
                <ion-button id="nav-my-account" (click)="gotoMyAccount()">
                    <ion-icon
                        class="toolbar-icon"
                        name="contact"
                        slot="icon-only"
                        routerDirection="root"
                        routerLink="/my-account"
                        routerLinkActive="route-active"
                    ></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-buttons>
</ion-toolbar>
<ion-toolbar *ngIf="!user">
    <ion-buttons slot="start" [ngStyle]="{'width': '100%', 'justify-content': 'center'}">
        <ion-button (click)="gotoAddPenmate()">
            <ion-icon
                class="toolbar-icon"
                slot="icon-only"
                name="search"
                size="large"
                [style.color]="'#fff'"
                routerDirection="root"
                routerLink="/find-an-inmate"
                routerLinkActive="route-active"
            ></ion-icon>
        </ion-button>
    </ion-buttons>
</ion-toolbar>
