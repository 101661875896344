<ion-header id="modal-header">
  <ion-toolbar>
    <div class="title-content">
      <ion-title> Upload Photos </ion-title>
      <ion-buttons end>
        <ion-button ion-button icon-only (click)="onDismiss(true)">
          <ion-icon name="close" class="close-icon"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="page-content">

    <div id="upload-options" [ngClass]="{'photos-attached': allPhotos.length > 0, 'is-postcard': isPostcard }">
        <div id="attach-photos" [ngClass]="{'photos-attached': files.length > 0 }">
          <div id="file-select" *ngIf="!isNative || !useNativeImagePicker">
            <ion-icon name="cloud-upload" class="icon"></ion-icon>
            <span>Add {{allPhotos.length > 0 ? 'more photos' : 'photos from phone'}}</span>
            <input
              #fileInput
              (click)="fileInput.value = ''"
              type="file"
              ngFileSelect
              [options]="options"
              (uploadOutput)="onUploadOutput($event)"
              [uploadInput]="uploadInput"
              [attr.multiple]="allowMultiple"
            />
          </div>
          <div id="file-select" *ngIf="isNative && useNativeImagePicker" (click)="onNativeFileUpload()">

            Add photos from your phone
          </div>
        </div>
      </div>
    <div id="selected-photos"  [ngClass]="{'photos-attached': allPhotos.length > 0 }">
      <div *ngFor="let f of allPhotos; let i = index;" class="photo-item">
        <ion-img src="{{f.base64Data}}" class="photo" *ngIf="f.base64Data"></ion-img>
        <ion-img src="{{f.url}}"  class="photo" *ngIf="!f.base64Data"></ion-img>

        <a class="remove-file" (click)="onRemoveFile(f)">
          <ion-icon name="close-circle" class="icon"></ion-icon>
        </a>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer class="modal-footer">
  <ion-row>
    <ion-col size="6">
      <ion-button ion-button icon-left (click)="onDismiss(true)" class="back">
        <ion-icon name="arrow-back"></ion-icon>
        Cancel
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button [disabled]="allPhotos?.length === 0" ion-button icon-right (click)="uploadFiles()" class="continue">
        Attach Photos
        <ion-icon name="ios-arrow-dropright-circle"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
