import { Component, ViewEncapsulation, AfterViewInit, ViewChild, } from '@angular/core';
import { NavController, ModalController, NavParams, AlertController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../services';
import { Storage } from '@ionic/storage';
import ErrorHandler from '../../lib/error-handler'
import { LoadingModal } from '../loading-modal/loading-modal'
import { createSupabaseClient } from '../../lib/supabase'


declare var google;
declare var Penmate;
declare var Beacon;

/*
  Generated class for the SearchLoadingModal page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-login-modal',
  templateUrl: 'login-modal.html',
  styleUrls: ['./login-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginModal implements AfterViewInit {
  loading = false;
  emailLoginForm;
  googleScriptLoaded;
  showEmailLoginSuccess = false;
  googleNonce; 
  showOtpError = false;
  supabase;
  showPasswordLogin = false;
  showCreatePassword = false;
  showInvalidLogin = false;
  showPasswordReset = false;
  showPasswordResetConfirmation = false;
  isExistingUser = false;
  showConfirmEmailNote = false;


  @ViewChild('googleButton', { static: false }) googleButton;

  constructor(
    public navCtrl: NavController,
    private navParams: NavParams,
    private storage: Storage,
    private alertCtrl: AlertController,
    public route: ActivatedRoute,
    public modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private authService: AuthService,

  ) {
    this.googleNonce = this.navParams.get('googleNonce');
    const emailRegex = "[A-Za-z0-9._%-\+]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}"
    this.emailLoginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      password: [''],
      passwordConfirmation: ['']

    });
    this.supabase = createSupabaseClient();

  }

  onSubmitEmailLogin = () => {
    if (!this.emailLoginForm.valid) return;
    const { email } = this.emailLoginForm.value;
    this.loading = true;
    
    this.authService.checkLogin(email).subscribe(async (res) => {
      try {

        if (!res.user && res.oauth) {
          this.loading = false;
          this.showCreatePassword = false;
          return this.onShowProviderLoginError();
        }
  
        if (!res.user || (res.user && !res.supabase_id)) {
          this.loading = false;
          this.showCreatePassword = true;
          return;
        }
        if (res.supabase_id) {
          const {data: hasPassword, error} = await this.supabase.rpc(
            'user_has_password',
            {user_id: res.supabase_id},
          );
          this.loading = false;
          if (hasPassword) {
             this.showPasswordLogin = true;
          } else {
            this.loading = true;
            this.authService.requestPasswordReset(email, true).subscribe(async (res) => {
              this.isExistingUser = true;
              this.loading = false;
              this.showEmailLoginSuccess = true;
            })
          }
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.onShowLoginError('There was an error logging you in');
        ErrorHandler.captureException(e, 'Login Error: Web', {  email  });
      }
    })

  }

  

  onLoginWithPassword = async () => {
    if (!this.emailLoginForm.valid) return;
    const { email, password } = this.emailLoginForm.value;
    this.loading = true;

    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Loading...' },
    });
    await loadingModal.present();
    const { data, error } =  await this.supabase.auth.signInWithPassword({
      email,
      password,
    })
    this.loading = false;
    await loadingModal.dismiss();
    if (error) {
      const errorMessage = error.message 
      const isInvalidLogin = /invalid login/i.test(error.message);
      if (isInvalidLogin) {
        this.showInvalidLogin = true;
        return;
      }
      ErrorHandler.captureMessage('Login Error: Web', {  email, error_message: error.message, error_status: error.status });
      return;
    }
    this.authService.handleAuthentication().subscribe(() => {
      this.cancel()
      this.authService.handleLoginNavigation(this.navCtrl).subscribe(() => {
        this.modalCtrl.getTop().then(modal => {
          modal.dismiss();
        });
      })
    });
    return { data, error }
  }

  isEmailSignupValid = () => {
    const emailValid = this.emailLoginForm.valid;
    const { password, passwordConfirmation } = this.emailLoginForm.value;
    if (!emailValid) {
      return false;
    }
    if (password.length < 6 || passwordConfirmation.length < 6 || (password !== passwordConfirmation))  {
      return false;
    }
    return true;
  }

  onSignUpWithEmail = async () => {
    if (!this.emailLoginForm.valid) return;
    const { email, password, passwordConfirmation } = this.emailLoginForm.value;
    if (password.length < 6 || passwordConfirmation.length < 6 || (password !== passwordConfirmation))  {
      return;
    }
    this.loading = true;
    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Loading...' },
    });
    await loadingModal.present();
    try {
      const { error } = await this.supabase.auth.signUp({
        email,
        password,
      })
      if (error) {
        return this.onShowLoginError('There was an error creating your account')
      }

      this.authService.handleAuthentication().subscribe(() => {
        this.cancel();
        this.authService.handleLoginNavigation(this.navCtrl).subscribe(() => {
          this.modalCtrl.getTop().then(modal => {
            modal.dismiss();
          });
        })
      });
    } catch (e) {
      ErrorHandler.captureException(e, 'Login Error: Signup', {  email  });
    } finally {
      this.loading = false;
      await loadingModal.dismiss();
      this.cancel();

    }
  }

  onAddPassword = async () => {
    if (!this.emailLoginForm.valid) return;
    const { email, password, passwordConfirmation } = this.emailLoginForm.value;
    if (password.length < 6 || passwordConfirmation.length < 6 || (password !== passwordConfirmation))  {
      return;
    }
    this.loading = true;
    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Loading...' },
    });
    await loadingModal.present();
    try {
      this.authService.requestPasswordReset(email, true).subscribe(async (res) => {
        this.showEmailLoginSuccess = true;
      })
    } catch (e) {
      ErrorHandler.captureException(e, 'Login Error: Add Pasword', {  email  });
    } finally {
      this.loading = false;
      await loadingModal.dismiss();
    }
  }

  onResetEmailLogin = () => {
    this.loading = false;
    this.showEmailLoginSuccess = false;
    // this.onCreateGoogleLogin();
  }

  onShowPasswordReset = () => {
    this.showInvalidLogin = false;
    this.showPasswordLogin = false;
    this.showPasswordReset = true;
  }

  onSubmitPasswordReset = async () => {
    this.showInvalidLogin = false;
    this.showPasswordLogin = false;
    this.showPasswordReset = true;
    const { email } = this.emailLoginForm.value;
    if (!email || !email.length) {
      return;
    }

    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Loading...' },
    });
    try {
      await loadingModal.present();
      this.authService.requestPasswordReset(email).subscribe(async (res) => {
        await loadingModal.dismiss();
        this.showPasswordResetConfirmation = true;
  
      }, async (e) => {
        await loadingModal.dismiss();
      })
    } catch (e) {
      await loadingModal.dismiss();
      this.modalCtrl.getTop().then(modal => {
        modal.dismiss();
      });
      this.showOtpError = true;
      ErrorHandler.captureException(e, 'Login Error: Web', {  email  });
    }
  }

  onConfirmPasswordReset = () => {
    this.showPasswordReset = false;
    this.showPasswordResetConfirmation = false;
    setTimeout(() => {
      this.onCreateGoogleLogin()
    }, 250)
  }


  onCreateGoogleLogin = () => {
    if (this.googleButton) {
      google.accounts.id.renderButton(this.googleButton.nativeElement, {
          type: "standard",
          size: "large",
          logo_alignment: "left",
          shape: "rectangular",
          text: "signin_with",
          width: '100vw',
          theme: "outline",
        }
      );
    }
  }

  async onShowLoginError(errorText) {
    const alert = await this.alertCtrl.create({
      header: 'Login Error',
      message: `${errorText}.  Please try again or contact support.`,
      buttons: [
        {
          text: 'Contact Support',
          handler: () => {
            Beacon('init', Penmate.env.helpScoutId);
            Beacon('open');
            Beacon('navigate', '/ask/message/');
          },
        },
        {
          text: 'Try again',
          cssClass: 'ok',
          handler: () => {
          },
        },
      ],
    });
    await alert.present();
    setTimeout(() => {
      Beacon('init', Penmate.env.helpScoutId);
    }, 1500);
  }

  async onShowProviderLoginError() {
    const alert = await this.alertCtrl.create({
      header: 'Sign in with Google',
      message: `The email you provided is linked to a Google account. Please sign in using the Sign in With Google button.`,
      buttons: [
        {
          text: 'Ok',
          cssClass: 'ok',
          handler: () => {
          },
        },
      ],
    });
    await alert.present();
  }


  async onCodeCompleted(token: string) {
    const { email } = this.emailLoginForm.value;
    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Loading...' },
    });
    try {
      this.showOtpError = false;
      this.loading = true;
      await loadingModal.present();
      const { error } = await this.supabase.auth.verifyOtp({
        email,
        token,
        type: 'email',
      })
      await loadingModal.dismiss();
      if (error) {
        this.showOtpError = true;
        ErrorHandler.captureMessage('Login Error: Web', {  email, error_message: error.message, error_status: error.status });
        return;
      }
      this.authService.handleAuthentication().subscribe(() => {
        this.authService.handleLoginNavigation(this.navCtrl).subscribe(() => {
          this.modalCtrl.getTop().then(modal => {
            modal.dismiss();
          });
        })
      });
    } catch (e) {
      await loadingModal.dismiss();
      this.modalCtrl.getTop().then(modal => {
        modal.dismiss();
      });
      this.showOtpError = true;
      ErrorHandler.captureException(e, 'Login Error: Web', {  email  });
    }
  }

  async ngAfterViewInit() {
    const nonce = this.googleNonce && this.googleNonce.nonce ? this.googleNonce.nonce : undefined;
    if (!nonce && this.storage) {
      await this.storage.set('skipLoginNonce', true);
    }
    google.accounts.id.initialize({
      client_id: "917829847500-pg727hl94irnrpdc65iqptpk1lgncqbf.apps.googleusercontent.com",
      ux_mode: 'redirect',
      login_uri: `${Penmate.env.rootUrl}/login/google`,
      nonce,
    });
    this.onCreateGoogleLogin();
 }

  cancel() {
    this.modalCtrl.dismiss();
  }


}
