import {
  Component,
  EventEmitter,
  ViewEncapsulation,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, ModalController, AlertController, LoadingController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import {
  UploadOutput,
  UploadInput,
  UploadFile,
  humanizeBytes,
  UploaderOptions,
  UploadStatus,
} from 'ngx-uploader';

import { FileUploader } from 'ng2-file-upload';
import { Store } from '@ngrx/store';
import { map as _map, filter, reject, get, isArray, take, isEmpty, uniqBy } from 'lodash';
import { take as rxJsTake } from 'rxjs/operators';
import {
  LetterService,
  AppState,
  EventService,
  AuthService,
  SharedService,
  NativeClientService,
} from '../services';
import { CreateMessageActions, SearchActions, UserActions } from '../actions';
// import { LoadingModal, PostcardCreatorPage, MessageCreatorPage }  from '../pages';

import { LoadingModal } from '../loading-modal/loading-modal';
import { PostcardCreatorPage } from '../postcard-creator/postcard-creator';

import {
  MessageCreatorPage,
  DEFAULT_MAX_SELECTED_PHOTOS,
  EMESSAGE_MAX_SELECTED_PHOTOS,
} from '../message-creator/message-creator';

declare var Penmate;

@Component({
  selector: 'pm-file-upload-modal',
  templateUrl: 'file-upload-modal.html',
  styleUrls: ['./file-upload-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadModal {
  public uploader: FileUploader = new FileUploader({ url: Penmate.env.apiUrl });

  public modal;
  public allowMultiple = false;
  public isPostcard;
  public state$;

  uploadInput: EventEmitter<UploadInput>;
  selectedPhotos$: Subject<any>;
  options: UploaderOptions;
  files: UploadFile[];
  addedFiles = [];
  allPhotos = [];
  formData: FormData;
  humanizeBytes;
  loadingSpinner;
  showMaxPhotosAlert = false;
  isNative = false;
  useNativeImagePicker = false;
  alertVisible = false;
  isFirstMessage = false;
  showUploadOptions = true;
  MAX_SELECTED_PHOTOS = DEFAULT_MAX_SELECTED_PHOTOS;
  user;

  constructor(
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    public letterService: LetterService,
    public store: Store<AppState>,
    public createMessageActions: CreateMessageActions,
    public route: ActivatedRoute,
    private eventService: EventService,
    private nativeService: NativeClientService,
    private sharedService: SharedService,
    private authService: AuthService,
    private searchActions: SearchActions,
    private userActions: UserActions,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
  ) {
    // this.allowMultiple = this.navParams.get('allowMultiple')
    this.options = {
      concurrency: 0,
      allowedContentTypes: ['image/jpeg', 'image/png', 'image/gif'],
    };

    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.selectedPhotos$ = new Subject<any>();

    this.humanizeBytes = humanizeBytes;

    this.allowMultiple = !!(this.route.snapshot.queryParams.allowMultiple || this.route.snapshot.paramMap.get('allowMultiple'));
    this.isPostcard = !!(this.route.snapshot.queryParams.isPostcard || this.route.snapshot.paramMap.get('isPostcard'));
    this.state$ = this.route.paramMap.pipe(map(() => window.history.state));

    this.store
      .select((state) => state.createMessage)
      .subscribe((createMessage) => {
        this.addedFiles = createMessage.selectedPhotos.slice();
        this.MAX_SELECTED_PHOTOS = createMessage.eMessage ? EMESSAGE_MAX_SELECTED_PHOTOS : DEFAULT_MAX_SELECTED_PHOTOS;
        this.isFirstMessage = get(createMessage, 'meta.firstMessage', false);
        this.allowMultiple = createMessage.eMessage ? false : this.allowMultiple
      });

    this.store
      .select((state) => state.user.deviceInfo)
      .subscribe((deviceInfo) => {
        const nativeImagePicker = !!get(deviceInfo, 'nativeModules.imagePicker', false);
        const isiOS = /ios/i.test(get(deviceInfo, 'os', ''));
        this.useNativeImagePicker =  isiOS && nativeImagePicker;
      });
    this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
      if (!isAuthenticated || !user) {
        return this.navCtrl.navigateRoot('/login');
      }
      this.store.dispatch(this.userActions.loginUser(user));
      return (this.user = user);
    });
    // this.isPostcard = this.navParams.get('isPostcard');
  }

  ionViewDidEnter() {
    this.isNative = !!this.nativeService.active;
    this.allPhotos = [];
  }

  getFileUrl(file) {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = function (e: any) {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file.nativeFile);
    });
  }

  // public onChange(event: any): void {
  //     event.target.value = '';
  // }

  hideLoadingSpinner = async () => {
    await this.loadingCtrl.dismiss();
  };

  showLoadingSpinner = async () => {
    this.loadingSpinner = await this.loadingCtrl.create({
      message: 'Please wait...',
    });
    await this.loadingSpinner.present();
  };

  onNativeFileUpload = (): void => {
    const nativeService = this.nativeService.data;
    const isPostcard = /new-postcard/.test(document.location.href);

    nativeService.subscribe(({ type, payload }) => {
      if (type === 'UPLOAD_PHOTOS_SUCCESS') {
        const photos = get(payload, 'photos', []);

        if (isPostcard) {
          const selectedImageUrl = get(photos[0], 'url');
          const postcard = { frontImage: { url: selectedImageUrl } };
          this.store.dispatch(this.createMessageActions.updatePostcard(postcard));
        } else {
          const allPhotos = this.addedFiles.slice(0);
          const maxPhotosReached = allPhotos.length >= this.MAX_SELECTED_PHOTOS;
          if (maxPhotosReached) {
            return;
          }
          const photosToAdd = take(photos, this.MAX_SELECTED_PHOTOS - allPhotos.length);
          photosToAdd.forEach((photo) => this.addedFiles.push(photo));
          this.store.dispatch(this.createMessageActions.addPhotosFromDevice(photosToAdd));
        }

        this.zone.run(() => {
          this.cd.detectChanges();
          this.onGoBack();
        });
      }
    });
    this.nativeService.sendMessage({
      type: 'ADD_PHOTOS_DEVICE',
      numOfSelectedPhotos: this.addedFiles.length,
    });
    this.eventService.track('add-photos', { provider: 'device' });
  };

  onUploadOutput = (output: UploadOutput): void => {
    if (output.type === 'allAddedToQueue') {
      if (this.showMaxPhotosAlert && this.addedFiles && this.addedFiles.length >= this.MAX_SELECTED_PHOTOS) {
        let maxPhotosMessage = `You've attached ${this.MAX_SELECTED_PHOTOS} photos to your message. We cant send more than ${this.MAX_SELECTED_PHOTOS} photos at a time or the facility may reject your message. To send more than ${this.MAX_SELECTED_PHOTOS} photos, please create a new message.`;
        if (this.isFirstMessage) {
          maxPhotosMessage = `You can send up to ${this.MAX_SELECTED_PHOTOS} photos for free with your first message. To send more than ${this.MAX_SELECTED_PHOTOS} photos, please create a new message.`;
        }
        this.onDisplayErrorAlert(
          'Photo limit reached',
          `Max ${this.MAX_SELECTED_PHOTOS} photos attached`,
          maxPhotosMessage,
          'OK',
        );
      }
      setTimeout(() => {
        this.addedFiles = this.files.slice();
        this.allPhotos = uniqBy(this.allPhotos.concat(this.addedFiles.slice(0, this.MAX_SELECTED_PHOTOS)), 'id');
        this.selectedPhotos$.next(this.allPhotos);
      }, 300);
      this.showMaxPhotosAlert = false;
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      if (this.addedFiles.length >= this.MAX_SELECTED_PHOTOS) {
        this.addedFiles = this.addedFiles.slice(0,this.MAX_SELECTED_PHOTOS);
        this.showMaxPhotosAlert = true;
        return;
      }
      this.addedFiles.push(output.file)
      this.addedFiles = this.addedFiles.slice(0);
      this.getOrientation(output.file.nativeFile, (orientation) => {
        this.getBase64(output.file.nativeFile, orientation, async (base64Data) => {
          if (base64Data) {
            const file: any = Object.assign(output.file, { base64Data });
            return this.files.push(file);
          }
          return this.getFileUrl(output.file).then((photoData) => {
            const file: any = Object.assign(output.file, { base64Data: photoData });
            return this.files.push(file);
          });
        });
      });
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      const index = this.files.findIndex(
        (file) => typeof output.file !== 'undefined' && file.id === output.file.id,
      );
      this.files[index] = output.file;
    } else if (output.type === 'cancelled' || output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
      // console.log(output.file.name + ' rejected');
    }
    this.files = this.files.filter((file) => file.progress.status !== UploadStatus.Done);
  };

  onDismiss = async (resetPhotos = false) => {
    await this.modalCtrl.dismiss();
    this.onGoBack(resetPhotos);
  };

  getOrientation(file, callback) {
    var reader: any, target: EventTarget;
    reader = new FileReader();
    reader.onload = (event) => {
      var view = new DataView(event.target.result);

      if (view.getUint16(0, false) != 0xffd8) return callback(-2);

      var length = view.byteLength,
        offset = 2;

      while (offset < length) {
        var marker = view.getUint16(offset, false);
        offset += 2;

        if (marker == 0xffe1) {
          if (view.getUint32((offset += 2), false) != 0x45786966) {
            return callback(-1);
          }
          var little = view.getUint16((offset += 6), false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;

          for (var i = 0; i < tags; i++)
            if (view.getUint16(offset + i * 12, little) == 0x0112)
              return callback(view.getUint16(offset + i * 12 + 8, little));
        } else if ((marker & 0xff00) != 0xff00) break;
        else offset += view.getUint16(offset, false);
      }
      return callback(-1);
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  }

  getBase64(file, orientation, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result;
      this.resetOrientation(base64, orientation, callback);
    };
    reader.onerror = (error) => {
      callback(false);
    };
  }

  resetOrientation(srcBase64, srcOrientation, callback) {
    var img = new Image();

    img.onload = () => {
      var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL());
    };

    img.src = srcBase64;
  }

  onGoBack = async (resetPhotos = true) => {
    const id = this.route.snapshot.paramMap.get('id');
    const isPostcard = /new-postcard/.test(document.location.href);
    // const component = isPostcard ? PostcardCreatorPage : MessageCreatorPage;
    const routePath = isPostcard ? `/penmates/${id}/new-postcard` : `/penmates/${id}/new-message`;
    if (resetPhotos) {
      this.allPhotos.forEach(this.onRemoveFile);
    }
    this.navCtrl.navigateForward(routePath);
  };

  onRemoveFile = (file) => {
    if (!!file.base64Data) {
      this.files = this.files.filter((f) => f.id !== file.id);
      this.allPhotos = this.allPhotos.filter((f) => f.id !== file.id);
      this.store.dispatch(this.createMessageActions.togglePhotoFromGallery(file));
    }
    if (/gphotos|google/.test(file.provider)) {
      this.allPhotos = this.allPhotos.filter((f) => f.url !== file.url);
      file.selected = false;
      this.store.dispatch(this.createMessageActions.togglePhotoFromGallery(file));
    }
    this.selectedPhotos$.next(this.allPhotos);
    this.addedFiles = this.files.slice();
    return this.addedFiles;
  }

  uploadFiles = async () => {
    if (isEmpty(this.allPhotos)) {
      return;
    }

    const photoFiles = _map(this.allPhotos, (photo) => {
      if (/google|gphotos/.test(photo.provider)) {
        return Object.assign({}, photo, { url: `${photo.url}=w1500-h1500` });
      }
      return photo;
    });

    this.modal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Attaching photos...' },
    });
    await this.modal.present();

    this.letterService.uploadPhotos(photoFiles).subscribe(async (uploaded) => {
      await this.modal.dismiss();
      const photos = reject(uploaded, (f) => f.base64Data || f.error);
      const failedUploads = filter(uploaded, (f) => f.base64Data && f.error);
      this.eventService.track('add-photos', { provider: 'device' });
      const isPostcard = /new-postcard/.test(document.location.href);

      if (photos.length > 0) {
        if (isPostcard) {
          const selectedImageUrl = get(photos[0], 'url');
          const postcard = { frontImage: {  url: selectedImageUrl } };
          this.store.dispatch(this.createMessageActions.updatePostcard(postcard));
        } else {
          this.store.dispatch(this.createMessageActions.addPhotosFromDevice(photos));
        }
      }
      if (failedUploads.length > 0) {
        return this.onDisplayErrorAlert(
          `Error uploading ${failedUploads.length} photos.`,
          'Try again?',
          '',
          'OK',
        );
      }
      this.onDismiss(false);
    });
  };

  onDisplayErrorAlert = async (titleText, subHeader, message, buttonText) => {
    if (!this.alertVisible) {
      const alert = await this.alertCtrl.create({
        header: titleText,
        subHeader,
        message,
        buttons: [
          {
            text: buttonText,
            handler: () => {
              this.alertVisible = false;
            },
          },
        ],
      });
      await alert.present();
    }
  };
}
