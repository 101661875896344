import { Component, ViewEncapsulation } from '@angular/core';
import { ConfirmOrderPage } from './confirm-order';

/*
  Generated class for the ConfirmOrder page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-page-postcard-confirm-order',
  styleUrls: ['./confirm-order.scss'],
  templateUrl: 'confirm-order.html',
  encapsulation: ViewEncapsulation.None,
})
export class PostcardConfirmOrderPage extends ConfirmOrderPage {}
