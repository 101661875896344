import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class SearchActions {
  static LOAD_AVAILABLE_STATES = 'LOAD_AVAILABLE_STATES';

  static CLEAR_SEARCH = 'CLEAR_SEARCH';
  static SEARCH_REQUEST = 'SEARCH_REQUEST';
  static SEARCH_SUCCESS = 'SEARCH_SUCCESS';
  static SEARCH_ERROR = 'SEARCH_ERROR';

  static SAVED_ROUTE = 'SAVED_ROUTE';
  static CLEAR_SAVED_ROUTE = 'CLEAR_SAVED_ROUTE';

  static SAVE_SEARCH_QUERY = 'SAVE_SEARCH_QUERY';

  static SAVE_SEARCH_RESULT = 'SAVE_SEARCH_RESULT';
  static CLEAR_SAVED_SEARCH_RESULT = 'CLEAR_SAVED_SEARCH_RESULT';

  static VIEW_SEARCH_RESULT = 'VIEW_SEARCH_RESULT';
  static VIEW_SEARCH_RESULT_SUCCESS = 'VIEW_SEARCH_RESULT_SUCCESS';
  static VIEW_SEARCH_RESULT_ERROR = 'VIEW_SEARCH_RESULT_ERROR';

  loadAvailableStates(states) {
    return this.createAction(SearchActions.LOAD_AVAILABLE_STATES, states);
  }

  clearSearch() {
    return this.createAction(SearchActions.CLEAR_SEARCH, {});
  }

  searchRequest(search) {
    return this.createAction(SearchActions.SEARCH_REQUEST, search);
  }

  searchSuccess(searchResults) {
    return this.createAction(SearchActions.SEARCH_SUCCESS, searchResults);
  }

  searchError(error = null) {
    return this.createAction(SearchActions.SEARCH_ERROR, error);
  }

  saveSearchQuery(payload) {
    return this.createAction(SearchActions.SAVE_SEARCH_QUERY, payload);
  }

  saveSearchResult(payload) {
    return this.createAction(SearchActions.SAVE_SEARCH_RESULT, payload);
  }

  clearSavedSearch(payload = null) {
    return this.createAction(SearchActions.CLEAR_SAVED_SEARCH_RESULT, payload);
  }

  saveRoute(payload) {
    return this.createAction(SearchActions.SAVED_ROUTE, payload);
  }

  clearSavedRoute(payload = null) {
    return this.createAction(SearchActions.CLEAR_SAVED_ROUTE, payload);
  }

  viewSearchResult(payload) {
    return this.createAction(SearchActions.VIEW_SEARCH_RESULT, payload);
  }

  viewSearchResultSuccess(payload) {
    return this.createAction(SearchActions.VIEW_SEARCH_RESULT_SUCCESS, payload);
  }

  viewSearchResultError(error) {
    return this.createAction(SearchActions.VIEW_SEARCH_RESULT_ERROR, error);
  }

  private createAction(type, payload) {
    return { type, payload };
  }
}
