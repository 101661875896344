<edit-message-navbar penmate="penmate" [hideBack]="hideBack"> </edit-message-navbar>

<ion-content *ngIf="currentMessage" class="content">
  <div id="order-info">
    <div id="order-detail-container">
      <div *ngIf="penmate?.showMap" class="map-container" [pmContactMap]="penmate">
        <div class="map-cover"></div>
        <div class="map"></div>
      </div>

      <div id="confirmation-content">
        <div id="mailing-info" class="section">
          <div class="address-row recipient">
            <ion-icon class="icon" name="md-paper-plane"></ion-icon>

            <div class="inner">
              <p class="letter-detail">
                <span *ngIf="currentMessage?.shippingMethod === 'emessage'">Message</span>
                <span *ngIf="currentMessage?.shippingMethod !== 'emessage' && currentMessage.messageType !== 'postcard'">Letter</span>
                <span *ngIf="currentMessage?.shippingMethod !== 'emessage' && currentMessage.messageType === 'postcard'">Postcard</span>
                <span
                  *ngIf="currentMessage.selectedPhotos.length > 0 && !isPostcard"
                  class="photo-price"
                >
                  +
                  <span
                    >{{currentMessage?.shippingMethod === 'emessage' ? math.min(currentMessage.selectedPhotos.length, 4) : currentMessage.selectedPhotos.length}}
                    <span *ngIf="currentMessage?.shippingMethod === 'emessage' ? math.min(currentMessage.selectedPhotos.length, 4) > 1 : currentMessage?.selectedPhotos?.length > 1"> photos </span>
                    <span *ngIf="currentMessage?.shippingMethod === 'emessage' ? math.min(currentMessage.selectedPhotos.length, 4) == 1 : currentMessage?.selectedPhotos?.length == 1"> photo</span>
                  </span>
                </span>
                to:
              </p>
              <p class="name">{{penmate?.name}}</p>
              <p class="address-facility">{{currentMessage?.eMessage?.name || penmate?.facility}}</p>
              <p class="address-shipping" *ngIf="!currentMessage?.eMessage">USPS First Class Mail</p>
            </div>
          </div>

          <div id='delivery-selection'>
            <div
              class="delivery-opts"
              [ngClass]="{'first-message': !user.has_sent_letter && !currentMessage.eMessage, 'postcard': isPostcard }"
            >

              <div
                class="delivery-opt emessage"
              >
                <div class="emessage--artwork-wrapper"><div class="emessage-artwork"></div></div>
                <div class="details">
                  <p class="name">
                    <span>
                     New Message
                    </span>

                  </p>

                  <p class="desc">
                    <span *ngIf="currentMessage?.shippingMethod == 'emessage'">Message</span>
                    <span *ngIf="currentMessage?.shippingMethod !== 'emessage' && currentMessage.messageType !== 'postcard'">Letter</span>
                    <span *ngIf="currentMessage?.shippingMethod !== 'emessage' && currentMessage.messageType === 'postcard'">Postcard</span>
                    <span
                      *ngIf="currentMessage.selectedPhotos.length > 0 && !isPostcard"
                    >
                      +
                      <span
                        >{{currentMessage?.shippingMethod === 'emessage' ? math.min(currentMessage.selectedPhotos.length, 4) : currentMessage.selectedPhotos.length}}
                        <span *ngIf="currentMessage?.shippingMethod === 'emessage' ?  math.min(currentMessage.selectedPhotos.length, 4) > 1 : currentMessage?.selectedPhotos?.length > 1"> photos </span>
                        <span *ngIf="currentMessage?.shippingMethod === 'emessage' ?  math.min(currentMessage.selectedPhotos.length, 4) == 1 : currentMessage?.selectedPhotos?.length == 1"> photo</span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
           </div>
          <div
            class="address-row mailing-address"
            [ngClass]="{'has-address': currentMessage.returnAddress?.valid}"
          >
            <div class="header">
              <ion-icon name="mail-open" class="icon"></ion-icon>

              <div>
                <p>
                  Send replies to:
                </p>
              </div>
            </div>
            <div>
              <p *ngIf="!returnAddressValid" class="address-select-null-state">
                Choose an option
              </p>
              <ion-segment (ionChange)="onReplyOptionChange($event)" value="{{currentReplyOption}}">
                <ion-segment-button
                  (click)="onToggleReturnAddressButton(currentMessage?.returnAddress?.valid)"
                  value="physical"
                  [ngClass]="{'skip-address': currentReplyOption === 'email'}"
                >
                  <ion-label>
                    <p *ngIf="currentMessage?.returnAddress?.valid">My return address</p>
                    <p *ngIf="!currentMessage?.returnAddress?.valid">Add return address</p>
                  </ion-label>
                </ion-segment-button>
                <ion-segment-button value="email" class="skip-button">
                  <ion-label><p class="skip">E-mail me</p></ion-label>
                </ion-segment-button>
              </ion-segment>
              <span
                *ngIf="isReturnAddressValid() && (!showEditAddress || currentMessage?.returnAddress?.addressType === 'physical')"
              >
                <div class="edited-address-info">
                  <p class="name">{{currentMessage?.returnAddress?.name}}</p>
                  <span *ngIf="currentMessage?.returnAddress?.addressType === 'physical'">
                    <p class="address-line-2">
                      {{currentMessage.returnAddress?.address_line1}},
                      {{currentMessage.returnAddress?.address_city}},
                      {{currentMessage.returnAddress?.address_state}}
                      {{currentMessage.returnAddress?.address_zip}}
                    </p>
                  </span>
                  <span *ngIf="currentMessage?.returnAddress?.addressType !== 'physical'">
                    <p class="address-line-2 email">
                      {{currentMessage.returnAddress?.email}}
                    </p>
                    <p class="address-line-2 email small">
                      {{currentMessage.returnAddress?.address_line1}}
                      <span *ngIf="currentMessage.returnAddress?.address_line2">
                        <br />
                        Apt/Unit {{currentMessage.returnAddress?.address_line2}}
                      </span>
                    </p>
                    <p class="address-line-2 email small">
                      {{currentMessage.returnAddress?.address_state}}
                      {{currentMessage.returnAddress?.address_zip}}
                    </p>
                  </span>
                  <a class="address-edit" (click)="onAddReturnAddress()">Edit / Change Address</a>
                </div>
              </span>

              <div *ngIf="showEditAddress">
                <p
                  class="skip-note"
                  *ngIf="currentReplyOption === 'email' && !currentMessage?.eMessage"
                  style="text-align:center; margin-bottom: 20px;"
                >
                  <span class="return-inst">We'll send replies to your email.</span>
                  <br />
                  <a (click)="onReturnAddressInfo()" class="info"> How does this work?</a>
                </p>

                <div *ngIf="currentReplyOption === 'email'" class="enter-name">
                  <p  class="emessage-address-note" *ngIf="currentMessage?.eMessage">
                    Full name and address required.
                  </p>
                  <ion-item class="form-field">
                    <ion-label position="stacked">Your Email:</ion-label>
                    <ion-input
                      type="email"
                      value="{{currentMessage.returnAddress?.email}}"
                      autoComplete="nope"
                      (ionChange)="onReturnAddressEmailChange($event)"
                    ></ion-input>
                  </ion-item>
                  <ion-item class="form-field">
                    <ion-label position="stacked">First name</ion-label>
                    <ion-input
                      type="text"
                      autoComplete="nope"
                      (ionChange)="onReturnAddressNameChange($event, 'first')"
                      value="{{currentMessage.returnAddress?.firstName}}"
                    ></ion-input>
                  </ion-item>
                  <ion-item class="form-field">
                    <ion-label position="stacked">Last name</ion-label>
                    <ion-input
                      type="text"
                      autoComplete="nope"
                      (ionChange)="onReturnAddressNameChange($event, 'last')"
                      value="{{currentMessage.returnAddress?.lastName}}"
                    ></ion-input>
                  </ion-item>

                  <ion-item class="form-field" style="margin-top:20px;">
                    <p
                      style="font-size: 18px; line-height: 23px; text-align: center; font-weight: 600;"
                      *ngIf="!currentMessage?.eMessage"
                    >
                      If email is not available, what address should {{penmate?.name}} write back
                      to?
                    </p>

                    <div id="return-address-autocomplete">
                      <ion-label position="stacked"
                        ><strong style="font-size: 15px;  color: #4a90e2; text-align: center;"
                          >Your Return Address:</strong
                        ></ion-label
                      >

                      <div class="address-state-zip-inputs">
                        <div class="address-input-full">
                          <ion-item class="form-field">
                            <ion-label position="stacked">Country</ion-label>
                            <ion-select 
                              value="{{currentMessage.returnAddress?.address_country || 'US'}}"
                              (ionChange)="onEmailReturnAddressChanged($event, 'address_country')"
                            >
                              <ion-select-option *ngFor="let country of COUNTRY_NAMES" value="{{country.code}}">{{country.name}}</ion-select-option>
                            </ion-select>
                          </ion-item>
                        </div>
                      </div>

                      <div class="address-state-zip-inputs">
                        <div class="address-input-full address-line1">
                          <ion-item class="form-field">
                            <ion-label position="stacked">Address</ion-label>
                            <ion-input
                              type="text"
                              autoComplete="nope"
                              value="{{currentMessage.returnAddress?.address_line1}}"
                              (ionChange)="onEmailReturnAddressChanged($event, 'address_line1')"
                              placeholder="Your address"
                            ></ion-input>
                          </ion-item>
                        </div>
                      </div>
                      <div class="address-state-zip-inputs" *ngIf="currentMessage.returnAddress?.address_country?.toLowerCase() == 'us' || !currentMessage.returnAddress?.address_country">
                        <div class="address-inputs-full address-line2">
                          <div class="autocomplete-input">
                            <ion-item class="form-field">
                              <ion-label position="stacked">Apt/Unit</ion-label>
                              <ion-input
                                type="text"
                                autoComplete="nope"
                                value="{{currentMessage.returnAddress?.address_line2}}"
                                (ionChange)="onEmailReturnAddressChanged($event, 'address_line2')"
                                placeholder="Apt # (optional)"
                              ></ion-input>
                            </ion-item>
                          </div>
                        </div>
                      </div>
                      <div class="address-state-zip-inputs">
                        <div class="address-inputs-left">
                          <div class="address-line-2 autocomplete-input">
                            <ion-item class="form-field">
                              <ion-label position="stacked">City</ion-label>
                              <ion-input
                                type="text"
                                autoComplete="nope"
                                value="{{currentMessage.returnAddress?.address_city}}"
                                (ionChange)="onEmailReturnAddressChanged($event, 'address_city')"
                                placeholder="City"
                              ></ion-input>
                            </ion-item>
                          </div>
                        </div>
                        <div class="address-inputs-right" *ngIf="currentMessage.returnAddress?.address_country?.toLowerCase() == 'us' || !currentMessage.returnAddress?.address_country">
                          <div class="address-state autocomplete-input">
                            <ion-item class="form-field">
                              <ion-label position="stacked">State</ion-label>
                              <ion-select 
                                value="{{currentMessage.returnAddress?.address_state}}"
                                (ionChange)="onEmailReturnAddressChanged($event, 'address_state')"
                              >
                                <ion-select-option *ngFor="let state of STATE_NAMES">{{state}}</ion-select-option>
                              </ion-select>
                            </ion-item>
                          </div>
                          <div class="address-zip autocomplete-input">
                            <ion-item class="form-field">
                              <ion-label position="stacked">Zip</ion-label>
                              <ion-input
                                autoComplete="nope"
                                type='tel' 
                                pattern="[0-9]*"
                                novalidate
                                value="{{currentMessage.returnAddress?.address_zip}}"
                                (ionChange)="onEmailReturnAddressChanged($event, 'address_zip')"
                              ></ion-input>
                            </ion-item>
                          </div>
                        </div>
                      </div>

                      <div class="address-state-zip-inputs" *ngIf="currentMessage.returnAddress?.address_country && currentMessage.returnAddress?.address_country?.toLowerCase() !== 'us' && currentMessage.returnAddress?.address_country?.toLowerCase() !== 'united states'">
                        <div class="address-inputs-left">
                          <div class="address-state autocomplete-input">
                            <ion-item class="form-field">
                              <ion-label position="stacked"> State / Province / Region</ion-label>
                              <ion-input
                                autoComplete="nope"
                                novalidate
                                value="{{currentMessage.returnAddress?.address_state}}"
                                (ionChange)="onEmailReturnAddressChanged($event, 'address_state')"
                              ></ion-input>
                            </ion-item>
                          </div>
                          <div class="address-zip autocomplete-input">
                            <ion-item class="form-field">
                              <ion-label position="stacked">ZIP / Postal Code</ion-label>
                              <ion-input
                                autoComplete="nope"
                                novalidate
                                value="{{currentMessage.returnAddress?.address_zip}}"
                                (ionChange)="onEmailReturnAddressChanged($event, 'address_zip')"
                              ></ion-input>
                            </ion-item>
                          </div>
                        </div>
                      </div>  

                    </div>

                    <ion-button
                      (click)="onSubmitAddress()"
                      class="saveBtn"
                      [disabled]="!isReturnAddressValid()"
                    >
                      Save & Continue
                    </ion-button>
                  </ion-item>
                </div>
              </div>
            </div>
            <div
              class="dotted-line"
              [ngClass]="{'address-valid': (isReturnAddressValid() && (!showEditAddress) ||  currentMessage?.returnAddress?.addressType === 'physical'), 'emessage': currentMessage?.eMessage}"
            ></div>
          </div>

          <div class="section" *ngIf="isReturnAddressValid() && (!showEditAddress || currentMessage?.returnAddress?.addressType === 'physical')">
            <div
              id="payment-options"
              class="payment-row"
              [ngClass]="{'address-valid': (isReturnAddressValid() && (!showEditAddress) ||  currentMessage?.returnAddress?.addressType === 'physical')}"
            >
              <div class="header">
                <ion-icon name="card" class="icon"></ion-icon>
                <div>
                  <p class="title">
                    <span *ngIf="paymentRequired && !currentMessage.isResend">Payment</span>
                    <span *ngIf="!paymentRequired && !currentMessage.isResend">Send it!</span>
                    <span *ngIf="currentMessage.isResend">Resend {{ currentMessage.messageType | titlecase}}</span>

                  </p>
                  <p class="subtitle">
                    <span *ngIf="!currentMessage.isResend">E-Delivery / Shipping</span>
                    <span *ngIf="currentMessage.isResend">Resend your letter or postcard</span>
                  </p>
                </div>
              </div>
              <div
                class="payment-options-title"
                *ngIf="currentMessage?.shippingMethod !== 'emessage' && user.creditBalance > 0"
              >
                <div>
                  <p style="font-weight: bold; margin: 0 0 8px 0;">
                    Your have letter credits!
                  </p>
                  <p *ngIf="user.creditBalance > 0" style="font-size:17px; margin: 0;">
                    <span class="subtitle">
                      You have <span class="bold">{{user?.creditBalance}}</span>
                      <span [ngPlural]="user?.creditBalance || 0">
                        <ng-template ngPluralCase="=1"> credit</ng-template>
                        <ng-template ngPluralCase="other"> credits</ng-template>
                      </span>
                      left.
                    </span>
                  </p>
                </div>
              </div>

              <div
                class="message-credits-info"
                *ngIf="currentMessage?.shippingMethod === 'emessage' && 
                user.messageCreditsAvailable &&
                user.messageCreditBalance > 0"
              >


                <div>
                  <p style="font-weight: bold; margin: 0 0 8px 0;">
                    You have message credits!
                  </p>
                  <div *ngIf="user.messageCreditBalance > 0">
                    <p style="font-size:17px; margin: 0;">
                      <span class="subtitle">
                        You have <span class="bold">{{user?.messageCreditBalance}}</span>
                        <span [ngPlural]="user?.messageCreditBalance || 0">
                          <ng-template ngPluralCase="=1"> credit</ng-template>
                          <ng-template ngPluralCase="other"> credits</ng-template>
                        </span>
                        left.
                      </span>
                    </p>
                    <p style="font-size:15px; margin: 5px 0 0 0;" *ngIf="user.has_sent_letter">
                      <span class="subtitle">
                        <span [ngClass]="{'warning': currentMessage?.meta.messageCreditCost > user.messageCreditBalance}">
                          This message requires <span class="bold green">{{currentMessage?.meta.messageCreditCost}}</span>
                          <span [ngPlural]="currentMessage?.meta?.messageCreditCost || 0" >
                            <ng-template ngPluralCase="=1"> credit</ng-template>
                            <ng-template ngPluralCase="other"> credits</ng-template>
                          </span>
                        </span>  
                      </span>
                      <br/>
                      <span *ngIf="currentMessage?.selectedPhotos.length > 0">(1 message 
                        <span *ngIf="currentMessage?.selectedPhotos.length > 0">
                          + 
                          <span [ngPlural]="math.min(currentMessage.selectedPhotos.length, 4)">
                            <ng-template ngPluralCase="=1"> 1 photo</ng-template>
                            <ng-template ngPluralCase="other"> {{math.min(currentMessage.selectedPhotos.length, 4)}} photos</ng-template>
                          </span>)
                        </span> 
                      </span>              
                    </p>
                  </div>
                </div>
              </div>


              <div
                class="payment-opts"
                [ngClass]="{'first-message': !user.has_sent_letter && currentMessage?.shippingMethod !== 'emessage', 'postcard': isPostcard }"
              >
                <div
                  class="payment-opt"
                  (click)="user.has_sent_letter ? onTogglePremiumUpgrade(false) : onToggleFirstMessageCoupon()"
                  [ngClass]="{'selected': currentMessage?.shippingMethod === 'letter' }"
                  *ngIf="user?.has_sent_letter ? 
                    currentMessage?.selectedPhotos.length === 0 : !isPostcard"
                >
                  <div [ngClass]="{'single-message-artwork': !isPostcard, 'photo-message-artwork': isPostcard}"></div>
                  <div class="details">
                    <p class="name">
                      <span *ngIf="!user?.has_sent_letter && !isPostcard">Print & Mail</span>
                      <span *ngIf="user?.has_sent_letter && !isPostcard">Send Message</span>
                      <span *ngIf="user?.has_sent_letter && isPostcard">Postcard message</span>

                    </p>
                    <p class="desc">
                      <span *ngIf="!user?.has_sent_letter">
                        No photos
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  class="payment-opt"
                  (click)="onTogglePremiumUpgrade(false)"
                  [ngClass]="{'selected': currentMessage?.shippingMethod === 'emessage', 'disabled': currentMessage?.selectedPhotos.length === 0 && !isPostcard }"
                  *ngIf="
                   user?.has_sent_letter ? currentMessage?.selectedPhotos.length > 0 : !user?.has_sent_letter"
                >
                  <div class="photo-message-artwork"></div>
                  <div class="details">
                    <p class="name">
                      <span *ngIf="isPostcard">Postcard</span>
                      <span *ngIf="!isPostcard && user?.has_sent_letter">Message + Photos</span>
                      <span *ngIf="!isPostcard && !user?.has_sent_letter">E-Message</span>
                    </p>
                    <p class="desc" *ngIf="!currentMessage.isResend">
                      <span *ngIf="isPostcard || freeShipping && currentMessage.shippingMethod != 'emessage' && currentMessage.photoType == 'regular'">
                        Free Shipping
                      </span>
                      <span *ngIf="!isPostcard && !user.has_sent_letter && currentMessage.photoType !== 'glossy'">
                        Free Photos
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div id="price-total" *ngIf="currentMessage?.eMessage || orderOptionSelected || (isPostcard && !user.has_sent_letter)  || user.has_sent_letter || creditTopUp === false || currentMessage.coupon?.active || isPostcard && orderOptionSelected">
                <span>

                  <div class="line-item bold" *ngIf="!creditTopUp">
                    <div>
                      <p class="label" *ngIf="!currentMessage.coupon">
                        <span *ngIf="!currentMessage?.eMessage">Single Message</span>

                      </p>
                      <p
                        class="upgrade-desc"
                        *ngIf="currentMessage?.shippingMethod !== 'emessage' && (!currentMessage.coupon || user.has_sent_letter)"
                      >
                        <span style="display:block; font-weight:bold; margin: 4px 0">
                          {{currentMessage && currentMessage.meta &&
                          currentMessage.meta.numberOfPages > 1 ? currentMessage.meta.numberOfPages
                          : '1'}}
                          <span *ngIf="currentMessage.messageType !== 'postcard'">
                            <span *ngIf="currentMessage?.shippingMethod == 'emessage'">Message</span>
                            <span *ngIf="currentMessage?.shippingMethod !== 'emessage'">
                              Page Letter
                              <span *ngIf="currentMessage?.meta.hasLetterCredit && currentMessage?.meta.letterCreditsDue > 0 && currentMessage?.selectedPhotos.length == 0">
                                 + Shipping
                              </span>
                            </span>
                          </span>
                          <span *ngIf="currentMessage.messageType == 'postcard'"> Postcard + Shipping</span>

                          <span *ngIf="!isPostcard && currentMessage?.selectedPhotos.length > 0">
                            + {{currentMessage.selectedPhotos.length}}
                            <span *ngIf="currentMessage?.selectedPhotos?.length > 1">
                              {{ currentMessage?.photoType?.toLowerCase() === 'glossy' ? "Glossy Photos" : "Photos" }}
                            </span>
                            <span *ngIf="currentMessage?.selectedPhotos?.length == 1"> {{ currentMessage?.photoType?.toLowerCase() === 'glossy' ? "Glossy Photo" : "Photo" }}</span>
                          </span>
                        </span>
                      </p>
                      <p
                        class="upgrade-desc"
                        *ngIf="currentMessage.coupon && !user.has_sent_letter"
                      >
                        <span style="display:block; font-weight:bold; margin: 4px 0">
                          <span *ngIf="!isPostcard && currentMessage.shippingMethod != 'emessage'">Text Message (no photos)</span>
                          <span *ngIf="isPostcard">Postcard</span>
                        </span>
                      </p>
                    </div>
                    <p class="price" *ngIf="paymentRequired && !currentMessage.isResend || currentMessage?.meta.hasLetterCredit && currentMessage?.meta.letterCreditsDue">
                      <span class="price free-message" *ngIf="!currentMessage.meta.hasLetterCredit && user.creditBalance > 0 && currentMessage.shippingMethod !== 'emessage'" style="font-size:14px;">
                        Free
                      </span>
                      <span class="price free-message" *ngIf="currentMessage.shippingMethod !== 'emessage' && (currentMessage.meta.hasLetterCredit && currentMessage?.meta.letterCreditsDue || currentMessage?.meta.hasLetterCredit && user.creditBalance > 0)" style="font-size:14px;">
                        {{currentMessage?.meta.letterCreditsDue}} 
                        <span [ngPlural]="currentMessage?.meta.letterCreditsDue" >
                          <ng-template ngPluralCase="=1"> credit </ng-template>
                          <ng-template ngPluralCase="other"> credits</ng-template>
                        </span>
                      </span>
                    </p>
                    <p class="price free-message" *ngIf="!paymentRequired && !currentMessage?.meta.creditsEnabled && !currentMessage?.meta.hasLetterCredit && !user.has_sent_emessage">
                      Free
                    </p>
                  </div>
                  <div
                    class="line-item choose-shipping"
                    id="choose-shipping"
                    *ngIf="!isPostcard &&(!user.creditBalance || user.creditBalance === 0 || onPromo)"
                  >
                  
                    <ion-list style="width:100%;">
                      <ion-radio-group
                        name="shippingMethod"
                        [(ngModel)]="currentMessage.shippingMethod"
                        (ionChange)="onChangeShipping($event)"
                      >
                        <ion-list-header>
                          <ion-label>
                            <ion-icon name="ios-paper-plane"></ion-icon>
                            <span *ngIf="!currentMessage.isResend">Choose delivery option</span>
                            <span *ngIf="currentMessage.isResend">Shipping details</span>
                          </ion-label>
                        </ion-list-header>


                        <ion-item class="shipping-option usps">
                          <ion-label>
                            <div class="speed-cost">
                              <div
                                style="
                                  width: 90%;
                                  display: block;
                                  text-wrap: pretty;
                                  "
                              >
                                <p class="service">
                                  <span>Print & Mail - 3-5 days</span>

                                </p>
                                <p
                                  style="
                                   width: 100%;
                                   font-size: 15px;
                                   color: #454465;
                                  "
                                >
                                  <span>USPS First-Class + Tracking</span>

                                </p>
                              </div>
                            </div>
                          </ion-label>
                          <ion-radio slot="start" value="letter" [checked]="currentMessage?.shippingMethod !== 'emessage'"></ion-radio>
                        </ion-item>
                        <ion-item class="shipping-option e-message" *ngIf="!isPostcard && !currentMessage.isResend" [disabled]="showEmessageDisabledMsg" (click)="onSendEmessage()">
                          <ion-label>
                            <div class="speed-cost">
                              <div
                                style="
                                  width: 90%;
                                  display: block;
                                  text-wrap: pretty;
                                  "
                              >
                                <p class="service hlt">
                                  <span>E-Message - 1-3 days</span>

                                </p>
                                <p
                                  style="
                                   width: 100%;
                                   font-size: 15px;
                                   color: #454465;
                                  "
                                >
                                  <span *ngIf="!showEmessageDisabledMsg">
                                    <span *ngIf="currentMessage?.eMessage">E-Mail / E-Delivery to <strong>{{currentMessage.eMessage.name | slice:0:40}}</strong></span>
                                    <span *ngIf="!currentMessage?.eMessage">E-Delivery / Check availability</span>
                                  </span>
                                  <span *ngIf="showEmessageDisabledMsg">
                                      E-message pending approval
                                  </span>
                                </p>
                              </div>

                              <p class="cost e-delivery">
                                <span
                                  [ngClass]="{'disabled': creditTopUp}"
                                  *ngIf="currentMessage?.shippingMethod == 'emessage'"
                                >
                                  <span *ngIf="!currentMessage?.meta?.deliveryOptions?.emessage">
                                    {{ currentMessage?.priceAfterCoupon * 0.01 | currency:'USD':'symbol':'1.2-2'}}
                                  </span>
                                </span>

                                <span
                                  [ngClass]="{'disabled': creditTopUp}"
                                  *ngIf="!currentMessage?.eMessage"
                                >
                                  <ion-icon name="ios-arrow-forward"></ion-icon>
                                </span>

                              </p>
                            </div>
                          </ion-label>
                          <ion-radio slot="start" value="emessage" [checked]="currentMessage?.shippingMethod == 'emessage'"></ion-radio>
                        </ion-item>
                      </ion-radio-group>
                    </ion-list>
                  </div>
                  <div
                    class="line-item choose-shipping"
                    id="choose-shipping"
                    *ngIf="isPostcard && currentMessage?.meta.hasLetterCredit"
                  >
                    <ion-list style="width:100%;">
                      <ion-list-header>
                        <ion-label>
                          <ion-icon name="ios-paper-plane"></ion-icon>
                          <span>Delivery: Print & Mail</span>
                        </ion-label>
                      </ion-list-header>


                      <ion-item class="shipping-option usps">
                        <ion-label>
                          <div class="speed-cost">
                            <div
                              style="
                                width: 90%;
                                display: block;
                                text-wrap: pretty;
                                "
                            >
                              <p class="service">
                                <span>USPS First-Class + Tracking</span>

                              </p>
                              <p
                                style="
                                 width: 100%;
                                 font-size: 15px;
                                 color: #454465;
                                "
                              >
                                <span>Delivery in 3-5 Days</span>

                              </p>
                            </div>

                            <p class="cost">
                              <span class="price free-message">
                                Free
                              </span>
                            </p>
                          </div>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </div>
                  <div
                    class="line-item choose-printing"
                    id="choose-printing"
                    *ngIf="!currentMessage.isResend && !isPostcard && currentMessage?.shippingMethod == 'letter' && currentMessage?.selectedPhotos.length > 0 && (!user.has_sent_letter ? (!couponCode || currentMessage.photoType === 'glossy') : true)"
                  >
                    <ion-list style="width:100%;">
                      <ion-radio-group
                        name="photoType"
                        value="{{currentMessage.photoType || 'regular'}}"
                        (ionChange)="onChangePhotoPrinting($event)"
                      >


                        <ion-list-header>
                          <ion-label class="photo-type">
                            <ion-icon name="ios-images"></ion-icon>
                            Choose photo printing
                          </ion-label>
                        </ion-list-header>

                        <ion-item class="option printing-option">
                          <ion-label>
                            <div class="speed-cost">
                              <div
                                style="
                                  width: 90%;
                                  display: block;
                                  text-wrap: pretty;
                                  "
                              >
                                <p class="service">Regular Photos - 3x5</p>
                                <p class="serrvice-desc">
                                  3x5 inch photos. Standard paper.
                                </p>
                              </div>

                              <p class="cost" *ngIf="user.creditBalance > 0 || currentMessage.coupon?.active || creditTopUp || !user.has_sent_letter || currentMessage?.selectedPhotos.length >= 4">
                                <span class="price free-message" style="font-weight:bold;">Free</span>
                                                   
                              </p>

                            </div>
                          </ion-label>
                          <ion-radio slot="start" value="regular"></ion-radio>
                        </ion-item>

                        <ion-item class="option printing-option" id="glossy-print-option">
                          <ion-label>
                            <div class="speed-cost">
                              <div
                                style="
                                  width: 90%;
                                  display: block;
                                  text-wrap: pretty;
                                  "
                              >
                                <p class="service glossy hlt">
                                   Glossy Photos - 4x6
                                </p>
                                <p class="service-desc">
                                  {{currentMessage?.selectedPhotos?.length}} x Glossy Photos 
                                </p>
                              </div>
                              <p class="cost bold green">
                                {{currentMessage?.meta.letterPremiumPhotoCreditsDue}}
                                <span [ngPlural]="currentMessage?.meta.letterPremiumPhotoCreditsDue">
                                  <ng-template ngPluralCase="=1"> credit</ng-template>
                                  <ng-template ngPluralCase="other"> credits</ng-template>
                                </span>
                              </p>
                            </div>
                          </ion-label>
                          <ion-radio slot="start" value="glossy"></ion-radio>
                        </ion-item>
                      </ion-radio-group>
                    </ion-list>
                  </div>
                  <div
                    class="line-item choose-shipping choose-credits"
                    id="choose-credits"
                    *ngIf="creditTopUpRequired || (currentMessage && (
                      user.hasSentEmessage ? photoCredits > 0 && !creditTopUpRequired || (currentMessage.meta.mailboxEnabled && currentMessage?.meta.messageCreditCost > user.messageCreditBalance)
                      : currentMessage?.meta.messageCreditCost > user.messageCreditBalance || !creditTopUpRequired && (user.messageCreditsAvailable || photoCredits > 0)
                    ))"
                  >
                    <form [formGroup]="creditOptionForm"  style="width: 100%;" *ngIf="creditOptionForm">
                      <ion-radio-group formControlName="creditOption">
                        <ion-list style="width:100%;" radio-group>
                          <ion-list-header *ngIf="creditTopUpRequired">
                            <ion-label>
                              <ion-icon name="ios-star"></ion-icon>
                              <span>{{currentMessage.isResend ? 'Resend Message' : 'Add Credits'}} </span>
                            </ion-label>
                          </ion-list-header>

                          <p class="option desc">
                            <span style="display:block; font-weight:bold; margin: 4px 0; text-align: center">
                              <span *ngIf="currentMessage?.shippingMethod !== 'emessage' && !isPostcard">{{currentMessage && currentMessage.meta &&
                              currentMessage.meta.numberOfPages > 1 ? currentMessage.meta.numberOfPages
                              : '1'}}</span>
                              <span *ngIf="currentMessage.messageType !== 'postcard'">
                                <span *ngIf="currentMessage?.shippingMethod === 'emessage'">E-Delivery: Message</span>
                                <span *ngIf="currentMessage?.shippingMethod !== 'emessage'">
                                  Page Letter
                                </span>
                              </span>
                              <span *ngIf="currentMessage.messageType == 'postcard'"> Send a Postcard </span>
                              <span *ngIf="!isPostcard && currentMessage?.shippingMethod !== 'emessage' && currentMessage?.selectedPhotos?.length > 0">
                                + {{currentMessage.selectedPhotos.length}}
                                <span *ngIf="currentMessage?.selectedPhotos?.length > 1">
                                  <span *ngIf="currentMessage?.shippingMethod !== 'emessage'">{{ currentMessage?.photoType?.toLowerCase() === 'glossy' ? "Glossy Photos" : "Photos" }}</span>
                                </span>
                                <span *ngIf="currentMessage?.selectedPhotos?.length == 1"> {{ currentMessage?.photoType?.toLowerCase() === 'glossy' ? "Glossy Photo" : "Photo" }}</span>
                              </span>
                              <span *ngIf="currentMessage?.shippingMethod == 'emessage' && currentMessage?.selectedPhotos?.length > 0">
                                + {{math.min(currentMessage.selectedPhotos.length, 4)}}
                                <span [ngPlural]="math.min(currentMessage.selectedPhotos.length, 4)">
                                  <ng-template ngPluralCase="=1"> photo</ng-template>
                                  <ng-template ngPluralCase="other"> photos</ng-template>
                                </span>
                              </span>
                            </span>
                          </p>
                          <p class="option desc credits-enabled-msg" style="text-align: center;">
                            <span  *ngIf="!creditTopUpRequired && currentMessage?.meta.letterCreditsDue > currentMessage.meta.letterCreditAmt || creditTopUpRequired && currentMessage?.shippingMethod !== 'emessage' && currentMessage?.meta.letterCreditAmt <= 0 && photoCredits == 0">
                              Add credits to send messages. 
                              <br/>
                            </span>
                            <span *ngIf="!isPostcard && !user.has_sent_emessage && currentMessage?.shippingMethod == 'emessage' && creditTopUpRequired">
                              Add credits to send e-message.
                              <br/>
                            </span>
                            <span *ngIf="creditTopUpRequired || !creditTopUpRequired && currentMessage?.meta.letterCreditAmt >= currentMessage.meta.letterCreditsDue || photoCredits > 0 && !creditTopUpRequired">
                              <span *ngIf="isPostcard ? user.has_sent_letter : !isPostcard || photoCredits > 0 && !creditTopUpRequired">
                                <span>
                                  Your message requires <span class="bold green">{{currentMessage?.meta.letterCreditsDue + (!currentMessage.meta.hasLetterCredit && photoCredits > 0 && currentMessage.meta.letterCreditAmt > 0 ? (photoCredits || 0) : 0)}}
                                  <span [ngPlural]="currentMessage?.meta.letterCreditsDue + (!currentMessage.meta.hasLetterCredit && photoCredits > 0 && currentMessage.meta.letterCreditAmount > 0 ? (photoCredits || 0) : 0)">
                                      <ng-template ngPluralCase="=1">credit.</ng-template>
                                      <ng-template ngPluralCase="other">credits.</ng-template>
                                  </span></span><span *ngIf="(photoCredits === 0 || currentMessage.meta.hasLetterCredit) && currentMessage?.meta.letterCreditAmt && currentMessage?.meta.letterCreditAmt > 0"><br/>You have <span class="bold green">{{currentMessage?.meta.letterCreditAmt}}
                                    <span [ngPlural]="currentMessage?.meta.letterCreditAmt">
                                      <ng-template ngPluralCase="=1">credit available.</ng-template>
                                      <ng-template ngPluralCase="other">credits available.</ng-template>
                                    </span></span>
                                  </span>
                                </span>
                                <span *ngIf="photoCredits > 0 && currentMessage?.meta.photoDiscount > 0">
                                  <br *ngIf="currentMessage?.meta.letterCreditsDue > 0"/> Your <span class="bold green">free photo credit</span> has been applied.
                                </span>
                              </span>
                            </span>
                            <span *ngIf="isPostcard && !user.has_sent_letter">
                              Send a single message or <span class="bold green">add credits</span> to send multiple messages.
                            </span>
                          </p>
                          <span *ngIf="creditTopUpRequired">
                            <ion-item class="shipping-option e-message" [ngClass]="{'option-disabled': !isPostcard && currentMessage?.selectedPhotos.length > 1, 'radio-selected': currentMessage.creditOption === '5' }">
                              <ion-label>
                                <div class="speed-cost">
                                  <div
                                    style="
                                      width: 90%;
                                      display: block;
                                      text-wrap: pretty;
                                      "
                                  >
                                    <p class="service">
                                      <span >Single {{isPostcard ? 'Postcard' : 'Message'}}</span>
                                    </p>
                                    <p class="option-disabled-msg" *ngIf="!isPostcard && currentMessage?.selectedPhotos?.length > 1">You have attached too many photos for this option.
                                      <span *ngIf="currentMessage?.selectedPhotos?.length - 1; let photoCountDiff">
                                        Remove {{ photoCountDiff >= 1 ? photoCountDiff : '' }}
                                        <span [ngPlural]="photoCountDiff" *ngIf="photoCountDiff">
                                          <ng-template ngPluralCase="=1"> photo </ng-template>
                                          <ng-template ngPluralCase="other"> photos</ng-template>
                                        </span>
                                        or choose another option.
                                      </span>
                                    </p>
                                    <p class="credit-desc" *ngIf="isPostcard || currentMessage?.selectedPhotos?.length < 2">
                                        Send 1 message <span *ngIf="!isPostcard">+ 1 {{currentMessage.photoType == 'glossy' ? 'glossy photo' : 'photo'}}</span>
                                    </p>
                                  </div>
                                </div>
                              </ion-label>
                              <ion-radio name="creditOption" slot="start" value="1" mode="md" [disabled]="!isPostcard && currentMessage?.selectedPhotos?.length > 1"></ion-radio>
                            </ion-item>
                            <ion-item class="shipping-option e-message credit-option-item" [ngClass]="{'radio-selected': currentMessage.creditOption === '5'}">
                              <ion-label>
                                <div class="speed-cost">
                                  <div
                                    style="
                                      width: 90%;
                                      display: block;
                                      text-wrap: pretty;
                                      "
                                  >
                                    <p class="service">
                                      <span>Add 5 credits: <span class="ea">$1.99 ea</span></span>
                                    </p>
                                    <p class="credit-desc">
                                      <span *ngIf="isPostcard">
                                        Send <span class="hlt">postcard</span> + 2 messages
                                      </span>
                                      <span *ngIf="!isPostcard">
                                        Send 5 messages or photos
                                      </span>
                                    </p>
                                  </div>

                                </div>
                              </ion-label>
                              <ion-radio name="creditOption" slot="start" value="5" mode="md" [disabled]="(currentMessage?.meta.letterCreditAmt || 0) + 5 < currentMessage.meta.letterCreditsDue" [checked]="currentMessage?.creditOption ? currentMessage?.creditOption === '5' : false"></ion-radio>
                            </ion-item>
                            <ion-item class="shipping-option e-message credit-option-item" *ngIf="!currentMessage.isResend" [ngClass]="{'radio-selected': currentMessage.creditOption === '10'}">
                              <ion-label>
                                <div class="speed-cost">
                                  <div
                                    style="
                                      width: 90%;
                                      display: block;
                                      text-wrap: pretty;
                                      "
                                  >
                                    <p class="service">
                                      <span>Add 10 credits: <span class="ea">$1.75 ea</span></span>
                                    </p>
                                    <p class="credit-desc">
                                      <span *ngIf="isPostcard">
                                        Send <span class="hlt">postcard</span> + 5 messages
                                      </span>
                                      <span *ngIf="!isPostcard">
                                        Send 10 messages or photos
                                      </span>
                                    </p>
                                  </div>

                                </div>
                              </ion-label>
                              <ion-radio name="creditOption" slot="start" value="10" mode="md" [checked]="currentMessage?.creditOption ? currentMessage?.creditOption == '10' : false"></ion-radio>
                            </ion-item>
                            <ion-item class="shipping-option e-message credit-option-item" *ngIf="!currentMessage.isResend" [ngClass]="{'radio-selected': currentMessage.creditOption === '15'}">
                              <ion-label>
                                <div class="speed-cost">
                                  <div
                                    style="
                                      width: 90%;
                                      display: block;
                                      text-wrap: pretty;
                                      "
                                  >
                                    <p class="service">
                                      <span>Add 15 credits: <span class="ea">$1.50 ea</span></span>
                                    </p>
                                    <p class="credit-desc">
                                      <span *ngIf="isPostcard">
                                        Send <span class="hlt">postcard</span> + 10 messages
                                      </span>
                                      <span *ngIf="!isPostcard">
                                        Send 15 messages or photos
                                      </span>
                                    </p>
                                  </div>

                                </div>
                              </ion-label>
                              <ion-radio name="creditOption" slot="start" value="15" mode="md" [checked]="currentMessage.creditOption ? currentMessage.creditOption == '15' : false"></ion-radio>
                            </ion-item>
                          </span>
                        </ion-list>
                      </ion-radio-group>

                    </form>
                  </div>
                  <div
                    class="line-item choose-printing"
                    id="first-photos-reminder"
                    *ngIf="!user.has_sent_letter && couponCode && currentMessage?.shippingMethod !== 'emessage' && !canShowEmessageReminder()"
                  >
                    <ion-list style="width:100%;">
                      <ion-list-header>
                        <ion-label class="photo-type">
                          <ion-icon name="ios-images"></ion-icon>
                          <span>Add Free Photos</span>
                        </ion-label>
                      </ion-list-header>


                      <ion-row class="ion-align-items-center">
                        <ion-col size="12">
                          <div class="desc">
                            <p> 
                               Your message is currently text only. You can add up to <span class="hlt bold">10 free photos</span> to your message. 
                            </p>
                          </div>
                        </ion-col> 
                        <ion-col size="12">
                          <ion-button
                            (click)="currentMessage.selectedPhotos.length > 0 ? onTogglePremiumUpgrade() : onCancel()"
                            class="toggleEmessageBtn"
                          >
                            Add Photos
                          </ion-button>
                        </ion-col> 
                      </ion-row>
                    </ion-list>
                    
                  </div>

                  <div class="line-item delivery-method" *ngIf="paymentRequired && !creditTopUpRequired || !creditTopUpRequired && currentMessage?.meta.letterCreditAmt >= currentMessage.meta.letterCreditsDue">
                    <p class="label bold">
                      {{isPostcard  ? 'Postcard Delivery ' : 'Message Delivery'}}
                    </p>
                  </div>
                  <div class="line-item" *ngIf="paymentRequired && !user.has_sent_letter && !isPostcard && currentMessage?.selectedPhotos.length > 0 && currentMessage?.photoType !== 'glossy' && currentMessage?.shippingMethod === 'letter'">
                    <p class="label bold green"> First Message</p>
                    <p class="price bold green">
                      {{currentMessage.selectedPhotos.length}} free {{currentMessage.selectedPhotos.length > 1 ? 'photos' : 'photo'}}
                    </p>
                  </div>
                  <div class="line-item photo-discount-subtotal" *ngIf="paymentRequired && photoCredits > 0 && currentMessage?.selectedPhotos.length > 0 && (currentMessage?.meta.letterCreditAmt == 0 || currentMessage?.meta.hasLetterCredit)">
                    <p class="label "> Subtotal </p>
                    <p class="price">
                      <span
                        *ngIf="loadingTotal"
                        style="padding:5px; display:flex; align-items:center; justify-content:center;"
                      >
                        <ion-spinner name="lines-small"></ion-spinner>
                      </span>
                      <span *ngIf="!loadingTotal">
                        {{ (currentMessage?.meta.letterSubtotal + currentMessage?.meta.photoDiscount) * 0.01 | currency:'USD':'symbol':'1.2-2'}}
                      </span>
                    </p>
                  </div>
                  <div class="line-item photo-discount-line-item" *ngIf="paymentRequired && currentMessage?.meta.photoDiscount > 0 && photoCredits > 0 && currentMessage?.selectedPhotos.length > 0 && (currentMessage?.meta.letterCreditAmt == 0 || currentMessage?.meta.hasLetterCredit)">
                    <p class="label bold green"> Photo Credits (Discount) </p>
                    <p class="price bold green">
                      <span
                        *ngIf="loadingTotal"
                        style="padding:5px; display:flex; align-items:center; justify-content:center;"
                      >
                        <ion-spinner name="lines-small"></ion-spinner>
                      </span>
                      <span *ngIf="!loadingTotal">
                        - {{ (currentMessage?.meta.photoDiscount) * 0.01 | currency:'USD':'symbol':'1.2-2'}}
                      </span>
                    </p>
                  </div>
                  <!-- <div class="line-item" *ngIf="currentMessage?.meta.letterCreditsDue <= 0" [ngClass]="{'free-message': currentMessage.coupon?.active && currentMessage?.shippingMethod !== 'emessage' && !isPostcard }">
                    <p class="label">
                     
                      <span *ngIf="!currentMessage.isResend"> 
                          <span *ngIf="currentMessage?.shippingMethod !== 'emessage' && currentMessage?.price > 0 && !currentMessage.meta.hasLetterCredit">
                              <span *ngIf="user?.has_sent_letter">Letter Subtotal</span>
                              <span *ngIf="!user?.has_sent_letter && currentMessage?.selectedPhotos?.length">
                                Letter & {{ currentMessage?.photoType?.toLowerCase() === 'glossy' ? 'Glossy Photos' : 'Photos'}}
                              </span>
                          </span>
                          <span class="bold" *ngIf="currentMessage?.shippingMethod == 'emessage' && currentMessage?.price > 0">
                            <span *ngIf="!!currentMessage?.meta.creditsEnabled && creditOptionForm.value.creditOption">
                              {{creditOptionForm?.value.creditOption}}
                              <span [ngPlural]="creditOptionForm?.value?.creditOption">
                                <ng-template ngPluralCase="=1"> credit</ng-template>
                                <ng-template ngPluralCase="other"> credits</ng-template>
                                &nbsp;(messages or photos)
                              </span>
                            </span>
                            <span *ngIf="!currentMessage?.meta.creditsEnabled || !creditOptionForm.value.creditOption">E-message Subtotal</span>

                          </span>

                      </span>
                      <span *ngIf="currentMessage.isResend"> Subtotal (+ discount)</span>
                      <span *ngIf="currentMessage.coupon?.active && currentMessage?.shippingMethod !== 'emessage' && !isPostcard" style="font-weight: bold;">
                         - Your letter is free!
                      </span>
                      <span *ngIf="currentMessage?.price > 0 && currentMessage?.shippingMethod !== 'emessage' && !user?.has_sent_letter && !currentMessage.coupon?.active && !creditTopUp" style="font-weight: bold;">
                        <span *ngIf="!isPostcard"> - First message discount</span>
                        <span  *ngIf="isPostcard"> Postcard</span>
                      </span>
                      <span *ngIf="currentMessage?.shippingMethod !== 'emessage' && creditTopUp" style="font-weight: bold;">
                         (3 Messages + Photos)
                      </span>
                    </p>
                    <p class="price" *ngIf="paymentRequired || !currentMessage?.meta.hasLetterCredit">
                      <span
                        *ngIf="loadingTotal"
                        style="padding:5px; display:flex; align-items:center; justify-content:center;"
                      >
                        <ion-spinner name="lines-small"></ion-spinner>
                      </span>
                      <span *ngIf="!loadingTotal">
                        <span *ngIf="!creditTopUp && currentMessage.isResend">
                          <span *ngIf="currentMessage?.priceAfterCoupon <= currentMessage?.meta.basePrice">
                            {{ (currentMessage?.priceAfterCoupon + currentMessage?.meta.photoDiscount) * 0.01 | currency:'USD':'symbol':'1.2-2'}}</span>
                          <span *ngIf="currentMessage?.priceAfterCoupon > currentMessage?.meta.basePrice">{{ currentMessage?.meta.basePrice * 0.01 | currency:'USD':'symbol':'1.2-2'}}</span>
                        </span>
                        <span *ngIf="currentMessage?.shippingMethod !== 'emessage'">
                          <span *ngIf="!user?.has_sent_letter">
                            {{ currentMessage?.priceAfterCoupon * 0.01 |  currency:'USD':'symbol':'1.2-2'}}
                          </span>
                          <span *ngIf="user?.has_sent_letter && (currentMessage?.meta.photoCredits <= 0 ||  currentMessage?.meta.photoDiscount <= 0 )">
                            {{ (currentMessage?.priceAfterCoupon - currentMessage?.meta.basePrice) * 0.01 |  currency:'USD':'symbol':'1.2-2'}}
                          </span>
                          <span *ngIf="user?.has_sent_letter && (currentMessage?.meta.photoCredits > 0 || currentMessage?.meta.photoDiscount > 0 )">
                            {{ ( currentMessage?.meta.basePrice) * 0.01 |  currency:'USD':'symbol':'1.2-2'}}
                          </span>
                        </span>
                        <span *ngIf="currentMessage?.shippingMethod == 'emessage'">
                          {{ (currentMessage?.priceAfterCoupon) * 0.01 |
                        currency:'USD':'symbol':'1.2-2'}}
                        </span>
                      </span>

                    </p>
                  </div>      -->
                            
                  <div class="line-item shipping-line-item" *ngIf="currentMessage?.shippingMethod !== 'emessage' && !paymentRequired && !creditTopUpRequired && user?.has_sent_letter" >
                    <p class="label">
                      <span *ngIf="currentMessage?.selectedPhotos.length === 0">
                        {{currentMessage && currentMessage.meta &&
                          currentMessage.meta.numberOfPages > 1 ? currentMessage.meta.numberOfPages
                          : '1'}} Page Letter
                      </span>
                      <span *ngIf="currentMessage?.selectedPhotos.length > 0">
                        Letter + Photos
                      </span>
                    </p>
                    <p class="price">
                       {{currentMessage?.meta.letterCreditsDue}} {{currentMessage?.meta.letterCreditsDue > 1 ? 'credits' : 'credit'}}
                    </p>
                  </div>
                  <!-- <div class="line-item extra-photos-line-item" *ngIf="currentMessage?.meta.letterCreditsDue <=0 && !currentMessage?.isResend && currentMessage?.priceAfterCoupon > currentMessage?.meta.basePrice">
                    <p class="label">
                      <span> + Extra photos</span>
                    </p>
                    <p class="price">
                      <span
                        *ngIf="loadingTotal"
                        style="padding:5px; display:flex; align-items:center; justify-content:center;"
                      >
                        <ion-spinner name="lines-small"></ion-spinner>
                      </span>
                      <span *ngIf="!loadingTotal">
                        <span>
                          {{ (currentMessage?.priceAfterCoupon - currentMessage?.meta.basePrice) * 0.01 |
                        currency:'USD':'symbol':'1.2-2'}}
                        </span>
                      </span>
                    </p>
                  </div> -->
                  <div class="line-item processing-line-item" *ngIf="paymentRequired && (!currentMessage?.meta.hasLetterCredit || currentMessage?.meta.hasLetterCredit && creditTopUpRequired || currentMessage?.meta.hasLetterCredit && photoCredits > 0)">
                    <p class="label">Transaction / Processing</p>
                    <p class="price">
                      <span
                        *ngIf="loadingTotal"
                        style="padding:5px; display:flex; align-items:center; justify-content:center;"
                      >
                        <ion-spinner name="lines-small"></ion-spinner>
                      </span>
                      <span *ngIf="!loadingTotal">
                        {{ currentMessage?.meta.processingFee * 0.01 | currency:'USD':'symbol':'1.2-2'}}
                      </span>
                    </p>
                  </div>
                  <div class="line-item bold" *ngIf="currentMessage?.selectedPhotos.length >= photoCredits ? paymentRequired : paymentRequired && (!currentMessage?.meta.hasLetterCredit || photoCredit > 0 && currentMessage?.meta.hasLetterCredit)">
                    <p class="label">
                        <span *ngIf="!currentMessage?.meta.hasLetterCredit || currentMessage?.meta.letterCreditApplied == 0 || photoCredits > 0 && currentMessage?.meta.hasLetterCredit" >Total</span>
                        <span *ngIf="currentMessage?.meta.hasLetterCredit && currentMessage?.meta.letterCreditApplied > 0 && photoCredits == 0">Subtotal</span>

                    </p>
                    <p class="price">
                      <span
                        *ngIf="loadingTotal"
                        style="padding:5px; display:flex; align-items:center; justify-content:center;"
                      >
                        <ion-spinner name="lines-small"></ion-spinner>
                      </span>
                      <span *ngIf="!loadingTotal && (!currentMessage?.meta.hasLetterCredit || currentMessage?.meta.letterCreditApplied == 0)">
                        {{ onCalculateTotalWithTax() | currency:'USD':'symbol':'1.2-2'}}
                      </span>
                      <span *ngIf="!loadingTotal && (currentMessage?.meta.hasLetterCredit && currentMessage?.meta.letterCreditApplied > 0)">
                        {{ onCalculateRegularTotal() | currency:'USD':'symbol':'1.2-2'}}
                      </span>
                    </p>
                  </div>
                  <div class="line-item" *ngIf="currentMessage?.meta.hasLetterCredit && currentMessage?.meta.letterCreditBalance > 0 && !creditTopUpRequired || !creditTopUpRequired && currentMessage?.meta.letterCreditAmt >= currentMessage.meta.letterCreditsDue">
                    <p class="label bold ">Credits Available</p>
                    <p class="price bold ">
                      <span
                        *ngIf="loadingTotal"
                        style="padding:5px; display:flex; align-items:center; justify-content:center;"
                      >
                        <ion-spinner name="lines-small"></ion-spinner>
                      </span>
                      <span *ngIf="!loadingTotal">
                        {{ currentMessage?.meta.letterCreditAmt }}
                        <span [ngPlural]="currentMessage?.meta.letterCreditAmt">
                          <ng-template ngPluralCase="=1"> credit </ng-template>
                          <ng-template ngPluralCase="other"> credits</ng-template>
                        </span>
                      </span>
                    </p>
                  </div> 
                  <div class="line-item bold photo-credits" *ngIf="!creditTopUpRequired && (!creditTopUp && photoCredits > 0 && currentMessage?.selectedPhotos?.length > 0)">
                    <p class="label">- Free Credits
                      <span [ngPlural]="currentMessage?.selectedPhotos?.length">
                        <ng-template ngPluralCase="=1"> (1 photo)</ng-template>
                        <ng-template ngPluralCase="other"> ({{currentMessage?.selectedPhotos.length > photoCredits ? photoCredits : currentMessage.selectedPhotos.length  }} photos)</ng-template>
                      </span>
                    </p>
                    <p class="price">
                      <span
                        *ngIf="loadingTotal"
                        style="padding:5px; display:flex; align-items:center; justify-content:center;"
                      >
                        <ion-spinner name="lines-small"></ion-spinner>
                      </span>
                      <span *ngIf="!loadingTotal">
                        <span [ngPlural]="math.min(currentMessage?.selectedPhotos.length, photoCredits)">
                          <ng-template ngPluralCase="=1"> 1 credit</ng-template>
                          <ng-template ngPluralCase="other"> {{math.min(currentMessage?.selectedPhotos.length, photoCredits) }} credits</ng-template>
                        </span>
                      </span>
                    </p>
                  </div>
                  <div class="line-item" *ngIf="!creditTopUpRequired && currentMessage?.meta.hasLetterCredit && currentMessage?.meta.letterCreditsDue > 0 || !creditTopUpRequired && currentMessage?.meta.letterCreditAmt >= currentMessage.meta.letterCreditsDue">
                    <p class="label bold green"> - Credits Used</p>
                    <p class="price bold green">
                      <span
                        *ngIf="loadingTotal"
                        style="padding:5px; display:flex; align-items:center; justify-content:center;"
                      >
                        <ion-spinner name="lines-small"></ion-spinner>
                      </span>
                      <span *ngIf="!loadingTotal">

                        {{ currentMessage?.meta.letterCreditsDue}}
                        <span [ngPlural]="currentMessage?.meta.letterCreditsDue">
                          <ng-template ngPluralCase="=1"> credit </ng-template>
                          <ng-template ngPluralCase="other"> credits</ng-template>
                        </span>

                      </span>
                    </p>
                  </div>  

                  <div class="line-item bold" *ngIf="!currentMessage?.meta.hasLetterCredit && currentMessage?.meta.letterCreditApplied > 0">
                    <p class="label">
                        Total
                    </p>
                    <p class="price">
                      <span
                        *ngIf="loadingTotal"
                        style="padding:5px; display:flex; align-items:center; justify-content:center;"
                      >
                        <ion-spinner name="lines-small"></ion-spinner>
                      </span>
                      <span *ngIf="!loadingTotal">
                        {{ onCalculateTotalWithTax() | currency:'USD':'symbol':'1.2-2'}}
                      </span>
                    </p>
                  </div>
                  <div
                    class="line-item choose-printing"
                    id="emessage-reminder"
                    *ngIf="!isPostcard && !isResend && (showEmessageDisabledMsg || canShowEmessageReminder())"
                  >
                    <ion-list style="width:100%;">
                      <ion-list-header>
                        <ion-label class="photo-type">
                          <ion-icon name="ios-paper-plane"></ion-icon>
                          <span *ngIf="!showEmessageDisabledMsg">Send e-message?</span>
                          <span *ngIf="showEmessageDisabledMsg">
                            Your emessage account is pending approval
                          </span>
                        </ion-label>
                      </ion-list-header>


                      <ion-row class="ion-align-items-center">
                        <ion-col size="12">
                          <div class="desc">
                            <p> 
                              <span *ngIf="!showEmessageDisabledMsg">⭐ E-message delivery is available for this facility. Your loved one can reply to you by e-message.</span>
                              <span *ngIf="showEmessageDisabledMsg">
                                Your emessage account is pending approval. You can still deliver messages using Print & Mail until approved.
                                <span *ngIf="currentMessage?.meta.letterCreditApplied > 50"> Your credits have been applied to this message.</span>
                              </span>

                            </p>
                          </div>
                        </ion-col> 
                        <ion-col size="12" *ngIf="!showEmessageDisabledMsg">
                          <ion-button
                            (click)="onChangeEmessage($event)"
                            class="toggleEmessageBtn"
                          >
                            Send E-message
                          </ion-button>
                        </ion-col> 
                      </ion-row>
                    </ion-list>
                    
                  </div>
                </span>
                <div id="emessage-payment" *ngIf="!loadingTotal && (currentMessage?.meta.hasLetterCredit ? creditTopUpRequired : !user.messageCreditsAvailable && paymentRequired)">
                  <h5 class="subtitle">
                    <ion-icon name="information-circle"></ion-icon>
                    &nbsp;You will not be charged yet.
                  </h5>
                  <p>
                    Your payment method may be authorized, but will not be charged until your message has been sent.
                    <span *ngIf="currentMessage?.shippingMethod == 'emessage'">
                      If your message is unable to be delivered via e-message, it will be shipped 
                      via USPS First-Class.</span>
                  </p>
                  <p *ngIf="creditTopUpRequired">
                    Message credit purchases are non-refundable and cannot be transferred between accounts.
                  </p>
                </div>
                <button
                  class="pay-with-card-button"
                  (click)="onAddPayment()"
                  *ngIf="currentMessage?.id && !currentMessage.payment && paymentRequired"
                >
                  <ion-icon name="card" class="icon"></ion-icon>
                  Add {{creditTopUpRequired ? 'Credits' : 'Payment'}}
                </button>
                <p  class="order-terms" 
                    *ngIf="currentMessage?.shippingMethod == 'emessage' && 
                    !!currentMessage?.meta.creditsEnabled && 
                    currentMessage?.meta.messageCreditCost > user.messageCreditBalance &&
                    currentMessage?.meta.mailboxActive 
                ">
                  Message credit purchases are non-refundable and cannot be transferred between accounts.
                </p>
              </div>
              <div class="dotted-line" [ngClass]="{'emessage':currentMessage?.shippingMethod == 'emessage'}"></div>
              <div
                class="free-letter-desc first-message"
                [ngClass]="{'has-photos':  currentMessage.selectedPhotos.length > 0}"
                *ngIf="!orderOptionSelected && !loadingTotal && currentMessage?.shippingMethod !== 'emessage' && !currentMessage.coupon && !user?.has_sent_letter && creditTopUp === null && !currentMessage.payment"
              >
                <div class="premium-artwork"></div>
                <p class="title">Send your first message!</p>
                <p class="subtitle">
                  <span *ngIf="!isPostcard"
                    >Send a free text message or
                    <span class="bold">add up to free 10 photos 🙌🏽.</span></span
                  >
                  <span *ngIf="isPostcard"
                    >Send your first postcard
                    <span class="bold">with free shipping. 🙌🏽.</span></span
                  >
                </p>
                <p class="cta">Select an option above.</p>
              </div>
              <div
                id="payment-detail-container"
                *ngIf="!canShowEmessageReminder() && ((currentMessage.meta.hasLetterCredit && currentMessage.meta.letterCreditAmt >= currentMessage.meta.letterCreditsDue) || !creditTopUpRequired && currentMessage?.meta.letterCreditAmt >= currentMessage.meta.letterCreditsDue || !paymentRequired && couponCode && currentMessage?.shippingMethod !== 'emessage')"
              >
                <div id="payment-success">
                  <div>
                    <p
                      class="payment-attached-success"
                    >
                      You're all set.&nbsp;<strong>Send it off!</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="payment-detail-container"
      *ngIf="currentMessage?.payment"
    >


      <div id="payment-success" *ngIf="currentMessage?.payment && currentMessage?.price > 0 || currentMessage.meta.hasLetterCredit && currentMessage.meta.letterCreditAmt >= currentMessage.meta.letterCreditsDue">
        <div>
          <div id="attached-payment" *ngIf="currentMessage?.payment">
            <ion-grid>
              <ion-row>
                <ion-col size="1" class="ok-icon">
                  <ion-icon name="checkmark-circle"></ion-icon>
                </ion-col>
                <ion-col size="9">
                  <p class="bill-to">Bill to</p>
                  <div class="card-info" [ngClass]="{'cashapp': currentMessage.payment.brand == 'cashapp'}">
                    <div class="payment-type card" *ngIf="currentMessage.payment.brand !== 'cashapp'">
                      <ion-icon name="ios-card"></ion-icon>
                      <span class="card-type">Card&nbsp;</span>
                      <span class="card-last4">ending in {{currentMessage.payment.last4}}</span>
                    </div>

                    <div *ngIf="currentMessage.payment.brand == 'cashapp'" class="payment-type cashapp-pay">
                      <span class="cashapp-logo"></span>
                      <span class="card-type">My Cash App</span>
                    </div>
                  </div>
                </ion-col>
                <ion-col size="2">
                  <a (click)="removePayment()">
                    <ion-icon name="ios-close-circle" class="remove-icon"></ion-icon>
                  </a>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <p
            *ngIf="paymentRequired && currentMessage.payment && !currentMessage.coupon || currentMessage.meta.hasLetterCredit && currentMessage.meta.letterCreditAmt >= currentMessage.meta.letterCreditsDue || !paymentRequired && couponCode"
            class="payment-attached-success"
          >
            You're all set.&nbsp;<strong>Send it off!</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer id="modal-footer">
  <div id="message-actions">
    <ion-grid>
      <ion-row>
        <ion-col size="6" class="back">
          <ion-button (click)="onCancel()" icon-left>
            <div class="button-inner">
              <ion-icon name="arrow-back"></ion-icon>
              Back
            </div>
          </ion-button>
        </ion-col>
        <ion-col size="6" class="continue">
          <ion-button
            icon-right
            [disabled]="loadingTotal || !currentMessage?.id || !orderOptionSelected && !user.has_sent_letter && !couponCode || !orderOptionSelected && user.creditBalance === 0 && paymentRequired && !currentMessage.payment || !returnAddressValid || (paymentRequired && !currentMessage.payment) || (showEditAddress && currentReplyOption === 'email') || !user.has_sent_letter && currentMessage.selectedPhotos.length === 0 && !orderOptionSelected && !couponCode"

            (click)="onHandleSubmit()"
          >
            <div class="button-inner">
              Send!
              <ion-icon name="md-paper-plane"></ion-icon>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
