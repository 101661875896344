import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { get, orderBy, compact } from 'lodash';
import { map } from 'rxjs/operators';
import { Validators, FormBuilder } from '@angular/forms';
import { CreateMessageActions, MyPenmateActions } from '../actions';
import { ActivatedRoute } from '@angular/router';
import { PopoverController, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { AppState, LetterService, EventService, mapStates } from '../services';

@Component({
  selector: 'pm-message-incomplete-modal',
  templateUrl: 'message-incomplete-modal.html',
  styleUrls: ['./message-incomplete-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageIncompleteModal {
  @ViewChild('scrollContent', { static: false }) scrollContent;
  penmate;
  navCtrl;
  isPostcard;
  formSubmitted;
  state$;
  STATE_NAMES;
  addressForm;
  currentMessage;

  constructor(
    private navParams: NavParams,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    private createMessageActions: CreateMessageActions,
    private myPenmateActions: MyPenmateActions,
    private store: Store<AppState>,
  ) {
    this.state$ = this.route.paramMap.pipe(map(() => window.history.state));
    this.currentMessage = this.navParams.get('currentMessage') || {};
    this.STATE_NAMES = mapStates.map((m) => m['abbreviation']);
    const { default_from_address, from_address, inmate } = this.currentMessage;
    this.penmate = this.navParams.get('penmate');
    this.navCtrl = this.state$.navCtrl;

    this.addressForm = this.formBuilder.group({
      toAddress: this.formBuilder.group({
        first_name: [null, Validators.required],
        last_name: [null, Validators.required],
        facility: [null, Validators.required],
        id_number: [inmate.inmate_id],
      }),
      fromAddress: this.formBuilder.group({
        name: [null, Validators.required],
        address_line1: [null, Validators.required],
        address_line2: [null],
        address_city: [null, Validators.required],
        address_country: ['US',  Validators.required],
        address_state: [null],
        address_zip: [null, Validators.required],
      }),
    });
    
    this.addressForm.controls.toAddress.patchValue(inmate);
    this.addressForm.controls.fromAddress.patchValue({
      ...default_from_address,
      address_line1: default_from_address.address_line1 || default_from_address.email_address_line1,
    });
  }


  onSaveAndContinue() {
    this.store.dispatch(
      this.createMessageActions.confirmMailingAddress(this.currentMessage.id, {
        confirmed_address: this.addressForm.value,
      }),
    );
    setTimeout(() => {
    	this.modalCtrl.dismiss();
    }, 500)
  }

  onCancel() {
    this.modalCtrl.dismiss();
  }

  onFocusZip = () => {
    if (this.scrollContent) {
      this.scrollContent.scrollToBottom();
    }
  };

}
