import { Component, ViewChild, Renderer, OnInit, HostListener } from '@angular/core';
import { NavController, LoadingController, ModalController, IonContent } from '@ionic/angular';

import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Headers, RequestOptions, Jsonp } from '@angular/http';
import { chunk, get, filter, orderBy } from 'lodash';
import { catchError } from 'rxjs/operators';
import { AppState, LetterService, ApiGateway, SharedService } from '../services';
import { Store } from '@ngrx/store';

import { PhotoPickerPhoto } from '../models';
import { SelectedPhotosModal } from '../selected-photos-modal/selected-photos-modal';
import * as moment from 'moment';

declare var Penmate;

@Component({
  templateUrl: 'ig-search-tab.html',
})
export class IGSearchTab implements OnInit {
  @ViewChild(IonContent, { static: false }) content: any;
  showSearchBar;
  isPostcard;
  selectedPhotos = [];
  searchInput = '';
  loadingSpinner;
  currentTab = 'searchUsers';
  tabs = {
    searchUsers: {
      title: 'Search Users',
      pagination: {},
      placeholder: 'Enter instagram username',
      user: null,
      showUser: true,
      photos: [],
    },
    searchTags: {
      title: 'Search Hashtags',
      placeholder: 'Enter a hashtag',
      tag: null,
      pagination: {},
      photos: [],
    },
  };

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public route: ActivatedRoute,
    private sharedService: SharedService,
    private store: Store<AppState>,
    private jsonp: Jsonp,
    private renderer: Renderer,
    private apiClient: ApiGateway,
  ) {}

  ngOnInit() {
    const path = get(this.route.snapshot, 'parent.routeConfig.path');
    this.isPostcard = path.match(/new-postcard/i);
  }

  onCancelSearchUser() {
    this.searchInput = null;
    this.tabs['searchUsers'].user = null;
  }
  onSearchInput(ev) {
    this.renderer.invokeElementMethod(ev.target, 'blur');
    this.onSubmitSearch();
  }

  onSearchUser(username = '') {
    const url = `${Penmate.env.apiUrl}/instagram/user-search`;
    if (username && username.length >= 1) {
      this.showLoadingSpinner();
      this.tabs.searchUsers.showUser = true;
      this.apiClient.get(url, { username }).subscribe(({ data: user }) => {
        this.tabs.searchUsers.user = user;
        this.tabs.searchUsers.photos = [];
        this.hideLoadingSpinner();
      });
    } else {
      this.tabs.searchUsers.user = null;
    }
  }

  onViewPostsFromUser(user, maxId = null) {
    const url = `${Penmate.env.apiUrl}/instagram/user-media`;
    const req = this.apiClient.get(url, { user_id: user.pk, max_id: maxId }).map(res => res);
    if (!maxId) {
      this.showLoadingSpinner();
    }
    req.subscribe(
      results => {
        if (!maxId) {
          this.hideLoadingSpinner();
        }

        this.tabs.searchUsers.showUser = false;
        this.tabs.searchUsers.pagination = results.pagination;
        const posts = results.data.map(p => this.formatSearchMedia(p));
        this.tabs.searchUsers.photos = this.tabs.searchUsers.photos.concat(chunk(posts, 2));
      },
      e => {
        this.hideLoadingSpinner();
      },
    );
    return req;
  }

  onTabChange(tabName) {
    this.currentTab = tabName;
    this.searchInput = null;
    // tslint:disable-next-line
    // this.content.resize();
  }

  onSearchTags(tag, maxId = null) {
    const url = `${Penmate.env.apiUrl}/instagram/tag-search`;
    const req = this.apiClient.get(url, { tag, max_id: maxId }).map(res => res);
    if (!maxId) {
      this.showLoadingSpinner();
    }

    req.subscribe(results => {
      if (!maxId) {
        this.hideLoadingSpinner();
      }
      const posts = results.data.map(p => this.formatSearchMedia(p));
      this.tabs.searchTags.tag = tag;
      this.tabs.searchTags.pagination = results.pagination;
      if (maxId) {
        this.tabs.searchTags.photos = this.tabs.searchTags.photos.concat(chunk(posts, 2));
      } else {
        this.tabs.searchTags.photos = chunk(posts, 2);
      }
    });
    return req;
  }

  onSubmitSearch() {
    this.currentTab === 'searchUsers'
      ? this.onSearchUser(this.searchInput)
      : this.onSearchTags(this.searchInput);
  }

  canShowNullState() {
    const searchTabVisible = this.currentTab !== 'myPhotos';
    if (this.currentTab === 'searchUsers') {
      return searchTabVisible && !this.tabs[this.currentTab].user;
    } else {
      return searchTabVisible && !this.tabs[this.currentTab].tag;
    }
  }

  hideLoadingSpinner = async () => {
    await this.loadingSpinner.dismiss();
  };

  showLoadingSpinner = async () => {
    this.loadingSpinner = await this.loadingCtrl.create({
      message: 'Please wait...',
    });
    await this.loadingSpinner.present();
  };

  onSelectPhoto = ({ url }) => {
    if (this.isPostcard) {
      this.sharedService.data.emit({
        event: { type: 'onSelectPostcardPhoto', data: { url } },
      });
      const penmateId = this.route.snapshot.parent.paramMap.get('id');
      return this.navCtrl.navigateBack(`/penmates/${penmateId}/new-postcard`);
    }
  };

  onLoadMore(event) {
    const nextMaxId = get(this.tabs[this.currentTab], 'pagination.next_max_id');

    let req;
    if (nextMaxId) {
      this.sharedService.data.emit({
        event: { type: 'pagination', data: { loading: true } },
      });
      switch (this.currentTab) {
        case 'searchUsers':
          const user = this.tabs['searchUsers'].user;
          req = this.onViewPostsFromUser(user, nextMaxId);
          req.subscribe(() => {
            this.sharedService.data.emit({
              event: { type: 'pagination', data: { loading: false } },
            });
            event.target.complete();
          });
          break;
        case 'searchTags':
          req = this.onSearchTags(this.tabs['searchTags'].tag, nextMaxId);
          req.subscribe(() => {
            this.sharedService.data.emit({
              event: { type: 'pagination', data: { loading: false } },
            });
            event.target.complete();
          });
          break;
        default:
          this.sharedService.data.emit({
            event: { type: 'pagination', data: { loading: false } },
          });
          break;
      }
    } else {
      event.target.complete();
    }
  }

  private formatSearchMedia(photo) {
    const is_carousel = !!get(photo, 'carousel_media');
    const is_video = !!get(photo, 'video_versions');
    let image: any = orderBy(get(photo, 'image_versions2.candidates', []), 'width', 'desc')[0];
    if (is_carousel) {
      const images = get(photo, 'carousel_media[0].image_versions2.candidates', []);
      image = orderBy(images, 'width', 'desc')[0];
    }
    const formattedPhoto: PhotoPickerPhoto = {
      url: image.url,
      id: photo.id,
      created_at: moment.unix(photo.taken_at).format(),
      provider: 'instagram',
      details: photo,
      is_carousel,
      is_video,
    };
    return formattedPhoto;
  }
}
