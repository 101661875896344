import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Http, RequestOptions, Headers } from '@angular/http';
import { map } from 'rxjs/operators';

import 'rxjs';
import { sample } from 'lodash';
const L = require('mapbox.js');
require('leaflet-makimarkers');

let mapBoxAccessToken =  'pk.eyJ1Ijoibmlja2wiLCJhIjoibzFaemw0WSJ9.cmGqHO9wJ53PySvjpN8nnw';

@Injectable({ providedIn: 'root' })
export class MapBoxService {
  tileLayerDefaultOps =  {
    maxZoom: 4,
  };
  tileUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  iconPost;
  iconHeart;
  iconPost2;
  map;

  constructor(private http: Http) {
    L.mapbox.accessToken = mapBoxAccessToken;
    L.MakiMarkers.accessToken = mapBoxAccessToken;
  }

  init(token) {
    mapBoxAccessToken = token;
  }

  drawMap(elementId, facilityLoc, userLoc) {

    this.iconPost = L.MakiMarkers.icon({icon: 'post', color: '#0c8918', size: 'm' });
    this.iconPost2 = L.MakiMarkers.icon({icon: 'post', color: '#f96106', size: 'm' });

    return new Observable(observer => {
      const facilityGeoLoc = this.geocodePlace(facilityLoc);
      const userGeoLoc = this.geocodePlace(userLoc);
      const layer = new L.TileLayer(this.tileUrl, this.tileLayerDefaultOps);
      this.map = L.map(elementId).addLayer(layer);

      Observable.forkJoin([facilityGeoLoc, userGeoLoc]).subscribe(places => {

        observer.next(places);

        const featureLayer = L.mapbox.featureLayer(places).addTo(this.map);
        const bounds = featureLayer.getBounds();
        this.map.fitBounds(bounds);

        const polyline = L.polyline([], {
          color: '#1b0548',
          weight: 2,
          fillColor: '#fff',
          dashArray: '5, 10',
        }).addTo(this.map);

        const coords = [];

        featureLayer.eachLayer( (l) => {
          const latLng = l.getLatLng();
          polyline.addLatLng(latLng);
          coords.push(latLng);
        });

        const marker1 = L.Marker.movingMarker(coords, [3000], {
          autostart: false,
          icon: this.iconPost,
        }).addTo(this.map);

        const marker2 = L.Marker.movingMarker(coords.reverse(), [3000], {
          autostart: false,
          icon: this.iconPost2,
        }).addTo(this.map);

      });

    });
  }

  geocodePlace(place) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const baseUrl = `https://api.tiles.mapbox.com/v4/geocode/mapbox.places/${place}.json`;
    return new Observable(observer => {
      this.http.get(
        `${baseUrl}?access_token=${mapBoxAccessToken}`,
        { headers },
      )
      .map((res: any) => res.json())
      .subscribe(data => {
        const feature = data.features[0] || {};
        observer.next(feature);
        observer.complete();
      }, (err) => {
        observer.error(err);
        observer.complete();
      });
    });
  }
}
