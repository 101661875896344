import {
  Component,
  ViewEncapsulation,
  NgZone,
  ElementRef,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  NavController,
  Platform,
  ModalController,
  ActionSheetController,
  AlertController,
  NavParams,
} from '@ionic/angular';
import { take, switchMap, debounceTime, takeUntil, filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { of, Subject} from 'rxjs';
import { ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { LoadingModal } from '../loading-modal/loading-modal';
import { SelectedPhotosModal } from '../selected-photos-modal/selected-photos-modal';
import { IGPhotoPicker } from '../ig-photo-picker/ig-photo-picker';
import { FileUploadModal } from '../file-upload-modal/file-upload-modal';
import { CreateMessageReturnAddress } from '../create-message-return-address/create-message-return-address';

import { CreateMessageActions, UserActions, MyPenmateActions } from '../actions';
import { Observable } from 'rxjs/Observable';
import { AppState, LetterService, AuthService, NativeClientService } from '../services';
import { Store, ActionsSubject } from '@ngrx/store';
import { truncate } from 'lodash';
import { get } from 'lodash';

/*
  Generated class for the CreateMessage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-message-text-editor',
  templateUrl: 'message-text-editor.html',
  styleUrls: ['./message-text-editor.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageTextEditor implements OnInit {
  keyboardShown;
  keyboardHeight;
  maxEditorHeight;
  keyboardEditorHeight;
  messageMaxlength;
  selectedPhotos = [];
  selectedPhotosCount;
  selectedPhotosModal;
  loadingModal;
  photoPickerProvider;
  photoPickerAccessToken;
  photoPickerModal;
  isPostcard;
  penmate;
  currentMessage;
  initialWindowHeight;
  useNativeTextEditor = false;
  textEditorForm;
  @ViewChild('actionButtons', { static: false }) actionButtons: ElementRef;
  @ViewChild('textEditor', { static: false }) textEditor: ElementRef;
  unsubscribe$ = new Subject<void>();
  private savingMessage = false;

  constructor(
    public navCtrl: NavController,
    public zone: NgZone,
    public navParams: NavParams,
    public route: ActivatedRoute,
    private platform: Platform,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private createMessageActions: CreateMessageActions,
    private myPenmateActions: MyPenmateActions,
    private actionsSubject: ActionsSubject,
    private nativeService: NativeClientService,
  ) {
    const defaultMaxEditorHeight = this.platform.height() * 0.75;
    this.maxEditorHeight = defaultMaxEditorHeight;
    this.initialWindowHeight = (window as any).innerHeight;

    this.textEditorForm = this.formBuilder.group({
      messageText: ['', Validators.required],
    });

    this.store
      .select(state => state.createMessage)
      .pipe(take(1))
      .subscribe(createMessage => {
        this.penmate = createMessage.penmate;
        this.currentMessage = createMessage;
        this.isPostcard = createMessage.messageType === 'postcard';
        this.selectedPhotos = createMessage.selectedPhotos;
        if (createMessage.message && createMessage.message.length > 0) {
          this.textEditorForm.patchValue({ messageText: createMessage.message });
        }
        if (createMessage.selectedPhotos.length === 0 && this.selectedPhotosModal) {
          this.selectedPhotosModal.dismiss();
        }
      });
    this.store
      .select(state => state.createMessage)
      .subscribe(createMessage => {
        this.currentMessage = createMessage;
      });

    // if (this.nativeService.active) {
    //   this.nativeService.data.subscribe(({ type, payload }) => {
    //     zone.run(() => {
    //       if (type === 'KEYBOARD_OPEN') {
    //         this.keyboardShown = true;
    //         this.maxEditorHeight = payload.maxHeight ? payload.maxHeight : 350;
    //         this.keyboardHeight = payload.keyboardHeight;
    //       }
    //       if (type === 'KEYBOARD_CLOSED') {
    //         // code...
    //         this.keyboardShown = false;
    //         this.maxEditorHeight = defaultMaxEditorHeight;
    //       }
    //     });
    //   });
    // }
    const windowVisibleViewport = (window as any).visualViewport;
    if (windowVisibleViewport) {
      windowVisibleViewport.addEventListener('resize', () => {
        const { pageTop, height } = windowVisibleViewport;
        if (pageTop > 0) {
          const actionButtonsTop = windowVisibleViewport.pageTop + 25;
          const textEditorTop = actionButtonsTop + 15;
          const textEditorHeight = height - 80;
          const right = 10;
          this.actionButtons.nativeElement.setAttribute(
            'style',
            `top: ${actionButtonsTop}px; right: ${right}px`,
          );
          this.textEditor.nativeElement.setAttribute(
            'style',
            `top: ${textEditorTop}px; width: 100%; position: fixed;  height:${textEditorHeight}px`,
          );
          this.actionButtons.nativeElement.classList.add('keyboard');
          return;
        }
        const right = 10;
        this.actionButtons.nativeElement.classList.remove('keyboard');
        this.actionButtons.nativeElement.setAttribute(
          'style',
          `top: 0; right: ${right}px; margin-top: 50px;`,
        );
        this.textEditor.nativeElement.setAttribute('style', ``);
      });
    }
  }

  ngOnInit(): void {
    if (!this.currentMessage || !this.currentMessage.id) {
      this.onSaveMessage();
    }
    this.actionsSubject.pipe(
      filter(action => 
        action.type === CreateMessageActions.SET_MESSAGE
      )
    ).subscribe(() => {
      this.savingMessage = false;
    });

    this.textEditorForm.valueChanges
      .pipe(
        debounceTime(900),
        filter(() => !this.savingMessage),
        switchMap(formValue => {
          this.savingMessage = true;
          return of(formValue);
        }),
      )
      .subscribe(({ messageText }) => {
        if (this.currentMessage && this.currentMessage.id) {
          this.store.dispatch(this.createMessageActions.editMessage(messageText));
        } else {
          this.onSaveMessage(messageText);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  } 

  getMessageMaxLength() {
    return this.navParams.get('maxLength');
  }

  getKeyboardFooterPos() {
    const keyboardOpenHeightDiff = this.initialWindowHeight - (window as any).innerHeight;
    return this.keyboardHeight - keyboardOpenHeightDiff;
  }

  onShowSelectedPhotos = async () => {
    this.selectedPhotosModal = await this.modalCtrl.create({
      component: SelectedPhotosModal,
      componentProps: {
        photos: this.selectedPhotos,
      },
    });
    await this.selectedPhotosModal.present();
  };

  onDeleteMessage() {
    this.store.dispatch(
      this.myPenmateActions.deleteMessage({
        penmateId: this.penmate.id,
        message: this.currentMessage,
      }),
    );
    const isPostcard = /new-postcard/.test(document.location.href);
    // const component = isPostcard ? PostcardCreatorPage : MessageCreatorPage;
    const routePath = isPostcard
      ? `/penmates/${this.penmate.id}/new-postcard`
      : `/penmates/${this.penmate.id}/new-message`;
    this.navCtrl.navigateBack(routePath);
  }

  onSaveMessage(messageText?: string) {
    const message = messageText || get(this.currentMessage, 'message', '');
    this.store.dispatch(this.createMessageActions.saveDraft({...this.currentMessage, message}));
  }

  cancelMessage = async () => {
    if (this.keyboardShown) {
      return true;
    }
    return this.modalCtrl.dismiss();
  };

  onSaveAndContinue() {
    const text = this.textEditorForm.value.messageText;
    this.actionsSubject.pipe(
      takeUntil(this.unsubscribe$),
      filter((action) => action.type === CreateMessageActions.SET_MESSAGE)
    ).subscribe((data) => {
      this.modalCtrl.dismiss();
    })

    if (this.currentMessage && this.currentMessage.id) {
      this.store.dispatch(this.createMessageActions.editMessage(text));
    } else {
      this.store.dispatch(this.createMessageActions.saveDraft({...this.currentMessage, message: text}));
    }
  }

  onTextAreaFocus() {
    this.keyboardShown = true;
  }

  onTextAreaBlur() {
    this.keyboardShown = false;
    const text = this.textEditorForm.value.messageText;
    this.store.dispatch(this.createMessageActions.editMessage(text));
  }

  onDisplayErrorAlert = async (titleText, buttonText) => {
    const alert = await this.alertCtrl.create({
      header: titleText,
      buttons: [
        {
          text: buttonText,
        },
      ],
    });
    await alert.present();
  };

  hasPhotos() {
    return this.selectedPhotos && this.selectedPhotos.length > 0;
  }
}
