import { Component, SecurityContext, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { NavController, Platform, ModalController, IonInfiniteScroll } from '@ionic/angular';
import { AppState, AuthService, EventService, SharedService, MyPenmateService } from '../services';
import { Store } from '@ngrx/store';
import { UserActions, CreateMessageActions, MyPenmateActions } from '../actions';
import { orderBy, isEmpty, get, chain, assignWith } from 'lodash';
import * as moment from 'moment';

import { MessageTextEditor } from '../message-text-editor/message-text-editor';
import { MyPenmatesPage } from '../my-penmates/my-penmates';
import { MessageShowPage } from '../message-show/message-show';
import { LoginPage } from '../login/login.page';
declare var Beacon;

import {
  takeWhile,
} from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'pm-page-message-index',
  templateUrl: 'message-index.html',
  styleUrls: ['./message-index.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageIndexPage implements OnInit {
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
  pageHeight;
  penmate;
  modal;
  messages;
  onGoBackFn;
  currentPage = 1;

  constructor(
    public navCtrl: NavController,
    public createMessageActions: CreateMessageActions,
    public myPenmateActions: MyPenmateActions,
    public userActions: UserActions,
    public authService: AuthService,
    public sharedService: SharedService,
    public eventService: EventService,
    private myPenmateService: MyPenmateService,
    private sanitizer: DomSanitizer,
    private platform: Platform,
    private store: Store<AppState>,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
  ) {
    this.pageHeight = platform.height();
    platform.ready().then(() => {
      this.store.dispatch(this.myPenmateActions.loadPenmates());
    });
  }

  mapMessages(messageArray) {
    const allSent = messageArray.filter((m) =>
      get(m, 'status', '').match(/processing|ready_to_be_mailed|mailed/i),
    );
    const sent = orderBy(
      allSent,
      [
        (o) => {
          return moment(o.timestamp).unix();
        },
        (o) => {
          return moment(o.send_date).unix();
        },
      ],
      'desc',
    );
    const draft = messageArray.filter((m) => get(m, 'status', '').match(/draft/i));
    const emessages = messageArray.filter((m) => m.message_type === 'emessage');
    const messages = emessages.concat(sent).concat(draft);
    const allMessages = (this.messages || []).concat(orderBy(
     messages,
      [
        (o) => {
          if (get(o, 'status', '').match(/draft/i)) {
            return moment(o.timestamp).unix();
          }
          return moment(o.timestamp).unix();
        },
        (o) => {
          return o.send_date ? moment(o.send_date).unix() : moment(o.timestamp).unix();
        },
      ],
      'desc',
    ));

    const mergedMessages = chain(allMessages)
      .groupBy('id')
      .map(objs => assignWith({}, ...objs, (msg1, msg2) => msg1 && (msg1.sent || msg1.processing ) ? msg1 : msg2))
      .orderBy(
        [
          (o) => {
            if (get(o, 'status', '').match(/draft/i)) {
              return moment(o.timestamp).unix();
            }
            return moment(o.timestamp).unix();
          },
          (o) => {
            return o.send_date ? moment(o.send_date).unix() : moment(o.timestamp).unix();
          },
        ],
        'desc',
      )
      .value();
    this.messages = mergedMessages
  }

  ngOnInit() {
    this.store
    .select((state) => state.myPenmates.currentPenmate)
    .pipe(takeWhile((penmate) => {
      return this.currentPage == 1;
    }))
    .subscribe((penmate) => {
      this.penmate = penmate;
      if (penmate && penmate.messages) {
        const allSent = penmate.messages.filter((m) =>
          get(m, 'status', '').match(/processing|ready_to_be_mailed|mailed/i),
        );
        const sent = orderBy(
          allSent,
          [
            (o) => {
              return moment(o.timestamp).unix();
            },
            (o) => {
              return moment(o.send_date).unix();
            },
          ],
          'desc',
        );
        const lastSentMessage = sent[0];
        this.mapMessages(penmate.messages);
        const confirmedToAddress = get(lastSentMessage || {}, 'confirmed_address.toAddress')
        if (confirmedToAddress) {
          this.penmate.facility = confirmedToAddress.facility || this.penmate.facility
          this.penmate.first_name = confirmedToAddress.first_name || this.penmate.first_name
          this.penmate.last_name = confirmedToAddress.last_name || this.penmate.last_name
        }
      }
    });
    this.sharedService.data.subscribe(({ event }) => {
      if (event.type === 'onGoBack') {
        this.onGoBackFn = get(event, 'data.onGoBack');
      }
    });
  }

  ionViewDidEnter() {
    Beacon('destroy');
    this.onFetchMessages(1).subscribe( );
  }

  ionViewWillEnter() {
    if (this.modal) {
      this.modal.dismiss();
    }
    const penmateId = this.route.snapshot.paramMap.get('id');
    this.store.dispatch(this.myPenmateActions.loadMessages(penmateId, 1));
  }

  onFetchMessages(pageNum = 1) {
    return new Observable(observer => {
      if (!this.penmate.id) {
        observer.next([])
        return observer.complete();
      }
      this.myPenmateService.loadMessages(this.penmate.id, pageNum).subscribe((res) => {
        this.currentPage = pageNum + 1;
        const messages = [...get(res, 'letters', []), ...get(res, 'emessages', [])]
        const sentMessages = messages.map(m => {
          let msg = Object.assign({}, m.data, m); // copy data key to top-level
          return msg;
        });
        this.mapMessages(sentMessages);
        this.store.dispatch(this.myPenmateActions.loadMessagesSuccess(messages) );
        observer.next(res)
        observer.complete();
      })
    });

  }

  loadNextPage(event) {
    if (this.penmate.id) {
      const nextPage = this.currentPage + 1;
      this.onFetchMessages(nextPage).subscribe((res: any) => {
        event.target.complete();
        event.target.disabled = !res.has_next_page;
      })
    }
  }

  public trackItem (_index, item) {
    return item.id;
  }
  

  onShowMessage(message) {
    message.sent || message.processing || message.message_type === 'emessage'
      ? this.showSentMessage(message)
      : this.showEditMessage(message);
  }

  showSentMessage(message) {
    const isEmessage = message.message_type === 'emessage' 
    this.navCtrl.navigateForward(`/penmates/${this.penmate.id}/messages/${message.id}?emessage=${isEmessage}`);
    this.eventService.track('show-message', { [isEmessage ? 'message_id' : 'letter_id']: message.id, penmate_id: this.penmate.id, emessage: isEmessage });
  }

  showEditMessage = async (message) => {
    this.store.dispatch(this.createMessageActions.setMessage(message));
    this.modal = await this.modalCtrl.create({ component: MessageTextEditor });
    this.eventService.track('edit-message', { letter_id: message.id, penmate_id: this.penmate.id });
    this.navCtrl.navigateForward(`/penmates/${this.penmate.id}/new-message`);
  };

  onCancel() {
    this.navCtrl.pop();
  }

  onGoBack = () => {
    if (this.onGoBackFn) {
      return this.onGoBackFn();
    }
    this.navCtrl.navigateForward('/my-penmates');
  };

  sanitizeHtml(html) {
    const formattedHtml = isEmpty(html) ? 'No text included' : html;
    return this.sanitizer.bypassSecurityTrustHtml(formattedHtml);
  }
}
