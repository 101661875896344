<ion-header>
  <ion-toolbar *ngIf="!penmate || !isMobile">
    <header id="toolbar-header">
      <div class="header-left">
        <a href="https://m.penmateapp.com"><div class="pm-logo-text"></div></a>
      </div>
      <div class="header-right">
        <a href="/add-penmate" *ngIf="isMobile"> Send a message</a>
      </div>
    </header>
  </ion-toolbar>
</ion-header>

<ion-content id="my-penmates">
  <div class="pm-contact" *ngIf="penmate && isMobile">
    <div class="penmate-avatar">
      <div class="icon-circle">
        <div class="inner-circle">
          <span *ngIf="penmate.initials && !penmate.image" class="initials"
            >{{penmate.initials}}</span
          >
          <img *ngIf="penmate.image" [src]="penmate.image" />
          <ion-icon *ngIf="!penmate.initials && !penmate.image" name="ios-person"></ion-icon>
        </div>
      </div>
    </div>
    <div class="contact-card">
      <div class="contact-details">
        <h3 class="name">{{ penmate.first_name }} {{penmate.last_name}}</h3>
        <h5 class="facility">{{ penmate.facility }}</h5>
      </div>
    </div>
    <div class="penmate-profile">
      <div id="map-container-{{penmate.id}}" class="map-container" [pmContactMap]="penmate">
        <div class="map-cover"></div>
        <div class="map"></div>
      </div>

      <div id="send-message-actions">
        <div id="send-message-title">
          <p class="title">
            Connect with {{ penmate.first_name }} {{penmate.last_name}}
          </p>
          <p class="subtitle">
            Send letters, photos, and postcards.
          </p>
        </div>
      </div>
      <a
        (click)="onExploreMedia(penmate)"
        class="message-button message-action send-message"
        *ngIf="isNative"
      >
        <ion-grid>
          <ion-row>
            <ion-col size="2">
              <ion-icon name="md-globe" size="large"></ion-icon>
            </ion-col>
            <ion-col size="9">
              <p>Explore what's popular</p>
            </ion-col>
            <ion-col size="1">
              <ion-icon name="ios-arrow-forward" size="large"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-grid>
      </a>
      <a (click)="onCreateMessage(penmate)" class="message-button send-message">
        <ion-row>
          <ion-col size="2">
            <ion-icon name="ios-create"></ion-icon>
          </ion-col>
          <ion-col size="9">
            <p>Send a message or photos</p>
          </ion-col>
          <ion-col size="1">
            <ion-icon name="ios-arrow-forward"></ion-icon>
          </ion-col>
        </ion-row>
      </a>
      <a (click)="onCreatePostcard(penmate)" class="message-button send-postcard">
        <ion-row>
          <ion-col size="2">
            <ion-icon name="ios-image"></ion-icon>
          </ion-col>
          <ion-col size="9">
            <p>Create a Postcard</p>
          </ion-col>
          <ion-col size="1">
            <ion-icon name="ios-arrow-forward"></ion-icon>
          </ion-col>
        </ion-row>
      </a>
      <a (click)="onViewMessages(penmate)" class="message-button message-action view-messages">
        <ion-row>
          <ion-col size="2">
            <ion-icon name="paper-plane" size="large"></ion-icon>
          </ion-col>
          <ion-col size="9">
            <p>View sent messages</p>
          </ion-col>
          <ion-col size="1">
            <ion-icon name="ios-arrow-forward" size="large"></ion-icon>
          </ion-col>
        </ion-row>
      </a>
    </div>
  </div>
  <div id="web-message" *ngIf="!penmate || !isMobile">
    <div *ngIf="!penmate">
      <div class="app-logo-top">
        <div class="app-logo"></div>
      </div>
      <div class="sorry-message">
        <h2>Sorry, this page is not available.</h2>
        <p>The link you are using may be broken, or the page has been removed.</p>
      </div>
    </div>
    <div *ngIf="penmate" class="penmate-info">
      <div class="penmate-avatar" *ngIf="penmate">
        <div class="icon-circle">
          <div class="inner-circle">
            <span *ngIf="penmate.initials && !penmate.image" class="initials"
              >{{penmate.initials}}</span
            >
            <img *ngIf="penmate.image" [src]="penmate.image" />
            <ion-icon *ngIf="!penmate.initials && !penmate.image" name="ios-person"></ion-icon>
          </div>
        </div>
      </div>
      <div class="connect-message">
        <h2>Connect with {{ penmate.first_name }} {{penmate.last_name}}</h2>
        <h5 class="facility">{{ penmate.facility }}</h5>

        <div>
          <a href="https://apps.apple.com/us/app/penmate-send-mail-to-jail/id955317881"
            ><div class="app-store-button"></div
          ></a>
          <a href="https://play.google.com/store/apps/details?id=com.penmate"
            ><div class="play-store-button"></div
          ></a>

          <p>
            or send an email to
            <a class="email" href="mailto:{{penmate.slug}}@penmateapp.com"
              >{{penmate.slug}}@penmateapp.com</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer>
  <app-footer-nav></app-footer-nav>
</ion-footer>
