<div class="add-payment-sheet-container">
  <div id="payment-form">
    <div class="payment-form-header">
      <div class="header-title">
        <ion-icon name="card"></ion-icon>
        <h2 class="title">
          <span>Payment</span>
        </h2>
      </div>
      <div class="close" (click)="onClose($event)">
        <ion-icon name="close"></ion-icon>
      </div>
    </div>
    <div class="payment-detail">
      <p class="price" *ngIf="!isEmessageSetup && !updatePayment">
        {{ data.amount | currency:'USD':true:'1.2-2' }}
      </p>
      <p class="premium-cta" *ngIf="currentMessage?.messageType !== 'postcard' && paymentDescription?.length">
        <span style="font-size: 25px;" >
          {{paymentDescription}}
        </span>
      </p>
      <p class="premium-cta" *ngIf="currentMessage?.creditTopUp">
        <span *ngIf="currentMessage?.messageType === 'postcard' ">Postcard</span>

        <span *ngIf="currentMessage?.messageType !== 'postcard' "
          >3 Message Pack + Free Photos
          <span *ngIf="currentMessage?.photoType === 'glossy'" class="photo-price">
            +
            <span
              >{{currentMessage?.selectedPhotos?.length}}
              <span *ngIf="currentMessage?.photoType === 'glossy'"> Premium/Glossy </span>

              <span *ngIf="currentMessage?.selectedPhotos?.length > 1"> photos </span>
              <span *ngIf="currentMessage?.selectedPhotos?.length == 1"> photo</span>
            </span>
          </span>
        </span>
      </p>
      <p class="premium-cta" *ngIf="!currentMessage?.creditTopUp">
          <span *ngIf="currentMessage?.messageType === 'postcard' ">Postcard</span>

          <span *ngIf="currentMessage?.messageType !== 'postcard' && currentMessage?.shippingMethod == 'emessage'"
            >E-Message
            <span *ngIf="currentMessage?.selectedPhotos?.length > 0 && !isPostcard" class="photo-price">
              + 4 Photos
              </span>
          </span>
      </p>
      <span *ngIf="!addCredits && !isEmessageSetup">
        <p class="delivery">
          Message Delivery:
        </p>
        <p class="recipient">
          To {{currentMessage?.penmate?.name}}
        </p>
        <p class="delivery">
          {{currentMessage?.penmate?.facility}}
        </p>
      </span>
    </div>

    <h3 class="billing-header"> Billing Details </h3>
    <div *ngIf="isEmessageSetup" id="emessage-payment">
      <h5 class="subtitle">
        <ion-icon name="information-circle"></ion-icon>
        &nbsp;You will not be charged yet.
      </h5>
      <p>
        You will not be charged until your messaging account has been approved.
        Once approved, you can add credits to send messages and photos.
      </p>
    </div>
    <div class="stripe-loading-container" *ngIf="stripeLoading">
      <ion-spinner name="crescent"></ion-spinner>
    </div>
    <div *ngIf="!paymentSourceSelected" id="payment-source-selection">
      <h5 class="title-label"> Select Payment Method</h5>
      <ion-list style="width:100%;">
        <ion-radio-group
          name="paymentSource"
          [(ngModel)]="paymentSource"
          (ionChange)="onChangePaymentSource($event)"
        >
 
          <ion-item class="payment-source-option card">
            <ion-label>
              <div class="payment-source-desc">
                <div class="icon">
                  <ion-icon name="card"></ion-icon>
                </div>
                <div>
                  <p class="service">
                    Pay with Card
                  </p>
                </div>
              </div>
            </ion-label>
            <ion-radio slot="start" value="card" [checked]="paymentSource == 'card'"></ion-radio>
          </ion-item>
          <ion-item class="payment-source-option cashapp">
            <ion-label>
              <div class="payment-source-desc">
                <div class="icon">
                  <span class="cashapp-logo"></span>
                </div>
                <div>
                  <p class="service">
                    Pay with Cash App
                  </p>
                </div>
              </div>
            </ion-label>
            <ion-radio slot="start" value="cashapp" [checked]="paymentSource == 'cashapp'"></ion-radio>
          </ion-item>

        </ion-radio-group>
      </ion-list>
    </div>

    <div *ngIf="paymentSourceSelected == 'cashapp'">
      <div id="address-element" #stripeAddressElement></div>

      <div id="payment-element" #stripePaymentElement></div>
      <div class="error" *ngIf="paymentErrorMsg">
        <p class="error-title">{{paymentErrorMsg}}</p>
        <p class="error-help">Please check card details or contact issuing bank.</p>
      </div>
      <ion-item class="save-payment"  *ngIf="!stripeLoading && !loading"
      >
        <ion-checkbox color="light" [(ngModel)]="savePayment"></ion-checkbox>
        <ion-label>Save this card for my letters & photos</ion-label>
      </ion-item>
      <ion-button
        class="submit-btn"
        type="submit"
        (click)="onPay()"
        [disabled]="!stripeValid || !addressValid || loading || stripeLoading"
      >
        <span *ngIf="loading"> <ion-spinner></ion-spinner><span>Processing...</span></span>
        <span *ngIf="!loading"> <span>Submit</span></span>
      </ion-button>
    </div>

    <div *ngIf="paymentSourceSelected == 'card'">
      <div id="checkout"></div>
    </div>

  </div>
</div>
