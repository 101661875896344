import * as Sentry from "@sentry/browser";

class ErrorHandler {

  captureMessage(label, context = {}) {
    Sentry.setContext(label, context);
    Sentry.captureMessage(label);
  }

  captureException(err, label= '', context = {}) {
    if (label && context) {
      Sentry.setContext(label, context);
    }
    Sentry.captureException(err);
  }
}


const handler = new ErrorHandler()
export default handler