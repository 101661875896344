<ion-header>
  <ion-toolbar>
     <ion-grid>
       <ion-row>
         <ion-col col size="5" *ngIf="back"> 
           <span>
             <a class="go-back"  (click)="onGoBack()" *ngIf="!hideBack && !showDoneWriting">
               <ion-icon name="arrow-back" ></ion-icon>
               Back
             </a>
           </span>
         </ion-col>
         <ion-col></ion-col>
         <ion-col>
           <div class="help-button" (click)="onShowHelpWidget()" *ngIf="showHelp"> 
             <ion-icon name="help-buoy"></ion-icon>
             <span class="text">HELP</span>
           </div>
         </ion-col>
 
       </ion-row>
     </ion-grid>
   </ion-toolbar>
   <div
     class="penmate-avatar"
   >
     <div class="icon-circle">
       <div class="inner-circle">
         <span *ngIf="penmate.initials && !penmate.image" class="initials">{{penmate.initials}}</span>
         <img *ngIf="penmate.image" [src]="penmate.image" />
         <ion-icon *ngIf="!penmate.initials && !penmate.image" name="ios-person" size="large"></ion-icon>
 
       </div>
     </div>
   </div>
 </ion-header>
 