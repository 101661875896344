<ion-header [ngClass]="{'emessage': !!currentMessage?.eMessage}">
  <ion-toolbar style="width: 100%;" >
    <div class="title-content">
      <h3>Send {{ penmate && penmate.first_name }} {{currentMessage?.eMessage ? 'an E-Message' : 'a Letter'}}</h3>
      <p>
       <span class="bold">Delivery to:</span> Inmate tablet or Mailroom
      </p>
    </div>
    <ion-icon name="trash" class="delete-message" (click)="onConfirmDeleteMessage()"></ion-icon>
  </ion-toolbar>
</ion-header>
<div class="message-avatar" [ngClass]="{'emessage': !!currentMessage?.eMessage}">
  <div class="icon-circle">
    <div class="inner-circle">
      <ion-icon name="paper-plane" size="large" *ngIf="currentMessage?.eMessage"></ion-icon>
      <ion-icon name="chatbubbles" size="large" *ngIf="!currentMessage?.eMessage"></ion-icon>

    </div>
  </div>
</div>
<ion-content [ngClass]="{'emessage': !!currentMessage?.eMessage}">
  <div class="page-content">
    <div class="current-emessage-facility" *ngIf="currentMessage?.eMessage">   
       <div id="facility-info">
          <div class="photo-message-artwork"></div>
          <p class="facility-title">{{currentMessage?.eMessage?.name}}</p>
          <p class="facility-address">{{currentMessage?.eMessage?.address}}</p>
          <div class="facility-details">
            <div class="facility-allowable">
               This facility allows: 
               <p class="photo-count">
                  E-Message + 4 Photos
               </p>

               <a  class="change-facility" (click)="onChangeEMessageFacility()">Change Location</a>

             </div>
          </div>
       </div>
    </div>
    <div class="message-actions">
      <button
        class="message-action edit-message"
        [ngClass]="{'has-message': currentMessage.message.length > 0, 'bg-notebook-paper': !currentMessage?.eMessage}"
        (click)="onEditMessage()"
      >
        <ion-grid>
          <ion-row class="header">
            <ion-col class="col-item" size="12" *ngIf="!currentMessage.message">
              <p class="button-text">Add a Message...</p>
            </ion-col>
          </ion-row>

          <div class="summary" *ngIf="currentMessage.summary">
            <p [innerHTML]="currentMessage.summary"></p>
            <p
              class="tap-hint"
              *ngIf="currentMessage.summary && currentMessage.summary.length > 10"
            >
              Tap to view full message
            </p>
          </div>
        </ion-grid>
      </button>
      <div class="message-action add-photos">
        <a class="attached-count" (click)="onShowSelectedPhotos()" *ngIf="hasPhotos()">
          {{selectedPhotos.length}} {{ selectedPhotos.length > 1 ? 'photos' : 'photo' }} added.
        </a>
        <ion-grid>
          <ion-row class="add-photo-thumbnails">
            <ion-col size="2">
              <ion-icon item-left name="md-photos" class="message-action-icon"></ion-icon>
            </ion-col>
            <ion-col size="10">
              <div class="add-photos-button" *ngIf="!hasPhotos()" (click)="onAddPhotos()">
                <p class="button-text">Add photos</p>
              </div>

              <div class="selected-photos" *ngIf="hasPhotos()">
                <ion-row>
                  <ion-col
                    size="3"
                    class="attached-photo-col"
                    *ngFor="let photo of selectedPhotos; let i = index"
                  >
                    <div class="attached-photo" *ngIf="i <= 2">
                      <a
                        [style.background-image]="'url(' + photo.url + ')'"
                        (click)="onShowSelectedPhotos()"
                        class="photo"
                      >
                      </a>
                    </div>
                  </ion-col>
                </ion-row>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="add-photo-buttons" *ngIf="hasPhotos()">
            <ion-col size="12">
              <ion-button icon-right (click)="onAddPhotos()">
                Add more
                <ion-icon name="ios-arrow-dropright-circle" class="icon"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="footer">
  <div id="message-navigation">
    <ion-grid>
      <ion-row>
        <ion-col size="6" class="back-button">
          <ion-button ion-button (click)="onSaveAndGoBack()" icon-right>
            <div class="button-inner">
              <ion-icon slot="start" name="ios-arrow-dropleft-circle"></ion-icon>
              Back
            </div>
          </ion-button>
        </ion-col>
        <ion-col size="6" class="continue-button">
          <ion-button icon-right id="message-continue-button" (click)="onSaveAndContinue()">
            <div class="button-inner">
              Continue
              <ion-icon slot="end" name="ios-arrow-dropright-circle"></ion-icon>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
