import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  NgZone,
  Inject,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Platform, AlertController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { get } from 'lodash';
import { AppState, AuthService, EventService, LetterService } from '../services';
import { MyPenmateActions, UserActions } from '../actions';
import { CreateMessageActions } from '../actions';

declare var Penmate;
declare var stripe;

import {
  StripeService,
  StripeFactoryService,
} from 'ngx-stripe';
import {loadStripe, Stripe, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';


@Component({
  selector: 'add-payment-sheet',
  templateUrl: 'add-payment-sheet.html',
  styleUrls: ['./add-payment-sheet.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddPaymentSheet implements OnInit, AfterViewInit {
  @ViewChild('stripePaymentElement', { static: false }) stripePaymentElement: ElementRef; 
  @ViewChild('stripeAddressElement', { static: false }) stripeAddressElement: ElementRef; 

  stripe: Stripe;
  paymentForm: FormGroup;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#585858',
        color: '#222',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#585858',
        },
      },
    },
  };
  paymentFieldsComplete = false;
  billingIntentClientSecret;
  paymentClientSecret;
  paymentMethodId;
  paymentErrorMsg;
  penmates = [];
  user;
  loading = false;
  stripeLoading = false;
  isCreditTopUp = false;
  currentMessage;
  savePayment = true;
  price;
  elements;
  cardHandler = this.onChange.bind(this);
  addressHandler = this.onChangeAddress.bind(this);
  addCredits = false;
  updatePayment = false;
  stripeForm;
  addressForm;
  stripeValid = false;
  addressValid = false;
  idToken;
  paymentDescription;
  paymentRemoved = false;
  setupIntent;
  letterId;
  isEmessageSetup;
  invoiceId;
  paymentSource;
  showPaymentSourceSelection = false;
  paymentSourceSelected;
  checkoutClientSecret;
  isCheckoutSession;
  checkoutSession;



  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public platform: Platform,
    public fb: FormBuilder,
    public authService: AuthService,
    public alertCtrl: AlertController,
    public letterService: LetterService,
    public myPenmateActions: MyPenmateActions,
    public eventService: EventService,
    public userActions: UserActions,
    public createMessageActions: CreateMessageActions,
    public store: Store<AppState>,
    private storage: Storage,
    private stripeService: StripeService,
    private stripeFactory: StripeFactoryService,
    private bottomSheetRef: MatBottomSheetRef<AddPaymentSheet>,
    private zone: NgZone,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit() {

    this.stripe = await loadStripe(Penmate.env.stripeKey);
    this.eventService.track('add-payment', {
      letter_id: this.data.letterId || this.data.invoiceId,
    });
    this.user = this.data.user;
    this.paymentDescription = this.data.paymentDescription;
    if (this.data.addCredits && this.data.currentMessage) {
      this.currentMessage = this.data.currentMessage;
      this.addCredits = true;
      this.paymentRemoved = !!this.data.paymentRemoved
      this.setupIntent = this.data.setupIntent;
      this.letterId = this.data.letterId;
    } else if (this.data.updatePayment) {
      this.currentMessage = this.data.currentMessage;
      this.updatePayment = true;
      this.letterId = this.currentMessage.id;
    } else {
      this.store
      .select(state => state.createMessage)
      .subscribe(currentMessage => {
        this.currentMessage = currentMessage;
        const creditsEnabled = this.currentMessage.meta.creditsEnabled;
        this.isEmessageSetup = /emessage/i.test(this.currentMessage.shippingMethod) && !creditsEnabled 
      });
    }
  }

  async ngAfterViewInit() {
    // this.stripeLoading = true;
    this.stripe = await loadStripe(Penmate.env.stripeKey);
    const url = new URL(window.location.href);
    const paymentIntentClientSecret = url.searchParams.get("setup_intent_client_secret") || url.searchParams.get("payment_intent_client_secret") ;
    const msgId = url.searchParams.get("messageId");
    this.storage.get('id_token').then(token => {
      this.idToken = token;
    })

    if (msgId && paymentIntentClientSecret) {
      this.paymentSourceSelected = 'cashapp';
      this.onSetupBillingIntent()
      this.stripeLoading = true;
      if (this.addCredits) {
        return this.authService.getPendingIntent().subscribe((invoice) => {
          this.currentMessage = invoice;
          this.currentMessage.returnAddress = invoice.return_address;
          this.stripe
          .retrieveSetupIntent(paymentIntentClientSecret)
          .then((result) => {
            this.zone.run(() => {
              const intentSucceeded = get(result, 'setupIntent.status', '').match(/succeeded/i)
              if (result.setupIntent && result.setupIntent.status && intentSucceeded) {
                this.stripeLoading = false
                this.onPaymentSuccess(result, this.currentMessage);
              } else {
                this.stripeLoading = false;
              }
            })
          })
        })
      }
      this.letterService.getLetterWithPenmate(msgId).subscribe(({ letter, penmate }) => {
        letter.penmate = penmate;
        this.currentMessage = letter;

        if (this.updatePayment) {
          return this.stripe.retrievePaymentIntent(paymentIntentClientSecret).then((result) => {
            this.zone.run(() => {
              const intentSucceeded = get(result, 'paymentIntent.status', '').match(/succeeded/i)
              if (result.paymentIntent && result.paymentIntent.status && intentSucceeded) {
                this.stripeLoading = false
                this.onPaymentSuccess(result, this.currentMessage);
              } else {
                this.stripeLoading = false;
                this.onPaymentError(result.paymentIntent.last_payment_error.message)
              }
            })
          })
        }

        this.stripe
        .retrieveSetupIntent(paymentIntentClientSecret)
        .then((result) => {
          this.zone.run(() => {
            const intentSucceeded = get(result, 'setupIntent.status', '').match(/succeeded/i)
            if (result.setupIntent && result.setupIntent.status && intentSucceeded) {
              this.stripeLoading = false
              this.onPaymentSuccess(result, letter);
            } else {
              this.stripeLoading = false;
            }
          })
        })
      }, () => {
        this.stripeLoading = false;
      }) 
    }
  }

  onChangePaymentSource(evt) {
    const paymentSource = get(evt, 'detail.value');
    this.store
    .select(state => state.createMessage)
    .take(1)
    .subscribe(currentMessage => {
      this.currentMessage = currentMessage;
      const creditsEnabled = this.currentMessage.meta.creditsEnabled;
      this.isEmessageSetup = /emessage/i.test(this.currentMessage.shippingMethod) && !creditsEnabled 
      this.paymentSourceSelected = paymentSource;
      if (paymentSource === 'cashapp') {
        this.stripeLoading = true;
        this.onSetupBillingIntent()
      }
      if (paymentSource === 'card') {
        this.onSetupCheckoutSession()
      }

    });

  }
  

  onSetupBillingIntent() {
    if (this.updatePayment) {
      return this.authService.updateBillingIntent(this.currentMessage.id).subscribe(async res => {
        this.stripeLoading = false;
        this.billingIntentClientSecret = res.client_secret;
        this.invoiceId = res.invoice_id;
        this.onSetupPaymentElement(this.billingIntentClientSecret)
      });
    }
    this.authService.setupBillingIntent().subscribe(async res => {
      this.stripeLoading = false;
      this.billingIntentClientSecret = res.client_secret;
      this.onSetupPaymentElement(this.billingIntentClientSecret)
    });
  }

  onSetupCheckoutSession() {
    const returnUrl = window.location.href.split('?')[0]
    const letterId = this.currentMessage ? this.currentMessage.id :  this.data.letterId;
    let confirmReturnUrl = `${returnUrl}?messageId=${letterId}`
    if (this.addCredits && letterId && letterId.length) {
      confirmReturnUrl = `${confirmReturnUrl}&letterId=${letterId}`
    }
    this.authService.createCheckoutSession({ returnUrl: confirmReturnUrl }).subscribe(res => {
      this.checkoutClientSecret = res.client_secret;
      this.onInitCheckout()
    })
  }

  async onInitCheckout() {
    this.stripeLoading = true;
    this.checkoutSession = await stripe.initEmbeddedCheckout({
      clientSecret: this.checkoutClientSecret,
      onComplete: async () => {
        const checkoutSessionId = get(this.checkoutSession, 'embeddedCheckout.checkoutSessionId');
        this.onCompleteCheckoutSession(checkoutSessionId);
      }
    });
    this.stripeLoading = false;
    this.checkoutSession.mount("#checkout");
  }

  async onCompleteCheckoutSession(sessionId) {
    const checkoutSessionId = sessionId || get(this.checkoutSession, 'embeddedCheckout.checkoutSessionId');
    this.authService.getCheckoutSession(checkoutSessionId).subscribe((data) => {
      if (data.setupIntent && data.setupIntent.id) {
        this.onPaymentSuccess(data)
      }
    })
  }
  
  onChange(stripeEvent) {
    if (stripeEvent) {
      this.stripeValid = stripeEvent.complete
    }
    this.cdr.detectChanges();
  }
  onChangeAddress(stripeEvent) {
    if (stripeEvent) {
      this.addressValid = stripeEvent.complete
    }
    this.cdr.detectChanges();
  }

  onSetupElementsForm(user, clientSecret, currentMessage) {
    const appearance = {}
    this.stripeLoading = false;
    this.elements = this.stripe.elements({clientSecret, appearance});
    this.stripeForm = this.elements.create('payment', {
      defaultValues: {
        billingDetails: {
          email: user.email,
        },
      },
    });

    const returnAddress: Record<string, any> = currentMessage.returnAddress || currentMessage.return_address || {}
    this.addressForm = this.elements.create('address', {
      mode: 'billing',
      fields: {
        phone: 'never',
      },
      defaultValues: {
        name: returnAddress.name,
        address: {
          line1: returnAddress.address_line1,
          city: returnAddress.address_city,
          state: returnAddress.address_state,
          postal_code: returnAddress.address_zip,
          country: 'US'
        }
      },
    })
    this.addressForm.mount(this.stripeAddressElement.nativeElement);
    this.stripeForm.mount(this.stripePaymentElement.nativeElement);
    this.stripeForm.addEventListener('change', this.cardHandler);
    this.addressForm.addEventListener('change', this.addressHandler);
  } 

  onSetupPaymentElement = async (clientSecret) =>  {
    if (this.addCredits || this.data.addCredits || (this.currentMessage && this.updatePayment)) {
      return this.onSetupElementsForm(this.user, clientSecret, this.currentMessage)
    } 
    this.store
    .select((state) => ({user: state.user, currentMessage: state.createMessage}))
    .take(1)
    .subscribe(({ user, currentMessage }) => {
      this.user = user;
      this.onSetupElementsForm(user, clientSecret, currentMessage)
    });
  }

  onStripeElementsChange(event) {
    this.paymentFieldsComplete = event.complete;
    this.paymentErrorMsg = null;
  }

  onCalculateTotal() {
    if (!this.currentMessage) {
      return;
    }
    const { shippingMethod } = this.currentMessage;
    const { basePrice, deliveryOptions, pricePerPage}  = get(this.currentMessage, 'meta', {});
    if (shippingMethod && deliveryOptions) {
      const showEmessageTotal = shippingMethod === 'emessage' &&  deliveryOptions['emessage']
      const price =  showEmessageTotal ? deliveryOptions['emessage'] : get(this.currentMessage, 'priceAfterCoupon');
      return price * 0.01;
    }
    return get(this.currentMessage, 'priceAfterCoupon') * 0.01;
  }

  onCalculateTax() {
    return this.onCalculateTotal() * 0.029 + 0.3;
  }

  onCalculateTotalWithTax() {
    return this.onCalculateTotal() + this.onCalculateTax();
  }

  onClose(event, currentMessage = null) {
    const eventParams = this.currentMessage ? { letter_id: this.currentMessage.id } : { letter_id: this.data.letterId, currMessageError: true}
    this.eventService.track('add-payment-cancel', eventParams);
    this.bottomSheetRef.dismiss(currentMessage);
  }

  onPay = () => {
    const eventParams = this.currentMessage ? { letter_id: this.currentMessage.id } : { letter_id: this.data.letterId, currMessageError: true}
    this.eventService.track('add-payment-start', eventParams)
    this.paymentErrorMsg = null;
    const returnUrl = window.location.href.split('?')[0]
    this.loading = true;
    let confirmReturnUrl = `${returnUrl}?messageId=${this.currentMessage.id}`
    if (this.addCredits && this.letterId && this.letterId.length) {
      confirmReturnUrl = `${confirmReturnUrl}&letterId=${this.letterId}`
    }
    if (this.updatePayment) {
      return this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: confirmReturnUrl,
        },
        redirect: 'if_required',
      }).then((res) => {
        this.loading = false;
        if (res.error){
          return this.onPaymentError(res.error)
        }
        if (res.paymentIntent) {
          this.onPaymentSuccess(res)
        }
      }).catch(error => {
        this.loading = false;
        this.onPaymentError(error)
      });
    }
    this.stripe.confirmSetup({
      elements: this.elements,
      confirmParams: {
        return_url: confirmReturnUrl,
      },
      redirect: 'if_required',
    }).then((res) => {
      this.loading = false;
      if (res.error){
        return this.onPaymentError(res.error)
      }
      if (res.setupIntent) {
        this.onPaymentSuccess(res)
      }
    }).catch(error => {
      this.loading = false;
      this.onPaymentError(error)
    });
  }

  onPaymentSuccess = (paymentIntent, message = undefined) => {
    const intentKey = this.updatePayment ? 'paymentIntent' : 'setupIntent';
    const paymentMethodId = get(paymentIntent, `${intentKey}.payment_method`);
    const paymentIntentId = get(paymentIntent, `${intentKey}.id`);
    const paymentIntentStatus = get(paymentIntent, `${intentKey}.status`)

    this.authService.getPaymentMethod(paymentMethodId).subscribe(data => {
      this.currentMessage = message || this.currentMessage;
      this.currentMessage.payment = { id: paymentMethodId, paymentIntentId, paymentIntentStatus, invoiceId: this.invoiceId, ...data };
      this.currentMessage.shippingMethod = this.currentMessage.shippingMethod;
      this.currentMessage.coupon = null;
      if (!this.addCredits && !this.data.addCredits && !this.updatePayment) {
        this.store.dispatch(this.createMessageActions.setMessage(this.currentMessage));
        this.store.dispatch(this.createMessageActions.saveDraft(this.currentMessage));
        this.authService.saveBilling(this.savePayment).subscribe();
      }
      this.loading = false;
      this.stripeLoading = false;
      if (this.checkoutSession) {
        this.checkoutSession.destroy()
      }
      if (this.addCredits || this.updatePayment) {
        return this.onClose(null, this.currentMessage);
      }
      const routeUrl = window.location.pathname
      this.router.navigateByUrl(`${routeUrl}?paymentSucceeded=true`).finally(() => {
        this.onClose(null, this.currentMessage);
      })
    })
  };

  onPaymentError = err => {
    this.paymentErrorMsg = `Payment Error: Invalid Payment`;
    if (err.message) {
      this.paymentErrorMsg = err.message || `Payment error: Card Declined`;
    }
    this.cdr.detectChanges();
    this.eventService.track('add-payment-error', {
      letter_id: get(this.data, 'letter_id'),
      error: err,
    });
  };
}