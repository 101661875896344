import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { ModalController, NavParams, Platform, AlertController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { get } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { LetterService, AppState, AuthService, EventService } from '../services';
import { LoadingModal } from '../loading-modal/loading-modal';
import { CreateMessageActions } from '../actions';
import { Store } from '@ngrx/store';
import { StripeCardComponent, StripeInstance, StripeFactoryService } from 'ngx-stripe';

import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';

declare var Card: any;
declare var Penmate: any;

/*
  Generated class for the PaymentPage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-app-upgrade-modal',
  styleUrls: ['./app-upgrade-modal.scss'],
  templateUrl: 'app-upgrade-modal.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppUpgradeModal {
  pageHeight;
  currentMessage;
  penmate;
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private platform: Platform,
    private letterService: LetterService,
    private authService: AuthService,
    private eventService: EventService,
    private alertCtrl: AlertController,
    private createMessageActions: CreateMessageActions,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private bottomSheet: MatBottomSheet,
  ) {
    this.store
      .select(state => state.createMessage)
      .subscribe(currentMessage => {
        this.currentMessage = currentMessage;
        this.penmate = currentMessage.penmate;
      });
  }

  onUpgrade = async () => {
    const openModal = await this.modalCtrl.getTop();
    this.eventService.track('premium-upgrade-conversion');
    return openModal.dismiss({ addCredits: true });
  };

  onCancel = async () => {
    const openModal = await this.modalCtrl.getTop();
    return openModal.dismiss();
  };
}
