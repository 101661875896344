import { Component, ViewEncapsulation, Input } from '@angular/core';
import { ModalController, NavParams, Platform, AlertController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { get, find } from 'lodash';
import { LoadingModal } from '../loading-modal/loading-modal';
import { LetterService, AppState, AuthService, EventService } from '../services';
import ISO_COUNTRY_CODES from './iso-phone-codes';
import { UserActions } from '../actions';

import { CreateMessageActions } from '../actions';
import { Store } from '@ngrx/store';
declare var Card: any;
declare var Penmate;
declare var Rollbar;
declare var Beacon;

/*
  Generated class for the PaymentPage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-account-verification-modal',
  styleUrls: ['./account-verification-modal.scss'],
  templateUrl: 'account-verification-modal.html',
  encapsulation: ViewEncapsulation.None,
})
export class AccountVerificationModal {
  pageHeight;
  paymentForm;
  currentMessage;
  cardNumber;
  countryCodes;
  phoneInputForm;
  codeInputForm;
  numberWithCountryCode;
  showCodeInput = false;
  currentVerificationId;
  user;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private alertCtrl: AlertController,
    private authService: AuthService,
    private userActions: UserActions,
    private store: Store<AppState>,
  ) {
    this.store
      .select(state => state.createMessage)
      .subscribe(currentMessage => {
        this.currentMessage = currentMessage;
      });
    this.store
      .select((state) => state.user)
      .subscribe((user) => {
        this.user = user;
      });
    this.onResetForm();
    this.countryCodes = ISO_COUNTRY_CODES;
    // this.selectedCountryName = 'United States';
  }

  ngOnInit() {
    setTimeout(() => {
      Beacon('init', Penmate.env.helpScoutId);
    }, 2000);
  }

  onRequestHelp =() => {
    const phoneNumber = get(this.phoneInputForm, 'value.phoneNumber');
    setTimeout(() => {
      Beacon('init', Penmate.env.helpScoutId);
      Beacon('prefill', {
        name: this.user.name,
        email: this.user.email,
        subject: `Verification Help - ${phoneNumber}`,
        text: `I'm having trouble verifying my account. My phone number is: ${phoneNumber}`
      })
      Beacon('open');
      Beacon('navigate', '/ask/message/');
    }, 500)
  }


  onDisplayErrorAlert = async (titleText, buttonText, subHeader, message) => {
    const alert = await this.alertCtrl.create({
      header: titleText,
      subHeader,
      message,
      buttons: [
        {
          text: buttonText,
        },
      ],
    });
    await alert.present();
  };

  onResetForm = () => {
    this.showCodeInput = false;
    this.phoneInputForm = this.formBuilder.group({
      selectedCountry: ['United States', Validators.required],
      phoneNumber: ['', Validators.compose([Validators.minLength(5), Validators.required])],
    });
    this.codeInputForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
  };

  onSendCode = async () => {
    const phoneNumber = get(this.phoneInputForm, 'value.phoneNumber');
    const selectedCountry = get(this.phoneInputForm, 'value.selectedCountry');
    const countryInfo = find(ISO_COUNTRY_CODES, code => code.Name === selectedCountry);
    let errorTitle = 'Invalid phone number';
    let errorMsg = 'Please enter a valid mobile / cell number';

    if (countryInfo) {
      this.numberWithCountryCode = `+${countryInfo.Dial}${phoneNumber}`;
      this.eventService.track('send-verification-code', { phoneNumber });
      return this.authService
        .createSMSVerification(this.numberWithCountryCode)
        .subscribe(
          ({ success = false }) => {
            if (success) {
              this.showCodeInput = true;
            } else {
              errorTitle = 'Verification Problem.';
              errorMsg = `Sorry, there is an issue verifying with your mobile carrier.
              Please try again or email us at <strong>
              <a href="mailto:support@penmateapp.com?subject=Verification Problem" title="Support email">support@penmateapp.com</a></strong>
              to finish activating your account.`;
              this.onDisplayErrorAlert(errorTitle, 'OK', '', errorMsg);
            }
          },
          ({ error }) => {
            this.eventService.track('phone-verification-number-invalid', { phoneNumber });
            if (error.duplicate_number) {
              errorTitle = 'Number already registered.';
              errorMsg = `Sorry, this number has already been registered.
              If this an error please email <strong>
              <a href="mailto:support@penmateapp.com?subject=Verification Problem" title="Support email">support@penmateapp.com</a></strong>`;
            }
            if (error.voip) {
              errorTitle = 'Verification Problem.';
              errorMsg = `Sorry, there is an issue verifying with your mobile carrier.
              Please email us at <strong>
              <a href="mailto:support@penmateapp.com?subject=Verification Problem" title="Support email">support@penmateapp.com</a></strong>
              to finish activating your account.`;
            }
            this.onDisplayErrorAlert(errorTitle, 'OK', '', errorMsg);
          },
        );
    }
    this.onDisplayErrorAlert(errorTitle, 'OK', '', errorMsg);
  };

  onSuccess = async () => {
    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: {
        message: 'Confirming...',
      },
    });
    await loadingModal.present();

    this.authService.authenticate().subscribe(async ({ user }) => {
      if (user) {
        this.store.dispatch(this.userActions.loginUser(user));
      }
      await loadingModal.dismiss();
      const openModal = await this.modalCtrl.getTop();
      return openModal.dismiss();
    });
  };

  onConfirmCode = async () => {
    const verificationCode = get(this.codeInputForm, 'value.code');

    if (verificationCode) {
      return this.authService
        .confirmSMSVerification(
          this.numberWithCountryCode,
          verificationCode
        )
        .subscribe(
          res => {
            if (res.success) {
              this.eventService.track('phone-verification-valid', res);
              return this.onSuccess();
            }
            this.eventService.track('phone-verification-code-invalid', res);
            this.onDisplayErrorAlert(
              'Invalid code',
              'OK',
              '',
              'The code you entered is invalid or has expired. Please make sure you entered the code correctly or request another code.',
            );
          },
          res => {
            this.eventService.track('phone-verification-code-invalid', res);

            if (res.duplicate_number) {
              return this.onDisplayErrorAlert(
                'Invalid phone number',
                'OK',
                '',
                'Please enter a valid phone number',
              );
            }
            this.onDisplayErrorAlert(
              'Invalid code',
              'OK',
              '',
              'The code you entered is invalid or has expired. Please make sure you entered the code correctly or request another code.',
            );
          },
        );
    }
    this.onDisplayErrorAlert(
      'Invalid code',
      'OK',
      '',
      'The code you entered is invalid or has expired. Please make sure you entered the code correctly or request another code.',
    );
  };

  onCancel = async () => {
    const openModal = await this.modalCtrl.getTop();
    Beacon('destroy');
    return openModal.dismiss();
  };
}
