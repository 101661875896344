import { NgModule } from '@angular/core';
import { Component } from '@angular/core';

import { PreloadAllModules, RouterModule, Routes, NoPreloading } from '@angular/router';
import { ScrollSpyModule } from 'ngx-scrollspy';

import * as pageComponents from './pages';

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: ':provider/callback', component: pageComponents.AuthCallback },
  { path: 'login', component: pageComponents.LoginPage },
  { path: 'login/confirm-email', component: pageComponents.LoginLinkCallback },
  { path: 'email/reset-password', component: pageComponents.ResetPasswordConfirm },
  { path: 'login/google', component: pageComponents.LoginPage },
  { path: 'magic/login', component: pageComponents.LoginPage },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'auth0/logout', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'find-an-inmate',
    redirectTo: 'add-penmate',
  },
  { path: 'add-penmate', component: pageComponents.AddPenmatePage },
  {
    component: pageComponents.AddPenmatePage,
    path: 'search',
  },
  {
    component: pageComponents.MyPenmatesPage,
    path: 'my-penmates',
  },
  { path: 'penmates', redirectTo: 'my-penmates', pathMatch: 'full' },
  {
    component: pageComponents.PenmateProfile,
    path: 'penmates/:id',
  },
  {
    component: pageComponents.MessageIndexPage,
    path: 'penmates/:id/messages',
  },
  {
    component: pageComponents.MessageShowPage,
    path: 'penmates/:id/messages/:message_id',
  },
  {
    component: pageComponents.MessagePaymentUpdate,
    path: 'billing/:id/payment',
  },
  {
    component: pageComponents.IGPhotoPicker,
    path: 'penmates/:id/new-message/instagram-photos',
  },
  {
    component: pageComponents.IGPhotoPicker,
    path: 'penmates/:id/new-postcard/instagram-photos',
  },
  {
    component: pageComponents.IGMyPhotosTab,
    path: 'penmates/:id/new-message/instagram-photos/me',
  },
  {
    component: pageComponents.IGMyPhotosTab,
    path: 'penmates/:id/new-postcard/instagram-photos/me',
  },
  {
    component: pageComponents.MessageCreatorPage,
    path: 'penmates/:id/new-message',
  },
  {
    component: pageComponents.MessageTextEditor,
    path: 'penmates/:id/new-message/edit-message',
  },
  {
    component: pageComponents.FileUploadModal,
    path: 'penmates/:id/new-message/upload',
  },
  {
    component: pageComponents.SelectedPhotosModal,
    path: 'penmates/:id/new-message/selected-photos',
  },
  {
    component: pageComponents.CreateMessageReturnAddress,
    path: 'penmates/:id/new-message/return-address',
  },
  {
    component: pageComponents.ConfirmOrderPage,
    path: 'penmates/:id/new-message/confirm-order',
  },
  {
    component: pageComponents.PostcardFileUploadModal,
    path: 'penmates/:id/new-postcard/upload',
  },
  {
    component: pageComponents.PostcardCreatorPage,
    path: 'penmates/:id/new-postcard',
  },
  {
    component: pageComponents.PostcardDesignPicker,
    path: 'penmates/:id/new-postcard/select-design',
  },
  {
    component: pageComponents.PostcardConfirmOrderPage,
    path: 'penmates/:id/new-postcard/confirm-order',
  },
  {
    component: pageComponents.AddCreditsPage,
    path: 'billing/add-credits',
  },
  {
    component: pageComponents.MyAccountPage,
    path: 'my-account',
  },
  {
    component: pageComponents.ConfirmOrderPage,
    path: 'messages/:message_id/send',
  },
  {
    path: 'search/for/:id',
    redirectTo: ':id',
  },
  {
    path: ':id',
    component: pageComponents.PenmateProfile,
  },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading }),
    ScrollSpyModule.forRoot(),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
