import { Component, ViewEncapsulation } from '@angular/core';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { get, trim } from 'lodash';
import { Router } from '@angular/router';
import ErrorHandler from '../../lib/error-handler'

import { LoginModal } from '../login-modal/login-modal';
import { LoadingModal } from '../loading-modal/loading-modal'

import { createSupabaseClient } from '../../lib/supabase'

import { AuthService, AppState, EventService, NativeClientService } from '../services';
declare var Penmate;
declare var google;
declare var Beacon

/*
  Generated class for the Login page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-login-page',
  templateUrl: 'login.page.html',
  styleUrls: ['./login.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginPage {
  modal: any;
  currentSearch;
  savedSearchResult;
  searchName;
  savedSearchQuery;
  supabase;
  scriptLoaded;
  googleNonce;
  loginError;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public store: Store<AppState>,
    private storage: Storage,
    private authService: AuthService,
    public alertCtrl: AlertController,
    private eventService: EventService,
    private nativeService: NativeClientService,
    private router: Router,
  ) {

    this.supabase = createSupabaseClient();

    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state;
    this.savedSearchQuery = get(state, 'searchQuery');

    if (this.savedSearchQuery) {
      const { first_name, last_name } = this.savedSearchQuery;
      this.searchName = `${first_name} ${last_name}`;
    }

    this.store
      .select(state => ({
        currentSearch: state.search.currentSearch,
        savedSearch: state.savedSearch,
      }))
      .subscribe(({ currentSearch, savedSearch }) => {
        this.currentSearch = currentSearch;
        this.savedSearchResult = get(savedSearch, 'savedSearchResult');
        if (this.savedSearchResult) {
          this.searchName = trim(
            this.savedSearchResult.first_name
              ? `${this.savedSearchResult.first_name} ${this.savedSearchResult.last_name}`
              : this.savedSearchResult.name,
          );
        }
      });
  }

  initGoogleLogin = async () => {
    let googleNonce;
    try {
      googleNonce = await this.storage.get('googleNonce');
      if (googleNonce) {
        this.googleNonce = JSON.parse(googleNonce);
      }
    } catch {}
    try { 
      if (!this.googleNonce && Penmate && Penmate.env.loginParams) {
        this.googleNonce = Penmate.env.loginParams;
        this.storage.set('googleNonce', JSON.stringify(this.googleNonce))
      }
    } catch {}
    this.handleAuthCallback();
  }

  handleAuthCallback = async () => {
    this.modal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Loading...' },
    });
    const isMagicLinkCallback = /magic\/login$/.test(this.router.url)
    const isGoogleCallback = /login\/google$/.test(this.router.url)
    if(!isMagicLinkCallback && !isGoogleCallback) return;
    try {
      await this.modal.present();
    } catch {}
    if (isGoogleCallback) {
      return this.handleGoogleCallback()
    }
  }

  handleGoogleCallback =  async () => {
    const isGoogleCallback = /login\/google$/.test(this.router.url)
    if (!isGoogleCallback) return;
    let skipNonce = false;
    try {
      skipNonce = await this.storage.get('skipLoginNonce') || false;
    } catch {}
    try {
      const loginParams = Penmate && Penmate.env ? Penmate.env.loginParams : {}
      const nonce = !skipNonce && this.googleNonce.nonce ? this.googleNonce.nonce : undefined;
      if (loginParams && loginParams.credential) {
        const { error } = await this.supabase.auth.signInWithIdToken({
          provider: 'google',
          token: loginParams.credential,
          nonce,
        })
        this.authService.handleAuthentication().subscribe(() => {
          this.authService.handleLoginNavigation(this.navCtrl).subscribe(() => {
            this.modalCtrl.getTop().then(modal => {
              modal.dismiss();
            });
          })
        });
        this.storage.remove('googleNonce');
        if (error) {
          return ErrorHandler.captureMessage("Google Login Error", { error });
        }
      }
    } catch (error) {
      ErrorHandler.captureMessage('Google Login Error', { error });
    }
  }

  ionViewDidEnter() {
    Beacon('destroy')
    if (this.nativeService.active) {
      return this.nativeService.sendMessage({ type: 'LOGOUT' });
    }
    this.initGoogleLogin()

    const parsedHash = new URLSearchParams(
      window.location.hash.substring(1) 
    );

    if (parsedHash && parsedHash.get('error_description')) {
      this.loginError = parsedHash.get('error_description')
      return this.onShowLoginError(this.loginError);
    }

    const url = new URL(window.location.href);
    const showSupport = url.searchParams.get("support");
    if (!!showSupport) {
      Beacon('init', Penmate.env.helpScoutId);
      Beacon('prefill', {
        subject: `Login Help`,
        fields: [
          {
            id: 51200,
            value: 274542
          },
        ]
      })
      Beacon('open');
      Beacon('navigate', '/ask/message/');
    }
  }

  async onShowLoginError(errorText) {
    const confirmRemove = await this.alertCtrl.create({
      header: 'Login Error',
      message: `${errorText}.   Please try again or contact support.`,
      buttons: [
        {
          text: 'Contact Support',
          handler: () => {
            Beacon('init', Penmate.env.helpScoutId);
            Beacon('open');
            Beacon('navigate', '/ask/message/');
          },
        },
        {
          text: 'Try again',
          cssClass: 'ok',
          handler: () => {
          },
        },
      ],
    });
    await confirmRemove.present();
    setTimeout(() => {
      Beacon('init', Penmate.env.helpScoutId);
    }, 1500);
  }

  login() {
    this.modal = this.modalCtrl.create({
      component: LoginModal,
      componentProps: { googleNonce: this.googleNonce }
    }).then(async (modal) => {
      await modal.present();
    })

  }
}
