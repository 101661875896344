<ion-header id="modal-header">
    <ion-toolbar>
        <div class="title-content">
            <ion-title>
                <span class="selected-count">{{ selectedCount || numOfPhotos }}</span> Photos
                attached
            </ion-title>
            <ion-buttons end>
                <ion-button ion-button icon-only (click)="goBack()" class="close-button">
                    <ion-icon name="close"></ion-icon>
                </ion-button>
            </ion-buttons>
        </div>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="page-content">
        <ion-grid id="photo-grid">
            <ion-row *ngFor="let row of photos; let rowIdx = index;">
                <ion-col size="6" *ngFor="let photo of row" class="photo-col">
                    <pm-photo-picker-photo
                        [photo]="photo"
                        [rowIdx]="rowIdx"
                        [confirmRemove]="true"
                        [allPhotos]="photos"
                        [showTogglePhoto]="showTogglePhoto"
                        [removeOnly]="true"
                    >
                    </pm-photo-picker-photo>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ion-content>
<ion-footer id="modal-footer">
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col size="12">
                    <ion-button icon-right (click)="goBack()">
                        <ion-icon name="arrow-back" mode="ios"></ion-icon>

                        Go back
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
