<ion-content>
  <div class="login-container">
    <ion-button class="back-btn" ion-button (click)="cancel()" icon-left>
      <div class="button-inner">
        <ion-icon name="arrow-back"></ion-icon>
        Back
      </div>
    </ion-button>
    <div class="logo"></div>
    <div class="login-box">
      <div [ngClass]="{'hidden': showEmailLoginSuccess }">
        <div *ngIf="showPasswordReset">
          <h3>Reset Password</h3>
          <p *ngIf="!showPasswordResetConfirmation">
            Enter your email to request a link to reset your password.
          </p>
        </div>

        <div class="social-logins" *ngIf="!showPasswordReset">
          <h4 class="social-login-title">Sign in With Google</h4>
          <div id="google-signin" #googleButton></div>
          <div id="or-divider">
            <div class="line"></div>
            <p>OR</p>
            <div class="line"></div>
          </div>
        </div>
        <form [formGroup]="emailLoginForm">
          <ion-item class="email-field name">
            <ion-label position="stacked">Email</ion-label>
            <ion-input
              type="text"
              placeholder="Sign in with email"
              formControlName="email"
              autoComplete="nope"
            ></ion-input>
          </ion-item>
          <p class="password-info add-password" *ngIf="isExistingUser">
            Please create a password to secure your account. 
          </p>
          <p class="password-info" *ngIf="showCreatePassword">
            Password must be at least 6 characters.
          </p>
          <ion-item class="input-field name" *ngIf="showPasswordLogin && !showPasswordReset || showCreatePassword">
            <ion-label position="stacked">
              {{showCreatePassword ? 'Create Password' :  'Password'}}
            </ion-label>
            <ion-input
              type="password"
              placeholder="Enter password"
              formControlName="password"
              autoComplete="nope"
            ></ion-input>
          </ion-item>
          <div *ngIf="showInvalidLogin && !showPasswordReset">
            <p class="invalid-login">
              Invalid email or password
            </p>
            <a class="reset-password-request" (click)="onShowPasswordReset()">
              Forgot Password?
            </a>
          </div>

          <ion-item class="input-field name" *ngIf="showCreatePassword">
            <ion-label position="stacked">Confirm Password</ion-label>
            <ion-input
              type="password"
              placeholder="Enter password"
              formControlName="passwordConfirmation"
              autoComplete="nope"
            ></ion-input>
          </ion-item>
          <ion-button 
            class="submitBtn" 
            ion-button
            icon-right 
            (click)="onSubmitEmailLogin()"
            *ngIf="!showCreatePassword && !showPasswordReset && !showPasswordLogin"
          >
            <span *ngIf="!loading">
              <div class="button-inner">
              Sign in with Password
              </div>
            </span>
            <ion-spinner *ngIf="loading"></ion-spinner>
          </ion-button>
          <ion-button 
            class="submitBtn" 
            ion-button
            icon-right 
            (click)="onLoginWithPassword()"
            *ngIf="!showCreatePassword && !showPasswordReset && showPasswordLogin"
          >
            <span *ngIf="!loading">
              <div class="button-inner">
              Sign in
              </div>
            </span>
            <ion-spinner *ngIf="loading"></ion-spinner>
          </ion-button>
            <ion-button 
              class="submitBtn" 
              ion-button
              icon-right 
              (click)="onSignUpWithEmail()"
              [disabled]="!isEmailSignupValid()"
              *ngIf="showCreatePassword && !isExistingUser"
              >
              <span *ngIf="!loading">
                <div class="button-inner">
                 Create account
                </div>
              </span>
              <ion-spinner *ngIf="loading"></ion-spinner>
            </ion-button>
            <div *ngIf="showPasswordReset && showPasswordResetConfirmation">
              <p style="text-align:center;">
                Please check your email for a link to reset your password.
              </p>
            </div>

            <ion-button 
              class="submitBtn" 
              ion-button
              icon-right 
              (click)="onSubmitPasswordReset()"
              [disabled]="!emailLoginForm.valid"
              *ngIf="showPasswordReset && !showPasswordResetConfirmation"
            >
              <span *ngIf="!loading">
                <div class="button-inner">
                  Reset Password
                </div>
              </span>
              <ion-spinner *ngIf="loading"></ion-spinner>
            </ion-button>

            <ion-button 
              class="submitBtn" 
              ion-button
              icon-right 
              (click)="onConfirmPasswordReset()"
              [disabled]="!emailLoginForm.valid"
              *ngIf="showPasswordReset && showPasswordResetConfirmation"
            >
              <span *ngIf="!loading">
                <div class="button-inner">
                  Ok
                </div>
              </span>
              <ion-spinner *ngIf="loading"></ion-spinner>
            </ion-button>
        </form>

      </div>

      <div [ngClass]="{'hidden': !showEmailLoginSuccess }" class="email-login-success">
        <div>
          <h3 class="title"> Check your Email! </h3>
          <p>
            We've sent a login link to you at
            <br />
            <span class="email">{{emailLoginForm.value.email}}</span>
            <br/>
          </p>
          <p class="otp-title bold">
            Tap the link to confirm your email and log in.
          </p>
        </div>
        <div class="resend">
          <p>Didn't get the email?</p>
          <ion-button 
          class="submitBtn" 
          ion-button
          icon-right 
          (click)="onResetEmailLogin()"
        >
          <div class="button-inner">
            Try again
          </div>
        </ion-button>
        </div>
      </div>
    </div>
  </div>
  <div class="triangleBg"></div>
</ion-content>
