import { Component, ViewEncapsulation } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { AddPenmatePage, MyPenmatesPage, LoginPage, MyAccountPage } from '../pages';
import { Store } from '@ngrx/store';
import { AppState, AuthService, EventService } from '../services';
import { MyPenmateActions, UserActions } from '../actions';
declare var Beacon;

@Component({
  selector: 'app-footer-nav',
  templateUrl: 'app-footer-nav.html',
  styleUrls: ['./app-footer-nav.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppFooterNav {
  penmates = [];
  user;

  constructor(
    public navCtrl: NavController,
    public platform: Platform,
    public authService: AuthService,
    public myPenmateActions: MyPenmateActions,
    public eventService: EventService,
    public userActions: UserActions,
    public store: Store<AppState>,
  ) {
    platform.ready().then(() => {
      this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
        if (isAuthenticated && user) {
          this.user = user;
          this.store.dispatch(this.userActions.loginUser(user));
          this.store.dispatch(this.myPenmateActions.loadPenmates());
        }
      });
      this.store
        .select(state => state.myPenmates.penmates)
        .subscribe(penmates => {
          this.penmates = penmates;
        });
    });
  }

  gotoAddPenmate() {
    this.navCtrl.navigateForward('/find-an-inmate');
    this.eventService.track('navigate-search');
    Beacon('destroy');
  }
  gotoPenmates() {
    if (this.penmates.length <= 0) {
      return;
    }
    Beacon('destroy');
    this.navCtrl.navigateForward('/penmates');
    this.eventService.track('navigate-my-penmates');
  }

  gotoMyAccount() {
    this.navCtrl.navigateForward('/my-account');
    this.eventService.track('navigate-my-account');
  }
}
