import { ActionReducer, Action } from '@ngrx/store';
import { SearchActions, CustomAction } from '../actions';
import { Search } from '../models/search';

const initialState: Search = {
  isLoading: false,
  availableStates: [],
  currentSearch: null,
  hasError: false,
  error: null,
  selectedSearchResult: null,
  savedSearchResult: null,
};

export function searchReducer(state = initialState, action: CustomAction): Search {
  switch (action.type) {
    case SearchActions.LOAD_AVAILABLE_STATES:
      const availableStates = action.payload;
      availableStates.sort().unshift('Federal');
      return Object.assign({}, state, { availableStates });

    case SearchActions.CLEAR_SEARCH:
      return Object.assign({}, state, {
        isLoading: false,
        selectedSearchResult: null,
        currentSearch: null,
      });

    case SearchActions.SEARCH_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        selectedSearchResult: null,
        currentSearch: action.payload,
      });

    case SearchActions.SEARCH_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        currentSearch: Object.assign({}, state.currentSearch, action.payload),
      });

    case SearchActions.SEARCH_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        hasError: true,
        error: action.payload,
      });

    case SearchActions.SAVE_SEARCH_RESULT:
      return Object.assign({}, state, {
        savedSearchResult: action.payload,
      });

    case SearchActions.VIEW_SEARCH_RESULT_SUCCESS:
      return Object.assign({}, state, {
        selectedSearchResult: action.payload,
        hasError: false,
      });

    default: {
      return state;
    }
  }
}
