<edit-message-navbar
  *ngIf="currentMessage"
  [penmate]="penmate"
  [back]="onGoBack"
  [showHelp]="showHelp"
></edit-message-navbar>
<ion-content>
  <div class="emessage-status" *ngIf="!currentMessage?.eMessage && !isReply">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4>
              Your {{currentMessage?.messageType}}
              <span *ngIf="currentMessage?.status === 'processing' && !currentMessage?.delivery_date"> is processing  📤</span>
              <span *ngIf="currentMessage?.status === 'ready_to_be_mailed'">is awaiting pickup 🚚</span>
              <span *ngIf="currentMessage?.status ==='mailed'">
                <span *ngIf="!currentMessage?.shipment_id">
                  <span>has been delivered 🙌</span>
                </span>
                <span *ngIf="currentMessage?.shipment_id">
                  <span *ngIf="!currentMessage?.delivery_date && !currentMessage?.tracking_history.delivery_completed">is on the way 🚀</span>
                  <span *ngIf="currentMessage?.delivery_date || !!currentMessage?.tracking_history.delivery_completed">has been delivered 🙌</span>
                </span>
              </span>
            </h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <p *ngIf="currentMessage?.status === 'processing' && !currentMessage?.delivery_date && !currentMessage?.tracking_history.delivery_completed">
            We're processing and preparing your order to ship.  Once your message is sent you will receive an e-mail notification.
          </p>
          <p *ngIf="currentMessage?.status === 'ready_to_be_mailed'">
            Your message has finished processing and is awaiting USPS pickup.
          </p>
          <p *ngIf="currentMessage?.status ==='mailed' && !currentMessage?.delivery_date && !currentMessage?.tracking_history.delivery_completed">
            Your message is on the way and will deliver soon!
          </p>
          <p *ngIf="currentMessage?.status ==='mailed' && (currentMessage?.delivery_date || !!currentMessage?.tracking_history.delivery_completed)">
            Your message has been delivered to the facility.
          </p>
          <p class="tracking-link">
            <a (click)="onShowDeliveryManager()">Tracking / Delivery Info </a>
            <ion-icon name="ios-arrow-forward"></ion-icon>
          </p>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="emessage-status" *ngIf="currentMessage?.eMessage">
   

    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              <h4>
                Your message
                <span *ngIf="currentMessage.status === 'processing'"> 
                 
                  <span *ngIf="showAddCredits"> has been approved.</span>
                  <span *ngIf="!showAddCredits"> is processing  📤</span>
                </span>
                <span *ngIf="!currentMessage.status === 'ready_to_be_mailed'">is on the way 🚀</span>

                <span *ngIf="currentMessage.status === 'mailed'">
                  <span *ngIf="currentMessage?.shipment_id">
                    <span *ngIf="!currentMessage?.delivery_date && !currentMessage?.tracking_history.delivery_completed">is on the way 🚀</span>
                    <span *ngIf="currentMessage?.delivery_date || !!currentMessage?.tracking_history.delivery_completed">has been delivered 🙌</span>
                  </span>
                  <span *ngIf="!currentMessage?.shipment_id">
                    has been delivered 🙌
                  </span>
                </span>
                <span *ngIf="currentMessage.status === 'ready_to_be_mailed'"> 
                  is on the way 🚀
                </span>
                <br/>
              </h4>            
              <p class="message-details">Tap for more details  <ion-icon class="arrow" name="arrow-down"></ion-icon></p>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p *ngIf="currentMessage?.status === 'processing'">
          <span *ngIf="currentMessage.eMessage">
            <span *ngIf="!!user.messageCreditBalance">
              <span *ngIf="showAddCredits">
                Add credits to your account to send messages and photos.
              </span>
              <span *ngIf="!showAddCredits">
                Your message is awaiting approval. Once your message is sent you will receive a confirmation email. Any replies you receive will be sent to your email.
              </span>
            </span>
            <span *ngIf="!user.messageCreditBalance">
              <span *ngIf="showAddCredits">
                Your messaging account has been approved. Add credits to your account to send messages and photos.
              </span>
              <span *ngIf="!showAddCredits">
                <span *ngIf="user.messageCreditBalance > 0">
                  Your message is processing.  Once your message is sent you will receive a confirmation email. Any replies you receive will be sent to your email.
                </span>
                <span *ngIf="!user.messageCreditBalance || user.messageCreditBalance <= 0">
                  We're verifying your delivery details. Once your message is sent you will receive a confirmation email. Any replies you receive will be sent to your email.
                </span>
              </span>
            </span>

          </span>
          <span *ngIf="!currentMessage.eMessage">
            We're verifying your delivery details. Once your message is sent you will receive a confirmation email. Any replies you receive will be sent to your email.
          </span>
          
        </p>
        <p *ngIf="currentMessage?.status === 'ready_to_be_mailed'">
          <span>
            Your message is currently processing and will be sent out soon.
          </span>
        </p>
        <ng-container *ngIf="currentMessage?.status === 'mailed'">
          <p *ngIf="!currentMessage?.meta.emessageShipment">
            Your e-message has been sent. Please allow 24-48 hours for approval. 
            Any replies you receive will be sent to your email and will also be shown in your recent<a (click)="onGoBack()">recent messages</a>. 
            <span *ngIf="currentMessage?.shipment_id">
              <br/> <br/> 
              If your message is unable to be delivered by e-message it will be delivered via USPS First-Class mail 
              and you can track delivery below.
            </span>
          </p>
          <p *ngIf="currentMessage?.meta.emessageShipment" class="eShipment">
            <span *ngIf="!currentMessage?.delivery_date && !currentMessage?.tracking_history.delivery_completed">
              <strong style="display:block; text-align:center; margin-bottom: 5px;">Your message has been mailed via USPS First-Class.</strong> 
                E-delivery was not approved for your recipient and we're required to deliver your message via USPS.
                <br/><br/>
                <span *ngIf="currentMessage?.meta.letterCreditBalance > 0">
                  Credits have been added to your account and will be automatically applied when you send messages and photos.
                </span>
              You can <a (click)="onShowDeliveryManager()">track your delivery</a> of this message and you will also receive a notification
              once delivery has been completed.    
            </span>

            <span *ngIf="currentMessage?.delivery_date || !!currentMessage?.tracking_history.delivery_completed">
                E-delivery was not available for your recipient and your delivery has been completed via USPS First Class.
                <br/><br/>
                You can view your USPS tracking scans by visiting your <a (click)="onShowDeliveryManager()">Tracking Page</a>
            </span>
          </p>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div id="addCredits" *ngIf="currentMessage && showAddCredits">
    <h2 class="title"> Your messaging account has been approved 🎉 </h2>
    <p> Add credits to send messages to {{currentMessage?.inmate.name}}</p>
    <button
      ion-button
      (click)="onAddCredits()"
      icon-left
    >
      <ion-icon name="star"></ion-icon>
      <span>
        &nbsp; Add Credits
      </span>
    </button>
  </div>
  <div
    id="new-message-editor"
    *ngIf="currentMessage"
    class="bg-notebook-paper message-editor-container"
    [ngClass]="{
      'has-attached': selectedPhotos && selectedPhotos.length > 0,
      'postcard-show': currentMessage?.order_type === 'postcard' && !currentMessage?.eMessage}"
  >
    <header class="header">
      <ion-row class="header-row">
        <ion-col col-8>
          <div id="return-address" *ngIf="currentMessage?.from_address || isReply">
            <p class="time-ago">{{currentMessage.timestamp | amDateFormat:'MM/DD/YY'}}</p>
            <p>
              <span class="from-label" *ngIf="!isReply">To: {{ penmate.name }} </span>
              <span class="to-label">
                From: {{ isReply ? currentMessage?.inmate.name : currentMessage?.from_address.name }} </span>
            </p>
          </div>
        </ion-col>
        <ion-col col-4>
          <div id="order-status" *ngIf="currentMessage?.status">
            <span class="label">Status: </span>
            <span *ngIf="currentMessage.shipment_id">
                <span class="status" *ngIf="!currentMessage.delivery_date && !currentMessage?.tracking_history.delivery_completed"
                  >{{ currentMessage.status == 'ready_to_be_mailed' ? 'Processing' :
                  currentMessage.status }}
                </span>
                <span class="status" *ngIf="currentMessage.delivery_date || !!currentMessage?.tracking_history.delivery_completed">Delivered </span>
            </span>
            <span *ngIf="currentMessage?.eMessage && !currentMessage.shipment_id">
                <span class="status" *ngIf="currentMessage.status != 'mailed'">Processing</span>
                <span class="status" *ngIf="currentMessage.status == 'mailed'">Sent</span>
            </span>
          </div>
          <div id="order-tracking" *ngIf="currentMessage && currentMessage.shipment_id">
            <a *ngIf="currentMessage && !isReply" (click)="onShowDeliveryManager()">
              Tracking / Delivery &#xbb;
            </a>
          </div>
        </ion-col>
      </ion-row>
    </header>
    <ion-list>
      <div *ngIf="currentMessage?.order_type === 'letter' || currentMessage?.eMessage || isReply">
        <div id="message-content" class="edit">
          {{isReply ? currentMessage?.text : currentMessage?.message }}
        </div>
      </div>
      <div *ngIf="currentMessage?.videos && currentMessage?.videos.length > 0">
        <div style="max-width: 100%; margin-bottom: 10px;" *ngFor="let video of currentMessage.videos;">
          <video controls width="100%" #videoPlayer>
            <source src="{{video.url}}" type="video/mp4">
          </video>
        </div>
      </div>
    </ion-list>
  </div>
  <ion-list *ngIf="currentMessage?.order_type === 'postcard' && !currentMessage?.eMessage">
    <div>
      <div id="edit-postcard">
        <div *ngIf="!currentPostcard?.frontImage && !currentMessage?.message">
          <h3 class="postcard-create-title">Create a Postcard</h3>
          <div class="postcard-back-template"></div>
        </div>
        <div
          id="select-design"
          class="postcard-details"
          [ngClass]="{'hasImage': currentPostcard?.frontImage}"
        >
          <p class="title">Your Postcard design</p>
          <div class="postcard-design" *ngIf="currentPostcard?.frontImage">
            <div
              class="front-image"
              [style.background-image]="'url(' + currentPostcard?.frontImage?.url + ')'"
            ></div>
          </div>
        </div>
        <div id="your-message" class="postcard-details">
          <p class="preview-label">Preview</p>

          <div class="postcard-back-template" *ngIf="currentMessage?.summary">
            <p class="postcard-text" [innerHTML]="currentMessage?.message"></p>
          </div>

          <p class="title">Your message</p>
          <p class="message-summary" [innerHTML]="currentMessage?.message"></p>
        </div>
      </div>
    </div>
  </ion-list>
</ion-content>

<ion-footer *ngIf="(currentMessage?.order_type !== 'postcard' || currentMessage?.eMessage) && !isReply || isReply && selectedPhotos.length > 0">
  <div id="message-actions">
    <button
      ion-button
      (click)="onShowSelectedPhotos()"
      icon-left
      [ngClass]="{'disabled': !selectedPhotos || selectedPhotos && selectedPhotos.length <= 0}"
    >
      <ion-icon name="ios-camera"></ion-icon>
      <span *ngIf="selectedPhotos && selectedPhotos.length > 0">
        {{selectedPhotos.length}} {{ selectedPhotos.length > 1 ? 'photos' : 'photos' }} added
      </span>
      <span *ngIf="!selectedPhotos || selectedPhotos && selectedPhotos.length == 0">
        No photos added
      </span>
    </button>
  </div>
</ion-footer>
