<ion-content>
  <div class="container">
    <div class="bg-circle left"></div>
    <div class="bg-circle right"></div>

    <ion-button (click)="onCancel()" class="close-button">
      <ion-icon name="close" class="close-icon"></ion-icon>
    </ion-button>

    <div class="inner">
      <div class="message-artwork-wrapper">
        <div class="message-artwork"></div>
      </div>
      <h1 class="title">
        E-Message
        <span class="title-2">
          <span *ngIf="!changeFacilityOnly">Send a message directly to their inbox.</span>
          <span *ngIf="changeFacilityOnly">Change Facility</span>

        </span>
      </h1>
      <div class="details">
        <p class="subtitle">
           <span *ngIf="!changeFacilityOnly">Send an e-message and deliver letters, photos, and e-cards in minutes.</span>
           <span *ngIf="changeFacilityOnly">Update the facility location below:</span>

        </p>

        <span *ngIf="!changeFacilityOnly">
          <p class="subtitle subtitle-2">How it works</p>
          <div class="detail-row shipping">
            <div class="step-circle">
              <div class="step step1"></div>
            </div>
            <ol>
              <li>
                <p class="text">
                  Select a Correctional Facility
              </li>
              <li>
                <p class="text">
                 Create your message & add photos
                </p>
              </li>
            </ol>
          </div>
          <div class="detail-row shipping">
            <div class="step-circle">
              <div class="step step2"></div>
            </div>

            <ol>
              <li value="3">
                <p class="text">
                  Your message is sent to your loved one's email, tablet, or facility mailroom.
                </p>
              </li>

            </ol>
          </div>
        </span>


      </div>
        <p class="subtitle subtitle-2" *ngIf="!changeFacilityOnly">
           Choose a Facility:
        </p>
        <form
          novalidate
          id="facility-form"
          [formGroup]="facilityForm"
        >
          <ion-item class="state-select" no-padding>
            <ion-label
              class="facility-search-label"
            >
              Select State
            </ion-label>

            <ion-select formControlName="selectedState" name="state">
              <ion-select-option *ngFor="let stateName of US_STATE_LIST"
                >{{stateName?.name}}</ion-select-option
              >
            </ion-select>
          </ion-item>
          <div
            class="facility-autocomplete"
          >
            <ion-label class="facility-search-label">Enter name of Jail or Prison</ion-label>
            <ng-select
              [items]="facilities$ | async"
              bindLabel="name"
              readonly="{{!facilityForm.value?.selectedState}}"
              [trackByFn]="trackByFn"
              [minTermLength]="3"
              [dropdownPosition]="'top'"
              [loading]="facilitiesLoading"
              [typeahead]="facilityInput$"
              [addTag]="false"
              addTagText="Search"
              formControlName="selectedFacility"
              (open)="onFacilitySearchFocus()"
              [notFoundText]="onFacilityNotFound()"
              placeholder="{{searchPlaceholder}}"
              (close)="onFacilitySearchSelect()"

            >
            </ng-select>
          </div>
        </form>


        <div id="selected-facility" [ngClass]="{'selected': !facilitySearchFocus && facilityForm.value?.selectedFacility?.name}">
          
           <div id="facility-info" *ngIf="!facilitySearchFocus && facilityForm.value?.selectedFacility?.name">
              <div class="photo-message-artwork"></div>
              <p class="facility-title">{{facilityForm.value?.selectedFacility?.name}}</p>
              <p class="facility-address">{{facilityForm.value?.selectedFacility?.emessaging?.address}}</p>
              <div class="facility-details">
                <div class="facility-allowable">
                   <p class="photo-count">
                    E-Message + 4 Photos
                   </p>
                   <p>
                   <span class="bold">Delivery to:</span> Inmate tablet or Mailroom
                 </p>
                 </div>
              </div>
           </div>
           <div id="paymentCta"  *ngIf="!facilitySearchFocus && facilityForm.value?.selectedFacility?.name">
             <ion-button (click)="onDismissModal()" ion-button icon-right class="upgradeBtn">
               <span>
                 <div class="button-inner">
                   <span *ngIf="!changeFacilityOnly">Send an E-Message</span>
                   <span *ngIf="changeFacilityOnly">Change to this Facility</span>

                   <ion-icon name="ios-arrow-dropright-circle"></ion-icon>
                 </div>
               </span>
             </ion-button>
             <p class="subtitle decline" *ngIf="!changeFacilityOnly"> <a (click)="onCancel()">... or send a Letter instead</a></p>
           </div>

        </div>


    </div>
  </div>
</ion-content>
