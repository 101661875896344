import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { ModalController, NavParams, Platform, AlertController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { get, isEmpty, debounce } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SearchService, LetterService, AppState, AuthService, EventService } from '../services';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  take,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { LoadingModal } from '../loading-modal/loading-modal';
import { CreateMessageActions } from '../actions';
import { Store } from '@ngrx/store';
import US_STATES from '../add-penmate/us-states'


declare var Penmate: any;


const EXCLUDED_STATES =  ["AK", "AS", "AR", "DE", "DC", "FM", "GU", "HI", "IA", "KS", "MH", "MS", "NM", "PW", "PR", "RI", "SC", "SD", "VT", "VI", "WV"]
/*
  Generated class for the PaymentPage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'pm-email-delivery-modal',
  styleUrls: ['./email-delivery-modal.scss'],
  templateUrl: 'email-delivery-modal.html',
  encapsulation: ViewEncapsulation.None,
})
export class EmailDeliveryModal {
  @Input() changeFacilityOnly;
  pageHeight;
  currentMessage;
  penmate;
  facilities$: Observable<any>;
  facilitiesLoading = false;
  facilitySearchFocus = false;
  facilityInput$ = new Subject<string>();
  US_STATE_LIST = US_STATES.map(({ name, abbrev }) => ({ name, abbrev}));
  facilityForm;
  searchPlaceholder = "Enter name of facility"
  onHandleLogging;


  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private platform: Platform,
    private letterService: LetterService,
    private searchService: SearchService,
    private authService: AuthService,
    private eventService: EventService,
    private alertCtrl: AlertController,
    private createMessageActions: CreateMessageActions,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private bottomSheet: MatBottomSheet,
  ) {



    this.store
      .select(state => state.createMessage)
      .subscribe(currentMessage => {
        this.currentMessage = currentMessage;
        this.penmate = currentMessage.penmate;
      });
    
    const penmateData = this.navParams.get('penmateData');
    let defaultStateName;
    if (penmateData && penmateData.state) {
      const defaultStateData = US_STATES.find((stateData) => stateData.abbrev == penmateData.state.toUpperCase());
      defaultStateName = defaultStateData ? defaultStateData.name : null;
    }
    this.facilityForm = this.fb.group(
      {
        selectedState: [defaultStateName, Validators.required],
        selectedFacility: [this.currentMessage.eMessage || {}, Validators.required],
      },
    );

    this.onHandleLogging = debounce(this._onHandleLogging, 5000, { trailing: 'true'})

  }

  ngOnInit() {
    this.US_STATE_LIST = US_STATES.map(({ name, abbrev }) => ({ name, abbrev}));
    this.loadFacilities();
    this.eventService.track('emessage-modal')
  }

  trackByFn(item: any) {
    return item.name;
  }


  onDismissModal = async () => {
    const openModal = await this.modalCtrl.getTop();
    const facility = get(this.facilityForm, 'value.selectedFacility');
    this.eventService.track('emessage-select-facility', { facility })
    return openModal.dismiss({ eMessage: facility });
  };

  onCancel = async () => {
    const openModal = await this.modalCtrl.getTop();
    this.eventService.track('emessage-cancel')
    return openModal.dismiss({ cancel: true});
  };

  onFacilitySearchFocus = () => {
    this.facilitySearchFocus = true;
  }

  onFacilitySearchSelect = () => {
    this.facilitySearchFocus = false;
  }

  onFacilityNotFound =() => {
    if (this.facilityForm.value.selectedState) {
      return 'Facility not found. Check correct state and spelling.'
    }
    return 'Not found. Select a state first.'
  }

  _onHandleLogging = (data) => {
    this.eventService.track('emessage-search', data)
  }

  private loadFacilities() {
    this.facilities$ = concat(
      of([]), // default items
      this.facilityInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => (this.facilitiesLoading = true)),
        switchMap(term => {
          const { selectedState} = get(this.facilityForm, 'value', {});
          const result = US_STATES.find(({ name}) => name === selectedState)
          if (isEmpty(term) || isEmpty(result)) {
            this.facilitiesLoading = false;
            return of([]);
          }
          const { abbrev: state } = result;
          return this.searchService.findFacility({ q: term, state, email: true }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.facilitiesLoading = false;
              this.searchPlaceholder = '';
            }),
          );
        }),
      ),
    );
  }
}
