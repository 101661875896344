import { Component, ViewEncapsulation, Input } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { get, orderBy, compact } from 'lodash';

// import { map as _map } from 'lodash';
import { map } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { PopoverController, ModalController } from '@ionic/angular';

@Component({
  selector: 'pm-message-tracking-modal',
  templateUrl: 'message-tracking-modal.html',
  styleUrls: ['./message-tracking-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageTrackingModal {
  penmate;
  navCtrl;
  trackingHistory;
  currentMessage;
  estDeliveryDate;
  deliveryWindowStart;
  deliveryWindowEnd;
  isPostcard;
  state$;

  constructor(
    private navParams: NavParams,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,

    public modalCtrl: ModalController,
  ) {
    this.state$ = this.route.paramMap.pipe(map(() => window.history.state));
    this.currentMessage = this.navParams.get('currentMessage');
    this.isPostcard = this.currentMessage.messageType === 'postcard';
    this.trackingHistory = this.currentMessage.tracking_history;
    if (this.trackingHistory) {
      this.estDeliveryDate = moment(this.trackingHistory.est_delivery_date).format('ddd, MMM D');
      if (this.trackingHistory.show_delivery_window) {
        this.deliveryWindowStart = moment(this.trackingHistory.delivery_window_start).format('ddd, MMM D');
        this.deliveryWindowEnd = moment(this.trackingHistory.delivery_window_end).format('ddd, MMM D');
      }
    }
    if (this.trackingHistory.tracking_details) {
      this.trackingHistory.tracking_details = compact(
        orderBy(this.trackingHistory.tracking_details, 'datetime', 'desc'),
      );
      // code...
    }

    this.penmate = this.navParams.get('penmate');
    this.navCtrl = this.state$.navCtrl;
  }

  onCloseTracking = () => {
    this.modalCtrl.dismiss();
  };

  createMomentObj = date => {
    return moment(date);
  };

  showTrackingState = state => {
    if (state === 'in_transit' || state === 'processing') {
      return !!get(this.trackingHistory, 'status').match(
        /in_transit|processing|mailed|delivery|delivered/i,
      );
    }
    if (state === 'out_for_delivery' || state === 'ready_to_be_mailed') {
      return !!get(this.trackingHistory, 'status').match(/delivery|delivered|mailed/i);
    }
    if (state === 'delivered' || state === 'mailed') {
      return !!get(this.trackingHistory, 'status').match(/delivered|^mailed/i);
    }
  };

  getTrackingEventIconClass = (trackingEvent: any) => {
    return {
      'text-secondary':
        trackingEvent.status === 'out_for_delivery' ||
        trackingEvent.status === 'ready_to_be_mailed' ||
        trackingEvent.status === 'pre_transit',
      'text-success': trackingEvent.status === 'mailed' || trackingEvent.status === 'delivered',
      'ep-icon-theme1-default':
        trackingEvent.status === 'in_transit' || trackingEvent.status === 'processing',
      'ep-icon-theme1-clock':
        trackingEvent.status === 'out_for_delivery' ||
        trackingEvent.status === 'ready_to_be_mailed',

      'ep-icon-theme1-delivered':
        trackingEvent.status === 'mailed' || trackingEvent.status === 'delivered',
      'ep-icon-theme1-barcode': trackingEvent.status === 'pre_transit',
    };
  };
}
