<ion-header>
  <ion-toolbar>
    <div class="my-account-header">
      <div class="my-account-icon">
        <div class="icon-circle">
          <div class="inner-circle">
            <ion-icon name="contact"></ion-icon>
          </div>
        </div>
      </div>
      <div class="user-details" *ngIf="user">
        <h4>My Account</h4>
        <p *ngIf="user?.provider == 'email'">{{user.email}}</p>
      </div>
      <ion-button ion-button class="logout" (click)="onLogOut()">
        <div class="button-inner">
          Log-out
          <ion-icon name="log-out"></ion-icon>
        </div>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="page-content">
    <form [formGroup]="myAccountForm">
      <ion-list>
        <div
          id="confirm-account-banner"
          (click)="onShowConfirmAccountModal()"
          *ngIf="user && !user.phone_number_verified && !skipConfirmation"
        >
          <div>
            <p class="title">Confirm your account</p>
            <p class="subtitle">Confirm your account to send messages</p>
          </div>
          <div>
            <ion-icon name="arrow-forward" size="large" class="arrow"></ion-icon>
          </div>
        </div>
        <div *ngIf="user?.id_verification_required" id="profile-verification">
          <div class="section-header">
            <div class="icon">
              <ion-icon name="contact"></ion-icon>
            </div>
            <h2>Delivery Verification Required</h2>
          </div>
          <div>
            <p class="instruction" *ngIf="!user?.id_verification_completed">
             <span *ngIf="user?.id_verification_recipient">
                Your recent message to {{user?.id_verification_recipient.name}} requires additional verification
                to complete delivery.
              </span>
              <span *ngIf="!user?.id_verification_recipient">
                Your recent message requires additional verification to complete delivery.
              </span>
            </p>
            <p class="instruction" *ngIf="user?.id_verification_completed">
              Your verification has been completed and is currently being reviewed. You will notification once your messages have been delivered.
            </p>
            <ion-button 
              id="complete-verification-btn"
              class="outline-button "
              outline
              (click)="onCompleteVerification()"
              *ngIf="!user?.id_verification_completed"
              [disabled]="sendingVerificationEmail"
            >
              {{sendingVerificationEmail ? 'Sending Email...' : 'Complete Verification'}}
            </ion-button>
          </div>
        </div>
        <div id="message-credit-info">
          <div class="section-header">
            <div class="icon">
              <ion-icon name="ios-star"></ion-icon>
            </div>
            <h2>Message Credits</h2>
          </div>
          <div class="message-credit-balance">
            <p class="credit-amount">
              <span>
                You have <span class="hlt">{{user?.total_message_credits}}</span>
                <span [ngPlural]="user?.total_message_credits || 0">
                  <ng-template ngPluralCase="=1"> credit</ng-template>
                  <ng-template ngPluralCase="other"> credits</ng-template>
                </span>
              </span>
            </p>
            <span *ngIf="user?.has_purchased_credits">
              <p class="credit-detail">
                Credits can be used to send messages and photos and are automatically applied when you send a message. 
              </p>
              <p class="credit-detail">
                  To purchase additional credits, create a message first. You can add credits before you send your message.
              </p>
            </span>
            <span *ngIf="!user?.has_purchased_credits">
              <p class="credit-detail">
                To add credits, create a message first.  You can add credits before you send your message.
              </p>
            </span>
          </div>
        </div>
        <div class="section-header">
          <div class="icon">
            <ion-icon name="ios-mail-open"></ion-icon>
          </div>
          <h2>Your return address</h2>
        </div>
        <ion-item>
          <ion-label position="stacked">Name</ion-label>
          <ion-input formControlName="returnAddressName"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Address</ion-label>
          <ion-textarea formControlName="returnAddressStreet"></ion-textarea>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">City</ion-label>
          <ion-input formControlName="returnAddressCity"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">State / Region / Country</ion-label>
          <ion-input formControlName="returnAddressState"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Zip / Postal Code</ion-label>
          <ion-input formControlName="returnAddressZip"></ion-input>
        </ion-item>
      </ion-list>
      <ion-button
        id="save-button"
        class="outline-button"
        ion-button
        outline
        (click)="onEditAccount()"
        [disabled]="!myAccountForm.valid"
      >
        Save Changes
      </ion-button>
    </form>
    <div id="delete-account">
      <mat-accordion>
        <mat-expansion-panel hideToggle  #mep="matExpansionPanel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <ion-button id="delete-account-btn" class="outline-button" ion-button>
                Delete Account
              </ion-button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container>
            <h3>Delete Account</h3>
            <p class="confirm-delete-text">You are about to delete your account. All your messages, photos, and contacts will be removed.
              Are you absolutely positive? There is no option to undo.</p>
            <div class="delete-actions">
              <ion-button 
                id="confirm-delete-btn" 
                class="outline-button danger" 
                ion-button
                (click)="onConfirmDeleteAccount()"
              >
                Yes, delete my account
              </ion-button>
              <ion-button 
                fill="outline" 
                id="cancel-delete-account-btn" 
                class="outline-button" 
                ion-button
                (click)="mep.expanded = false;"
              >
                Cancel
              </ion-button>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div id="help-support">
      <ion-icon name="ios-help-buoy"></ion-icon>
      <h3 class="list-title">Need help?</h3>
      <h4>
        <a (click)="onShowHelpWidget('/')">View common questions</a> <br />
        or<br />
        <a (click)="onShowHelpWidget('/ask/message/')">Send us a message</a> <br />
        we're here to help.
      </h4>
    </div>
  </div>
</ion-content>

<ion-footer>
  <app-footer-nav></app-footer-nav>
</ion-footer>
