import { ActionReducer, Action } from '@ngrx/store';
import { SearchActions, CustomAction } from '../actions';

const initialState = {
  savedSearchResult: null,
  savedSearchQuery: null,
  savedRoute: null,
};

export function saveSearchReducer(state = initialState, action: CustomAction) {
  switch (action.type) {
    case SearchActions.SAVED_ROUTE:
      return Object.assign({}, state, {
        savedRoute: action.payload,
      });

    case SearchActions.CLEAR_SAVED_ROUTE:
      return Object.assign({}, state, {
        savedRoute: null,
      });

    case SearchActions.SAVE_SEARCH_RESULT:
      return Object.assign({}, state, {
        savedSearchResult: action.payload,
      });

    case SearchActions.CLEAR_SAVED_SEARCH_RESULT:
      return Object.assign({}, state, {
        savedSearchResult: null,
        savedSearchQuery: null,
      });

    case SearchActions.SAVE_SEARCH_QUERY:
      return Object.assign({}, state, {
        savedSearchQuery: action.payload,
      });

    default: {
      return state;
    }
  }
}
