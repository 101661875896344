import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { debounceTime, map, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { get } from 'lodash';
import { CreateMessageActions, CustomAction } from '../actions';
import { AppState, LetterService } from '../services';


@Injectable()
export class CreateMessageEffects {
  @Effect()
  saveDraft$: Observable<any> = this.actions$
    .pipe(ofType(CreateMessageActions.SAVE_DRAFT))
    .pipe(debounceTime(300))
    .pipe(map((action: CustomAction) => action.payload))
    .pipe(
      switchMap(draft => {
        return this.letterService.saveDraft(draft).pipe(
          map(letter => {
            const message = Object.assign({}, draft, letter);
            return this.createMessageActions.setMessage({
              ...message,
              isResend: message.is_resend,
              defaultReturnAddress: message.default_from_address,
              numberOfPages: message.number_of_pages,
              emessageAvailable: message.emessage_available,
              messageType: draft.messageType,
            });
          }),
        );
      }),
    );

    @Effect()
    editMessage$: Observable<any> = this.actions$
      .pipe(ofType(CreateMessageActions.EDIT_MESSAGE))
      .pipe(debounceTime(700))
      .pipe(
        map((action: CustomAction) => {
          return action.payload;
        }),
      )
      .pipe(withLatestFrom(this.store.select(state => state.createMessage)))
      .pipe(
        switchMap(([text, message]) => {
          return this.letterService.saveDraftText(message.id, text).pipe(
            map(letter => {
              return this.createMessageActions.setMessage(letter);
            }),
          );
        }),
      );


  @Effect()
  confirmMailingAddress: Observable<any> = this.actions$
    .pipe(ofType(CreateMessageActions.CONFIRM_ADDRESS))
    .pipe(debounceTime(300))
    .pipe(map((action: CustomAction) => action.payload))
    .pipe(
      switchMap(({ id, address }) => {
        return this.letterService.confirmMailingAddress(id, address).pipe(
          map(letter => {
            return this.createMessageActions.setMessage({
              ...letter,
              confirmedAddress: get(letter, 'data.confirmed_address'),
              isResend: letter.is_resend,
              returnAddress: letter.from_address,            
            });
          }),
        );
      }),
    );

  @Effect()
  fetchDraft$: Observable<any> = this.actions$
    .pipe(ofType(CreateMessageActions.FETCH_MESSAGE))
    .pipe(debounceTime(300))
    .pipe(
      map((action: CustomAction) => {
        return action.payload;
      }),
    )
    .pipe(
      switchMap(id => {
        return this.letterService.getLetter(id).map(letter => {
          return this.createMessageActions.setMessage(letter);
        });
      }),
    );

  @Effect()
  fetchDrafts$: Observable<any> = this.actions$
    .pipe(ofType(CreateMessageActions.FETCH_DRAFTS_REQUEST))
    .pipe(debounceTime(300))
    .pipe(
      map((action: CustomAction) => {
        return action.payload;
      }),
    )
    .pipe(
      switchMap(id => {
        return this.letterService.fetchDrafts().map(drafts => {
          return this.createMessageActions.fetchDraftsSuccess(drafts);
        });
      }),
    );

  @Effect()
  addPhotosFromDevice$: Observable<any> = this.actions$
    .pipe(ofType(CreateMessageActions.ADD_PHOTOS_FROM_DEVICE))
    .pipe(debounceTime(300))
    .pipe(
      map((action: CustomAction) => {
        return action.payload;
      }),
    )
    .pipe(withLatestFrom(this.store.select(state => state.createMessage)))
    .pipe(
      map(([_action, message]) => {
        return this.createMessageActions.saveDraft(message);
      }),
    );

  // @Effect() addPhotos$ = this.actions$
  //   .ofType(CreateMessageActions.ADD_PHOTOS_FROM_DEVICE)
  //   .debounceTime(300)
  //   .withLatestFrom(this.store.select(state => state.createMessage))
  //   .map(([_action, message]) => {
  //     return this.createMessageActions.saveDraft(message);
  //   });

  constructor(
    private actions$: Actions,
    private createMessageActions: CreateMessageActions,
    private letterService: LetterService,
    private store: Store<AppState>,
  ) {}
}
