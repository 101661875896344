<edit-message-navbar penmate="penmate" [back]="onGoBack"> </edit-message-navbar>

<ion-content id="view-penmate">
  <div class="pm-contact" *ngIf="penmate">
    <div class="contact-card">
      <div class="contact-details">
        <h3 class="name">{{ penmate.first_name }} {{penmate.last_name}}</h3>
        <h5 class="facility">{{ penmate.facility }}</h5>
      </div>

      <div id="recent-messages">
        <h3 class="title">Recent Messages</h3>
        <div class="no-messages null-state" *ngIf="messages && messages.length == 0">
          <p>No messages found =(</p>
        </div>
        <ion-list>
          <ion-grid>
            <ion-row
              *ngFor="let message of messages; let i = index; trackBy: trackItem"
              class="message-item"
              (click)="onShowMessage(message)"
              [ngClass]="{'sent': message.sent || message.processing, 'incomplete': message.needs_info, 'reply': message.message_type === 'emessage'}"
            >
              <ion-col size="2" class="message-icon">
                <ion-icon
                  name="ios-paper-plane"
                  *ngIf="!message.needs_info && (message.sent || message.processing)"
                ></ion-icon>
                <ion-icon
                  name="ios-alert"
                  *ngIf="message.needs_info && message.processing"
                ></ion-icon>
                <ion-icon name="md-create" *ngIf="!message.sent && !message.processing && message.message_type !== 'emessage'"></ion-icon>
                 <ion-icon name="ios-chatboxes" *ngIf="message.message_type === 'emessage'"></ion-icon>

                <span class="status" *ngIf="message.message_type === 'emessage'"> Reply </span>

                <span class="status" *ngIf="!message.needs_info && (message.sent || message.processing)"> Sent </span>
                <span class="status" *ngIf="message.message_type !== 'emessage' && !message.needs_info && !message.sent && !message.processing"> Draft </span>
                <span class="status error" *ngIf="message.needs_info && message.processing"> Error </span>
              </ion-col>
              <ion-col size="8">
                <div
                  class="sent-message"
                  [ngClass]="{'sent-message': message.sent || message.processing, 'draft': !message.sent && !message.processing }"
                >
                  <p class="time-ago">
                    <span *ngIf="message.sent || message.processing"> Sent </span>
                    <span *ngIf="!message.sent && !message.processing && message.message_type !== 'emessage'"> Drafted </span>
                    <span *ngIf="message.message_type == 'emessage'"> Received </span>

                    <span class="message-type">
                        <span *ngIf="message.message_type === 'emessage'">a reply </span> 
                        <span *ngIf="message.eMessage">an e-message </span> 

                        <span *ngIf="!message.eMessage && message.message_type !== 'emessage'">a {{message.order_type == 'postcard' ? 'postcard' : 'letter '}} </span>
                    </span>

                    <span *ngIf="message.sent || message.processing">
                      <span *ngIf="message.send_date">{{message.send_date | amTimeAgo }}</span>
                      <span *ngIf="!message.send_date">{{message.timestamp | amTimeAgo }}</span>
                    </span>

                    <span *ngIf="!message.sent && !message.processing"
                      >{{message.timestamp | amTimeAgo }}</span
                    >
                  </p>
                  <div *ngIf="message.order_type == 'postcard'">
                    <ion-grid>
                      <ion-row [style.align-items]="'center'">
                        <ion-col size="5">
                          <div
                            class="postcard-front-image"
                            [style.background-image]="'url(' + message.postcard?.frontImage?.url + ')'"
                          ></div>
                        </ion-col>
                        <ion-col size="7">
                          <span
                            class="postcard-message"
                            *ngIf="message.summary.length > 0"
                            [innerHTML]="sanitizeHtml(message.summary)"
                          ></span>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </div>
                  <p class="msg-summary" *ngIf="message.order_type != 'postcard'">
                    <span [innerHTML]="sanitizeHtml(message.summary)"> </span>
                  </p>
                </div>
              </ion-col>
              <ion-col size="2" class="arrow-col">
                <ion-icon
                  (click)="onShowMessage(message)"
                  name="arrow-forward"
                  class="view-message-icon"
                ></ion-icon>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-list>
        <ion-infinite-scroll threshold="100px" (ionInfinite)="loadNextPage($event)">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            loadingText="Loading messages...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer>
  <app-footer-nav></app-footer-nav>
</ion-footer>
