import { Component, ViewEncapsulation, Renderer2 } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute, } from '@angular/router'
import {  AppState } from '../services';

import { get } from 'lodash';
import { LoadingModal } from '../loading-modal/loading-modal';

import { Store } from '@ngrx/store';

declare global {
  interface Window {
      confetti: any;
  }
}

@Component({
  selector: 'pm-add-credits-success-modal',
  styleUrls: ['./add-credits-success-modal.scss'],
  templateUrl: 'add-credits-success-modal.html',
  encapsulation: ViewEncapsulation.None,
})
export class AddCreditsSuccessModal {
  pageHeight;
  paymentForm;
  currentMessage;

  constructor(
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.onLoadConfettiJs()
  }

  ngOnInit() {
    setTimeout(() => {
      this.onFireConfetti()
    }, 1500)
  }

  onLoadConfettiJs() {
    if (!window.confetti) {
      const script = this.renderer.createElement('script');
      script.src = `https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.2/dist/confetti.browser.min.js`;
      this.renderer.appendChild(document.head, script);
    }
  }

  onFireConfetti() {

    const count = 200;

    function fire(particleRatio, opts) {
      window.confetti({
        origin: { y: 0.7 },
        ...opts,
        particleCount: Math.floor(count * particleRatio)
      });
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });

  }

  onContinue = async () => {
    const url = new URL(window.location.href);
    const letterId = url.searchParams.get("letterId");
    const routePath = `/penmates`;
    if (letterId && letterId.length) {
      return this.navCtrl.back();
    }
    this.router.navigateByUrl(routePath);
    const openModal = await this.modalCtrl.getTop();
    openModal.dismiss();
  };
}
