import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Store } from '@ngrx/store';
import { map, catchError } from 'rxjs/operators';
import { omit, get, find, isEmpty } from 'lodash';
import * as uuid from 'uuid';
import { Storage } from '@ionic/storage';
import { Http } from '@angular/http';
import { ApiGateway } from './api-gateway.service';
import { AppState } from './index';

const storageConfig = {
  name: '__PMdb',
  storeName: 'appStore',
  driverOrder: ['sqlite', 'indexeddb', 'localstorage'],
};
const storage = new Storage(storageConfig);

declare var Penmate;
declare var gtag;


@Injectable({ providedIn: 'root' })
export class EventService {
  sessionId;

  constructor(public apiClient: ApiGateway, private http: Http, private store: Store<AppState>) {}

  start = async (payload = {}) => {
    const deviceId = await this.getDeviceId();
    this.sessionId = new Date().getTime();
    return this.apiClient.post(
      `${Penmate.env.apiUrl}/metrics/start`,
      {},
      {
        event: 'start',
        payload: {
          ...payload,
          device_id: deviceId,
          session_id: this.sessionId,
        },
      },
    );
  };

  gaUserData =  data => {
    if (isEmpty(data)) {
      return;
    }
    gtag('set', 'user_data', data);
  }

  gaEventTrack = (event, payload = {}) => {
    gtag('event', event, payload);
  }
  
  awConversion = value => {
    gtag('event', 'conversion', {
      'send_to': 'AW-955984403/ClTpCMOOqKoYEJPU7McD',
      'value': value || 3.99,
      'currency': 'USD',
      'transaction_id': ''
    });
  }

  awAddPenmate = () => {
    gtag('event', 'conversion', {'send_to': 'AW-955984403/fCNPCJ7fvKoYEJPU7McD'});
  }

  track = async (event, payload = {}) => {
    const deviceId = await this.getDeviceId();
    const penmate_id = get(payload, 'penmate_id');
    let tz = payload['timezone'];
    if (!tz) {
      try {
        tz = Intl.DateTimeFormat().resolvedOptions().timeZone
      } catch {
        /* Intl.DateTimeFormat not supported */
      }
    }
    if (!penmate_id) {
      return this.apiClient.post(
        `${Penmate.env.apiUrl}/metrics`,
        {},
        {
          event,
          payload: {
            timezone: tz,
            ...payload,
            device_id: deviceId,
            session_id: this.sessionId
          },
        },
      );
    }

    return new Promise((resolve, reject) => {
      this.store
        .select(state => ({
          penmates: state.myPenmates.penmates,
          currentPenmate: state.myPenmates.currentPenmate,
        }))
        .subscribe(async ({ penmates, currentPenmate }) => {
          const eventPayload = {
            event,
            payload: {
              ...payload,
              device_id: deviceId,
              session_id: this.sessionId,
              penmate: {},
            },
          };
          const penmate =
            find(penmates, ({ id }) => id === parseInt(penmate_id, 10)) || currentPenmate;
          if (penmate_id && penmate) {
            eventPayload.payload.penmate = {
              id: penmate.id,
              first_name: penmate.first_name,
              last_name: penmate.last_name,
              name: penmate.name,
              slug: penmate.slug,
              facility: penmate.facility,
            };
          } else {
            delete eventPayload.payload.penmate;
          }
          try {
            const res = await this.apiClient.post(
              `${Penmate.env.apiUrl}/metrics`,
              {},
              eventPayload,
            );
            resolve(res);
          } catch (e) {
            reject(e);
          }
        })
        .unsubscribe();
    });
  };

  getDeviceId = async () => {
    const deviceId = await storage.get('deviceId');
    if (deviceId) {
      return deviceId;
    }
    const nextDeviceId = uuid.v4();
    await storage.set('deviceId', nextDeviceId);
    return nextDeviceId;
  };
}
