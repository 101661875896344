import { Action } from '@ngrx/store';

export * from './user.actions';
export * from './search.actions';
export * from './my-penmate.actions';
export * from './create-message.actions';


export interface CustomAction extends Action {
  type: string;
  payload?: any;
}
