<ion-content>
  <div
    id="search-hero"
    *ngIf="!showAddFacilityInfo && 
        addPenmateForm?.value?.addressMethod.type === FORM_TYPE.byAddress"
  >
    <div class="hero-corner-l"></div>
    <div class="hero-corner-r"></div>
    <div class="hero-main"></div>
  </div>
  <div class="container">
    <div
      class="close-cancel"
      *ngIf="showAddFacilityInfo || 
        addPenmateForm?.value?.addressMethod.type === FORM_TYPE.bySearch"
    >
      <ion-icon name="close" (click)="onCancel()"></ion-icon>
    </div>
    <h2 class="page-title">
      Find an Inmate
    </h2>
    <h3 class="page-subtitle">
      Find an inmate and send letters, photos, and more
      <span class="hlt">from your phone.</span>
    </h3>

    <form
      novalidate
      id="add-penmate-form"
      [formGroup]="addPenmateForm"
      (ngSubmit)="submit(addPenmateForm.value, addPenmateForm.valid)"
    >
      <div id="name-fields" *ngIf="!showAddFacilityInfo">
        <div class="form-header">
          <p class="form-subtitle">
            Find an inmate <br />
            by name and location
          </p>
        </div>

        <div class="form-inputs">
          <ion-item class="name-input" no-padding>
            <ion-input
              type="text"
              formControlName="first_name"
              placeholder="First name"
              [class.invalid]="fieldIsInvalid('first_name')"
              name="first_name"
            >
            </ion-input>
          </ion-item>
          <small class="input-error" *ngIf="fieldIsInvalid('first_name')">Required.</small>
          <ion-item class="name-input" no-padding>
            <ion-input
              type="text"
              formControlName="last_name"
              placeholder="Last name"
              [class.invalid]="fieldIsInvalid('last_name')"
              name="last_name"
            >
            </ion-input>
          </ion-item>
          <small class="input-error" *ngIf="fieldIsInvalid('last_name')">Required.</small>

          <ion-item class="state-select" no-padding>
            <ion-label
              [class.invalid]="fieldIsInvalid('addressMethod.byAddress.state')"
              [ngStyle]="{'color': fieldIsInvalid('addressMethod.byAddress.state') ? 'red' : '' }"
            >
              State
            </ion-label>

            <ion-select formControlName="state" name="state">
              <ion-select-option *ngFor="let stateName of US_STATE_LIST"
                >{{stateName}}</ion-select-option
              >
            </ion-select>
          </ion-item>
          <small class="input-error" *ngIf="fieldIsInvalid('gender')">Required.</small>

          <div
            formGroupName="addressMethod"
            *ngIf="addPenmateForm.value.addressMethod.type === FORM_TYPE.bySearch"
          >
            <div id="penmate-search-form">
              <h6 class="search-form-header">
                Search Jails & Prisons
              </h6>
              <div class="toggled-form-fields" formGroupName="bySearch">
                <ion-item class="search-input-gender search-input-age" no-padding>
                  <ion-label>Gender:</ion-label>
                  <ion-select
                    formControlName="gender"
                    name="gender"
                    [class.invalid]="fieldIsInvalid('addressMethod.bySearch.gender')"
                  >
                    <ion-select-option value="M">Male</ion-select-option>
                    <ion-select-option value="F">Female</ion-select-option>
                  </ion-select>
                  <small class="input-error" *ngIf="fieldIsInvalid('addressMethod.bySearch.gender')"
                    >Required.</small
                  >
                </ion-item>
                <ion-item
                  id="search-input-age"
                  no-padding
                  [class.invalid]="fieldIsInvalid('addressMethod.bySearch.age_range')"
                >
                  <ion-label>Choose age range:</ion-label>
                  <ion-select formControlName="age_range" name="age_range">
                    <ion-select-option *ngFor="let age of ageOptions">{{age}}</ion-select-option>
                  </ion-select>
                  <small
                    class="input-error"
                    *ngIf="fieldIsInvalid('addressMethod.bySearch.age_range')"
                    >Required.</small
                  >
                </ion-item>
                <ion-item
                  id="search-input-ethnicity"
                  [class.invalid]="fieldIsInvalid('addressMethod.bySearch.ethnicity')"
                  no-padding
                >
                  <ion-label>Choose race/ethnicity:</ion-label>
                  <ion-select formControlName="ethnicity" name="ethnicity">
                    <ion-select-option *ngFor="let race of raceOptions">{{race}}</ion-select-option>
                  </ion-select>
                  <small
                    class="input-error"
                    *ngIf="fieldIsInvalid('addressMethod.bySearch.ethnicity')"
                    >Required.</small
                  >
                </ion-item>
              </div>
            </div>
          </div>

          <div
            class="facility-autocomplete"
            *ngIf="user && (addPenmateForm?.value?.state && addPenmateForm.value?.addressMethod?.type === FORM_TYPE.byAddress)"
          >
            <ion-label>Enter name of Jail or Prison</ion-label>
            <ng-select
              [items]="facilities$ | async"
              bindLabel="name"
              [trackByFn]="trackByFn"
              [minTermLength]="3"
              [dropdownPosition]="'top'"
              [loading]="facilitiesLoading"
              [typeahead]="facilityInput$"
              typeToSearchText="Enter name of Jail or Prison"
              [addTag]="true"
              addTagText="Search:"
              formControlName="selectedFacility"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div formGroupName="addressMethod" *ngIf="showAddFacilityInfo">
        <div id="penmate-profile-form" formGroupName="byAddress">
          <div>
            <div #pmContact class="pm-contact">
              <div class="header-icon">
                <div class="building-icon"></div>
              </div>

              <div class="penmate-profile">
                <p class="profile-title">
                  Connect with
                  <span class="name">
                    {{ addPenmateForm?.value?.first_name | titlecase }} {{
                    addPenmateForm?.value?.last_name | titlecase }}
                  </span>
                </p>
                <div id="map-container" class="map-container" [pmContactMap]="penmate">
                  <div class="map-cover"></div>
                  <div class="map"></div>
                </div>

                <p class="facility-title">
                  {{ addPenmateForm?.value?.selectedFacility?.name | titlecase }}
                </p>

                <div id="profile-details-form">
                  <ion-label position="stacked" class="section-title">
                    Enter one of the following
                  </ion-label>

                  <ion-item>
                    <ion-label position="stacked"> Inmate ID </ion-label>
                    <ion-input
                      type="text"
                      id="manual-input-inmate-id"
                      formControlName="inmate_id"
                      name="inmate_id"
                    >
                    </ion-input>
                    <small
                      class="input-error"
                      *ngIf="formSubmitted && addPenmateForm.value?.addressMethod?.byAddress?.facility && addPenmateForm.errors?.addressMethod"
                      >Please enter an inmate id or birthdate</small
                    >
                  </ion-item>
                  <p class="or-divider">OR</p>
                  <ion-item>
                    <ion-label position="stacked"> Birthdate </ion-label>
                    <ion-input
                      type="date"
                      id="manual-input-birth-date"
                      formControlName="birthdate"
                      name="birthdate"
                    >
                    </ion-input>
                    <small
                      class="input-error"
                      *ngIf="formSubmitted && addPenmateForm.value?.addressMethod?.byAddress?.facility && addPenmateForm.errors?.addressMethod"
                      >Please enter an inmate id or birthdate</small
                    >
                  </ion-item>

                  <ion-label position="stacked" class="housing-title section-title">
                    Add Housing info <span class="small">(Optional) </span>
                  </ion-label>

                  <ion-item id="housing-input">
                    <ion-label position="stacked"> Housing/Cell Number </ion-label>

                    <ion-input
                      id="manual-input-address_line1"
                      type="text"
                      formControlName="address_line1"
                      name="address_line1"
                    >
                    </ion-input>
                  </ion-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-actions">
        <ion-button
          class="submit-btn"
          expand="block"
          *ngIf="addPenmateForm?.value?.addressMethod?.type !== FORM_TYPE.bySearch && !showAddFacilityInfo"
          (click)="onAddPenmate(addPenmateForm.value)"
          [disabled]="!isAddPenmateFormValid() || showAddPenmateLoading"
        >
          <ion-label>
            <ion-spinner *ngIf="showAddPenmateLoading"></ion-spinner> Continue
          </ion-label>
        </ion-button>
        <ion-button
          id="manual-address-submit-button"
          class="submit-btn"
          type="submit"
          ion-button
          round
          [disabled]="!addPenmateForm.valid"
          *ngIf="addPenmateForm?.value?.addressMethod?.type === FORM_TYPE.bySearch || showAddFacilityInfo"
        >
          <ion-icon
            slot="start"
            name="ios-search"
            *ngIf="addPenmateForm?.value?.addressMethod?.type === FORM_TYPE.bySearch"
          ></ion-icon>

          <span *ngIf="addPenmateForm?.value?.addressMethod?.type === FORM_TYPE.bySearch"
            >Search</span
          >
          <span *ngIf="addPenmateForm?.value?.addressMethod?.type !== FORM_TYPE.bySearch"
            >Submit</span
          >
        </ion-button>

        <div
          class="search-cta"
          *ngIf="addPenmateForm?.value?.addressMethod?.type !== FORM_TYPE.bySearch && !showAddFacilityInfo"
          (click)="changeFormType(FORM_TYPE.bySearch)"
        >
          <div class="icon">
            <ion-icon name="ios-search"></ion-icon>
          </div>
          <div class="cta">
            <p>Don't know their location?</p>
            <a class="link"><span class="hlt">Search</span> all Jails & Prisons</a>
          </div>
        </div>
      </div>

      <p
        class="cancel-action"
        (click)="onCancel()"
        *ngIf="showAddFacilityInfo || addPenmateForm?.value?.addressMethod?.type == FORM_TYPE.bySearch"
      >
        <a>... or Cancel and Start Over </a>
      </p>
    </form>
    <div class="hero-illus-footer"></div>
  </div>
</ion-content>

<ion-footer>
  <app-footer-nav></app-footer-nav>
</ion-footer>
