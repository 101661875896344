import { Component, ViewChild, Renderer, ViewEncapsulation } from '@angular/core';
import {
  NavController,
  LoadingController,
  ModalController,
  IonContent,
  IonTabs,
  AlertController,
} from '@ionic/angular';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute, Router } from '@angular/router';
import { Headers, RequestOptions, Jsonp } from '@angular/http';
import { chunk, get, filter, orderBy, debounce, find } from 'lodash';
import { debounceTime } from 'rxjs/operators';

import {
  AppState,
  LetterService,
  ApiGateway,
  SharedService,
  AuthService,
  EventService,
} from '../services';
import { Store } from '@ngrx/store';
import { PhotoPickerPhoto } from '../models';
// import {  IGMyPhotosTab } from '../pages';
import * as moment from 'moment';
import { SearchActions } from '../actions';

import { IGMyPhotosTab } from '../ig-photo-picker/ig-my-photos-tab';
import { IGSearchTab } from '../ig-photo-picker/ig-search-tab';
import { SelectedPhotosModal } from '../selected-photos-modal/selected-photos-modal';
import { CreateMessageActions } from '../actions';

const MAX_SELECTED_PHOTOS = 10;

declare var Penmate;

@Component({
  selector: 'pm-ig-photo-picker',
  templateUrl: 'ig-photo-picker.html',
  styleUrls: ['./ig-photo-picker.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IGPhotoPicker {
  @ViewChild(IonContent, { static: false }) content: any;
  @ViewChild('igTabs', { static: false }) igTabs;
  accessToken;
  isPostcard;
  loadingSpinner;
  currentTabTitle;
  placeholder;
  title;
  photos;
  user;
  linkInput = '';
  selectedPhotosModal;
  myPhotosTab;
  searchTab;
  showPaginationSpinner;
  selectedPhotos = [];

  onPasteLink = debounce(
    async evt => {
      if (this.selectedPhotos.length >= MAX_SELECTED_PHOTOS) {
        return this.onDisplayErrorAlert(
          'Photo limit reached',
          `Max ${MAX_SELECTED_PHOTOS} photos attached`,
          `You've attached ${MAX_SELECTED_PHOTOS} photos to your message. We cant send more than ${MAX_SELECTED_PHOTOS} photos at a time or the facility may reject your message. To send more than ${MAX_SELECTED_PHOTOS} photos, please create a new message.`,
          'OK',
        );
      }

      const urlRegex = /(\b((https?):\/\/)?(www.instagram|instagram.com))\/[a-zA-Z]\/.+?(?=[^a-zA-Z\d\s.])/gi;
      const text = get(evt, 'target.value', '');
      if (text.match(urlRegex)) {
        const baseUrl = text.split('?')[0];
        const fetchUrl = `${baseUrl}?__a=1`;
        try {
          const response = await fetch(fetchUrl);
          const json = await response.json();
          const imageData = get(json, 'graphql.shortcode_media', {});
          if (!imageData.display_url) {
            return;
          }
          const photo = {
            selected: true,
            url: imageData.display_url,
            id: imageData.id,
            created_at: moment.unix(imageData.taken_at_timestamp).format(),
            provider: 'instagram',
            source: 'graphql',
            details: imageData,
            is_carousel: false,
            is_video: imageData.is_video,
          };
          this.eventService.track('add-photos', { provider: 'instagram', source: 'public-link' });
          this.store.dispatch(this.createMessageActions.togglePhotoFromGallery(photo));
        } finally {
          this.linkInput = '';
          if (this.isPostcard) {
            this.onDismiss();
          }
        }
      }
    },
    500,
    { leading: false, trailing: true },
  );

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public route: ActivatedRoute,
    public router: Router,
    private sharedService: SharedService,
    private createMessageActions: CreateMessageActions,
    private authService: AuthService,
    private eventService: EventService,
    private searchActions: SearchActions,
    private store: Store<AppState>,
    private jsonp: Jsonp,
    private renderer: Renderer,
    private apiClient: ApiGateway,
  ) {
    // this.accessToken = this.navParams.get('accessToken');
    // this.isPostcard = this.navParams.get('isPostcard');

    this.store
      .select(state => state.createMessage)
      .subscribe(message => {
        this.selectedPhotos = message.selectedPhotos;
        this.photos = chunk(this.selectedPhotos, 2);
      });

    this.sharedService.data.pipe(debounceTime(100)).subscribe(({ event }) => {
      const label = get(event, 'type');
      const data = get(event, 'data', {});
      if (label === 'pagination') {
        this.showPaginationSpinner = !!data.loading;
      }
    });

    this.store
      .select(state => state.user.authentications)
      .subscribe(authentications => {
        const igAuth = get(authentications, 'instagram') || {};
        if (!!igAuth.access_token) {
          this.accessToken = igAuth.access_token;
        }
      });
  }

  ionViewDidEnter() {
    const path = get(this.route.snapshot, 'routeConfig.path');
    this.isPostcard = path.match(/new-postcard/i);
    const penmateId = this.route.snapshot.paramMap.get('id');
    const { showUserMedia = false } = history.state;

    if (!!showUserMedia) {
      const messageType = this.isPostcard ? 'new-postcard' : 'new-message';
      const routeUrl = `/penmates/${penmateId}/${messageType}/instagram-photos/me`;
      return this.router.navigateByUrl(routeUrl, {
        state: {
          accessToken: this.accessToken,
        },
      });
    }
  }

  onInstagramAuth(provider) {
    this.store.dispatch(this.searchActions.saveRoute(window.location.pathname));

    this.authService.authenticateProvider('instagram').subscribe(({ account } = {}) => {
      const penmateId = this.route.snapshot.paramMap.get('id');
      const messageType = this.isPostcard ? 'new-postcard' : 'new-message';
      const routeUrl = `/penmates/${penmateId}/${messageType}/instagram-photos/me`;
      if (account) {
        return this.router.navigateByUrl(routeUrl, {
          state: {
            accessToken: account.access_token,
          },
        });
      }
    });
  }

  onDismiss = async () => {
    const penmateId = this.route.snapshot.paramMap.get('id');

    const messageType = this.isPostcard ? 'new-postcard' : 'new-message';
    try {
      await this.loadingCtrl.dismiss();
      this.navCtrl.navigateRoot(`/penmates/${penmateId}/${messageType}`);
    } catch (e) {
      this.navCtrl.navigateRoot(`/penmates/${penmateId}/${messageType}`);
    }
    // await this.modalCtrl.dismiss();
  };

  onShowSelectedPhotos = async () => {
    this.selectedPhotosModal = await this.modalCtrl.create({
      component: SelectedPhotosModal,
      componentProps: { photos: this.selectedPhotos },
    });
    await this.selectedPhotosModal.present();
  };

  showLoadingSpinner = async () => {
    this.loadingSpinner = await this.loadingCtrl.create({
      message: 'Please wait...',
    });
    await this.loadingSpinner.present();
  };

  hideLoadingSpinner = async () => {
    if (this.loadingSpinner) {
      await this.loadingSpinner.dismiss();
    }
  };

  onDisplayErrorAlert = async (titleText, subHeader, message, buttonText) => {
    const alert = await this.alertCtrl.create({
      header: titleText,
      subHeader,
      message,
      buttons: [
        {
          text: buttonText,
        },
      ],
    });
    await alert.present();
  };

  private formatSearchMedia(photo) {
    const image: any = orderBy(photo.images, 'width', 'desc')[1 || 0];
    const is_carousel = !!get(photo, 'carousel_media');
    const is_video = !!get(photo, 'video_versions');

    const formattedPhoto: PhotoPickerPhoto = {
      url: image.url,
      id: photo.id,
      created_at: moment.unix(photo.created_at).format(),
      provider: 'instagram',
      details: photo,
      is_carousel,
      is_video,
    };
    return formattedPhoto;
  }
}
