<ion-content fullscreen>
  <ion-card>
    <div class="demo-placeholder"></div>
    <ion-card-header>
      <ion-card-subtitle>Attach Photos</ion-card-subtitle>
      <ion-card-title>Add Instagram Photo</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ol>
        <li>Copy link from url</li>
        <li>Paste below</li>
        <li>Instagram photo will show here</li>
      </ol>

      <ion-item>
        <ion-label position="stacked">Paste instagram link:</ion-label>
        <ion-input required type="text" oninput="onLinkChange(event)"></ion-input>
      </ion-item>
    </ion-card-content>
  </ion-card>
</ion-content>
