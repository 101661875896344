import { Component, Input, ChangeDetectorRef, NgZone, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { Observer } from 'rxjs/Observer';
import {
  NavController,
  IonSlides,
  IonSlide,
  Platform,
  ModalController,
  AlertController,
  LoadingController,
} from '@ionic/angular';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { mergeMap, distinctUntilChanged } from 'rxjs/operators';

import * as moment from 'moment';
import { ScrollSpyService } from 'ngx-scrollspy';
import {
  mapStates,
  AppState,
  AuthService,
  MyPenmateService,
  EventService,
  NativeClientService,
  LetterService,
} from '../services';
import { MyPenmateActions, UserActions, CreateMessageActions } from '../actions';

import { ViewChild, ViewChildren } from '@angular/core';
import { sample, orderBy, findIndex, find, get } from 'lodash';
import { Store } from '@ngrx/store';
import { IonContent } from '@ionic/angular';
// import {
//   AddPenmatePage,
//   LoginPage,
//   MessageIndexPage,
//   MessageCreatorPage,
//   PostcardCreatorPage,
//   PenmateProfile,
//   LoadingModal,
// } from '../pages';

import { AddPenmatePage } from '../add-penmate/add-penmate';
import { LoginPage } from '../login/login.page';
import { MessageIndexPage } from '../message-index/message-index';
import { MessageCreatorPage } from '../message-creator/message-creator';
import { PostcardCreatorPage } from '../postcard-creator/postcard-creator';
import { PenmateProfile } from '../penmate-profile/penmate-profile';
import { LoadingModal } from '../loading-modal/loading-modal';
import { EmailMessagesModalPage } from '../email-messages-modal/email-messages-modal';
import { EmailDeliveryModal } from '../email-delivery-modal/email-delivery-modal';

declare var Beacon;

/**
 * Returns absolute element top
 */
function getElementTop(el: HTMLElement) {
  return el.getBoundingClientRect().top;
}

@Component({
  selector: 'pm-page-my-penmates',
  templateUrl: 'my-penmates.html',
  styleUrls: ['./my-penmates.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyPenmatesPage {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChildren('pmContact') pmContactEl;
  public pageHeight;
  public pageWidth;
  public modal;
  public penmate;
  public penmates = [];
  public emailMessages = [];
  public drafts = [];
  public waypointHit;
  public currentMessage;
  public pendingEmailMessage;
  public waypoints: { index: number; position: number }[] = [];
  public isNative;
  public emailMessagesModal;
  public pendingDraft;
  public user;

  constructor(
    private platform: Platform,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    private myPenmateActions: MyPenmateActions,
    private myPenmateService: MyPenmateService,
    private userActions: UserActions,
    private createMessageActions: CreateMessageActions,
    private store: Store<AppState>,
    private letterService: LetterService,
    private authService: AuthService,
    private eventService: EventService,
    private nativeService: NativeClientService,
    private scrollSpy: ScrollSpyService,
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone,
  ) {
    this.pageHeight = platform.height();
    this.pageWidth = platform.width();

    this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
      if (!isAuthenticated || !user) {
        return this.navCtrl.navigateForward('/login');
      }
      if (!!isAuthenticated && user) {
        this.user = user;
        this.eventService.gaUserData(user.profile_hash)
      }
    });
  }

  setupWaypoints() {
    this.pmContactEl.changes.subscribe(t => {
      for (const pmEl of t._results) {
        const position = pmEl.nativeElement.getBoundingClientRect().top;
        const index = pmEl.nativeElement.getAttribute('data-index');
        this.registerWaypoint(parseInt(index, 10), position);
      }
      this.waypointHit = this.getScrollSpy('list-scroller');
      this.waypointHit.subscribe(index => {
        const penmates = this.penmates;
        const item = penmates[index];
        if (!item.showMap) {
          penmates[index] = Object.assign({}, { ...item, showMap: true });
          this.penmates = Object.assign([], penmates);
        }
      });
    });
  }

  ionViewDidEnter() {
    this.isNative = !!this.nativeService.active;
    this.myPenmateService.getPenmates().subscribe(penmates => {
      this.store.dispatch(this.myPenmateActions.loadPenmatesSuccess(penmates));
      if (penmates.length === 0) {
        this.penmates = penmates;
        return this.navCtrl.navigateForward('/find-an-inmate');
      }

      this.setupWaypoints();
      this.store.dispatch(this.createMessageActions.fetchDrafts());

      this.store
        .select(state => state.user.emailMessages)
        .subscribe(emailMessages => {
          this.emailMessages = emailMessages.map(message =>
            Object.assign({}, message, { message: message['body'], emailMessage: true }),
          );
        });

      this.store
        .select(state => state.createMessage)
        .subscribe(currentMessage => {
          this.currentMessage = currentMessage;
        });

      this.store
        .select(state => state.myPenmates.drafts)
        .subscribe(drafts => {
          this.drafts = drafts;
        });

      this.store
        .select(state => state.user.pendingEmailMessage)
        .subscribe(pendingEmailMessage => {
          this.pendingEmailMessage = pendingEmailMessage;
        });

      this.store
        .select(state => state.myPenmates.penmates)
        .subscribe(myPenmates => {
          if (myPenmates && myPenmates.length > 0) {
            this.penmates = orderBy(myPenmates, 'id', 'desc');
            // Maps are initialized on scroll. We'll autoload the first two..
            //
            if (this.penmates[0]) {
              this.penmates[0].showMap = true;
            }
            if (this.penmates[1]) {
              this.penmates[1].showMap = true;
            }

            if (!this.changeDetectorRef['destroyed']) {
              this.changeDetectorRef.detectChanges(); // TODO: force detectCahanges? why?
            }
          }
        });
    });
    Beacon('destroy');
  }

  onViewMessages(penmate) {
    this.store.dispatch(this.myPenmateActions.viewPenmate(penmate));
    this.navCtrl.navigateForward(`/penmates/${penmate.id}/messages`);
    this.eventService.track('view-messages', { penmate_id: penmate.id });
  }

  onEditDraft = async (draft, penmate) => {
    if (draft.emailMessage) {
      return this.onEditEmailDraft(draft, penmate);
    }
    const messageType = get(draft, 'data.messageType') === 'postcard' ? 'postcard' : 'message';
    const draftData = get(draft, 'data', {});
    const draftMessage = { ...draftData, id: draft.id };
    this.eventService.track('edit-draft', {
      letter_id: draft.id,
      message_type: messageType,
      penmate_id: penmate.id,
    });
    this.store.dispatch(this.createMessageActions.setMessage({ ...draftMessage, payment: null }));
    this.navCtrl.navigateForward(`/penmates/${penmate.id}/new-${messageType}`);
  };

  requiresIdVerification(penmate) {
    if (!penmate || !this.user || !this.user.id_verification_required || !this.user.id_verification_recipient) {
      return false;
    }
    if (this.user.id_verification_completed) {
      return false;
    }
    const recipientId = this.user.id_verification_recipient.id || null
    return recipientId && penmate.id && penmate.id === recipientId
  }

  onMyAccount() {
    this.navCtrl.navigateForward('/my-account');
  }

  onEditEmailDraft = async (draft, penmate) => {
    const loadingModal = await this.modalCtrl.create({
      component: LoadingModal,
      componentProps: { message: 'Loading letter...' },
    });
    await loadingModal.present();

    this.letterService.updateEmailMessage(draft.id, { createLetter: true }).subscribe(async res => {
      await loadingModal.dismiss();
      const letter = get(res, 'letter', {});
      const letterPenmate = get(res, 'penmate');
      this.store.dispatch(this.userActions.setPendingEmailMessage(letter));
      if (letterPenmate) {
        this.store.dispatch(this.myPenmateActions.viewPenmate(letterPenmate));
        this.store.dispatch(this.createMessageActions.setMessage(letter));
        this.navCtrl.navigateForward(`/penmates/${letterPenmate.id}/new-message`);
        this.store.dispatch(this.myPenmateActions.loadPenmates());
        this.eventService.track('email-message-create', {
          letter_id: letterPenmate.id,
          penmate_id: letterPenmate.id,
        });
      }
    });
    return;
  };

  onExploreMedia(penmate) {
    this.nativeService.sendMessage({ type: 'SHOW_LIBRARY', payload: { penmate } });
  }

  onSendEMessage(penmate) {
    return new Promise(async (resolve, reject) => {
      if (this.currentMessage && this.currentMessage.eMessage || penmate.emessage_facility) {
        this.onCreateMessage(penmate, this.currentMessage.eMessage || penmate.emessage_facility)
        return resolve(null)
      }
      this.modalCtrl
        .create({
          component: EmailDeliveryModal,
        })
        .then(async (modal) => {
          await modal.present();
          modal.onDidDismiss().then((payload: any) => {
            const eMessage = get(payload, 'data.eMessage');
            if (eMessage) {
              setTimeout(() => {
                this.onCreateMessage(penmate, eMessage)
              }, 60)
            }
          });
        })
        .finally(() => {
          resolve(null);
          // TRACK UPGRADE
          // this.eventService.track('show-app-upgrade', {
          //   letter_id: this.currentMessage.id,
          // });
        });
    });
  }

  onCreateMessage(penmate, eMessage = null) {
    if (this.currentMessage.penmate && this.currentMessage.penmate.id !== penmate.id) {
      this.store.dispatch(this.createMessageActions.resetMessage());
    }

    let draft = { penmate };
    if (this.pendingEmailMessage) {
      draft = { ...this.pendingEmailMessage, penmate };
      this.store.dispatch(this.userActions.clearPendingEmailMessage());
    }
    this.store.dispatch(
      this.createMessageActions.setMessage({ ...draft, messageType: 'letter', eMessage, payment: null }),
    );
    this.navCtrl.navigateForward(`/penmates/${penmate.id}/new-message`);
    this.eventService.track('create-letter', { penmate_id: penmate.id, eMessage });
  }

  onCreatePostcard(penmate) {
    if (this.currentMessage.penmate && this.currentMessage.penmate.id !== penmate.id) {
      this.store.dispatch(this.createMessageActions.resetMessage());
    }
    this.store.dispatch(this.createMessageActions.setMessage({ penmate, messageType: 'postcard' }));
    this.navCtrl.navigateForward(`/penmates/${penmate.id}/new-postcard`);
    this.eventService.track('create-postcard', { penmate_id: penmate.id });
  }

  onRemovePenmate = async penmate => {
    const cancelButton = {
      text: 'Cancel',
      role: 'cancel',
      cssClass: 'alert-button',
      handler: () => {},
    };

    const delButton = {
      text: 'Yes',
      cssClass: 'delete-button',
      handler: () => {
        this.removePenmate(penmate.id);
      },
    };

    const confirm = await this.alertCtrl.create({
      header: 'Are you sure you want to remove this Penmate?',
      cssClass: 'alert-confirm',
      message: '<span class="alert-text-confirm">Important: This will remove all of your messages for this person.</span>',
      buttons: [cancelButton, delButton],
    });
    await confirm.present();
  };

  removePenmate(id) {
    this.myPenmateService.removePenmate(id).subscribe(res => {
      this.store.dispatch(this.myPenmateActions.removePenmateSuccess(res));
      this.store.dispatch(this.myPenmateActions.loadPenmates());
    });
  }

  getPendingDraft = penmate => {
    if (this.emailMessages.length > 0) {
      return find(this.emailMessages, draft => get(draft, 'inmate.id') === get(penmate, 'id'));
    }
    try {
      const drafts = orderBy(this.drafts, (d) => new Date(d.created_at).getTime(), 'desc')
      return find(drafts, draft => get(draft, 'recipient.id') === get(penmate, 'id'));
    } catch {
      const drafts = orderBy(this.drafts, 'id', 'desc');
      return find(drafts, draft => get(draft, 'recipient.id') === get(penmate, 'id'));
    }
  };

  private getScrollSpy(id) {
    return this.scrollSpy
      .getObservable(id)
      .pipe(
        mergeMap((e: any) => {
          const pos = e.target.scrollTop + window.outerHeight - 80;
          if (pos) {
            const elemId = this.getWaypointAtPosition(pos);
            if (elemId) {
              return of(elemId);
            }
            return Observable.empty();
          }
          return Observable.empty();
        }),
      )
      .pipe(distinctUntilChanged());
  }

  private registerWaypoint(index: number, position: number) {
    this.waypoints.push({ index, position });
  }

  private getWaypointAtPosition(scrollPos: number): string {
    let id = null;
    let lastDistance = Infinity; //smallest, positive distance
    for (let entry of this.waypoints) {
      let distance = scrollPos - entry.position + 10;
      if (distance > 0 && distance < lastDistance) {
        id = entry.index;
        lastDistance = distance;
      }
    }
    return id;
  }
}
