<ion-header>
  <ion-toolbar>
    <div class="header-content">
      <ion-icon name="close" class="icon" size="large" (click)="onCloseModal()"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content class="preview-photo-modal">
  <div id="preview-photo-modal">
    <ngx-image-viewer [src]="images"></ngx-image-viewer>
    <div id="caption-container" *ngIf="photo.caption">
      <p>{{photo.caption}}</p>
    </div>
  </div>
</ion-content>
