import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { get } from 'lodash';

@Component({
  selector: 'pm-preview-photo-modal',
  templateUrl: 'preview-photo-modal.html',
  styleUrls: ['./preview-photo-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewPhotoModal {
  photo;
  images = [];

  constructor(private modalCtrl: ModalController) {}

  onCloseModal = async () => {
    await this.modalCtrl.dismiss();
  };

  ionViewDidEnter() {
    const url = get(this.photo, 'url');
    if (url) {
      this.images = [url];
    }
  }
}
