import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { get, includes, isEmpty, find, orderBy, compact } from 'lodash';
import { map } from 'rxjs/operators';
import { Validators, FormBuilder } from '@angular/forms';
import { CreateMessageActions, MyPenmateActions } from '../actions';
import { ActivatedRoute } from '@angular/router';
import { PopoverController, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Appearance } from '@angular-material-extensions/google-maps-autocomplete';
import { AppState, LetterService, EventService, mapStates } from '../services';
import { Namefully } from 'namefully'

@Component({
  selector: 'pm-confirm-address-modal',
  templateUrl: 'confirm-address-modal.html',
  styleUrls: ['./confirm-address-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmAddressModal {
  @ViewChild('scrollContent', { static: false }) scrollContent;
  public appearance = Appearance;
  penmate;
  navCtrl;
  isPostcard;
  formSubmitted;
  state$;
  STATE_NAMES;
  addressForm;
  currentMessage;
  addressType = 'fromUser';
  savedReturnAddress = {};
  returnAddressForm;
  penmateId;
  user;
  shouldConfirmAddress = false;
  validationResult: Record<string, any> = {}
  confirmedAddress;
  currentMessageSub;
  
  constructor(
    private navParams: NavParams,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    public letterService: LetterService,
    private createMessageActions: CreateMessageActions,
    private myPenmateActions: MyPenmateActions,
    private store: Store<AppState>,
  ) {
    this.state$ = this.route.paramMap.pipe(map(() => window.history.state));
    this.currentMessage = this.navParams.get('currentMessage') || {};
    this.user = this.navParams.get('user') || {};
    this.penmate = this.navParams.get('penmate');
    this.STATE_NAMES = mapStates.map(m => m['abbreviation']);
    this.navCtrl = this.state$.navCtrl;
    this.currentMessageSub = this.store.select((state) => state.createMessage).subscribe((currentMessage) => {
      this.currentMessage = currentMessage
    })
    this.store
      .select(({ createMessage }) => ({ createMessage }))
      .take(1)
      .subscribe(async ({ createMessage: currentMessage }) => {
        const { defaultReturnAddress, returnAddress } = currentMessage;  
        let {
          firstName,
          lastName,
          name,
          address_line1,
          address_line2,
          address_city,
          address_state,
          address_country,
          address_zip,
        } = returnAddress;

        if ((!firstName || !lastName) && name && name.length >= 2) {
          const nameParts = new Namefully(name);
          firstName = nameParts.hasMiddle ? `${nameParts.first} ${nameParts.middle}` : nameParts.first
          lastName = nameParts.last
        } 

        const formattedStateName = address_state && address_state.length ? address_state.toUpperCase() : '';
        const isStateValid =  address_country === 'other' || formattedStateName.length && this.STATE_NAMES.includes(formattedStateName);
        const defaultStateName = isStateValid ? formattedStateName : undefined;
        this.returnAddressForm = this.formBuilder.group({
          email: [this.user.email, Validators.required],
          firstName: [firstName, Validators.required],
          lastName: [lastName, Validators.required],
          name: [name],
          address_line1: [address_line1, Validators.required],
          address_line2: [address_line2],
          address_city: [address_city, Validators.required],
          address_state: [defaultStateName, Validators.required],
          address_country: [address_country || 'US'],
          address_zip: [address_zip, Validators.required],
        });

        if (name || address_line1 || address_city || address_state || address_zip) {
          this.returnAddressForm.markAllAsTouched()
        }
    })
  }

  onConfirmAddress = (addressType) => {
    const validatedAddress = {
      addressType,
      ...(this.returnAddressForm.value || {}),
      ...(this.validationResult.validated_address || {}),
    }
    const address = addressType == 'suggested' ? validatedAddress : this.returnAddressForm.value;
    this.confirmedAddress = {
      addressType,
      ...address,
    };
    this.onSetReturnAddress(address)
  }

  onEditAddress = () => {
    this.shouldConfirmAddress = false;
  }

  onSetReturnAddress = (address) => {
    this.store.dispatch(
      this.createMessageActions.setMessage({
        returnAddress: {
          addressType: 'email',
          ...(address || {}),
          validated: true,
        },
      }),
    );
  }

  onSaveDraftAndClose() {
    this.store.dispatch(this.createMessageActions.saveDraft(this.currentMessage));
    this.onCancel()
  }

  onSaveAndContinue() {
    const isIntl = this.returnAddressForm.value['address_country'] === 'other'
    if (isIntl) {
      this.onSaveDraftAndClose();
      return;
    }
    if (
        this.confirmedAddress && 
        this.confirmedAddress.addressType &&
        this.currentMessage && get(this.currentMessage, 'returnAddress.validated')
      ) {
        this.onSaveDraftAndClose();
        return;
    }
    this.shouldConfirmAddress = false;
    const addressData = this.returnAddressForm.value;
    this.letterService.validateAddress(this.currentMessage.id, addressData).subscribe((res) => {
      this.validationResult = res;
      this.shouldConfirmAddress = !!res.needs_confirmation
      if (!this.shouldConfirmAddress) {
        this.onSetReturnAddress(addressData)
        this.onSaveDraftAndClose()
      }
    })
  }

  isFieldInvalid = (fieldName) => {
    const control = this.returnAddressForm.controls[fieldName];
    if (control) {
      return control.errors && control.touched
    }
    return false;
  }

  onCancel() {
    this.modalCtrl.dismiss(this.returnAddressForm.value);
    if (this.currentMessageSub && this.currentMessageSub.unsubscribe) {
      this.currentMessageSub.unsubscribe()
    }
  }

  onFocusZip = () => {
    if (this.scrollContent) {
      this.scrollContent.scrollToBottom();
    }
  };
}
