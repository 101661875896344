<ion-content>
  <div class="container">
    <div class="bg-circle left"></div>
    <div class="bg-circle right"></div>

    <ion-button (click)="onCancel()" class="close-button">
      <ion-icon name="close" class="close-icon"></ion-icon>
    </ion-button>

    <div class="inner" *ngIf="currentMessage">
      <div class="inner-content">
        <h1 
          class="title-header title"
          [ngClass]="{'payment-updated': !!currentMessage.sale.paid }"

        >
          <ion-icon name="information-circle"></ion-icon>
          &nbsp; 
          <span *ngIf="!currentMessage.sale.paid">Update Payment Info</span>
          <span *ngIf="!!currentMessage.sale.paid">Payment Updated.</span>

        </h1>
        <div class="details" *ngIf="!loading">
          <div *ngIf="!currentMessage.sale.paid">
            <p class="desc credits-enabled-msg text-center bold">
                There was an payment error when attempting to send your letter to {{penmate.name}}.
            </p>
            <p class="desc credits-enabled-msg text-center bold">
              Update your payment details below to continue sending your message.
            </p>
          </div>
          <div *ngIf="!!currentMessage.sale.paid" class="payment-updated">
            <p class="desc credits-enabled-msg text-center bold payment-details-updated">
              ✅&nbsp; Your payment details have been updated and your letter will now be processed.
            </p>
          </div>
        </div>
        <span
          *ngIf="loading"
          style="display:flex; align-items:center; justify-content:center;"
      >
        <ion-spinner name="lines-small"></ion-spinner>
      </span>
      </div>


    </div>
  </div>

</ion-content>

<ion-footer id="modal-footer" *ngIf="currentMessage">
  <div id="message-actions">
    <ion-grid>
      <ion-row>
        <ion-col 
          size="12" 
          class="continue" 
          [ngClass]="{'payment-updated': !!currentMessage.sale.paid }"
        >
          <ion-button
            class="pay-with-card-button"
            (click)="onSubmit()"
            *ngIf="currentMessage?.id"
          >
            <span *ngIf="!currentMessage.sale.paid">
              <ion-icon name="card" class="icon"></ion-icon>
              &nbsp;Update Payment Details
            </span>
            <span *ngIf="!!currentMessage.sale.paid">
              Continue
            </span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
