import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  NavController,
  NavParams,
  Platform,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
const s = require('underscore.string');
// import {
//   SelectedPhotosModal,
//   LoadingModal,
//   MessageTrackingModal,
//   MessageIndexPage,
//   LoginPage,
// } from '../pages';

import { MessageIndexPage } from '../message-index/message-index';
import { LoginPage } from '../login/login.page';
import { SelectedPhotosModal } from '../selected-photos-modal/selected-photos-modal';
import { LoadingModal } from '../loading-modal/loading-modal';
import { MessageTrackingModal } from '../message-tracking-modal/message-tracking-modal';
import { MessageDeliveryModal } from '../message-delivery-modal/message-delivery-modal';

import { MessageIncompleteModal } from '../message-incomplete-modal/message-incomplete-modal';

import { UserActions, MyPenmateActions } from '../actions';

import { AppState, AuthService, EventService, LetterService, NativeClientService } from '../services';
import { find, isNil } from 'lodash';

declare var Beacon;
declare var Penmate;

@Component({
  selector: 'pm-page-show-message',
  templateUrl: 'message-show.html',
  styleUrls: ['./message-show.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageShowPage implements OnInit {
  messageText;
  penmate;
  currentMessage;
  currentPostcard;
  selectedPhotosModal;
  selectedPhotos = [];
  popover;
  modal;
  showHelp = true;
  isReply;
  shouldRequestRating;
  user;
  addCreditCancel = false;
  showAddCredits = false;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    public myPenmateActions: MyPenmateActions,
    public route: ActivatedRoute,
    public userActions: UserActions,
    public authService: AuthService,
    public eventService: EventService,
    public letterService: LetterService,
    private platform: Platform,
    private store: Store<AppState>,
    private nativeService: NativeClientService,
  ) {
    platform.ready().then(() => {
      this.authService.authenticate().subscribe(({ isAuthenticated, user }) => {
        if (isAuthenticated) {
          this.store.dispatch(this.userActions.loginUser(user));
          const metrics = user.metrics || {}
          const canRequestRating = this.nativeService.active;
          if (canRequestRating && metrics.request_rating && !metrics.rating_requested) {
            this.nativeService.shouldRequestRating = true;
            this.shouldRequestRating = true;
          }
        } else {
          this.navCtrl.navigateForward('/login');
        }
      });
    });
  }

  ngOnInit() {
    this.currentMessage = this.route.snapshot.paramMap.get('currentMessage');
    this.isReply = this.route.snapshot.queryParams['emessage'] === 'true';
    this.addCreditCancel = this.route.snapshot.queryParams['addCreditCancel'] === 'true';
    this.store.dispatch(
      this.myPenmateActions.loadMessage({
        message_id: this.route.snapshot.paramMap.get('message_id'),
        emessage: this.isReply,
      }),
    );

    this.store
      .select((state) => state.myPenmates.currentPenmate)
      .subscribe((penmate) => {
        this.penmate = penmate;
        if (this.penmate && this.penmate.messages) {
          this.currentMessage = find(
            this.penmate.messages.filter((m) =>
              this.isReply ? m.message_type === 'emessage' : true,
            ),
            (m) => m.id === parseInt(this.route.snapshot.paramMap.get('message_id'), 10),
          );
          if (this.currentMessage) {
            this.currentPostcard = this.currentMessage.postcard;
            this.selectedPhotos = (this.isReply ? this.currentMessage.attachments : this.currentMessage.selectedPhotos) || [];
          }
        }
      });
    this.store
    .select((state) => state.user)
    .subscribe((user) => {
      this.user = user;
    })
  }


  ionViewDidEnter() {
    setTimeout(() => {
      Beacon('init', Penmate.env.helpScoutId);
    }, 800);
    
    if (this.currentMessage && this.currentMessage.needs_info) {
      this.onShowMessageIncomplete();
    }

    if (this.currentMessage && this.currentMessage.eMessage) {
      this.checkMessageCredits()
    }
  }

  checkMessageCredits() {
    if (this.currentMessage && this.currentMessage.eMessage) {
      const isProcessing = this.currentMessage.status === 'processing';
      const requiresCredits = !this.user.messageCreditBalance && this.user.messageCreditBalance <= 0
      const creditsEnabled = !!this.currentMessage.meta.creditsEnabled
      if (requiresCredits && isProcessing && creditsEnabled) {
        this.showAddCredits = true;
      }
    }
  }

  onAddCredits() {
    this.navCtrl.navigateForward(`/billing/add-credits?letterId=${this.currentMessage.id}&penmateId=${this.penmate.id}`);
  }

  onUpdatePayment() {
    this.navCtrl.navigateForward(`/billing/${this.currentMessage.id}/payment`);
  }

  onShowMessageIncomplete = async () => {
    this.modal = await this.modalCtrl.create({
      component: MessageIncompleteModal,
      componentProps: {
        navCtrl: this.navCtrl,
        penmate: this.penmate,
        currentMessage: this.currentMessage,
        showBackdrop: true,
      },
    });
    await this.modal.present();
  };

  onShowSelectedPhotos = async () => {
    if (this.selectedPhotos.length <= 0) {
      return;
    }
    this.selectedPhotosModal = await this.modalCtrl.create({
      component: SelectedPhotosModal,
      componentProps: {
        photos: this.selectedPhotos,
        showTogglePhoto: false,
      },
    });
    await this.selectedPhotosModal.present();
  };

  onDeleteMessage() {
    this.store.dispatch(
      this.myPenmateActions.deleteMessage({
        penmateId: this.penmate.id,
        message: this.currentMessage,
      }),
    );
    this.eventService.track('delete-message', { letter_id: this.currentMessage.id });
  }

  onShowDeliveryManager = async () => {

    this.store
      .select((state) => state.myPenmates.currentPenmate.messages)
      .take(1)
      .subscribe(async (messages) => {
        if (!messages) {
          return;
          // code...
        }
        this.currentMessage = find(
          messages,
          (m) => m.id === parseInt(this.route.snapshot.paramMap.get('message_id'), 10),
        );
        if (!this.currentMessage) {
          return;
        }

        this.modal = await this.modalCtrl.create({
          component: MessageDeliveryModal,
          cssClass: 'message-tracking-popover message-delivery-modal',
          showBackdrop: true,
          componentProps: {
            navCtrl: this.navCtrl,
            penmate: this.penmate,
            currentMessage: this.currentMessage,
            onShowTracking: this.onShowTrackingHistory,
            onEditAddress: this.onShowMessageIncomplete,
            showBackdrop: true,
          },
        });
        await this.modal.present();
      })
      .unsubscribe();
  };

  onShowTrackingHistory = async () => {
    this.eventService.track('show-message-tracking', { letter_id: this.currentMessage.id });

    this.store
      .select((state) => state.myPenmates.currentPenmate.messages)
      .take(1)
      .subscribe(async (messages) => {
        if (!messages) {
          return;
        }
        this.currentMessage = find(
          messages,
          (m) => m.id === parseInt(this.route.snapshot.paramMap.get('message_id'), 10),
        );
        if (!this.currentMessage) {
          return;
        }

        this.modal = await this.modalCtrl.create({
          component: MessageTrackingModal,
          cssClass: 'message-tracking-popover ',
          showBackdrop: true,
          componentProps: {
            navCtrl: this.navCtrl,
            penmate: this.penmate,
            currentMessage: this.currentMessage,
            showBackdrop: true,
          },
        });
        await this.modal.present();
      })
      .unsubscribe();
  };

  onGoBack = () => {
    Beacon('destroy');
    const sinceDate = moment('2023-06-18');
    const messageCreatedAt = moment(this.currentMessage.created_at);
    const isNewReply = this.currentMessage.created_at && messageCreatedAt.isAfter(sinceDate)
    if(this.isReply && isNewReply && this.shouldRequestRating) {
      this.eventService.track('rating-request-pending');
      const payload = {
        modalTitle: "Good News! You received a reply!",
        modalSubtitle: "How was your experience sending your message?",
        modalSubtitleStyle: {textAlign: 'center', paddingHorizontal: 45},
        helpBtnText: "I need help",
        rateBtnText: "It was great!"
      }
      setTimeout(() => {
        this.nativeService.sendMessage({ type: 'REQUEST_RATING', payload })
        this.shouldRequestRating = undefined;
        this.nativeService.shouldRequestRating = false;
      }, 500)
    }

    this.navCtrl.navigateForward(`/penmates/${this.penmate.id}/messages`);
  }
}
